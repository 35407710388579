import React, { useContext,useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

function GeneralSetting() {
    // const [formData, setFormData] = useState(initialFormData);
    const { endPointUrl, Token } = useContext(MyContext);
    const [settings, setSettings] = useState({
      });

      const generalsetting = async () => {
        try {
          const { data } = await axios.post(endPointUrl + "/fetchSettings", {
            Token,
          });

          setSettings(data.data[0]);
          console.log(settings);
        } catch {
          console.log('Fetching settings failed:', error);
        }
      };
      useEffect(() => {
        generalsetting();
      }, []);

      const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
             await axios.post(endPointUrl + '/updateSettings', {
              Token,
              ...settings,
            });
            message.success("Settings Updated Successfully");
            console.log('Settings updated successfully');
          } catch (error) {
            console.log('Updating settings failed:', error);
          }
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
          ...prevSettings,
          [name]: value,
        }));
      };
    
    
    return (
      <>
       <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h2">Update Settings</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleFormSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Percentage per Bid</label>
                        <Form.Control
                        name="Priceperbid"
                          value={settings?.Priceperbid}
                          placeholder="Price Per Bid"
                          type="number"
                          onChange={handleInputChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>
                          Email address
                        </label>
                        <Form.Control
                         name="Email"
                         value={settings?.Email}
                          placeholder="Email"
                          type="email"
                          onChange={handleInputChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Support</label>
                        <Form.Control
                         name="Supporti"
                         value={settings?.Supporti}
                          placeholder="Support"
                          type="text"
                          onChange={handleInputChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Support Number</label>
                        <Form.Control
                         name="Support_Number"
                         value={settings?.Support_Number}
                          placeholder="Support Number"
                          type="text"
                          onChange={handleInputChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    style={{backgroundColor:"#07b274"}}
                  >
                    Update Profile
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </>
    )
};

export default GeneralSetting;