import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import { BsArrowLeftShort } from "react-icons/bs"

import {
    Button,
    Card,
    Form,
    Container,
    Row,
    Col,
} from "react-bootstrap";

function ReviewAdd({ Type, handleCloseForm, UserAccType, freelancer }) {
    const { endPointUrl, Token } = useContext(MyContext);
    const [projectAccToUSer, setProjectAccToUSer] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [allProjects, setAllProjects] = useState([]);
    const [formData, setFormData] = useState({
        To_ID: '',
        From_Name: '',
        To_Name: '',
        Review: '',
        Stars: '',
        P_Name: '',
        Review_Date: ''
    });


    const handleUserSelect = (selectedOption) => {
        setSelectedUser(selectedOption);
        setFormData({ ...formData, To_ID: selectedOption.value, To_Name: selectedOption.label })
        // Assuming projectAccToUSer is your state setter function
        let filteredProjects
        if (Type === "Freelancer")
            filteredProjects = allProjects.filter(item => item.Awarded_To_ID === selectedOption.value);
        else
            filteredProjects = allProjects.filter(item => item.Awarded_By_ID === selectedOption.value);

        console.log('filteredProjects', filteredProjects)
        console.log('selectedOption', selectedOption)
        setProjectAccToUSer(filteredProjects);
    };

    const handleProjetselect = (selectedOption) => {
        setSelectedProject(selectedOption)
        setFormData({ ...formData, P_Name: selectedOption.label })
    }

    const addReviews = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(endPointUrl + "/addreview", {
                Token,
                ...formData
            });
            setFormData({
                To_ID: '',
                From_Name: '',
                To_Name: '',
                Review: '',
                Stars: '',
                Review_Date: '',
                P_Name: ''
            });
            setSelectedUser("")
            message.success("Review add sucessfully")
        } catch (error) {
            console.log("Error adding Review:", error);
        }
    }

    const getProjects = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/findAllProject", {
                Token,
            })
            if (freelancer) {
                const filteredProjects = data.data.filter(item => Type == "Freelancer" ? item.Awarded_To_ID : item.Awarded_By_ID === freelancer[0].id);
                setProjectAccToUSer(filteredProjects);
            }
            setAllProjects(data.data);
        } catch (err) {
            console.log("Error", err)
        }
    }

    useEffect(() => {
        getProjects();
        if (freelancer) {
            setFormData({ ...formData, To_ID: freelancer[0].id, To_Name: `${freelancer[0].Name}  ${freelancer[0].Last_Name}` })
            setSelectedUser({ value: freelancer[0].id, label: `${freelancer[0].Name}  ${freelancer[0].Last_Name}` })
        } else {
            setSelectedUser("")
        }
        setSelectedProject("")
    }, [Type]);

    const formattedOptions = UserAccType.map(user => ({ value: user.id, label: `${user.Name} ${user.Last_Name}` }));
    const formattedProjectOptions = projectAccToUSer.map(user => ({ value: projectAccToUSer.P_ID, label: user.Job_Title }))
    return (
        <>
            <Container className='addskilltest' fluid>
                <div className="container-fluid d-flex">
                    <span className="close profile"
                        onClick={() => handleCloseForm()}
                    >
                        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
                    </span>
                    <h6 className="heading-6">Add Review</h6>
                </div>
                <Row>
                    <Col md="12">
                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                            <Card.Header style={{ backgroundColor: '#07b274' }}>
                                <Card.Title as="h4">Fill Details</Card.Title>
                                <hr></hr>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={(e) => addReviews(e)}>
                                    <Row>
                                        <Col className="pr-1" md="6">
                                            <Form.Group>
                                                <label htmlFor="name">From Name</label>
                                                <Form.Control
                                                    placeholder="Enter name"
                                                    type="text"
                                                    name="name"
                                                    value={formData.From_Name}
                                                    onChange={(e) => setFormData({ ...formData, From_Name: e.target.value })}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pr-1" md="6">
                                            <Form.Group>
                                                <label>{Type} Name</label>
                                                <Selectant
                                                    name="Skills"
                                                    options={formattedOptions}
                                                    onChange={handleUserSelect}
                                                    value={selectedUser}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="6">
                                            <Form.Group>
                                                <label htmlFor="date">Published Date</label>
                                                <Form.Control
                                                    placeholder="Date"
                                                    type="date"
                                                    name="date"
                                                    value={formData.Review_Date}
                                                    onChange={(e) => setFormData({ ...formData, Review_Date: e.target.value })}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pr-1" md="6">
                                            <Form.Group>
                                                <label htmlFor="rating">Rating</label>
                                                <Form.Control
                                                    placeholder="Rating above 5"
                                                    type="number"
                                                    name="rating"
                                                    value={formData.Stars}
                                                    step="0.5"
                                                    min="0"
                                                    max="5"
                                                    onChange={(e) => {
                                                        const newValue = parseFloat(e.target.value);
                                                        console.log(newValue)
                                                        if (isNaN(newValue)) {
                                                            console.log("Enter")
                                                            setFormData({ ...formData, Stars: "" });
                                                        }
                                                        else if (newValue >= 0 && newValue <= 5) {
                                                            setFormData({ ...formData, Stars: newValue });
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="12">
                                            <Form.Group>
                                                <label>{selectedUser.label} Project</label>
                                                <Selectant
                                                    name="Project"
                                                    options={projectAccToUSer.map(user => ({ value: projectAccToUSer.P_ID, label: user?.jobs?.Title }))}
                                                    onChange={handleProjetselect}
                                                    value={selectedProject}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col className="pr-1 w-full" md="12">
                                            <Form.Group>
                                                <label htmlFor="review">Review</label>
                                                <Form.Control
                                                    placeholder="Enter your review here"
                                                    as="textarea"
                                                    name="review"
                                                    value={formData.Review}
                                                    onChange={(e) => setFormData({ ...formData, Review: e.target.value })}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            className="btn-fill pull-right"
                                            type="submit"
                                            variant="info"
                                            style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default ReviewAdd;