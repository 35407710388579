import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import MyContext from "MyContext";
import moment from "moment";
// import { useNavigate } from "react-router";
import Profile from '../assets/img/default-avatar.png'
import Master from "./Masterss";
import { message } from "antd";


function BiddingUserInfo({ item }) {
  const { Token, endPointUrl, user } = useContext(MyContext);
  const [showmodel, setshowModel] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [bidder, setbidder] = useState({});
  const [Count, setCount] = useState(0);
  const [milestone, setmilestone] = useState([]);
  const [invited, setInvited] = useState([]);

  // const Navigate = useNavigate();
  const getuserData = async (id) => {
    const { data } = await axios.post(endPointUrl + "/getUserById", {
      Token,
      id,
    });
    setbidder(data.data);

    const result = await axios.post(endPointUrl + "/user/getMilestoneforBid", {
      Token,
      Bid_ID: item.Bid_ID,
    });
    if (result.data.milestones.length !== 0) {
      setmilestone(result.data.milestones);
    } else {
      const result = await axios.post(endPointUrl + "/project/getMilestoneforBid", {
        Token,
        Bid_ID: item.Bid_ID,
      });
      setmilestone(result.data.milestones);
    }
    // console.log(result.data.milestones);

  };
  const getCount = () => {
    for (let index = 0; index < milestone.length; index++) {
      const item = milestone[index];
      console.log(milestone);
      if (item.MilstoneStatus === "Up for Review") {
        setCount((Count) => Count + 1);
      }
    }
  };
  useEffect(() => {
    setCount((Count) => Count = 0)
    getCount()
  }, [milestone]);

  const handleExpModel = async () => {
    if (item.View_Status === false) {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, View_Status: true });
    }
    setshowModel(true);
    document.body.classList.add("modal-open");
  };

  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");
  };

  function extractFirst100Words(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    const textContent = tempElement.textContent || tempElement.innerText || "";
    const words = textContent.trim().split(/\s+/).slice(0, 30);
    const first100Words = words.join(" ");

    return first100Words;
  }
  // const updateAppprovedReject=async(status)=>{
  //   try{
  //     await axios.post(endPointUrl+"/user/UpdateJobs",{Token,JobID:item.JobID,Status:status})
  //   }catch{
  //     message.error('error in updating status')
  //   }
  // }
  const handleApprovedReject = async (status) => {
    try {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, Bid_Status: status })
      message.success(`Bid ${status} successfully`)
      CloseModel();
    } catch {
      message.error("Some occur on Approved/Rejection");
    }
    window.location.reload(false);

  }

  const getInvitationForJob = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/getInvitationByJobID", { JobID: item.JobID, Token })
      if (data.status) {
        const value = data.data.map((item) => {
          return item['User.id']
        })

        setInvited(value);
      }
    } catch (error) {
      console.log("Error while check bidder is invited or not ", error);
    }
  }

  const checkInvited = (id) => {
    if (invited.length > 0) {
      return invited.includes(id)
    }
  }

  useEffect(() => {
    getuserData(item.UserID);
    getInvitationForJob();
    // console.log(item.Bid_ID);
  }, []);

  const New = item.View_Status === false;
  const Review = item.Bid_Status === "Submitted" && item.View_Status;
  const Approved = item.Bid_Status === "Approved" && item.View_Status;
  const Rejected = item.Bid_Status === "Reject" && item.View_Status;

  return (
    <div>
      {/* { Show ?  */}
      <>
        <div
          className="current-jobs"
          style={{ cursor: "pointer", marginTop: '0px' }}
          onClick={() => handleExpModel()}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                src={
                  bidder?.Photo === "" || bidder?.Photo == null
                    ? Profile
                    : `${endPointUrl}/images/${bidder?.Photo}`
                }
                alt="" />
              <div>
                <span
                  className="job-name"
                  // onClick={() => {
                  //   setdetailview(!detailview);
                  //   setdetailjob(item);
                  //   window.scrollTo(0, 0);
                  // }}
                  style={{
                    // fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  {bidder.Name}
                </span>
                <div className="current-tags">
                  {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                    if (index < 5) {
                      return val.length > 0 ? <span>{val}</span> : null
                    }
                  })}
                  {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                  {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

                </div>
              </div>
            </div>
            <p>{extractFirst100Words(item.Proposal)}...</p>

            {/* <div id="keywords">
                        {(item?.Keywords ? item.Keywords.split(",") : []).map(
                          (skill) => {
                            return skill.length > 0 ? (
                              <span> #{skill}</span>
                            ) : null;
                          }
                        )}
                      </div> */}
          </div>
          <div>
            <div>
              <div className="avg-bite">
                <strong>$ {item.BidAmount}</strong>
              </div>
              <span className="total-bits"> {milestone.length} milestone</span>
              <div className="total-bits">{moment(item.Bid_date).format("DD-MM-YYYY")}</div>
              <div style={{ color: "#07b273" }}>{checkInvited(bidder.id) ? "Invited" : ""}</div>
            </div>
          </div>
        </div>
        <div id="myModal"
          className={showmodel ? "model-open" : "model"}
          style={{ overflow: "auto" }}
        >
          <div className="modal-content" style={{ color: "black" }}>
            <div className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
              &times;
            </div>

            <div className="biddiv">
              {/* </div> */}
              <div className="job-name" style={{ flex: '5', fontWeight: '600', fontSize: '30px', padding: "30px" }}>

                <img
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  src={
                    bidder?.Photo === "" || bidder?.Photo == null
                      ? Profile
                      : `${endPointUrl}/images/${bidder?.Photo}`
                  }
                  alt="" />
                <span>{bidder.Name}</span>

                <div className="current-tags">
                  {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                    if (index < 3) {
                      return val.length > 0 ? <span>{val}</span> : null
                    }
                  })}
                  {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 3 ? "+" : ""}
                  {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 3 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 3 : ""}

                </div>
              </div>
              <div className="biddingInformation" style={{ flex: '4' }}>
                <div id="bidheadStatus">
                  {New && (
                    <div>
                      <p>New</p>
                    </div>
                  )}
                  {Review && (
                    <div>
                      <p>Reviewed</p>
                    </div>
                  )}
                  {Approved && (
                    <div>
                      <p>Approved</p>
                    </div>
                  )}
                  {Rejected && (
                    <div>
                      <p>Rejected</p>
                    </div>
                  )}
                </div>
                <div>
                  <span className="bidhead">Price</span>
                  <span id="bidheadValue">${item.BidAmount}</span>
                </div>
                <div>
                  {/* <span className="bidhead">Duration</span>
                  <span id="bidheadDuration">
                    {item.Duration + "/"}
                    {item.DurationType}
                  </span> */}
                  {item.Duration != 0 ? <div>
                    <span className="bidhead">Duration</span><br />
                    <span id="bidheadValue">
                      {item.Duration} {item?.DurationType === "Monthly" ? "Month" : item?.DurationType === "Weekly" ? "Week" : "Hour"}
                    </span>
                  </div> :
                    <>
                      <div>
                        <span className="bidhead">Payment Type </span>
                        <span id="bidheadValue" style={{marginLeft:"5px",fontSize:"20px"}}>
                          {item?.DurationType === "Monthly" ? "Monthly" : item?.DurationType === "Weekly" ? "Weekly" : "Hourly"}
                        </span>
                      </div>
                      <div>
                        <span className="bidhead">Work Hours </span>
                        <span id="bidheadValue" style={{ fontSize: '20px', fontWeight: "bold",marginLeft:"5px" }}>
                          {item.MinHours}-{item.MaxHours} hrs / Week
                          {/* {item.MinHours}-{item.MaxHours}hrs /{item?.DurationType === "Monthly" ? "Month" : item?.DurationType === "Weekly" ? "Week" : "Hour"} */}
                        </span>
                      </div>
                    </>}
                </div>
              </div>


            </div>

            <b style={{ marginTop: "10px" }}>Proposal</b>
            <span dangerouslySetInnerHTML={{ __html: item.Proposal }} className="biddingProposal"></span>
            <Master milestone={milestone} />
          </div>
        </div>
      </>



    </div>
  );
}

export default BiddingUserInfo;
