
import React, { useState, useContext, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'bootstrap/dist/css/bootstrap.min.css';
import '.././assets/css/testimonial.css';
import axios from "axios";
import MyContext from "../MyContext";
import PaginationSection from './PaginationSection';
import Switch from 'react-switch'; // Import the react-switch component
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import {
    Card,
    Table,
    Button,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { Description } from "@mui/icons-material";

function GuideDigilanxer() {
    const [formData, setFormData] = useState({
        Guide_Title: '',
        Guide_ShortDec: '',
        Description: '',
    });
    const [image, setImage] = useState(null);
    const { endPointUrl, Token } = useContext(MyContext);
    const [guidedigilancer, setGuidedigilancer] = useState([]);
    const [showForm, setShowForm] = useState("");
    const [isPublished, setIsPublished] = useState(false); // State to handle the published status


    const handleshowform = async (value) => {
        setFormData({
            Guide_Title: '',
            Guide_ShortDec: '',
            Description: '',
        });
        setShowForm(value);
        setIsPublished(false)
    }

    const getGuidedigilancer = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/guidedatafind", { Token });
            if (data) {
                setGuidedigilancer(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const adddata = async (e) => {
        e.preventDefault();
        console.log("IMAGE", image)
        const formdata = new FormData();
        formdata.append("photo", image);
        try {
            const { data } = await axios.post(
                endPointUrl + "/user/imageUpload",
                formdata
            );

            const response = await axios.post(endPointUrl + "/admin/guidedataCreate", {
                Token,
                ...formData,
                Guide_Image: data.fileName
            });
            if (response.data.data) {
                setGuidedigilancer([...guidedigilancer, response.data.data]);
                setFormData({
                    Guide_Title: '',
                    Guide_ShortDec: '',
                    Description: '',
                });


                // setIsPublished(false);
            } else {
                // Handle error if needed
                console.log("Error adding data:", response.data.message);
            }
        } catch (error) {
            console.log("Error adding data:", error);
        }
    }

    const getGuideDataById = async (GId) => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/getGuideDataById", { Token, GId });
            if (data) {
                setShowForm("Update");
                // Store the data in formData
                setFormData(data.data);
            }
        } catch (err) {
            console.error("Error while handling edit model:", err);
        }
    };


    const editdata = async (e, GId) => {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append("photo", image);
        console.log(image)
        try {
            let responseimg;
            if (image) {
                console.log(image)
                responseimg = await axios.post(
                    endPointUrl + "/user/imageUpload",
                    formdata
                );
            }

            const response = await axios.post(endPointUrl + "/admin/guidedataUpdate", {
                Token,
                ...formData,
                Guide_Image: responseimg?.data?.fileName
            });
            if (response.data.data) {
                // Update the state with the edited data
                setGuidedigilancer((prevGuidedigilancer) => {
                    const updatedGuidedigilancer = prevGuidedigilancer.map((guide) => {
                        if (guide.GID == GId) {
                            // Update the data data with formData
                            // guide.Guide_Image = data.fileName;
                            // return formData;
                            return {
                                ...guide,
                                ...formData,
                                Guide_Image: responseimg?.data?.fileName || guide.Guide_Image // Update image if available
                            };
                        } else {
                            return guide;
                        }
                    });
                    return updatedGuidedigilancer;
                });
                setShowForm("");
                // Clear the form or perform other necessary actions
                setFormData({
                    Guide_Title: '',
                    Guide_ShortDec: '',
                    Description: '',
                });
            } else {
                // Handle error if needed
                console.log("Error editing data:", response.data.message);
            }
        } catch (error) {
            console.log("Error editing data:", error);
        }
    }



    const deletedata = async (e, GID) => {
        e.preventDefault();
        try {
            // Make an API call to delete the data
            const response = await axios.post(endPointUrl + "/admin/guidedataDelete", { Token, GID });
            if (response.data && response.data.data) {
                // Filter out the deleted data from the state
                setGuidedigilancer((prevGuidedigilancer) =>
                    prevGuidedigilancer.filter((guide) => guide.GID !== GID)
                );
            } else {
                console.error("Error deleting data:", response.data.message);
            }
            setFormData({
                Guide_Title: '',
                Guide_ShortDec: '',
                Description: '',
            });
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const onEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData({ ...formData, Description: data });
    };


    const data = React.useMemo(() => guidedigilancer, [guidedigilancer]);
    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: ' ',
                Cell: tableProps => (
                    <div><img
                        src={tableProps.row.original.Guide_Image === "" || tableProps.row.original.Guide_Image == null
                            ? null
                            : `${endPointUrl}/images/${tableProps.row.original.Guide_Image}`}
                        // src={`${endPointUrl}/images/${tableProps.row.original.Photo}`}
                        width={40}
                        height={40}
                        style={{ borderRadius: "50%" }}
                    /></div>
                )
            },
            {
                Header: "Title",
                accessor: "Guide_Title",
                width: "10%"
            },
            {
                Header: "ShortDec",
                accessor: (row) => (row.Guide_ShortDec?.length > 250 ? row.Guide_ShortDec.slice(0, 250) + "..." : row.Guide_ShortDec),
                width: "30%",
            },
            {
                Header: "Description",
                accessor: (row) => (row.Description?.length > 250 ? row.Description.slice(0, 250) + "..." : row.Description),
                Cell: ({ row }) => (
                    <div dangerouslySetInnerHTML={{ __html: row.original.Description?.length > 250 ? row.original.Description.slice(0, 250) + "..." : row.original.Description }} />
                ),
                width: "50%",
            },
            {
                Header: "Action",
                accessor: "GlobalReview_ID",
                Cell: ({ row }) => (
                    <div style={{ display: "flex" }}>
                        <span
                            // onClick={() => editdata(row.original.GlobalReview_ID)}
                            onClick={() => getGuideDataById(row.original.GID)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
                        </span>
                        {/* "Delete" icon */}
                        <span
                            onClick={(e) => deletedata(e, row.original.GID)}
                            style={{ cursor: "pointer" }}
                        >
                            <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                        </span>
                    </div>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        getGuidedigilancer();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page: tablePage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <Container fluid>
            <Row>
                <h1 style={{ width: "100%" }}>Digilanxer Guide</h1>
                <Col md="12">
                    {showForm === "Add" ?
                        <>
                            <Container className='addskilltest' fluid>
                                <div className="container-fluid d-flex">
                                    <span className="close profile" onClick={() => setShowForm("")}>
                                        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
                                    </span>
                                    <h6 className="heading-6">Add Data</h6>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                                            <Card.Header style={{ backgroundColor: '#07b274' }}>
                                                <Card.Title as="h4">Fill Details</Card.Title>
                                                <hr></hr>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form onSubmit={(e) => {
                                                    adddata(e)
                                                }
                                                }
                                                >
                                                    <Row>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label htmlFor="name">Title</label>
                                                                <Form.Control
                                                                    placeholder="Enter name"
                                                                    type="text"
                                                                    name="name"
                                                                    value={formData.Guide_Title}
                                                                    onChange={(e) => setFormData({ ...formData, Guide_Title: e.target.value })}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label htmlFor="date">ShortDec</label>
                                                                <Form.Control
                                                                    placeholder="ShortDec"
                                                                    type="text"
                                                                    name="shortDec"
                                                                    value={formData.Guide_ShortDec}
                                                                    onChange={(e) => setFormData({ ...formData, Guide_ShortDec: e.target.value })}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1 w-full" md="12">
                                                            <Form.Group>
                                                                <label htmlFor="description">Description</label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={formData.Description} // Set CKEditor data to formData's Description field
                                                                    onReady={(editor) => {
                                                                        // You can store the "editor" and use it when needed.
                                                                        console.log('Editor is ready to use!', editor);
                                                                    }}
                                                                    onChange={onEditorChange}
                                                                    onBlur={(event, editor) => {
                                                                        console.log('Blur.', editor);
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        console.log('Focus.', editor);
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label htmlFor="img">Thumbaile</label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="img"
                                                                    onChange={(e) => setImage(e.target.files[0])}
                                                                // onChange={(e) => setFormData({ ...formData, Guide_Image: e.target.value })}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button
                                                            className="btn-fill pull-right"
                                                            type="submit"
                                                            variant="info"
                                                            style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        showForm === "Update" ?
                            <>

                                <Container className='addskilltest' fluid>

                                    <div className="container-fluid d-flex">
                                        <span className="close profile " onClick={() => setShowForm("")}>
                                            <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
                                        </span>
                                        <h6 className="heading-6">Update Data</h6>
                                    </div>
                                    <Row>
                                        <Col md="12">
                                            <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                                                <Card.Header style={{ backgroundColor: '#07b274' }}>
                                                    <Card.Title as="h4">Fill Details</Card.Title>
                                                    <hr></hr>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form onSubmit={(e) => editdata(e, formData.GID)}>
                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label htmlFor="name">Title</label>
                                                                    <Form.Control
                                                                        placeholder="Enter name"
                                                                        type="text"
                                                                        name="name"
                                                                        value={formData.Guide_Title}
                                                                        onChange={(e) => setFormData({ ...formData, Guide_Title: e.target.value })}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label htmlFor="date">ShortDec</label>
                                                                    <Form.Control
                                                                        placeholder="ShortDec"
                                                                        type="text"
                                                                        name="shortDec"
                                                                        value={formData.Guide_ShortDec}
                                                                        onChange={(e) => setFormData({ ...formData, Guide_ShortDec: e.target.value })}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1 w-full" md="12">
                                                                <Form.Group>
                                                                    <label htmlFor="description">Description</label>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={formData.Description} // Set CKEditor data to formData's Description field
                                                                        onReady={(editor) => {
                                                                            // You can store the "editor" and use it when needed.
                                                                            console.log('Editor is ready to use!', editor);
                                                                        }}
                                                                        onChange={onEditorChange}
                                                                        onBlur={(event, editor) => {
                                                                            console.log('Blur.', editor);
                                                                        }}
                                                                        onFocus={(event, editor) => {
                                                                            console.log('Focus.', editor);
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label htmlFor="img">Thumbaile</label>
                                                                    <Form.Control
                                                                        type="file"
                                                                        name="img"
                                                                        onChange={(e) => setImage(e.target.files[0])}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button
                                                                className="btn-fill pull-right"
                                                                type="submit"
                                                                variant="info"
                                                                style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                            :
                            <Card className="stripped-tabled-with-hover"> {/* Correct the class name */}
                                {/* <div></div> */}
                                <div>
                                    <Button className="btn btn-lg mr-3 float-right p-2" onClick={() => handleshowform("Add")}>Add Data</Button>
                                </div>
                                <Card.Header style={{ paddingTop: "5px" }}>
                                    <Card.Title as="h4">
                                        <Form className="d-flex">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                className="mx-2"
                                                aria-label="Search"
                                                value={globalFilter || ''}
                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                            />
                                        </Form>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <Table className="table-hover table-striped" {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps(), {
                                                            style: { width: column.width },
                                                        })}>
                                                            {column.render("Header")}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? " 🔽"
                                                                        : " 🔼"
                                                                    : ""}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                            {tablePage.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell) => (
                                                            <td {...cell.getCellProps({
                                                                style: {
                                                                    width: cell.column.width,
                                                                },
                                                            })}>{cell.render("Cell")}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <PaginationSection
                                    pageIndex={pageIndex}
                                    pageCount={pageOptions.length}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pageOptions={pageOptions}
                                />
                            </Card>
                    }

                </Col>
            </Row>
        </Container>
    );
}

export default GuideDigilanxer;

