import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MyContext from "MyContext"
import {Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import EmployerInvoices from "./EmployerInvoices";
import FreelancerSubsInvoices from "./FreelancerSubsInvoices";
import SkillTestInvoices from "./SkillTestInvoices";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";

function Invoices() {
  const history = useHistory();
  const [activeCard, setActiveCard] = useState("ongoing");
  const [showEmployerInvoice, setShowEmployerInvoice] = useState(true);
  const [showFreelancerInvoice, setShowFreelancerInvoice] = useState(false);
  const [showSkillTestInvoice, setshowSkillTestInvoice] = useState(false);



  const navigateToEmployerInvoice = () => {
    // history.push("/admin/Published-Jobs"); 
    setShowEmployerInvoice(true);
    setShowFreelancerInvoice(false);
    // setActiveCard("ongoing");
    setshowSkillTestInvoice(false);
  };
  const navigateToFreelancerInvoice = () => {
    // history.push("/admin/Draft-Jobs"); 
    setShowFreelancerInvoice(true);
    setShowEmployerInvoice(false);
    // setActiveCard("closed");
    setshowSkillTestInvoice(false);

  };
  const navigateToSkillTestInvoice = () => {

    setshowSkillTestInvoice(true);
    setShowEmployerInvoice(false);
    setShowFreelancerInvoice(false);
  }

  const navigateToFinance = () => {
    history.push("/admin/Finance");
  };

  return (
    <>
      <Container fluid>
        <div className="close profile" onClick={navigateToFinance} >
          <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 20px 0" }} />
        </div>

        <Row>
          <Col lg="6" sm="12">
            <Card className={`card-stats ${activeCard === "ongoing" ? "active" : ""}`} onClick={navigateToEmployerInvoice} >
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Employer Payment Invoices</p>
                      <Card.Title as="h3"></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col lg="6" sm="12">
            <Card className={`card-stats ${activeCard === "closed" ? "active" : ""}`} onClick={navigateToFreelancerInvoice} >
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Freelancer Subscription Invoices</p>
                      <Card.Title as="h3"></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className={`card-stats ${activeCard === "closed" ? "active" : ""}`} onClick={navigateToSkillTestInvoice} >
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Freelancer SkillTest Invoices</p>
                      <Card.Title as="h3"></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
      <Container fluid className="projecttable">
        {showEmployerInvoice && <EmployerInvoices />}

        {showFreelancerInvoice && <FreelancerSubsInvoices />}
        {showSkillTestInvoice && <SkillTestInvoices></SkillTestInvoices>}

      </Container>
    </>
  );
}

export default Invoices;
