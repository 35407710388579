import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import Profile from "assets/img/default-avatar.png";
import ClosedProjects from "./FreelancerClosedProjects";
import OngoingProjects from "./FreelancerOngoingProjects";
import BiddedOpenJobs from "./FreelanceropenBidJobs";
import BiddedClosedJobs from "./FreelancerclosedBidJobs";
import edit from "assets/img/edit1.jpg";
import { IoMdClose } from "react-icons/io";
import "assets/css/demo.css"
import { BsArrowLeftShort } from "react-icons/bs"
import AddFreelancer from "./Userform";
import MyContext from "MyContext";
import ReviewAdd from "./ReviewAdd";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Education from "./Education";
import WorkExp from "./WorkExp";
import Portfolio from "./Portfolio";
import Certificate from "./Certificate";
import { useHistory } from "react-router-dom";
import AllBids from "./AllBids";
import AddBid from "./AddBid";
import certified from "../assets/img/certified.svg";

function FreelancerDetails() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [password, setPassword] = useState({ newPassword: "", conformPassword: "" })
  const [updatePassword, setUpdatePassword] = useState(false);
  const [BidBalance, setBidBalance] = useState(false);
  const [freelancer, setfreelancer] = useState([]);
  const [activeproject, setActiveproject] = useState(0);
  const [activejob, setActivejob] = useState(0);
  const [finance, setfinance] = useState([]);
  const [showpass, setshowpass] = useState([false, false]);
  const [reviewForm, setReviewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedFreelancer, setSelectedFreelancer] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const Id = location.pathname.split('/').pop();
  const [freelancerStatus, setFreelancerStatus] = useState(true); // Initialize with null or the default status value
  const [showfeatured, setShowfeatured] = useState(false);
  const [takenTest, setTakenTest] = useState([]);
  const [bidTab, setBidTab] = useState(0);
  // const [formData, setFormData] = useState({});

  const getTests = async (Id) => {
    const { data } = await axios.post(endPointUrl + '/getSkilltestforfreelancer', {
      Token,
      id: Id,
    })
    setTakenTest(data?.assesment);
  };

  const handleEditClick = (freelancer) => {
    setSelectedFreelancer(freelancer);
    setShowEditForm(true);
  };

  const handleEditFormClose = () => {
    setSelectedFreelancer(null);
    setShowEditForm(false);
  };

  const getFreelancers = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/getUserById", {
        Token,
        id: Id,
      });

      if (data && data.data) {
        // Convert the single object to an array with one element
        const freelancerArray = [data.data];
        setfreelancer(freelancerArray);
        console.log(freelancer[0].Bid_Balance)
        setFreelancerStatus(data.data.Status_by_Admin); // Assuming "Status" is a boolean value
        if (data.data.featured) {
          setShowfeatured(true);
        }
      } else {
        console.log("No data received from the server.");
      }
      // console.log(data.data);
    } catch {
      console.log("get Freelancer details is not working");
    }
  };

  const getFreelancerfinance = async (Id) => {
    console.log(Id)
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getfreelancerfinance", {
        Token,
        Awarded_To_ID: Id,
      });
      setfinance(data.data[0])

      // console.log(data.data[0]);
      // console.log(data.data[0].totalEarningsSum);
    } catch {
      console.log("get Freelancer details is not working");
    }
  };

  useEffect(() => {
    getFreelancers(Id);
    getFreelancerfinance(Id);
    getTests(Id)

  }, []);

  const handleprojectTabchange = (tab) => {
    setActiveproject(tab);
  }
  const handlejobTabchange = (tab) => {
    setActivejob(tab);
  }

  const handleStatusToggle = async (freelancerId, newStatusValue) => {
    // console.log("Toggle called for freelancer ID:", freelancerId);
    try {
      await axios.post(endPointUrl + "/updateuserProfile", {
        Token,
        id: freelancerId,
        Status_by_Admin: newStatusValue,
      });

      setFreelancerStatus(newStatusValue);

    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const navigateToFinance = () => {
    history.push("/admin/Freelancers");
  };
  const handleCloseForm = () => {
    setReviewForm(false)
  }
  const handlepasswordUpdate = async (e) => {


    e.preventDefault()
    if (password.newPassword !== password.conformPassword) {
      message.warning("Password Not Match")
    } else {
      const { data } = await axios.post(endPointUrl + "/user/forgotPassword", {
        Token,
        Email: freelancer[0].Email,
        Password: password.newPassword
      })
      if (data.data) {
        message.success("Password Update");
      }
    }
  }


  const handleBidBalanceUpdate = async (e, freelancerId) => {
    console.log("freelancerid:", freelancerId);
    //  let idd=freelancerId.id;

    e.preventDefault();

    const newBidBalance = parseFloat(e.target.elements[0].value);
    if (isNaN(newBidBalance) || newBidBalance < 0) {

      alert("Invalid bid balance. Please enter a positive number.");
      return;
    }

    const { data } = await axios.post(
      endPointUrl + "/updateuserProfile",
      {
        Token,
        id: freelancerId,
        Bid_Balance: newBidBalance,
      })

    if (data.status) {
      message.success("Bid Balance updated succesfully")
    }
    else {
      message.error("Something Went Wrong")
    }
  }




  function getLanguage(item) {
    let languages;
    try {
      languages = JSON.parse(item);
    } catch (error) {
      return (<span>{item?.Language}</span>);
    }

    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  }

  return (
    <>
      {updatePassword ?
        <div className="position-fixed d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%', zIndex: 5, top: 0, left: 0, backgroundColor: "#00000070" }}>
          <IoMdClose className="text-white position-absolute" style={{ top: "5px", right: "10px", borderRadius: "50%", fontSize: "1.5rem" }} onClick={() => {
            setPassword({ newPassword: "", conformPassword: "" })
            setUpdatePassword(false)
          }
          } />
          <div className="bg-white p-5 rounded">
            <form style={{ position: "relative" }} onSubmit={(e) => handlepasswordUpdate(e)}>
              <p className="text-center">Update Password</p>
              <input type={`${showpass[0] ? "text" : "password"}`} value={password.newPassword} placeholder="Enter New Password" onChange={(e) => setPassword({ ...password, newPassword: e.target.value })} className="outline-none border-bottom" style={{ width: "100%" }} />
              <span style={{ position: "absolute", right: "10px" }} onClick={() => setshowpass({ ...showpass, 0: !showpass[0] })}>
                {showpass[0] ? "hide" : "show"}
              </span>
              <input type={`${showpass[1] ? "text" : "password"}`} value={password.conformPassword} placeholder="Confirm Password" onChange={(e) => setPassword({ ...password, conformPassword: e.target.value })} className="outline-none border-bottom" style={{ width: "100%" }} />
              <span style={{ position: "absolute", right: "10px" }} onClick={() => setshowpass({ ...showpass, 1: !showpass[1] })}>
                {showpass[1] ? "hide" : "show"}
              </span>
              <button type="submit" className="btn btn-secondary">Submit</button>
            </form>
          </div>
        </div> : null}
      {reviewForm ?
        <ReviewAdd
          UserAccType={freelancer}
          Type={"Freelancer"}
          handleCloseForm={handleCloseForm}
          freelancer={freelancer}
        />
        :


        <>
          {!showEditForm ? (
            <>
              <span className="container-fluid d-flex justify-content-start">
                <span className="close profile" onClick={navigateToFinance}>
                  <BsArrowLeftShort style={{ fill: "#07b274", margin: "-20px 10px 0 -10px" }} />
                </span>
                <h6 className="heading-6" style={{ margin: "-4px 0 0 20px" }}>Freelancer Details</h6>
              </span>
              <div>
                <Row className="modalContainer">
                  {freelancer.map((item) =>
                    <>
                      <Col md="8" style={{ paddingRight: "10px", paddingLeft: "25px" }}>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="about">
                            <strong style={{ display: "flex", alignItems: "center" }}>ABOUT</strong>
                            <p>{item?.About}</p>
                          </div>
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>

                          <Education ID={item?.id} />


                          {/* </div> */}
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>

                          <WorkExp ID={item?.id} />


                          {/* </div> */}
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>

                          <Portfolio ID={item?.id} />

                          {/* </div> */}
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>

                          <Certificate ID={item?.id} />

                          {/* </div> */}
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="projects">

                            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>PROJECTS</strong>

                            <div>
                              <div className="jobMatch" style={{ marginBottom: "10px" }}>
                                <span style={
                                  activeproject === 0
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => handleprojectTabchange(0)}>

                                  In Progress
                                </span>

                                <span style={
                                  activeproject === 1
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => handleprojectTabchange(1)}>     Closed
                                </span>

                              </div>
                              {activeproject === 0 && <OngoingProjects ID={item?.id} />}
                              {activeproject === 1 && <ClosedProjects ID={item?.id} />}
                            </div>
                          </div>
                        </Card>
                        {/* <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>

                          <div className="projects">
                            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>JOBS</strong>

                            <div>
                              <div className="jobMatch" style={{ marginBottom: "10px" }}>
                                <span style={
                                  activejob === 0
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => handlejobTabchange(0)}>

                                  Open Jobs
                                </span>

                                <span style={
                                  activejob === 1
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => handlejobTabchange(1)}>     Closed Jobs
                                </span>

                              </div>
                              {activejob === 0 && <BiddedOpenJobs ID={item?.id} />}
                              {activejob === 1 && <BiddedClosedJobs ID={item?.id} />}
                            </div>

                          </div>
                        </Card>
 */}

                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="projects">
                            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>BIDS</strong>

                            <div>
                              <div className="jobMatch" style={{ marginBottom: "10px" }}>
                                <span style={
                                  bidTab === 0
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => setBidTab(0)}>

                                  All Bids
                                </span>


                                <span style={
                                  bidTab === 1
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => setBidTab(1)}>Add Bid
                                </span>

                              </div>
                              {bidTab === 0 && <AllBids ID={item?.id} />}
                              {bidTab === 1 && <AddBid ID={item?.id} FreelancerID={Id} />}
                            </div>

                          </div>



                        </Card>

                      </Col>

                      <Col md="4" style={{ marginTop: "15px" }}>
                        <div className="compo2">

                          <div className="profileConatiner">

                            <div className="profile-details">
                              <div className="editimg" >
                                <img src={edit} alt="" onClick={() => handleEditClick(item)} />
                              </div>
                              <img
                                src={
                                  item?.Photo === "" || item?.Photo == null
                                    ? Profile
                                    : `${endPointUrl}/images/${item?.Photo}`
                                }
                                alt=""
                              // style={{width:'20%',display:"flex"}}
                              />
                              {showfeatured ? (
                                <div className="featuredd">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                                    <path d="m18 0 8 12 10-8-4 20H4L0 4l10 8 8-12z"></path>
                                  </svg>
                                </div>
                              ) : null}
                              <span className="profilename">{item?.Name}</span>
                              <span className="profileDesignation">
                                {item?.Title}
                              </span>
                              <span className="d-flex justify-content-around" style={{ gap: "5px", marginTop: "4%" }}>
                                <button className="bg-white text-gray-300 mb-1 py-1 px-2 rounded-lg border border-black hover:bg-gray-300 fs-6" style={{ color: '#888888', fontSize: "12px" }} onClick={() => setReviewForm(true)}>Add Review</button>
                                <button className="bg-white text-gray-300 mb-1 py-1 px-2 rounded-lg border border-black hover:bg-gray-300 fs-6" style={{ color: '#888888', fontSize: "12px" }} onClick={() => setUpdatePassword(true)}>Update Password</button>
                                <button className="bg-white text-gray-300 mb-1 py-1 px-2 rounded-lg border border-black hover:bg-gray-300 fs-6" style={{ color: '#888888', fontSize: "12px" }} onClick={() => setBidBalance(true)}>Bid Balance</button>
                              </span>

                            </div>
                            <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                              <div class="radio-inputs">
                                <label class="radio">
                                  <input
                                    type="radio"
                                    name="radio"
                                    checked={freelancerStatus === true} // Assuming "true" represents "Active"
                                    onChange={() => handleStatusToggle(Id, true)} // Set status to "Active"
                                  />
                                  <span class="status">Active</span>
                                </label>

                                <label class="radio">
                                  <input type="radio" name="radio"
                                    checked={freelancerStatus === false} // Assuming "false" represents "Inactive"
                                    onChange={() => handleStatusToggle(Id, false)} // Set status to "Inactive"
                                  />

                                  <span class="status">InActive</span>
                                </label>
                              </div>
                            </div>
                            {/* <hr></hr> */}
                            <div className="skillsset-card" style={{ marginTop: "10px" }}>
                              <h5>Finance</h5>
                              <ul>
                                <li>Total Earning : ${finance?.totalEarningsSum + finance?.fundsInEscrowSum + finance?.pendingPaymentSum}</li>
                                <li>Released Amount : ${finance?.totalEarningsSum}</li>
                                <li>Funds in Escrow : ${finance?.fundsInEscrowSum}</li>
                                <li>Pending Amount : ${finance?.pendingPaymentSum}</li>
                                {/* <li>Revenue Generate :5</li> */}
                              </ul>
                            </div>
                            <hr></hr>
                            <div className="skillsset-card">
                              {" "}
                              <h5>Skills</h5>
                              <div className="skillview"  >
                                {((item?.Skills) ? item?.Skills : '').split(",")?.map((item) => (
                                  <span>{item}</span>
                                ))}
                              </div>
                            </div>
                            <hr></hr>
                            <div className="skillsset-card rate-card" style={{ marginTop: "10px" }}>
                              <h5>Bank Details</h5>
                              <div className="user-details" >
                                <p>Bank Name</p>
                                <span>{item?.Bank_Name}</span>
                              </div>
                              <div className="user-details">
                                <p>Account Name</p>
                                <span>{item?.Account_Name}</span>
                              </div>
                              <div className="user-details">
                                <p>Account Number</p>
                                <span>{item?.Account_Number}</span>
                              </div>
                              <div className="user-details">
                                <p>IFSC Code</p>
                                <span>{item?.IFSC_CODE}</span>
                              </div>
                              <div className="user-details">
                                <p>Swift Code</p>
                                <span>{item?.SWIFT_CODE}</span>
                              </div>
                              <div className="user-details">
                                <p>Branch Detail</p>
                                <span>{item?.BRANCH_DETAILS}</span>
                              </div>
                              <div className="user-details">
                                <p>Bank Country</p>
                                <span>{item?.BANK_COUNTRY}</span>
                              </div>
                            </div>
                            <hr style={{ borderTop: "1px solid #E4E4E4" }} />

                            <div className="skillsset-card rate-card" style={{ marginTop: "10px" }}>
                              <h5>Profile</h5>
                              <div className="user-details" style={{ marginTop: "20px" }}>
                                <p>Location</p>
                                <span>{item?.City + "," + item?.State}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Type</p>
                                <span>{item.Type}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Language</p>
                                <span>{getLanguage(item?.Language)}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Gender</p>
                                <span>{item?.Gender}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Email</p>
                                <span>{item?.Email}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Phone Number</p>
                                <span>+91 - {item?.Mobile}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop:'5%'}}>
                          {takenTest.length > 0 &&
                            <div style={{ fontSize: '20px' }}>
                              <div><strong style={{ padding: 0 }}>Digilanxer Certified</strong></div>
                              <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
                            </div>
                          }

                          {takenTest.length > 0 && takenTest?.map((item) => {
                            {
                              return (
                                item.Result == "Pass" ?

                                  <div className="skills-card" style={{ margin: "10px 0px", display: 'flex' }}>
                                    <h5><img src={certified} style={{ color: "#07b273", width: "50px", }} />
                                    </h5>
                                    <div className="skills-employee " style={{ fontWeight: 'bold', marginLeft: "5px" }}>
                                      Digilanxer Certified {item?.userexam?.SKT_Title} Expert
                                    </div>
                                  </div> : null)
                            }
                          })}
                        </div>
                      </Col>


                      {/* <Card.Title as="h4">
                              <Form className="d-flex">
                                <Form.Control
                                  type="search"
                                  placeholder="Search"
                                  className="mx-2"
                                  aria-label="Search"
                                  value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                                />
                              </Form>

                            </Card.Title> */}
                    </>
                  )
                  }
                </Row>
              </div>
            </>
          ) : (
            <>

              <div>
                <span className="container-fluid d-flex justify-content-start">

                  <span className="close profile" onClick={() => setShowEditForm(false)}>
                    <BsArrowLeftShort style={{ fill: "#07b274", margin: "-30px 0 0 -10px" }} />
                  </span>
                </span>
                <AddFreelancer
                  Type="Freelancer"
                  initialformData={selectedFreelancer} // Pass the selected freelancer's data as initial form data
                  isFormVisible={showEditForm}
                />
              </div>
            </>
          )}:
          {BidBalance ?
            <div className="position-fixed d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%', zIndex: 5, top: 0, left: 0, backgroundColor: "#00000070" }}>
              <div className="position-fixed d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%', zIndex: 5, top: 0, left: 0, backgroundColor: "#00000070" }}>
                <IoMdClose className="text-white position-absolute" style={{ top: "5px", right: "10px", borderRadius: "50%", fontSize: "1.5rem" }} onClick={() => setBidBalance(null)} />
                <div className="bg-white p-5 rounded">
                  <form style={{ position: "relative" }} onSubmit={(e) => handleBidBalanceUpdate(e, Id)}>
                    <p className="text-center"><strong>Update Bid Balance</strong></p>
                    <p ><strong>Current Bid Balance:</strong>{freelancer[0].Bid_Balance || setBidBalance(e.target.value)}</p>
                    <input type="number" min="0" value={BidBalance} placeholder="Add Bids" onChange={(e) => setBidBalance(e.target.value)} className="outline-none " style={{ width: "100%" }} />
                    <button type="submit" className="btn btn-secondary" style={{ marginTop: 20 }}>Update</button>

                  </form>

                </div>
              </div>
            </div> : null}
        </>
      }
    </>
  );
}

export default FreelancerDetails;
