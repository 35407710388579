import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs"
import Profile from "assets/img/default-avatar.png"
import { useLocation } from "react-router-dom";
import { Collapse } from "antd";
const { Panel } = Collapse;
import Selectant from "react-select";
// import { CKEditor } from "ckeditor4-react";
import { useHistory } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import EditJob from "./editJob";
import Milestone from "./Milestone";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const ApplyBid = () => {
    const { endPointUrl, Token } = useContext(MyContext);
    const [jobPostTime, setJobPostTime] = useState();
    const [Proposal, setProposal] = useState("");
    const [items, setitems] = useState({});
    const [detailview, setdetailview] = useState(false);
    const [user, setuser] = useState({});
    const location = useLocation();
    const [showEditForm, setShowEditForm] = useState(false);
    const FreelancerID = location.pathname.split('/').pop();
    const loc = location.pathname.split("/");
    const Id = loc[loc.length - 2]
    const [timeType, setTimeType] = useState("");
    const [detailjob, setdetailjob] = useState();
    const [freelancer, setFreelancer] = useState();
    const [showMileStone, setShowMileStone] = useState(false);
    const history = useHistory();
    const [bid, setbid] = useState({
        BidAmount: "",
        Duration: "",
        DurationType: "",
        Bid_ID: "",
        UserID: "",
        ShortDescription: "",
        MinHours: 0,
        MaxHours: 0,
    });
    const [forms, setForms] = useState([
        { Amount: "", Description: "", M_Due_Date: "", status: "" },
    ]);
    const [milestonesAmountCheck, setMilestoneAmountCheck] = useState(0);

    const getFreelancers = async () => {
        try {
            const res = await axios.post(endPointUrl + "/AlreadyBid", {
                Token,
                JobID: Id,
                UserID: FreelancerID,
            });

            if (res.data.status) {
                message.warning("Freelancer Already bid on this Job!")
                history.push(`/admin/Freelancer-Details/${FreelancerID}`);
            }
            else {
                const { data } = await axios.post(endPointUrl + "/getUserById", {
                    Token,
                    id: FreelancerID,
                });

                if (data && data.data) {
                    setFreelancer(data.data);
                } else {
                    console.log("No data received from the server.");
                }
            }
        } catch(error) {
            console.log("get Freelancer details is not working", error);
        }
    };

    const Jobs = async (Id) => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/getemployerJobpostedDetails", {
                JobID: Id,
                Token,
            });
            setitems(data.data.job);
            setdetailjob(data.data.job);
            setuser(data.data.employer);
            setbid({ ...bid, DurationType: data?.data?.Budget_Type })
        } catch {
            console.log("get all details projects is not working");
        }
    };

    const navigateToFinance = () => {
        window.history.back();
    };

    useEffect(() => {
        getFreelancers()
        Jobs(Id);
    }, []);

    const onEditorChange = (evt, editor) => {
        const data = evt.editor && evt.editor.getData();
        setProposal(data);
    };

    useEffect(() => {
        const currentTime = new Date();
        const uploadTimestamp = new Date(detailjob?.createdAt);
        const timeDifference = currentTime.getTime() - uploadTimestamp.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            setJobPostTime(days)
            setTimeType("day")
            setTimeType(hours > 1 ? "days" : "day")
        } else if (hours > 0) {
            setJobPostTime(hours)
            setTimeType(hours > 1 ? "hours" : "hour")
        } else if (minutes > 0) {
            setJobPostTime(minutes)
            setTimeType(hours > 1 ? "minutes" : "minute")
        } else {
            setJobPostTime(seconds)
            setTimeType(hours > 1 ? "seconds" : "second")
        }
    })

    const checkProposallength = (content) => {
        const strippedContent = content.replace(/<[^>]+>/g, "");

        // Count the words in the content
        const wordCount = strippedContent.trim().split(/\s+/).length;

        // Check if the word count is less than 50
        if (wordCount < 20) {
            return true;
        }
        return false;
    };


    const handleHourlymilestone = async (Bid_ID) => {
        try {
            await axios.post(endPointUrl + "/user/createMilestone", {
                Amount: parseInt(bid.BidAmount) * parseInt(bid.MinHours),
                Description: bid.ShortDescription,
                Token,
                Bid_ID,
                MilstoneStatus: "Pending",
            });
        } catch (error) {
            console.log("Error while update hourly milestone", error);
        }
    };


    const updateBidBalance = async () => {
        try {
            await axios.post(endPointUrl + "/updateBidBalance", {
                Token,
                id: user.id,
                value: -1,
            });
            console.log("Bid Update successful");
        } catch {
            console.log("Bid Update unsuccessful");
        }
    };


    const submitBiddingHour = async () => {
        const isAnyFieldEmpty = bid?.BidAmount === "" || bid?.DurationType === "" || bid?.MinHours === 0 || bid?.MaxHours === 0 || bid?.ShortDescription === ""

        if (isAnyFieldEmpty) {
            message.warning("Please fill in all fields");
            return;
        }
        if (checkProposallength(Proposal)) {
            message.warning("Proposal should have minmium 20 words");
            return;
        }
        if (parseInt(bid.MinHours) > parseInt(bid.MaxHours)) {
            message.warning("Min Hours is not be greater than max hours");
            return;
        }
        try {
            const { data } = await axios.post(endPointUrl + "/user/applyBid", {
                ...bid,
                Duration: 0,
                Token,
                Proposal,
                Bid_Status: "Active",
                UserID: freelancer.id,
                JobID: detailjob?.JobID,
                Job_Name: detailjob?.Title,
                To_ID: detailjob?.Employer_ID,
                From_Name: freelancer.Name + " " + freelancer.Last_Name,
                Email: freelancer.Email,
                imgURL: freelancer.Photo === "" || freelancer.Photo == null
                    ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                    : freelancer.Photo,
                Bid_date: new Date(),
            });
            handleHourlymilestone(data.data.Bid_ID);
            updateBidBalance();
            message.success("Bid Added Successfully");
            history.push(`/admin/Freelancer-Details/${FreelancerID}`);
        } catch (error) {
            console.log('some error', error);
            message.error("Some Error Ocurr");
        }
    }


    const handlemilestone = async (item, Bid_ID) => {
        item.map(async (res) => {
            await axios.post(endPointUrl + "/user/createMilestone", {
                ...res,
                Token,
                Bid_ID,
                MilstoneStatus: "Pending",
            });
        });
    };


    const handleApplyBtn = async (status) => {
        const isAnyFieldEmpty = forms.some(
            (form) =>
                form.Amount === "" || form.Description === "" || form.M_Due_Date === ""
        );

        if (isAnyFieldEmpty) {
            message.warning("Please fill in all fields");
            return;
        }
        // message.success("bid Applied Successfully")
        let milamount = 0;
        forms.map((item) => (milamount += parseFloat(item.Amount)));
        // console.log(milamount + " " + bid.BidAmount);
        if (parseFloat(milamount) === parseFloat(bid.BidAmount)) {
            if (checkProposallength(Proposal)) {
                message.warning("Proposal should have minmium 20 words");
                return;
            }
            try {
                const { data } = await axios.post(endPointUrl + "/user/applyBid", {
                    ...bid,
                    Token,
                    Proposal,
                    Bid_Status: status,
                    UserID: freelancer.id,
                    JobID: detailjob?.JobID,
                    Job_Name: detailjob?.Title,
                    To_ID: detailjob?.Employer_ID,
                    From_Name: freelancer.Name + " " + freelancer.Last_Name,
                    Email: freelancer.Email,
                    imgURL: freelancer.Photo === "" || freelancer.Photo == null
                        ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                        : freelancer.Photo,
                    Bid_date: new Date(),
                });

                handlemilestone(forms, data.data.Bid_ID);
                updateBidBalance();
                message.success("Bid Added Successfully");
                history.push(`/admin/Freelancer-Details/${FreelancerID}`);
            } catch (error) {
                console.log('some error', error);
                message.error("Some Error Ocurr");
            }
        } else {
            message.warning("Milestone Amount Does not Match with Bid Amount");
        }
    };

    return (
        <>
            <Container fluid>
                <div className="container-fluid d-flex align-items-center">
                    <span className="close profile" onClick={navigateToFinance}>
                        <BsArrowLeftShort style={{ fill: "#07b274", margin: "-20px 10px 0 -10px" }} />
                    </span>
                    <h6 className="heading-6" style={{ padding: "20px 0 20px 0" }}>Job Details</h6>
                </div>
                <Row>
                    <Col md="8">
                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>
                            <Card.Header style={{ backgroundColor: '#07b274' }}>
                                <Card.Title as="h4">{items?.Title}</Card.Title>
                                <hr></hr>
                            </Card.Header>
                            <Card.Body>

                                <div className="about">
                                    <span>{items?.ShortDesc}</span>
                                </div>
                                <hr></hr>
                                <div className="about">
                                    <h6>Detail Description</h6>
                                    <span dangerouslySetInnerHTML={{ __html: items?.Description }}></span>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>
                            <h4 style={{ margin: 0 }}>About this Project</h4>
                            <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
                                <p style={{ color: "#5c5b5b" }}><span></span> Posted {parseInt(jobPostTime)} {timeType} ago</p>
                            </div>
                            <div>
                                <div className="typesandbudget">
                                    <span><strong>type: </strong>{detailjob?.ProjectType || ''}</span> Add Project Type  in place of Budget_Type here...
                                    <span><strong>Budget: </strong>${detailjob?.Budget_From}-{detailjob?.Budget_To}/{detailjob?.Budget_Type === "Monthly" ? "Month" : detailjob?.Budget_Type === "Weekly" ? "Week" : "Hour"}</span>
                                </div>
                                <div className="typesandbudget">
                                    <span><strong>category: </strong>{detailjob?.Category}</span>
                                    <span><strong>technology: </strong>{detailjob?.Technology}</span>
                                </div>
                                <div className="typesandbudget">
                                    <span><strong>Location: </strong>{detailjob?.Location}</span>
                                    <span><strong>language: </strong>{detailjob?.Language || ""}</span>
                                </div>
                            </div>
                            <hr />


                            {/* Place BID *************************************/}
                            <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h4 style={{ margin: 0 }}>Place Your Bid</h4>
                            </span>

                            <form contentEditable={false}>
                                <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                    <div>
                                        <label style={{ width: '100%' }} htmlFor="BidAmount">
                                            <p>{detailjob?.ProjectType === "Hourly" ? "Hourly Rate" : "Bid Amount"}<span className="asterisk">*</span></p>
                                            <input
                                                type="number"
                                                id="BidAmount"
                                                name="BidAmount"
                                                value={bid?.BidAmount}
                                                placeholder={detailjob?.ProjectType === "Hourly" ? "Enter Hourly Rate" : "Enter Bid Amount"}
                                                // onChange={(e) => setbid({ ...bid, BidAmount: e.target.value })}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.slice(0, 6)
                                                    if (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0) {
                                                        setbid({ ...bid, BidAmount: inputValue });
                                                    } else {
                                                        setbid({ ...bid, BidAmount: "" })
                                                    }
                                                }}
                                            />
                                        </label>
                                    </div>
                                    {detailjob?.ProjectType === "Hourly" ?
                                        <div>
                                            <label style={{ width: '100%' }} htmlFor="paymentType">
                                                <p>Payment Type<span className="asterisk">*</span></p>
                                                <Selectant
                                                    style={{ width: '100%' }}
                                                    id="PaymentType"
                                                    name="Budget_type"
                                                    onChange={(e) =>
                                                        setbid({ ...bid, DurationType: e.value })
                                                    }
                                                    options={[{ value: "Weekly", label: "Weekly" }, { value: "Monthly", label: "Monthly" }]}
                                                />
                                            </label>
                                        </div>
                                        : <></>}
                                </div>

                                <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                    {detailjob?.ProjectType === "Fixed" ?
                                        <div>
                                            <label htmlFor="Duration">
                                                <p>Duration<span className="asterisk">*</span>{detailjob?.Budget_Type === "Monthly" ? "(Month)" : detailjob?.Budget_Type === "Weekly" ? "(Week)" : "(Hour)"}</p>
                                                <input
                                                    type="number"
                                                    id="Duration"
                                                    name="Duration"
                                                    // step={0.1}
                                                    value={bid?.Duration}
                                                    // onChange={(e) => setbid({ ...bid, Duration: e.target.value })}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value.slice(0, 6)
                                                        if (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0) {
                                                            setbid({ ...bid, Duration: inputValue });
                                                        } else {
                                                            setbid({ ...bid, Duration: "" })
                                                        }
                                                    }}
                                                />
                                            </label>
                                        </div> :

                                        <>
                                            <div>
                                                <label>Work Hours (Weekly)<span className="asterisk">*</span></label>
                                                <div style={{ display: 'flex' }}>
                                                    <div className="bid_range" style={{ paddingRight: "2%", marginRight: "10%" }}>
                                                        <label htmlFor="Min_Hours" style={{ color: "GrayText" }}>Min Hours </label>
                                                        <input
                                                            type="text"
                                                            name="Min Hours"
                                                            placeholder="Min Hours"
                                                            value={bid?.MinHours}
                                                            onChange={(e) => {
                                                                const input = e.target.value;
                                                                if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                                                    setbid({ ...bid, MinHours: input.slice(0, 6) });
                                                                } else {
                                                                    setbid({ ...bid, MinHours: "" });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="bid_range" style={{ paddingRight: "2%" }}>
                                                        <label htmlFor="Max Hours" style={{ color: "GrayText" }}>Max Hours</label>
                                                        <input
                                                            type="text"
                                                            name="Max_Hours"
                                                            placeholder="Max Hours"
                                                            value={bid?.MaxHours}
                                                            onChange={(e) => {
                                                                const input = e.target.value;
                                                                if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                                                    setbid({ ...bid, MaxHours: input.slice(0, 6) });
                                                                } else {
                                                                    setbid({ ...bid, MaxHours: "" });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p>Cover Letter<span className="asterisk">*</span></p>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Short Description"
                                                    onChange={(event) => {
                                                        if (event.target.value.split(" ").length <= 50)
                                                            setbid({ ...bid, ShortDescription: event.target.value })
                                                        else
                                                            message.warning("Short Description Not More than 50 Words")
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </form>

                            <div className="userdeatail_container" style={{ marginTop: "3%", width: '100%', gridTemplateColumns: "91%" }}>
                                <div>
                                    <p>Proposal<span className="asterisk">*</span></p>
                                    {/* <CKEditor
                                        initData={Proposal}
                                        // readOnly={editable}
                                        config={{
                                            toolbar: [
                                                { name: "document", items: ["Undo", "Redo"] },
                                                { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                                                { name: "styles", items: ["Format"] },
                                                {
                                                    name: "basicstyles",
                                                    items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                                                },
                                            ],
                                        }}
                                        onChange={onEditorChange}
                                    /> */}
                                    <CKEditor
                                        placeholder="Give Description About Your Project..."
                                        data={Proposal}
                                        editor={ClassicEditor}
                                        onChange={onEditorChange}
                                    />
                                </div>
                            </div>

                            {showMileStone ?
                                <Milestone
                                    forms={forms}
                                    setForms={setForms}
                                    detailjob={detailjob}
                                    milestonesAmountCheck={milestonesAmountCheck}
                                    setMilestoneAmountCheck={setMilestoneAmountCheck}
                                // Bid_Status={bid.Bid_Status}
                                /> : <></>}


                            {detailjob?.ProjectType === "Hourly" ? <div className="userdeatail_container" style={{ marginTop: "3%", gridTemplateColumns: "91%" }}>
                                <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={() => { submitBiddingHour(); }}>Apply Bid</button>
                            </div> : showMileStone === false && detailjob?.ProjectType === "Fixed" ?
                                <div>
                                    <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={() => { setShowMileStone(true); }}>Add Milestone</button>
                                </div> :
                                <>
                                    <button
                                        className="btn"
                                        style={{ padding: "10px", margin: "10px 5px" }}
                                        onClick={() => { handleApplyBtn("Active") }}
                                    >
                                        Apply For Bid
                                    </button>
                                </>}
                        </Card>
                    </Col>
                    {/* Place BID *************************************/}

                    <Col md="4" style={{ marginTop: "15px" }}>
                        <div className="compo2">
                            <div className="profileConatiner">
                                <div className="profile-details">
                                    <div className="editimg" >
                                        <EditJob Job={items} />
                                    </div>
                                    <img
                                        src={
                                            user?.Photo === "" || user?.Photo == null
                                                ? Profile
                                                : `${endPointUrl}/images/${user?.Photo}`
                                        }
                                        alt=""
                                    // style={{width:'20%',display:"flex"}}
                                    />
                                    <span className="profilename">{user?.Name}</span>
                                    <span className="profileDesignation">
                                        {user?.Company}
                                    </span>
                                </div>
                            </div>


                            <div className="uploadDocs skillsset-card" style={{ marginTop: "10px" }}>
                                <h5>
                                    {items?.IsActive ? "Documents" : "Upload Documents"}{" "}
                                </h5>
                                {items?.IsActive ? (
                                    <div className="uploadtags">
                                        {(items.Documents
                                            ? items.Documents.split(",")
                                            : []
                                        ).map((tag, index) => (
                                            <span key={index}>
                                                <a
                                                    href={`${endPointUrl}/images/${tag}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {tag}
                                                </a>
                                            </span>
                                        ))}
                                    </div>
                                ) : (
                                    // <DocumentUpload detail={items} getjobs={getjobs} />
                                    <p>Document</p>
                                )}
                            </div>
                            <hr style={{ width: "100%", background: "" }} />
                            <div className="skillsset-card">
                                <h5>Budget Details</h5>
                                <small>
                                    <span>{items?.Budget_From}-</span>
                                    <span>{items?.Budget_To}/</span>
                                    <span>{items?.Budget_Type}</span>
                                </small>
                            </div>
                            <hr style={{ width: "100%", background: "" }} />
                            <div className="skillsset-card">
                                {" "}
                                <h5>Job Type</h5>
                                <small>{items?.Type}</small>
                            </div>
                            <hr style={{ width: "100%", background: "" }} />
                            <div className="skillsset-card">
                                {" "}
                                <h5>Project Type</h5>
                                <small>{items?.ProjectType}</small>
                            </div>
                            <hr style={{ width: "100%", background: "" }} />
                            <div className="skillsset-card">
                                {" "}
                                <h5>Skills</h5>
                                <div className="skillview">
                                    {((items?.SkillsRequired) ? items?.SkillsRequired : '').split(",")?.map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </div>
                            </div>
                            <div className="skillsset-card">
                                <h5>Technology</h5>
                                <div className="skillview">
                                    {((items?.Technology) ? items?.Technology : '').split(",").map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </div>
                            </div>
                            <div className="skillsset-card">
                                <h5>Category</h5>
                                <div className="skillview">
                                    {((items?.Category) ? items?.Category : '').split(",").map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </div>
                            </div>
                            <div className="skillsset-card">
                                <h5>Language</h5>
                                <div className="skillview">
                                    {((items?.Language) ? items?.Language : '').split(",").map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </div>
                            </div>
                            <div className="skillsset-card">
                                <h5>KeyWords</h5>
                                <div className="skillview">
                                    {(items?.Keywords)?.split(",").map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default ApplyBid;