import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import PaginationSection from './PaginationSection';

import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import QuestionForm from './Questionform';
import UploadCSVfile from "./UploadquesCSVfile";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function QuestionBank() {
  // const [formData, setFormData] = useState(initialFormData);
  const { endPointUrl, Token } = useContext(MyContext);
  const [formData, setFormData] = useState({});
  const [skillmaster, setskillmaster] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [showAdd, setshowAdd] = useState(false);
  // const [Edit, setEdit] = useState(false);
  const [isPublished, setIsPublished] = useState(false); // State to handle the published status
  const [allDetails, setallDetails] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null); // State to store the selected question for update
  const [showmodel, setshowModel] = useState(false);

  const handleAddData = async (data) => {
    console.log("found", data)
    setallDetails(data.data);
    setshowModel(false);
    document.body.classList.remove("modal-open");
  }

  const handleAddshow = () => {
    // setshowAdd(!showAdd);
    setshowAdd(true);
    setIsUpdateMode(false);
  };

  // const CloseDetails = () => {
  //   setshowAdd(false);
  //   setEdit(false);
  // };

  const handleUpdate = async (quesID) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getQuesById", {
        Token,
        Ques_ID: quesID,
      });
      setSelectedQuestion(data.data);
      console.log(data.data);
      setFormData({
        Question: data.data.Question,
        Option1: data.data.Option1,
        Option2: data.data.Option2,
        Option3: data.data.Option3,
        Option4: data.data.Option4,
        Answer: data.data.Answer,
      });
      console.log(formData)
      // setSelectedSkills(
      //   data.data.Skills.map((skill) => ({ value: skill, label: skill }))
      // );
      setIsPublished(data.data.Type === 'Published');
      setshowAdd(true);
      setIsUpdateMode(true);
      message.success("Data fetched successfully");
    } catch (error) {
      console.log("Failed to update question:", error);
      message.error("something went wrong");
    }
  };

  const handleDelete = async (quesID) => {
    try {
      await axios.post(endPointUrl + "/admin/deletequestion", {
        Token,
        Ques_ID: quesID,
      });
      // Remove the deleted question from the table
      setallDetails((prevDetails) =>
        prevDetails.filter((detail) => detail.Ques_ID !== quesID)
      );
      message.success("Question deleted successfully.");
    } catch (error) {
      console.log("Failed to delete question:", error);
      message.error("Failed to delete question.");
    }
  };
  useEffect(() => {
    handlesquestionbank();
  }, []);

  const getallquestion = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getallquestion", {
        Token,
      });

      setallDetails(data.data);
      console.log(data.data);
      // console.log(allDetails);

    } catch {
      console.log("get allquestion is not working");
    }
  };

  useEffect(() => {
    getallquestion();
  }, []);

  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "Question",
        accessor: "Question",
      },
      {
        Header: "Skills",
        accessor: "Skills",
      },
      {
        Header: "Answer",
        accessor: "Answer",
      },
      {
        Header: "Actions",
        accessor: "Ques_ID",
        Cell: ({ value }) => (
          <div>
            {/* "Update" icon */}
            <span
              onClick={() => handleUpdate(value)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
            </span>
            {/* "Delete" icon */}
            <span
              onClick={() => handleDelete(value)}
              style={{ cursor: "pointer" }}
            >
              <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
            </span>
          </div>
        ),
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    // pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;


  const handlesquestionbank = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
        Token,
      });

      if (data && data.data && Array.isArray(data.data)) {
        const skillNames = data.data.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));
        setskillmaster(skillNames);
      } else {
        console.log('Failed to fetch skills. Data may be empty or not in the expected format.');
      }
    } catch (error) {
      console.log('Failed to fetch skills:', error);
    }

  };

  const handleFormSubmit = async () => {
    setshowAdd(false);
    setIsUpdateMode(false);
    getallquestion();
  };

  const handleExpModel = async () => {
    setshowModel(true);
    document.body.classList.add("modal-open");

  };
  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");
  };

  return (
    <>
      {!isUpdateMode && !showAdd && (
        <>
          <span className="container-fluid d-flex justify-content-start">
            <h6 className="heading-6" style={{ margin: "20px 0 -10px 20px" }}>Question Bank</h6>
          </span>
          <span className="container-fluid d-flex justify-content-end">
            <button
              className="btn mb-1 btn-lg mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleExpModel()}>
              Import Questions
            </button>
            <button
              className="btn mb-1 btn-lg mr-3"
              style={{ cursor: "pointer" }}
              onClick={handleAddshow}
            >
              Add Question
            </button>
          </span>
        </>
      )}
      {(showAdd || isUpdateMode) && (
        <span className="container-fluid d-flex justify-content-start">
          <span className="close profile" onClick={() => setshowAdd(false) || setIsUpdateMode(false)}>
            <BsArrowLeftShort style={{ fill: "#07b274", margin: "-30px 0 0 -10px" }} />
          </span>
        </span>
      )}
      {(showAdd || isUpdateMode) ? (

        <QuestionForm
          selectedQuestion={selectedQuestion}
          isUpdateMode={isUpdateMode}
          initialFormData={formData}
          initialSelectedSkills={selectedSkills}
          initialIsPublished={isPublished}
          onSubmit={handleFormSubmit}
          onClose={() => setshowAdd(false)}
          skillmaster={skillmaster}
        />
      ) : null}
      {!showAdd && !isUpdateMode && (
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">
                    <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="mx-2"
                        aria-label="Search"
                        value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                      />
                    </Form>

                  </Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped " {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr  {...row.getRowProps()}>
                            {/* {console.log(row.original.id)} */}
                            {row.cells.map((cell) => {
                              //  const { column } = cell.value;
                              //  let Id = column.Name;
                              //  console.log(cell.value);

                              return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                            })}
                          </tr>
                        );
                      }

                      )}
                    </tbody>

                  </Table>
                </Card.Body>
                <PaginationSection
                  pageIndex={pageIndex}
                  pageCount={pageOptions.length}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageOptions={pageOptions}
                />

              </Card>

            </Col>
          </Row>
        </Container>
      )}
      <div id="myModal"
        className={showmodel ? "model-open" : "model"}
        style={{ overflow: "auto" }}
      >
        <div className="modal-content" style={{ color: "black" }}>
          <div className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
            &times;
          </div>
          <div><UploadCSVfile handleAddData={handleAddData} /></div>
        </div>
      </div>
    </>
  )
};

export default QuestionBank;