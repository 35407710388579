import React, { useContext, useState } from "react";
import "../assets/css/login.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import img1 from '../assets/img/img.png'
import img2 from '../assets/img/logo.png'
import MyContext from "MyContext";
function login({ updateUser }) {
  const { endPointUrl } = useContext(MyContext)
  const history = useHistory();
  const [data, setdata] = useState({ Email: "", Password: "" });
  const handleLogin = async () => {
    // history.push("/admin/dashboard");
    localStorage.setItem("user", "sudehfiwueiuf3i")
    try {
      const response = await axios.post(endPointUrl + "/admin/login", { ...data, Token: 'W4lyaidIGicUCR1gCtaC2JEbn4D2AvzyRwRAHCCcUjvWOx6Epe' });
      if (response.data.status) {
        console.log('respoinse .data ', response.data);
        updateUser(response?.data?.data)
        history.push("/admin/dashboard");
        message.success(response.data.message)
        return;
      }
      message.error(response.data.message)

    } catch {
      message.error("Email or Password is incorret");
    }
  };
  return (

    <div className="container-fluid p-5" style={{ backgroundColor: '#07b274', height: '100vh' }}>
      <div className="d-flex mx-auto w-75 login ">
        <img src={img1} alt="" className="login-img" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          className="login-form"
        >
          <img src={img2} alt="" className="w-50 d-block m-auto my-5" />

          <label htmlFor="username">Email</label>
          <input type="text" id="username" name="username" onChange={(e) => setdata({ ...data, Email: e.target.value })} />

          <label htmlFor="password">Password</label>
          <input type="password" id="password" name="password" onChange={(e) => setdata({ ...data, Password: e.target.value })} />

          <button type="submit" className="btn">
            Login
          </button>
        </form>
      </div>
    </div>

  );
}

export default login;
