import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import PaginationSection from "./PaginationSection";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";

function EmployerFinance(Id) {
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();
  const [ProjId, setID] = useState([]);
  const [project, setproject] = useState([]);
  // const [showmodel, setshowModel] = useState(false);

  const getFinance = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getemployerpayment", {
        Awarded_By_ID: Id.ID,
        Token,
      });

      setallDetails(data.data);
      console.log(data.data);

    } catch (err) {
      console.log("get employer finance is not working", err);
    }
  };
  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "Project",
        accessor: "jobs.Title",
        Cell: (({ value }) => {
          return <div style={{ cursor: 'pointer' }}>{value}</div>
        })
      },
      {
        Header: "Paid Amount",
        accessor: "paidPayment",
      },
      {
        Header: "Due Amount",
        accessor: "duePayment",
      },
      {
        Header: "Total Amount",
        accessor: "TotalPayment",
      },
    ],
    []
  );
  useEffect(() => {
    getFinance(Id);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const handleclick = (Id) => {
    history.push(`/admin/Employer-Finance-Details/${Id}`);
  };

  // const CloseDetails = () => {
  //   setShowDetails(false);
  // };




  return (
    <>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead >
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {/* {console.log(row.original.id)} */}
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td onClick={() => handleclick(row.original.P_ID)} {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}

                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>

      </Container>












    </>
  )
}

export default EmployerFinance;