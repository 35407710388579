import { message } from "antd";
import React, { useState } from "react";

function Curdcontenior({ item, handleUpdate, handleDelete, id, idName }) {
  const [edit, setedit] = useState(false);
  const [value, setvalue] = useState(item.Name);
  return (
    <tr>
      <td className="border-0">{id + 1}</td>
      <td className="border-0">
        {!edit ? (
          <>{item.Name}</>
        ) : (
          <input
            type="text"
            className="border-primary w-25"
            onChange={(e) => {
              e.preventDefault();
              setvalue(e.target.value);
            }}
            placeholder="Enter text"
            value={value}
            autoFocus
          />
        )}
      </td>
      <td className="d-flex align-items-cente"> {!edit ? (

        <img src={require('../../assets/img/edit.png')}
          style={{ width: '20px' }}
          onClick={() => {
            setvalue(item.Name)
            setedit(!edit);
          }}
        >
        </img>
      ) : (
        <img src={require('../../assets/img/update.png')}
          style={{ width: '20px' }}
          onClick={() => {
            console.log(item)
            console.log(item[idName])
            handleUpdate(item[idName], value);
            setedit(!edit);
          }}
        ></img>
      )}
        <img src={require('../../assets/img/delete.png')} style={{ width: '20px', marginLeft: '20px' }}
          onClick={() => handleDelete(item[idName])}
        >

        </img></td>
    </tr>
  );
}

export default Curdcontenior;
