import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs"
// import BiddingCard from "./BiddingCard";
import Profile from "assets/img/default-avatar.png"
import { useLocation } from "react-router-dom";
import Master from "./Masterss";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';

import { Collapse } from "antd";
const { Panel } = Collapse;

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import EmployerPaymentt from "./EmployerPaymentt";





const EmployerFinanceDetails = () => {
  const { endPointUrl, Token } = useContext(MyContext);

  const [items, setitems] = useState({});
  const [detailview, setdetailview] = useState(false);
  const [user, setuser] = useState({});
  const location = useLocation();
  const [bidder, setbidder] = useState({});
  const [milestone, setmilestone] = useState([]);

  const Id = location.pathname.split('/').pop();

  const Projects = async (Id) => {
    // console.log(Id);
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getEmployerProjectdetails", {
        P_ID: Id,
        Token,
      });
      setitems(data.projectInfo);
      setuser(data.projectInfo.employers);
      setbidder(data.projectInfo.freelancers);
      if (data.projectInfo.Proj.length !== 0) {
        setmilestone(data.projectInfo.Proj);
      }

      console.log(data.projectInfo.Proj);
      // console.log(data.projectInfo);
    } catch {
      console.log("get all details projects is not working");
    }
  };
  //   const data = React.useMemo(() => allDetails, [allDetails]);

  useEffect(() => {
    Projects(Id);
  }, []);

  const Ongoing = items?.Project_status === "in_Progress";
  const Closed = items?.Project_status === "closed";



  return (
    <>
      <Container fluid>
        <div className="container-fluid d-flex justify-content-between">
          <h6 className="heading-6" style={{ padding: "20px 0 20px 0" }}>Employer Finance Details </h6>
        </div>
        <Row>
          <Col md="12">
            {/* <Card className="`card-user`">
              <Card.Header style={{backgroundColor:'#07b274'}}>
                <Card.Title as="h4">{items?.jobs?.Title}</Card.Title>
              <hr></hr>
              </Card.Header> */}
            <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

              <Card.Header style={{ backgroundColor: '#07b274' }}>
                <Card.Title as="h4">{items?.jobs?.Title}</Card.Title>
                <hr></hr>
              </Card.Header>
              <Card.Body>
                <div className="biddiv" >
                  {/* <div style={{flex:'1'}} className="card-image profile">
           
             
             */}
                  {/* </div> */}
                  <div className="job-name" style={{ flex: '5', fontWeight: '600', fontSize: '30px', padding: "30px" }}>
                    <img
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                      src={
                        bidder?.Photo === "" || bidder?.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${bidder?.Photo}`
                      }
                      alt="" />
                    <span >{bidder.Name}</span>
                    <div className="current-tags">
                      {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                        if (index < 3) {
                          return val.length > 0 ? <span>{val}</span> : null
                        }
                      })}
                      {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 3 ? "+" : ""}
                      {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 3 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 3 : ""}

                    </div>
                  </div>
                  <div className="biddingInformation" style={{ flex: '2' }}>
                    <div id="bidheadStatus">
                      {Ongoing && (
                        <div>
                          <p>Ongoing</p>
                        </div>
                      )}
                      {Closed && (
                        <div>
                          <p>Closed</p>
                        </div>
                      )}
                    </div>
                    <div>
                      <span className="bidhead">Total Amount</span>
                      <span id="bidheadValue">${items?.bid?.BidAmount}</span>
                    </div>
                  </div>
                </div>
                {/* <b>Proposal</b> */}

                <div>
                  <EmployerPaymentt ID={Id} />
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>

    </>
  )
}

export default EmployerFinanceDetails;