import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import Draftskilltest from "./draftskilltest";
import Publishedskilltest from "./Publishedskilltest";
import { BsArrowLeftShort } from "react-icons/bs"
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

function SkillTest() {
  // const [formData, setFormData] = useState(initialFormData);
  const { endPointUrl, Token } = useContext(MyContext);
  const [formData, setFormData] = useState({
    Status: "Draft"
  });
  const [skillmaster, setskillmaster] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isPublished, setIsPublished] = useState(false); // State to handle the published status
  const [activeCard, setActiveCard] = useState("published");
  const [showPublishedskilltest, setShowPublishedskilltest] = useState(true);
  const [showDraftskilltest, setShowDraftskilltest] = useState(false);
  const [showAdd, setshowAdd] = useState(false);
  const [changeAmount, setchangeAmount] = useState('');
  const [isPaid, setIsPaid] = useState(true);


  const handleAddshow = () => setshowAdd(!showAdd);
  const CloseDetails = () => {
    setshowAdd(false);
  };
  useEffect(() => {
    handleskillsmaster();
  }, []);
  const handleAmountChange = (event) => {
    setchangeAmount(event.target.value);
    setFormData({
      ...formData, Amount: event.target.value
    })
  };
  const handlePaidChange = (checked) => {
    setIsPaid(checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      isPaid: checked,
    }));
  };


  const handleskillsmaster = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
        Token,
      });

      if (data && data.data && Array.isArray(data.data)) {
        const skillNames = data.data.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));
        setskillmaster(skillNames);
      } else {
        console.log('Failed to fetch skills. Data may be empty or not in the expected format.');
      }
    } catch (error) {
      console.log('Failed to fetch skills:', error);
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPaid == true && !formData.Amount) {
      message.warning("Amount is required");
      return;
    }
    try {
      const selectedSkillsString = selectedSkills.map((skill) => skill.value).join(', ');

      const { data } = await axios.post(endPointUrl + '/addskilltest', {
        Token,
        ...formData,
        Skills: selectedSkillsString,

      });
      handleskillsmaster();
      setshowAdd(false);
      // Handle the response data as needed
      console.log('API Response:', data);

      // Show a success message
      message.success('Skill test submitted successfully.');
    } catch (error) {
      // Handle error response or show an error message
      console.log('Skill test submission failed:', error);
      if (error?.response?.data?.err?.name === "SequelizeUniqueConstraintError") {
        message.error('Skill Title already exists!');
      }
      else if (error?.response?.data?.err?.name === "SequelizeValidationError") {
        message.error('No of question minimum value 10');
      }
      else if ((error?.response?.data?.custom === "examques")) {
        message.error(error?.response?.data?.message);
      }
      else {
        message.error('Something Went Wrong');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSkillSelect = (selectedOption) => {
    setSelectedSkills(selectedOption);
  };
  const handleStatusChange = (checked) => {
    setIsPublished(checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      Status: checked ? "Published" : "Draft",
    }));
  };


  const navigateToPublishedskilltest = () => {
    // history.push("/admin/Published-Jobs"); 
    setShowPublishedskilltest(true); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
    setShowDraftskilltest(false);
    setActiveCard("published");
  };
  const navigateToDraftskilltest = () => {
    // history.push("/admin/Draft-Jobs"); 
    setShowDraftskilltest(true);
    setShowPublishedskilltest(false);
    setActiveCard("draft");
  };

  return (
    <>
      {!showAdd ? (
        <>
          <Container fluid>
            <Row>
              <Col lg="6" sm="12">
                <Card className={`card-stats ${activeCard === "published" ? "active" : ""}`} onClick={navigateToPublishedskilltest} style={{ borderBottom: activeCard === "published" ? "4px solid #07b273" : "" }}>
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col sm="7">
                        <div className="numbers">
                          <p className="card-category">Published Skill Test</p>
                          <Card.Title as="h3"></Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" sm="12">
                <Card className={`card-stats ${activeCard === "draft" ? "active" : ""}`} onClick={navigateToDraftskilltest} style={{ borderBottom: activeCard === "draft" ? "4px solid #07b273" : "" }}>
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Draft Skill Test</p>
                          <Card.Title as="h3"></Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container fluid className="skilltestable">
            <span className="container-fluid d-flex justify-content-end">
              <button
                className="btn mb-1 btn-lg mr-3"

                style={{ cursor: "pointer" }}
                onClick={handleAddshow}
              >
                ADD
              </button>
            </span>
            {/* Render Publishedjobs component if showPublishedJobs is true */}
            {/* {showPublishedskilltest && <Publishedskilltest />} */}
            {showDraftskilltest && <Draftskilltest skillmaster={skillmaster} />}
            {showPublishedskilltest && <Publishedskilltest skillmaster={skillmaster} />}
          </Container>
        </>
      ) : (
        // <span className="container-fluid d-flex justify-content-start">
        <>
          <span className="close profile" onClick={() => CloseDetails()}>
            <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
          </span>
          <Container className='addskilltest' fluid>
            <div className="container-fluid d-flex justify-content-between">
              <h6 className="heading-6">Add Skill Test</h6>
            </div>
            <Row>
              <Col md="12">
                <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                  <Card.Header style={{ backgroundColor: '#07b274' }}>
                    <Card.Title as="h4">Fill Details</Card.Title>
                    <hr></hr>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Title</label>
                            <Form.Control
                              name="SKT_Title"
                              placeholder="Title"
                              type="text"
                              value={formData.SKT_Title}
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6" style={{ display: 'flex', alignItems: 'center' }} >
                          <Form.Group>
                            <label style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "30px" }}>Status</label>
                            {/* Use the react-switch for the sliding switch */}
                            <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "50px" }}>
                              <Switch
                                onChange={handleStatusChange}
                                checked={isPublished}
                                onColor="#86d3ff"
                                onHandleColor="#07b274"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              />
                              <span style={{ marginLeft: '10px' }}>
                                {isPublished ? 'Published' : 'Draft'}
                              </span>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Published Date</label>
                            <Form.Control
                              name="Published_Date"
                              placeholder="Published_Date"
                              type="date"
                              value={formData.Published_Date}
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>No. of Questions</label>
                            <Form.Control
                              name="No_of_Questions"
                              placeholder="Questions"
                              type="number"
                              value={formData.No_of_Questions}
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Skills</label>
                            <Selectant
                              name="Skills"
                              options={skillmaster}
                              isMulti
                              onChange={handleSkillSelect}
                              value={selectedSkills}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6" >
                          <Form.Group>
                            <label>Test Duration (in min.)</label>

                            <Form.Control
                              name="Duration"
                              placeholder="Duration"
                              type="number"
                              value={formData.Duration}
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6" style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Group>
                            <label style={{ display: 'flex', justifyContent: 'flex-start', }}>Paid</label>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Switch
                                onChange={handlePaidChange}
                                checked={isPaid}
                                onColor="#86d3ff"
                                onHandleColor="#07b274"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              />
                            </div>
                          </Form.Group>
                        </Col>

                        {isPaid && (
                          <Col className="pl-1" md="6" >
                            <Form.Group>
                              <label style={{ display: 'flex', marginTop: '10px' }}>Amount:</label>
                              <Form.Control
                                type="number"
                                value={formData.Amount}
                                onChange={handleAmountChange}
                                style={{ display: 'flex', marginTop: '0px', border: "1px solid lightgrey", padding: '7px', borderRadius: "4px" }}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          Expiry time: 1 year
                        </Col>
                      </Row>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row >
          </Container >
        </>
      )
      }
    </>
  )
};

export default SkillTest;