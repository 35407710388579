import React from "react";
import GeneralSetting from "views/GeneralSetting";
import { useHistory } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Settings() {

  const history = useHistory();
  const navigateToPaymentSettings = () => {
    history.push("/admin/Payments-settings");
  };
  const navigateToGeneralSettings = () => {
    history.push("/admin/General-Settings");
  };
  const navigateToSkillMaster = () => {
    history.push("/admin/Skill-master");
  };
  const navigateToCategoryMaster = () => {
    history.push("/admin/Category-master");
  };
  const navigateToTechnologyMaster = () => {
    history.push("/admin/Technology-Master");
  };
  const navigateToLocationMaster = () => {
    history.push("/admin/Location-Master");
  };
  const navigateToLanguageMaster = () => {
    history.push("/admin/Language-Master");
  };

  const navigateToDegreeMaster = () => {
    history.push("/admin/Degree-Master");
  };

  return (
    <>
      <Container fluid>
        <Row>
          {/* <Col lg="6" sm="12">
            <Card className="card-stats"
            //  onClick={navigateToPaymentSettings}
            >
               <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Payment Settings</Card.Title>
                    </div>
                  </Col> 
                </Row>
              </Card.Body> 
            </Card>
          </Col> */}
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToGeneralSettings}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">General Settings</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToDegreeMaster}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Degree Master</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToSkillMaster}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Skill Master</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToCategoryMaster}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Category Master</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToTechnologyMaster}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Technology Master</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToLocationMaster}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Location Master</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToLanguageMaster}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      <p className="card-category">Settings</p>
                      <Card.Title as="h3">Language Master</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>


        </Row>

      </Container>
    </>
  );
}

export default Settings;
