import React, { useState, useContext, useEffect } from "react";
import MyContext from "MyContext";
import Selectant from "react-select";
import axios from "axios";
import { message } from "antd";
import Select from "react-select";
import { Country } from "country-state-city";
import edit from "assets/img/edit1.jpg";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function EditJob({ Job, button, setdetailview }) {
    const [showmodel, setshowModel] = useState(false);
    const { endPointUrl, Token } = useContext(MyContext);
    const [skillmaster, setskillmaster] = useState([]);
    const [Description, setDescription] = useState(Job.Description);
    const [Languagemaster, setLanguagemaster] = useState([]);
    const [LocationSelected, setLocationSelected] = useState(Job?.Location);
    const [technologyMaster, settechnologyMaster] = useState([]);
    const [LanguageSelected, setLanguageSelected] = useState(Job?.Language);
    const [categoryMaster, setCategorymaster] = useState([]);
    const [LocationMaster, setLocationMaster] = useState([]);
    const [tags, setTags] = useState(Job.Keywords ? Job.Keywords.split(",") : []);
    const [skillSelected, setskillSelected] = useState(Job.SkillsRequired);
    const [technologySelected, settechnologySelected] = useState(Job.Technology);
    const [categorySelected, setCategorySelected] = useState(Job.Category);

    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        a.push({ value: "Other", label: "Other" })
        setskillmaster(a);
    };

    const handletechnologyMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getAlltechnology", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        a.push({ value: "Other", label: "Other" })
        settechnologyMaster(a);
    };

    const handleLocationMaster = async () => {
        let a = [];
        Country.getAllCountries().map((item) => a.push({ value: item.name, label: item.name }));
        a.push({ value: "Other", label: "Other" })
        setLocationMaster(a);
    };

    const handlecategoryMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallcategory", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        a.push({ value: "Other", label: "Other" })
        setCategorymaster(a);
    };

    const handleLanguageMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        setLanguagemaster(a);
    };

    const [job, setjob] = useState({
        Title: Job?.Title || "",
        Budget_From: Job?.Budget_From || "",
        ProjectType: Job?.ProjectType || "",
        Budget_To: Job?.Budget_To || "",
        Budget_Type: Job?.Budget_Type || "",
        Type: Job?.Type || "",
        Completion_Date: Job?.Completion_Date || null,
        expertise: Job?.expertise || "",
        projectTime: Job?.projectTime || "",
        projectScope: Job?.projectScope || "",
        JobCategory: Job?.JobCategory || "",
        shortDescription: Job?.shortDescription || "",
        SkillsRequired: Job?.SkillsRequired || "",
        Category: Job?.Category || "",
    });



    const [form, setForm] = useState({
        jobTitle: "",
        Budget_Type: "",
        Budget_From: "",
        ProjectType: "",
        Budget_To: "",
        Type: "",
        skillSelected: "",
        categorySelected: "",
        technologySelected: "",
        // LocationSelected: [],
        // LanguageSelected: "",
        Description: "",
        expertise: "",
        projectScope: "",
        shortDescription: "",
        projectTime: "",
    })


    const handleExpModel = () => {
        setshowModel(true);
        setjob({
            Title: Job?.Title || "",
            Budget_From: Job?.Budget_From || "",
            ProjectType: Job?.ProjectType || "",
            Budget_To: Job?.Budget_To || "",
            Budget_Type: Job?.Budget_Type || "",
            Type: Job?.Type || "",
            Completion_Date: Job?.Completion_Date || null,
            expertise: Job?.expertise || "",
            projectTime: Job?.projectTime || "",
            projectScope: Job?.projectScope || "",
            JobCategory: Job?.JobCategory || "",
            shortDescription: Job?.shortDescription || "",
            Description: Job?.Description || "",
            SkillsRequired: Job?.SkillsRequired || "",
            Category: Job?.Category || "",
        })

        setDescription(Job?.Description);
        setLocationSelected(Job?.Location);
        setLanguageSelected(Job?.Language);
        setTags(Job.Keywords ? Job.Keywords.split(",") : []);
        setskillSelected(Job.SkillsRequired);
        settechnologySelected(Job.Technology);
        setCategorySelected(Job.Category);
    };

    const CloseModel = () => {
        setshowModel(false);
    };

    const handleSubmitUseredit = async (e) => {
        e.preventDefault();
        const newFormErrors = {
            Budget_FromTitle: "",
            jobTitle: "",
            ProjectType: "",
            Budget_Type: "",
            Budget_From: "",
            Budget_To: "",
            Type: "",
            skillSelected: "",
            categorySelected: "",
            technologySelected: "",
            // LocationSelected: "",
            // LanguageSelected: "",
            Description: "",
            expertise: "",
            projectScope: "",
            shortDescription: "",
            projectTime: "",
        };

        let showError = false;
        if (job.Budget_From > job.Budget_To) {
            showError = true
            newFormErrors.Budget_FromTitle = "Budget from cant be less than Budget to"
        } else {
            newFormErrors.Budget_FromTitle = ''
        }
        if (job.Title === null || job.Title === "") {
            showError = true;
            newFormErrors.jobTitle = 'Please fill out this field!';
        } else {
            newFormErrors.jobTitle = '';
        }
        if (job.ProjectType === null || job.ProjectType === "") {
            showError = true;
            newFormErrors.ProjectType = 'Please fill out this field!';
        } else {
            newFormErrors.ProjectType = '';
        }
        if (job.Budget_Type == null || job.Budget_Type === "") {
            showError = true;
            setForm({ ...form, Budget_Type: 'Please fill out this field!' });
            newFormErrors.Budget_Type = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, Budget_Type: "" })
        }
        if (job.Budget_From == null || job.Budget_From === "") {
            showError = true;
            setForm({ ...form, Budget_From: 'Please fill out this field!' });
            newFormErrors.Budget_From = 'Please fill out this field!';
        }
        else if (isNaN(parseInt(job.Budget_From))) {
            showError = true;
            setForm({ ...form, Budget_From: 'Should contain only numbers' });
            newFormErrors.Budget_From = 'Should contain only numbers!';
        }
        else {
            setForm({ ...form, Budget_From: "" })
        }
        if (job.Budget_To == null || job.Budget_To === "") {
            showError = true;
            setForm({ ...form, Budget_To: 'Please fill out this field!' });
            newFormErrors.Budget_To = 'Please fill out this field!';

        } else if (parseInt(job.Budget_From) > parseInt(job.Budget_To)) {
            showError = true;
            setForm({ ...form, Budget_From: 'Mininum Budget cant be more than Maximum' });
            newFormErrors.Budget_From = 'Mininum Budget cant be more than Maximum';
        } else if (isNaN(parseInt(job.Budget_To))) {
            showError = true;
            setForm({ ...form, Budget_To: 'Should contain only numbers' });
            newFormErrors.Budget_To = 'Should contain only numbers!';
        } else {
            setForm({ ...form, Budget_To: "" })

        }
        if (job.Type == null || job.Type === "") {
            showError = true;
            setForm({ ...form, Type: 'Please fill out this field!' });
            newFormErrors.Type = 'Please fill out this field!';
        } else {
            setForm({ ...form, Type: "" })
        }
        if (skillSelected.length == 0) {
            showError = true;
            setForm({ ...form, skillSelected: 'Please fill out this field!' });
            newFormErrors.skillSelected = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, skillSelected: "" })
        }
        if (categorySelected.length == 0) {
            showError = true;
            setForm({ ...form, categorySelected: 'Please fill out this field!' });
            newFormErrors.categorySelected = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, categorySelected: "" })
        }
        if (technologySelected.length == 0) {
            showError = true;
            setForm({ ...form, technologySelected: 'Please fill out this field!' });
            newFormErrors.technologySelected = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, technologySelected: "" })
        }
        // if (LocationSelected.length == 0) {
        //   showError = true;
        //   setForm({ ...form, LocationSelected: 'Please fill out this field!' });
        //   newFormErrors.LocationSelected = 'Please fill out this field!';
        // }
        // else {
        //   setForm({ ...form, LocationSelected: "" })
        // }
        // if (LanguageSelected.length == 0) {
        //   showError = true;
        //   setForm({ ...form, LanguageSelected: 'Please fill out this field!' });
        //   newFormErrors.LanguageSelected = 'Please fill out this field!';
        // }
        // else {
        //   setForm({ ...form, LanguageSelected: "" })
        // }
        if (Description === "") {
            showError = true;
            setForm({ ...form, Description: 'Please fill out this field!' });
            newFormErrors.Description = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, Description: "" })
        }
        if (job.projectScope.length === 0) {
            showError = true;
            setForm({ ...form, projectScope: "Please fill out this field!" })
            newFormErrors.projectScope = "Please fill out this field!";
        }
        if (job.projectTime.length === 0) {
            showError = true;
            setForm({ ...form, projectTime: "Please fill out of this field!" });
            newFormErrors.projectTime = "Please fill out this field!";
        }
        if (job.expertise.length === 0) {
            showError = true;
            setForm({ ...form, expertise: "Please fill out this field!" })
            newFormErrors.expertise = "Please fill out this field!";
        }
        if (job.shortDescription.length === 0) {
            showError = true;
            setForm({ ...form, shortDescription: "Please fill out this field!" })
            newFormErrors.shortDescription = "Please fill out this field!"
        }
        setForm(newFormErrors);
        if (showError === false) {
            try {
                await axios.post(endPointUrl + "/user/UpdateJobs", {
                    ...job,
                    JobID: Job.JobID,
                    Location: Array.isArray(LocationSelected) ? LocationSelected.join(",") : LocationSelected,
                    SkillsRequired: Array.isArray(skillSelected) ? skillSelected.join(",") : skillSelected,
                    Technology: Array.isArray(technologySelected) ? technologySelected.join(",") : technologySelected,
                    Category: Array.isArray(categorySelected) ? categorySelected.join(",") : categorySelected,
                    Language: Array.isArray(LanguageSelected) ? LanguageSelected.join(",") : LanguageSelected,
                    Token,
                });
                if (button) {
                    setdetailview(false);
                }
                setshowModel(false);
                message.success(" Job updated SuccessFully");
            } catch (error) {
                console.log("Error Job is not updated", error)
                message.error("Job is Not Updated");
            }
        }
    };

    const onEditorChange = (evt, editor) => {
        const data = evt.editor && evt.editor.getData();
        setjob({ ...job, Description: data });
    };
    useEffect(() => {
        handleskillsmaster();
        handlecategoryMaster();
        handleLanguageMaster();
        handletechnologyMaster();
        handleLocationMaster();
    }, []);
    return (
        <>
            {button ? <button
                style={{
                    background: "#283c0ade",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
                onClick={() => handleExpModel()}
            >
                Edit
            </button> :
                <div className="editimg" >
                    <img src={edit} alt="" onClick={() => handleExpModel()} />
                </div>
            }
            <div
                id="myModal"
                className={showmodel ? "model-open" : "model"}
                style={{ overflow: "scroll" }}
            >
                <div className="modal-content" style={{ color: 'black' }}>
                    <span className="close" onClick={CloseModel}>
                        &times;
                    </span>
                    <h2>Edit Job Post</h2>

                    <form
                        onSubmit={(e) => {
                            handleSubmitUseredit(e)
                        }}
                    >
                        <div style={{ marginTop: '5%', boxShadow: '0px 0px 5px 2px #f2f2f2', padding: '50px 10px', boxSizing: 'border-box', borderRadius: '10px' }}>
                            <div className="userdeatail_container">
                                <div>
                                    <label>Job heading<span className="asterisk">*</span></label>
                                    <input
                                        type="text"
                                        value={job.Title}
                                        name="Title"
                                        placeholder="Enter Your title"
                                        className={form.jobTitle != "" ? "errorborder" : ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value.slice(0, 80);
                                            if (!inputValue.trim()) {
                                                return setjob({ ...job, Title: "" });
                                            }
                                            setjob({ ...job, Title: inputValue });
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.jobTitle}</small>
                                </div>

                                <div className={job.projectScope ? "userdeatail_container" : ""} style={{ padding: "0px" }}>
                                    <div>
                                        <label>Project Scope<span className="asterisk">*</span></label>
                                        <Selectant
                                            name="Projectscope"
                                            placeholder="Project Scope"
                                            value={job?.projectScope.split(",").map((item) => ({ value: item, label: item }))}
                                            className={form.projectScope != "" ? "errorborder" : ""}
                                            options={[{ value: "", label: "Select Project Scope" }, { value: "One Time Project", label: "One Time project" }, { value: "Long Time Project", label: "Long Time Project" }]}
                                            onChange={(value) => setjob({ ...job, projectScope: value.value })}
                                        />
                                        <small style={{ color: "#ec1c24" }}>{form.projectScope}</small>
                                    </div>

                                    {job.projectScope === "One Time Project" ?
                                        <div>
                                            <label>Select<span className="asterisk">*</span></label>
                                            <Selectant
                                                name="Projectscope"
                                                className={form.projectTime != "" ? "errorborder" : ""}
                                                placeholder="Select"
                                                value={job?.projectTime.split(",").map((item) => ({ value: item, label: item }))}
                                                options={[{ value: "Less than 1 month", label: "Less than 1 month" }, { value: "1 to 3 months", label: "1 to 3 months" }, { value: "3 to 6 months", label: "3 to 6 months" }, { value: "Greater than 1 year", label: "Greater than 1 year" }]}
                                                onChange={(value) => setjob({ ...job, projectTime: value.value })}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.projectTime}</small>
                                        </div> : <></>}

                                    {job.projectScope === "Long Time Project" ?
                                        <div>
                                            <label>Select<span className="asterisk">*</span></label>
                                            <Selectant
                                                name="Projectscope"
                                                value={job?.projectTime.split(",").map((item) => ({ value: item, label: item }))}
                                                className={form.projectTime != "" ? "errorborder" : ""}
                                                placeholder="Select"
                                                options={[{ value: "1 to 3 months", label: "1 to 3 months" }, { value: "3 to 6 months", label: "3 to 6 months" }, { value: "Greater than 1 year", label: "Greater than 1 year" }]}
                                                onChange={(value) => setjob({ ...job, projectTime: value.value })}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.projectTime}</small>
                                        </div> : <></>}
                                </div>
                            </div>



                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <label>Expertise<span className="asterisk">*</span></label>
                                    <Selectant
                                        className={form.expertise != "" ? "errorborder" : ""}
                                        value={job?.expertise.split(",").map((item) => ({ value: item, label: item }))}
                                        options={[{ value: "Beginner", label: "Beginner" }, { value: "Intermediate", label: "Intermediate" }, { value: "Expert", label: "Expert" },]}
                                        onChange={(value) => setjob({ ...job, expertise: value.value })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.expertise}</small>
                                </div>

                                <div>
                                    <label>Project Type<span className="asterisk">*</span></label>
                                    <Selectant
                                        value={job?.ProjectType.split(",").map((item) => ({ value: item, label: item }))}
                                        className={form.ProjectType != "" ? "errorborder" : ""}
                                        options={[{ value: "Fixed", label: "Fixed" }, { value: "Hourly", label: "Hourly" }]}
                                        onChange={(value) => {
                                            setjob({ ...job, ProjectType: value.value })
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.ProjectType}</small>
                                </div>
                            </div>


                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <label>Budget<span className="asterisk">*</span></label>
                                    <div style={{ display: 'flex' }}>
                                        <div className="bid_range" style={{ paddingRight: "2%", marginRight: "10%" }}>
                                            <label htmlFor="Budget_From" style={{ color: "GrayText" }}>From </label>
                                            <input
                                                value={job?.Budget_From}
                                                type="text"
                                                name="Budget_From"
                                                placeholder={job.ProjectType === "Hourly" ? "Min hourly" : "Min"}
                                                className={form.Budget_From != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                                        setjob({ ...job, Budget_From: input.slice(0, 6) });
                                                    } else {
                                                        setjob({ ...job, Budget_From: "" });
                                                    }
                                                }}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.Budget_From}</small>
                                        </div>
                                        <div className="bid_range" style={{ paddingRight: "2%" }}>
                                            <label htmlFor="Budget_To" style={{ color: "GrayText" }}>To </label>
                                            <input
                                                value={job?.Budget_To}
                                                type="text"
                                                name="Budget_To"
                                                placeholder={job.ProjectType === "Hourly" ? "Max hourly" : "Max"}
                                                className={form.Budget_To != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                                        setjob({ ...job, Budget_To: input.slice(0, 6) });
                                                    } else {
                                                        setjob({ ...job, Budget_To: "" });
                                                    }
                                                }}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.Budget_To}</small>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="Priority" style={{ margin: 0 }}>Job Type<span className="asterisk">*</span></label>
                                    <div className="type_job" style={{ marginTop: "20px" }}>
                                        <div>
                                            <input style={{ width: "auto" }}
                                                type="radio" value="Full-time" name="Type"
                                                checked={job.Type === "Full-time" ? true : false}
                                                onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Full time</div>
                                        <div>
                                            <input style={{ width: "auto" }}
                                                type="radio" value="Part-time" name="Type"
                                                checked={job.Type === "Part-time" ? true : false}
                                                onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Part time</div>
                                    </div>
                                    <small style={{ color: "#ec1c24" }}>{form.Type}</small>
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <label>Payment Type<span className="asterisk">*</span></label>
                                    <Selectant
                                        name="Budget_type"
                                        className={form.Budget_Type != "" ? "errorborder" : ""}
                                        value={job?.Budget_Type.split(",").map((item) => ({ value: item, label: item }))}
                                        onChange={(e) =>
                                            setjob({ ...job, Budget_Type: e.value })
                                        }
                                        options={[{ value: "Hourly", label: "Hourly" }, { value: "Weekly", label: "Weekly" }, { value: "Monthly", label: "Monthly" }]}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.Budget_Type}</small>
                                </div>

                                <div>
                                    <label htmlFor="SkillsRequired">Skills<span className="asterisk">*</span></label>
                                    <Selectant
                                        isMulti
                                        label={"Enter Skills"}
                                        value={Array.isArray(skillSelected) ? skillSelected.map((item) => ({ value: item, label: item })) : skillSelected?.split(",").map((item) => ({ value: item, label: item }))}
                                        options={skillmaster}
                                        onChange={(selectedOptions) => {
                                            const skill = selectedOptions.map(option => option.value);
                                            setskillSelected(skill);
                                        }}
                                        className={form.skillSelected != "" ? "errorborder" : ""}
                                        type="skills"
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.skillSelected}</small>

                                    {/* {skillSelected.includes("Other") ? <div style={{ marginTop: '2%' }}>
                    <input
                      type="text"
                      placeholder="Enter Description"
                    />
                  </div> : <></>} */}
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <label htmlFor="SkillsRequired">Job Category<span className="asterisk">*</span></label>
                                    <Selectant
                                        isMulti
                                        label="Category"
                                        value={Array.isArray(categorySelected) ? categorySelected.map((item) => ({ value: item, label: item })) : categorySelected?.split(",").map((item) => ({ value: item, label: item }))}
                                        options={categoryMaster}
                                        onChange={(selectedOptions) => {
                                            const category = selectedOptions.map(option => option.value);
                                            setCategorySelected(category);
                                        }}
                                        className={form.categorySelected != "" ? "errorborder" : ""}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.categorySelected}</small>
                                    {/* {categorySelected.includes("Other") ?
                    <div style={{ marginTop: '2%' }}>
                      <input
                        type="text"
                        placeholder="Enter Description"
                      />
                    </div> : <></>} */}
                                </div>


                                <div>
                                    <label htmlFor="Enter Technology">Technology<span className="asterisk">*</span></label>
                                    <Selectant
                                        isMulti
                                        label="Technology"
                                        value={Array.isArray(technologySelected) ? technologySelected.map((item) => ({ value: item, label: item })) : technologySelected?.split(",").map((item) => ({ value: item, label: item }))}
                                        options={technologyMaster}
                                        onChange={(choosedOption) => {
                                            const tech = choosedOption.map((item) => item.value)
                                            settechnologySelected(tech)
                                        }}
                                        className={form.technologySelected != "" ? "errorborder" : ""}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.technologySelected}</small>

                                    {/* {technologySelected.includes("Other") ?
                    <div style={{ marginTop: '2%' }}>
                      <input
                        type="text"
                        placeholder="Enter Description"
                      />
                    </div> : <></>} */}
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <label htmlFor="SkillsRequired">Location Preference</label>
                                    <Select
                                        isMulti
                                        // className={form.LocationSelected != "" ? "errorborder" : ""}
                                        options={LocationMaster}
                                        value={Array.isArray(LocationSelected) ? LocationSelected.map((item) => ({ value: item, label: item })) : LocationSelected?.split(",").map((item) => ({ value: item, label: item }))}

                                        // getOptionLabel={(option) => option.name}
                                        // getOptionValue={(option) => option.name}
                                        onChange={(selectedOptions) => {
                                            const lang = selectedOptions.map(option => option.value);
                                            setLocationSelected(lang);
                                        }}
                                    />
                                    {/* <small style={{ color: "#ec1c24" }}>{form.LocationSelected}</small> */}
                                </div>

                                <div>
                                    <label htmlFor="Enter Language">Language Preference</label>
                                    <Selectant
                                        isMulti
                                        label="Language"
                                        options={Languagemaster}
                                        value={Array.isArray(LanguageSelected) ? LanguageSelected.map((item) => ({ value: item, label: item })) : LanguageSelected?.split(",").map((item) => ({ value: item, label: item }))}
                                        onChange={(selectedOptions) => {
                                            const lang = selectedOptions.map(option => option.value);
                                            setLanguageSelected(lang);
                                        }}
                                    // className={form.LanguageSelected != "" ? "errorborder" : ""}
                                    />
                                    {/* <small style={{ color: "#ec1c24" }}>{form.LanguageSelected}</small> */}
                                </div>
                            </div>


                            <div className="userdeatail_container" style={{ gridTemplateColumns: "100%", marginTop: "3%" }}>
                                <div>
                                    <label>Short Description<span className="asterisk">*</span></label>
                                    <input
                                        style={{ width: '99%' }}
                                        type="text"
                                        value={job.shortDescription}
                                        className={form.shortDescription != "" ? "errorborder" : ""}
                                        placeholder="Short Description"
                                        onChange={(event) => {
                                            if (event.target.value.split(" ").length <= 50)
                                                setjob({ ...job, shortDescription: event.target.value })
                                            else
                                                message.warning("Short Description Not More than 50 Words")
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.shortDescription}</small>
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ gridTemplateColumns: "100%", marginTop: "3%" }}>
                                <label htmlFor="Description">Description<span className="asterisk">*</span></label>
                                <article>
                                    {Description ?
                                        // <CKEditor
                                        //     initData={Description}
                                        //     placeholder="Give Description About Your Project..."
                                        //     className={form.Description != "" ? "errorborder" : ""}
                                        //     config={{
                                        //         toolbar: [
                                        //             { name: "document", items: ["Undo", "Redo"] },
                                        //             { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                                        //             { name: "styles", items: ["Format"] },
                                        //             {
                                        //                 name: "basicstyles",
                                        //                 items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                                        //             },
                                        //         ],
                                        //     }}
                                        //     onChange={onEditorChange}
                                        // />
                                        <CKEditor
                                            placeholder="Give Description About Your Project..."
                                            className={form.Description != "" ? "errorborder" : ""}
                                            data={Description}
                                            editor={ClassicEditor}
                                            onChange={onEditorChange}
                                        />
                                        : <></>}
                                </article>
                                <small style={{ color: "#ec1c24" }}>{form.Description}</small>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <input
                                    type="submit"
                                    value="Edit Job"
                                    className="buton"
                                    style={{ marginBlockStart: "20px" }}
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default EditJob;