import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css";
import MyContext from "MyContext";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PaginationSection from "./PaginationSection";
import ReactPaginate from "react-paginate";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function SkillTestInvoices() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [ID, setID] = useState([]);
  const [allDetails, setallDetails] = useState([]);
  const [sortOption, setSortOption] = useState("Latest");

  const history = useHistory();

  
  function generateInvoiceNumber(paymentDate,CertificatePayment_ID) {
    const date = new Date(paymentDate);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month because it's zero-based
    const invoiceCountStr = '001'; // You can start with '001' and increment it for each invoice
    const invoiceNumber = `INV-${year}-${month}-00${CertificatePayment_ID}`;
    return invoiceNumber;
  }

  const getPaidSkillTestInvoices = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/get-all-certificate-payment", {
        Token,
      });

      setallDetails(data.data);
      // console.log(data.data);
    } catch {
      console.error("getPaidSkillTestInvoices is not working");
    }
  };

  // const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);
  const data = React.useMemo(
    () => (Array.isArray(allDetails) ? allDetails : []),
    [allDetails]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "", // Access the payment date to generate the invoice number
        Cell: ({ row }) => (
          <span>
            {generateInvoiceNumber(row.original.Payment_date, row.original.CertificatePayment_ID)}
          </span>
        ),
      },
      {
        Header: "Candidate",
        accessor: "",
        Cell: ({ row }) => {
            // console.log(row.original);
            const name = row.original.user.Name;
            return name;
          }
      },
      {
        Header: "Skill Test",
        accessor: "skillTest.SKT_Title",
      },
      {
        Header: "Amount",
        accessor: "Amount",
      },
      {
        Header: "Payment Date",
        accessor: "Payment_date",
        Cell: ({ value }) => <span>{moment(value).format("DD/MM/YYYY")}</span>,
        // sortType: "datetime", // Enable sorting by Payment Date
      },
      {
        Header: "Payment Reference",
        accessor: "PaymentReference",
      },
      {
        Header: "Action",
        accessor: "Payment_ID",
        Cell: ({ row }) => (
          <div>
            <span
              onClick={() => handleclick(row.original.CertificatePayment_ID, row.original.Payment_date)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <Button style={{ fill: "#07b274" }}>View</Button>
            </span>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getPaidSkillTestInvoices();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
      sortBy: [{ id: "Payment_date", desc: sortOption === "Oldest" }],
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };


  const handleclick = (CertificatePayment_ID, Payment_date) => {
    const invoiceNumber = generateInvoiceNumber(Payment_date, CertificatePayment_ID);
    // const url = `/Invoice-Generator/${Id}/${invoiceNumber}`;
    const url = `/Test-Invoice-Generator/?invoiceNumber=${invoiceNumber}&Id=${CertificatePayment_ID}`;
    window.open(url, '_blank'); // Open the URL in a new tab
  };
  return (
    <>

      <Container fluid >
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Col lg="10">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        //   className="mx-2"
                        aria-label="Search"
                        value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <Form.Control
                        as="select"
                        value={sortOption}
                        onChange={handleSortChange}
                      //   className="mx-2"
                      >
                        <option value="Latest">Latest</option>
                        <option value="Oldest">Oldest</option>
                      </Form.Control>

                      {/* //   type="search"
                    //   placeholder="Sort"
                    //   aria-label="Sort"
                    //   value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} */}

                    </Col>
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {/* {console.log(row.original.id)} */}
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}
                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />

            </Card>

          </Col>
        </Row>
      </Container>



    </>
  );
}
export default SkillTestInvoices;