import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom"; // Import useNavigate from react-router-dom
import MyContext from "MyContext";
import axios from "axios";
import moment from "moment";
import BiddingUserInformation from "./BiddingUserInfo";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function BiddingCard({ ID }) {
  const history = useHistory(); // Initialize useHistory hook
  const { Token, endPointUrl } = useContext(MyContext);
  const [bits, setBits] = useState([]);
  const [employebit, setemployebit] = useState([]);
  const [totallength, settotallength] = useState(0);
  const [Allbits, setAllBits] = useState({
    New: [],
    Review: [],
    Approved: [],
    Rejected: [],
  });
  const [invitation, setInvitation] = useState([])
  const [activejob, setActivejob] = useState(0);
  const handlejobClick = (buttonIndex) => {
    // console.log(bits);
    setActivejob(buttonIndex);
  };

  const gettotalbits = async (ID) => {
    console.log(ID)
    const { data } = await axios.post(endPointUrl + "/user/job/totalBid", {
      Token,
      JobID: ID
    });


    const res = await axios.post(endPointUrl + "/getInvitationByJobID", {
      Token,
      JobID: ID
    });



    settotallength((data.data).length);

    // console.log((data.data));

    const New = data.data.filter(
      (item) => item.View_Status === false);

    const Review = data.data.filter(
      (item) => item.Bid_Status === "Active" && item.View_Status
    );
    const Approved = data.data.filter(
      (item) => item.Bid_Status === "Approved" && item.View_Status
    );
    // let approved = Approved.map((item) => {
    //   return item;
    // })
    const Rejected = data.data.filter(
      (item) => item.Bid_Status === "Reject" && item.View_Status
    );
    setActivejob(0);
    setBits(New);
    setAllBits({ New, Review, Approved, Rejected });
    setInvitation(res.data.data)
    setemployebit(Approved);
  };

  // sorting based on price
  const handleSortByAmount = (value) => {
    const sortedBits = [...bits]; // Create a copy of the 'bits' array
    if (value === "low")
      sortedBits.sort((a, b) => a.BidAmount - b.BidAmount);
    else if (value === "high")
      sortedBits.sort((a, b) => b.BidAmount - a.BidAmount);
    else if (value === "new") {
      sortedBits.sort((a, b) => moment(a.Bid_date).diff(moment(b.Bid_date)));
    }
    else if (value === "old") {
      sortedBits.sort((a, b) => moment(b.Bid_date).diff(moment(a.Bid_date)));
    }
    // Sort by the 'Amount' property in ascending order
    setBits(sortedBits); // Update the state with the sorted array
  };


  useEffect(() => {
    gettotalbits(ID);
  }, []);

  const showInvitations = () => {
    if (invitation.length > 0) {
      return invitation?.map((item) => {
        return (<>
          {/* <div>{item["User.id"]}</div> */}
          <div>{item["User.Name"]} {item["User.Last_Name"]}</div>
          <hr />
        </>)
      })
    }
    else {
      return (<span>No Invitation</span>)
    }
  }

  return (
    <>

      <Card className="jobContainer">

        <div style={{ fontSize: '26px', fontWeight: '600', lineHeight: '39px' }}>
          <span>Bids({totallength})</span>
          <select name="Bits" id="Bits" style={{
            background: "#ffffff",
            borderRadius: "3px",
            outline: "none", fontSize: "12px", padding: '5px 0', display: "inline-block", float: 'right'
          }} onChange={(e) => handleSortByAmount(e.target.value)} >
            <option>Sort</option>
            <option value="low">Low to High</option>
            <option value="high">High to Low</option>
            <option value="new">Latest</option>
            <option value="old">Oldest</option>
          </select></div>
        <div className="jobMatch">
          <span
            style={
              activejob === 0
                ? {
                  borderBottom: "4px solid #07b273",
                }
                : null
            }
            onClick={() => {
              setBits(Allbits.New);
              handlejobClick(0);
            }}
          >
            New({Allbits.New.length})
          </span>
          <span
            style={
              activejob === 1
                ? {
                  borderBottom: "4px solid #07b273",
                }
                : null
            }
            onClick={() => {
              setBits(Allbits.Review);
              handlejobClick(1);
            }}
          >
            Review({Allbits.Review.length})
          </span>
          <span
            style={
              activejob === 2
                ? {
                  borderBottom: "4px solid #07b273",
                }
                : null
            }
            onClick={() => {
              setBits(Allbits.Approved);
              handlejobClick(2);
            }}
          >
            Approved({Allbits.Approved.length})
          </span>
          <span
            style={
              activejob === 3
                ? {
                  borderBottom: "4px solid #07b273",
                }
                : null
            }
            onClick={() => {
              setBits(Allbits.Rejected);
              handlejobClick(3);
            }}
          >
            Rejected({Allbits.Rejected.length})
          </span>

        </div>
        <hr />

        {bits.length > 0 ? bits.map((item) => (
          <div className="bittingCard">
            {/* <h1>{activejob}</h1> */}
            <BiddingUserInformation item={item} />
          </div>
        )) : <span>No bids</span>}
      </Card>

    </>
  );
}

export default BiddingCard;