import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import skillMaster from "views/skillMaster";
// import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
// import Maps from "views/Maps.js";
import Freelancer from "views/Freelancer.js";
import Employers from "views/Employers";
import GeneralSetting from "views/GeneralSetting";
import Finance from "views/Finance";
import SiteManagement from "views/SiteManagement";
import Report from "views/Report";
import Support from "views/Support";
import Settings from "views/Settings";
import QuestionBank from "views/QuestionBank";
import Jobs from "views/Jobs";
import Projects from "views/Projects";
import SkillTest from "views/SkillTest";
import Verification from "views/Verification";
import Testimonial from "views/Testimonial";
import GuideDigilanxer from "views/GuideDigilanxer";
import Newsletter from "views/Newsletter";
import PartnerWithUs from "views/PartnerWithUs";
import DisplayPartnerWithUs from "views/DisplayPartnerWithUs";
import DisplayProduct from "views/DisplayProduct";
import Bids from "views/Bids";
import DisplayDispute from "views/DisplayDispute";
// import Upgrade from "views/Upgrade.js";

const dashboardRoutes = [
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-alien-33",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  {
    id: 1,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
    accordian: false,
  },
  // {
  //   id:2,
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",

  // },
  {
    id: 3,
    path: "/Freelancers",
    name: "Freelancers",
    icon: "nc-icon nc-bell-55",
    component: Freelancer,
    layout: "/admin",
    accordian: false,
    // accordian:true,
    // accordianpath:[{path: "/icons",
    // name: "Web",
    // icon: "nc-icon nc-atom",
    // component: Icons,
    // layout: "/admin"},{path: "/icons",
    // name: "App",
    // icon: "nc-icon nc-atom",
    // component: Icons,
    // layout: "/admin"},{path: "/icons",
    // name: "Software",
    // icon: "nc-icon nc-atom",
    // component: Icons,
    // layout: "/admin"},]
  },
  {
    id: 4,
    path: "/Employers",
    name: "Employers",
    icon: "nc-icon nc-bell-55",
    component: Employers,
    layout: "/admin",
    accordian: false,
    // accordianpath: [
    //   {
    //     path: "/Ongoing Projects",
    //     name: "Ongoing Projects",
    //     icon: "nc-icon nc-atom",
    //     component: OngoingProjects,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    //   {
    //     path: "/Closed Projects",
    //     name: "Closed Projects",
    //     icon: "nc-icon nc-atom",
    //     component: Icons,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    // ],
  },
  {
    id: 5,
    path: "/jobs",
    name: "Jobs",
    icon: "nc-icon nc-atom",
    component: Jobs,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 6,
    path: "/projects",
    name: "Projects",
    icon: "nc-icon nc-atom",
    component: Projects,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 7,
    path: "/SkillTests",
    name: "Certification",
    icon: "nc-icon nc-atom",
    component: SkillTest,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 8,
    path: "/QuestionBank",
    name: "QuestionBank",
    icon: "nc-icon nc-atom",
    component: QuestionBank,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 9,
    path: "/Finance",
    name: "Finance",
    icon: "nc-icon nc-bell-55",
    component: Finance,
    layout: "/admin",
    accordian: false,
    // accordianpath: [
    //   {
    //     path: "/Pending Invoices",
    //     name: "Pending Invoices",
    //     icon: "nc-icon nc-atom",
    //     component: Icons,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    //   {
    //     path: "/Payment to Freelancer",
    //     name: "Payment to Freelancer",
    //     icon: "nc-icon nc-atom",
    //     component: Icons,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    //   {
    //     path: "/Track Payment",
    //     name: "Track Payment",
    //     icon: "nc-icon nc-atom",
    //     component: Icons,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    //   {
    //     path: "/Refunds",
    //     name: "Refunds",
    //     icon: "nc-icon nc-atom",
    //     component: Icons,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    // ],
  },
  {
    id: 10,
    path: "/Reviews",
    name: "Reviews",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
    accordian: false,
  },
  // {
  //   id: 11,
  //   path: "/SiteManagement",
  //   name: "Site Management",
  //   icon: "nc-icon nc-bell-55",
  //   component: SiteManagement,
  //   layout: "/admin",
  //   accordian: false,
  //   // accordianpath: [
  //   //   {
  //   //     path: "/Banners",
  //   //     name: "Banners",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   //   {
  //   //     path: "/Edit",
  //   //     name: "Edit Content",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   //   {
  //   //     path: "/Advertisements",
  //   //     name: "Advertisements",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   //   {
  //   //     path: "/Style-Change",
  //   //     name: "Style Change",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   // ],
  // },
  // {
  //   id: 12,
  //   path: "/Messages",
  //   name: "Messages",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   id: 13,
  //   path: "/Reports",
  //   name: "Reports",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Report,
  //   layout: "/admin",
  //   accordian: false,
  //   // accordianpath: [
  //   //   {
  //   //     path: "/Report1",
  //   //     name: "Report1",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   // ],
  // },
  // {
  //   id: 14,
  //   path: "/Support",
  //   name: "Support",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Support,
  //   layout: "/admin",
  //   accordian: false,
  //   // accordianpath: [
  //   //   {
  //   //     path: "/Open",
  //   //     name: "Open",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   //   {
  //   //     path: "/Closed",
  //   //     name: "Closed",
  //   //     icon: "nc-icon nc-atom",
  //   //     component: Icons,
  //   //     layout: "/admin",
  //   //     accordian: false,
  //   //   },
  //   // ],
  // },
  {
    id: 15,
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-chart-pie-35",
    component: Settings,
    layout: "/admin",
    accordian: false,
    // accordianpath: [

    //   {
    //     path: "/Payments-settings",
    //     name: "Payments Settings",
    //     icon: "nc-icon nc-atom",
    //     component: Icons,
    //     layout: "/admin",
    //     accordian: false,
    //   },
    //   {
    //     path: "/General-Settings",
    //     name: "General Settings",
    //     icon: "nc-icon nc-atom",
    //     component: GeneralSetting,
    //     layout: "/admin",
    //     accordian: false,
    //   },

    // ],

  },
  {
    id: 16,
    path: "/Verification",
    name: "verification",
    icon: "nc-icon nc-chart-pie-35",
    component: Verification,
    layout: "/admin",
    accordian: false,

  },
  {
    id: 17,
    path: "/Testimonial",
    name: "testimonial",
    icon: "nc-icon nc-chart-pie-35",
    component: Testimonial,
    layout: "/admin",
    accordian: false,

  },
  {
    id: 18,
    path: "/guidDigilanxer",
    name: "guide Digilanxer",
    icon: "nc-icon nc-chart-pie-35",
    component: GuideDigilanxer,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 18,
    path: "/newsletter",
    name: "newsletter",
    icon: "nc-icon nc-chart-pie-35",
    component: Newsletter,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 19,
    path: "/Product",
    name: "Product",
    icon: "nc-icon nc-chart-pie-35",
    component: DisplayProduct,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 20,
    path: "/PartnerWithUs",
    name: "Partner With US",
    icon: "nc-icon nc-chart-pie-35",
    component: DisplayPartnerWithUs,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 21,
    path: "/Dispute",
    name: "Dispute",
    icon: "nc-icon nc-chart-pie-35",
    component: DisplayDispute,
    layout: "/admin",
    accordian: false,
  },
  // {
  //   id: 21,
  //   path: "/AllBids",
  //   name: "Bids",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Bids,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // accordianpath: [
  //   {
  //     path: "/Skill",
  //     name: "SkillMaster",
  //     icon: "nc-icon nc-atom",
  //     component: skillMaster,
  //     layout: "/admin",
  //     accordian: false,
  //   },
  //   {
  //     path: "/Category-master",
  //     name: "Category Master",
  //     icon: "nc-icon nc-atom",
  //     component: Icons,
  //     layout: "/admin",
  //     accordian: false,
  //   },
  //   {
  //     path: "/Technology-Master",
  //     name: "Technology Master",
  //     icon: "nc-icon nc-atom",
  //     component: Icons,
  //     layout: "/admin",
  //     accordian: false,
  //   },
  // ],

];

export default dashboardRoutes;
