import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import ProjectDetails from "./Projectdetailsss";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import PaginationSection from "./PaginationSection";

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";



function Closedprojects() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [ID, setID] = useState([]);
  const [allDetails, setallDetails] = useState([]);
  const history = useHistory();

  const getclosedprojects = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getProjectsbyStatus", {
        Token,
      });

      setallDetails(data.data.closedprojects);
      // console.log(data.data.closedprojects);

      // console.log(allDetails);

    } catch {
      console.log("get ongoing projects is not working");
    }
  };
  // const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);
  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Project ID",
        accessor: "P_ID",
      },
      {
        Header: "Freelancer",
        accessor: "freelancers.Name",
      },
      {
        Header: "Employer",
        accessor: "employers.Name",
      },
      {
        Header: "Title",
        accessor: "jobs.Title",
      },
      {
        Header: "Project Type",
        accessor: "jobs.ProjectType",
      },
      {
        Header: "Freelancer Amount",
        accessor: "Proj",
        Cell: ({ value }) => {
          const amounts = value.map((milestone) => milestone.Amount);
          return <span>{amounts.join(", ")}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "Project_status",
      },

      {
        Header: "Bid Amount",
        accessor: "bid.BidAmount",
      },
      {
        Header: "Completion Date",
        accessor: "Completion_Date",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            {/* "Update" icon */}
            <span
              onClick={() => handleprojclick(row.original.P_ID)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <Button style={{ fill: "#07b274" }}>Show More</Button>
            </span>
          </div>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getclosedprojects();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  //   const EmployerSelect = (Id) => {
  //     setID(Id);
  //     let select = allDetails.filter((item) => {
  //       // console.log(cell);
  //       return (item.id === Id);
  //     })
  //     setemployer(select);
  //     console.log(employer)
  //   };

  const handleprojclick = (Id) => {
    // setShowDetails(true);
    // ProjectSelect(Id);
    console.log(Id);
    history.push(`/admin/Project-Details/${Id}`);
  };
  //   const CloseModel = () => {
  //     setshowModel(false);
  //   };

  //   const handleTabchange = (tab) => {
  //     setActiveproject(tab);
  //   }

  return (
    <>

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {/* {console.log(row.original.id)} */}
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}
                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>
      </Container>



    </>
  );
}

export default Closedprojects;