import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MyContext from "MyContext";
import JobDetails from "./Jobdetailsss";
import { useHistory } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs"
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import moment from 'moment';
import PaginationSection from "./PaginationSection";
import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function EmployerPostedJobs(Id) {
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();
  const [JobID, setID] = useState([]);
  const [job, setjob] = useState([]);


  const getPostedJobs = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/JobpostedByemployer", {
        Awarded_By_ID: Id.ID,
        Token,
      });

      setallDetails(data.data);
      console.log(Id);

    } catch {
      console.log("get ongoing projects is not working");
    }
  };
  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "JobID",
        accessor: "JobID",
      },
      {
        Header: "Title",
        accessor: "Title",
        Cell: ({ value }) => (
          <div style={{ cursor: 'pointer' }}>{value}</div>
        ),
      },
      {
        Header: "Skills Required",
        accessor: "SkillsRequired",
      },
      {
        Header: "Type",
        accessor: "Type",
      },
      {
        Header: "Posted Date",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      // {
      //   Header: "Start Date",
      //   accessor: "createdAt",
      //   Cell: ({ value }) => (
      //     <span>{moment(value).format('DD/MM/YYYY')}</span>
      //   ),
      // },
    ],
    []
  );
  useEffect(() => {
    getPostedJobs(Id);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const JobSelect = (Id) => {
    setID(Id);
    let select = allDetails.filter((item) => {
      // console.log(cell);
      return (item.id === Id);
    })
    setjob(select);
    console.log(job)
  };

  const handleclick = (Id) => {
    history.push(`/admin/Jobs-Details/${Id}`);
  };

  const CloseDetails = () => {
    setShowDetails(false);
  };


  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0">
              <Table className="table-hover table-striped " {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr  {...row.getRowProps()}>
                        {/* {console.log(row.original.id)} */}
                        {row.cells.map((cell) => {
                          //  const { column } = cell.value;
                          //  let Id = column.Name;
                          //  console.log(cell.value);

                          return (<td onClick={() => handleclick(row.original.JobID)} {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                        })}

                      </tr>
                    );
                  }

                  )}
                </tbody>
              </Table>
            </Card.Body>
            <PaginationSection
              pageIndex={pageIndex}
              pageCount={pageOptions.length}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
            />
          </Card>

        </Col>
      </Row>
    </Container>
  )
}

export default EmployerPostedJobs;