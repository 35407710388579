import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Profile from "assets/img/default-avatar.png";
import PaginationSection from './PaginationSection';
import Switch from 'react-switch'; // Import the react-switch component
import "assets/css/demo.css"
import { BsArrowLeftShort } from "react-icons/bs"
import MyContext from "MyContext";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function AllFreelancer({ handlecount }) {
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [freelancerData, setFreelancerData] = useState(null); // Store selected row data
  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();

  // Function to handle featured toggle
  const handleFeaturedToggle = async (freelancerId, newFeaturedValue) => {
    try {
      // Store the current page before the operation
      const currentPageBeforeOperation = pageIndex;
      const response = await axios.post(endPointUrl + "/updateuserProfile", {
        Token,
        id: freelancerId,
        featured: newFeaturedValue,
      });
      if (response.status) {
        setallDetails((prevDetails) => {
          const updatedData = prevDetails.map((freelancer) => {
            if (freelancer.id === freelancerId) {
              return { ...freelancer, featured: newFeaturedValue }
            } else {
              return freelancer
            }
          }
          )
          return updatedData;
        }
        );
      }
      handlecount()

    } catch (error) {
      console.error("Error updating featured status:", error);
    }
  };

  const handleStatusToggle = async (freelancerId, newStatusValue) => {
    try {
      await axios.post(endPointUrl + "/updateuserProfile", {
        Token,
        id: freelancerId,
        Status_by_Admin: newStatusValue,
      });

      setallDetails((prevDetails) =>
        prevDetails.map((freelancer) =>
          freelancer.id === freelancerId
            ? { ...freelancer, Status_by_Admin: newStatusValue }
            : freelancer
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const getFreelancers = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/freelancers", {
        Token,
      });
      setallDetails(data.data);
    } catch {
      console.log("get Freelancer details is not working");
    }
  };
  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: ' ',
        Cell: tableProps => (
          <div><img
            src={tableProps.row.original.Photo === "" || tableProps.row.original.Photo == null
              ? Profile
              : `${endPointUrl}/images/${tableProps.row.original.Photo}`}
            // src={`${endPointUrl}/images/${tableProps.row.original.Photo}`}
            width={40}
            height={40}
            style={{ borderRadius: "50%" }}
          /></div>
        )
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "City",
        accessor: "City",
      },
      {
        Header: "Contact No.",
        accessor: "Mobile",
      },
      {
        Header: "Active",
        accessor: "Status_by_Admin",
        Cell: ({ row }) => (
          <Switch
            onChange={(checked) => handleStatusToggle(row.original.id, checked)}
            checked={row.original.Status_by_Admin}
            onColor="#86d3ff"
            onHandleColor="#07b274"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        ),
      },
      {
        Header: "Featured Freelancer",
        accessor: "featured",
        Cell: ({ row }) => (
          <Switch
            onChange={(checked) => {
              if (row.original.profile_progress >= 70) {
                handleFeaturedToggle(row.original.id, checked)
              } else {
                message.warning("Profile progress must be at least 70% to feature freelancer.");
              }
            }}
            checked={row.original.featured}
            onColor="#86d3ff"
            onHandleColor="#07b274"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        ),
      },

      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            {/* "Update" icon */}
            <span
              onClick={() => handleclick(row.original.id)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <Button style={{ fill: "#07b274" }}>Show More</Button>
            </span>
          </div>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getFreelancers();
  }, []);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const handleclick = (Id) => {
    history.push(`/admin/Freelancer-Details/${Id}`);
  };;


  return (
    <>

      {/* {!showDetails ? ( */}

      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}

                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />

            </Card>

          </Col>
        </Row>
      </Container>

    </>
  );
}

export default AllFreelancer;
