import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import PendingVerification from "./PendingVerification";
import RejectedVerification from "./RejectedVerification";
import VerifiedVerification from "./VerifiedVerification";
import Profile from "assets/img/default-avatar.png"
import "assets/css/demo.css"
import { BsArrowLeftShort } from "react-icons/bs"
import MyContext from "MyContext";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import AddEmployer from "./Userform";
import UploadCSVfile from "./UploadCSVfile";
import { useHistory } from "react-router-dom";



function Verification() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [items, setitems] = useState({});
  const [showPending, setShowPending] = useState(true);
  const [showRejected, setShowRejected] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [activeCard, setActiveCard] = useState("Pending");
  const [showAdd, setshowAdd] = useState(false);
  const [showmodel, setshowModel] = useState(false);
  const history = useHistory();

  const count = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/countPendingandRejected", {
        Token,
      });
      setitems(data.data);
      //  console.log(data.data.publishedjobCount);
    } catch {
      console.log("counts is not working");
    }
  };

  useEffect(() => {
    count();
  }, []);


  const handleCount = () => {
    count();
  }

  const navigateToPending = () => {
    // history.push("/admin/Published-Jobs"); 
    setShowPending(true); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
    setShowRejected(false);
    setShowVerified(false);
    setshowAdd(false);
    setActiveCard("Pending");
  };
  const navigateToVerified = () => {
    setShowPending(false);
    setShowRejected(false);
    setShowVerified(true);
    setshowAdd(false);
    setActiveCard("verifedEmployed");
  };
  const navigateToRejected = () => {
    // history.push("/admin/Draft-Jobs"); 
    setShowPending(false);
    setShowRejected(true);
    setShowVerified(false);
    setshowAdd(false);
    setActiveCard("featuredemployer");

  };
  const navigateToAdd = () => {

    setShowPending(false);
    setShowRejected(false);
    setShowVerified(false);
    setshowAdd(true);
    setActiveCard("all");
  };

  const handleBackArrowClick = () => {
    setShowPending(true); // Reset showall to true when back arrow is clicked
    setshowAdd(false);
    setActiveCard("all");
  };
  const handleExpModel = async () => {
    setshowModel(true);
    document.body.classList.add("modal-open");



  };

  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");



  };
  return (
    <>
      <>
        <Container fluid>
          <Row>
            <Col lg="4" sm="12">
              <Card className={`card-stats ${activeCard === "Pending" ? "active" : ""}`} onClick={navigateToPending} style={{ borderBottom: activeCard === "Pending" ? "4px solid #07b273" : "" }}>
                <Card.Body>
                  <Row>
                    <Col xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart text-warning"></i>
                      </div>
                    </Col>
                    <Col sm="7">
                      <div className="numbers">
                        <p className="card-category">Pending Verification</p>
                        <Card.Title as="h3">{items?.PendingVerCount}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="12">
              <Card className={`card-stats ${activeCard === "verifedEmployed" ? "active" : ""}`} onClick={navigateToVerified} style={{ borderBottom: activeCard === "verifedEmployed" ? "4px solid #07b273" : "" }}>
                <Card.Body>
                  <Row>
                    <Col xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart text-warning"></i>
                      </div>
                    </Col>
                    <Col sm="7">
                      <div className="numbers">
                        <p className="card-category">Verified Verification</p>
                        <Card.Title as="h3">{items?.VerifiedVerCount}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>



            <Col lg="4" sm="12">
              <Card className={`card-stats ${activeCard === "featuredemployer" ? "active" : ""}`} onClick={navigateToRejected} style={{ borderBottom: activeCard === "featuredemployer" ? "4px solid #07b273" : "" }}>
                <Card.Body>
                  <Row>
                    <Col xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart text-warning"></i>
                      </div>
                    </Col>
                    <Col sm="7">
                      <div className="numbers">
                        <p className="card-category">Rejected Verification</p>
                        <Card.Title as="h3">{items?.RejectedVerCount}</Card.Title>
                      </div>
                    </Col>
                    

                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>



        <Container fluid >
          {showPending && <PendingVerification handleCount={handleCount} />}
          {showRejected && <RejectedVerification handleCount={handleCount} />}
          {showVerified && <VerifiedVerification handleCount={handleCount} />}

        </Container>
      </>
      <div id="myModal"
        className={showmodel ? "model-open" : "model"}
        style={{ overflow: "auto" }}
      >
        <div className="modal-content" style={{ color: "black" }}>
          <div className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
            &times;
          </div>
          <div><UploadCSVfile /></div>
        </div>
      </div>
    </>
  );
}

export default Verification;
