import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import AllEmployers from "./AllEmployers";
import FeaturedEmployers from "./FeaturedEmployers";
import Profile from "assets/img/default-avatar.png"
import "assets/css/demo.css"
import { BsArrowLeftShort } from "react-icons/bs"
import MyContext from "MyContext";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import AddEmployer from "./Userform";
import UploadCSVfile from "./UploadCSVfile";
import { useHistory } from "react-router-dom";



function Employer() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [items, setitems] = useState({});
  const [showall, setShowall] = useState(true);
  const [showfeaturedEmployer, setShowfeaturedEmployer] = useState(false);
  const [activeCard, setActiveCard] = useState("all");
  const [showAdd, setshowAdd] = useState(false);
  const [showmodel, setshowModel] = useState(false);
  const history = useHistory();

  const count = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/counts", {
        Token,
      });
      setitems(data.data);
      //  console.log(data.data.publishedjobCount);



    } catch {
      console.log("counts is not working");
    }
  };

  useEffect(() => {
    count();
  }, []);


  const navigateToall = () => {
    // history.push("/admin/Published-Jobs"); 
    setShowall(true); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
    setShowfeaturedEmployer(false);
    setshowAdd(false);
    setActiveCard("all");
  };
  const navigateTofeaturedEmployer = () => {
    // history.push("/admin/Draft-Jobs"); 
    setShowall(false);
    setShowfeaturedEmployer(true);
    setshowAdd(false);
    setActiveCard("featuredemployer");

  };
  const navigateToAdd = () => {
    setShowall(false);
    setShowfeaturedEmployer(false);
    setshowAdd(true);
    setActiveCard("all");
  };

  const handleBackArrowClick = () => {
    setShowall(true); // Reset showall to true when back arrow is clicked
    setshowAdd(false);
    setActiveCard("all");
  };
  const handleExpModel = async () => {
    setshowModel(true);
    document.body.classList.add("modal-open");



  };

  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");



  };
  return (
    <>

      {showAdd ? (
        <>
          <div>
            <span className="container-fluid d-flex justify-content-start">

              <span className="close profile" onClick={() => handleBackArrowClick()}>
                <BsArrowLeftShort style={{ fill: "#07b274", margin: "-30px 0 0 -10px" }} />
              </span>
            </span>
            <AddEmployer Type="Employer" />
          </div>

        </>
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col lg="8" sm="12">
                <Card className={`card-stats ${activeCard === "all" ? "active" : ""}`} onClick={navigateToall} style={{ borderBottom: activeCard === "all" ? "4px solid #07b273" : "" }}>
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col sm="7">
                        <div className="numbers">
                          <p className="card-category">All Employer</p>
                          <Card.Title as="h3">{items?.employer}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col lg="6" sm="12">
                <Card className={`card-stats ${activeCard === "featuredemployer" ? "active" : ""}`} onClick={navigateTofeaturedEmployer} style={{ borderBottom: activeCard === "featuredemployer" ? "4px solid #07b273" : "" }}>
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col sm="7">
                        <div className="numbers">
                          <p className="card-category">Featured Employers</p>
                          <Card.Title as="h3">{items?.featuredemployer}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </Container>

          <span className="container-fluid d-flex justify-content-end">
            <button
              className="btn mb-1 btn-lg mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleExpModel()}
            >
              Import Employers
            </button>
            <button
              className="btn mb-1 btn-lg mr-3"
              style={{ cursor: "pointer" }}
              onClick={navigateToAdd}
            >
              Add Employer
            </button>
          </span>

          <Container fluid >
            {showall && <AllEmployers />}
            {showfeaturedEmployer && <FeaturedEmployers />}

          </Container>
        </>
      )}
      <div id="myModal"
        className={showmodel ? "model-open" : "model"}
        style={{ overflow: "auto" }}
      >
        <div className="modal-content" style={{ color: "black" }}>
          <div className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
            &times;
          </div>
          <div><UploadCSVfile /></div>
        </div>
      </div>
    </>
  );
}

export default Employer;
