import React from "react";
import { useHistory } from "react-router-dom";
import PendingBankStatus from "./PendingBankStatus";


// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Finance() {

  const history = useHistory();
  const navigateToTrackpayment = () => {
    history.push("/admin/Track-Payments");
  };
  const navigateToPaymentToFreelancer = () => {
    history.push("/admin/Payment-To-Freelancer");
  };
  const navigateToInvoices = () => {
    history.push("/admin/Invoices");
  };
  const navigateToFreelancerpaymentDetails = () => {
    history.push("/admin/Freelancer-Payment-Details");
  };
  const navigateToPendingBankStatus = () => {
    history.push("/admin/Pending-Bank-Status")
  }
  const navigateToRefundDetails = () => {
    history.push("/admin/Refund-Details")
  }


  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToInvoices}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      {/* <p className="card-category">Finance</p> */}
                      <Card.Title as="h4">Invoices</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToPaymentToFreelancer}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="numbers">
                      {/* <p className="card-category">Finance</p> */}
                      <Card.Title as="h4">Payment to Freelancer</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToTrackpayment}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      {/* <p className="card-category">Finance</p> */}
                      <Card.Title as="h4" >Track Payment</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToRefundDetails}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="numbers">
                      {/* <p className="card-category">Finance</p> */}
                      <Card.Title as="h4">Employer Refunds</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToFreelancerpaymentDetails}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                    <p className="card-category">Finance</p>
                    <Card.Title as="h3">Pending Bank Transfer</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
          </Col> */}
          <Col lg="6" sm="12">
            <Card className="card-stats" onClick={navigateToPendingBankStatus}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                      {/* <p className="card-category">Finance</p> */}
                      <Card.Title as="h4">Pending Bank Transfer</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    </>


  );
}

export default Finance;
