import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import ClosedProjects from "./EmployerClosedProjects";
import OngoingProjects from "./EmployerOngoingProjects";
import PostedJobs from "./EmployerPostedJobs";
import Profile from "assets/img/default-avatar.png"
import "assets/css/demo.css"
import { BsArrowLeftShort } from "react-icons/bs"
import MyContext from "MyContext";
import edit from "assets/img/edit1.jpg";
import AddFreelancer from "./Userform";
import ReviewAdd from "./ReviewAdd";
import { IoMdClose } from "react-icons/io";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

import { useLocation } from "react-router-dom";
import Education from "./Education";
import WorkExp from "./WorkExp";
import EmployerFinance from "./EmployerFinance";
import { useHistory } from "react-router-dom";
import AddJob from "./AddJob";


function EmployerDetails() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [employer, setemployer] = useState([]);
  const [password, setPassword] = useState({ newPassword: "", conformPassword: "" })
  const [updatePassword, setUpdatePassword] = useState(false);
  const [activeproject, setActiveproject] = useState(0);
  const [activejob, setActivejob] = useState(0);
  const [reviewForm, setReviewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showpass, setshowpass] = useState([false, false]);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [analytics, setAnalytics] = useState([]);
  const [finance, setfinance] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const Id = location.pathname.split('/').pop();
  const [employerStatus, setEmployerStatus] = useState(true); // Initialize with null or the default status value
  const [showfeatured, setShowfeatured] = useState(false);


  const Analytics = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/analytics", {
        id: Id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };

  const handleEditClick = (employer) => {
    console.log("Edit clicked");
    console.log("inhandleeditclick", employer)
    setSelectedEmployer(employer);
    setShowEditForm(true);
  };

  const handleEditFormClose = () => {
    setSelectedFreelancer(null);
    setShowEditForm(false);
  };

  const getEmployers = async (Id) => {
    console.log(Id)
    try {
      const { data } = await axios.post(endPointUrl + "/getUserById", {
        Token,
        id: Id,
      });

      if (data && data.data) {
        // Convert the single object to an array with one element
        const freelancerArray = [data.data];
        setemployer(freelancerArray);
        setEmployerStatus(data.data.Status_by_Admin); // Assuming "Status" is a boolean value
        if (data.data.featured) {
          setShowfeatured(true);
        }
      } else {
        console.log("No data received from the server.");
      }
      console.log(data.data);
    } catch {
      console.log("get Employers details is not working");
    }
  };

  const getEmployersfinance = async (Id) => {
    console.log(Id)
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getemployerfinance", {
        Token,
        Awarded_By_ID: Id,
      });
      setfinance(data.data[0])

      // console.log(data.data[0]);
      // console.log(data.data[0].totalEarningsSum);
    } catch {
      console.log("get Freelancer details is not working");
    }
  };


  useEffect(() => {
    getEmployers(Id);
    Analytics();
    getEmployersfinance(Id);
  }, []);

  const handleprojectTabchange = (tab) => {
    setActiveproject(tab);
  }
  const handlejobTabchange = (tab) => {
    setActivejob(tab);
  }

  const handleStatusToggle = async (employerId, newStatusValue) => {
    console.log("Toggle called for freelancer ID:", employerId);
    try {
      await axios.post(endPointUrl + "/updateuserProfile", {
        Token,
        id: employerId,
        Status_by_Admin: newStatusValue,
      });

      // setallDetails((prevDetails) =>
      //   prevDetails.map((freelancer) =>
      //     freelancer.id === freelancerId
      //       ? { ...freelancer, Status_by_Admin: newStatusValue }
      //       : freelancer
      //   )
      // );
      setEmployerStatus(newStatusValue);

    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const navigateToFinance = () => {
    history.push("/admin/Employers");
  };

  const handleCloseForm = () => {
    setReviewForm(false)
  }

  const handlepasswordUpdate = async (e) => {

    e.preventDefault()
    console.log(password.newPassword, "==", password.conformPassword)
    if (password.newPassword !== password.conformPassword) {
      message.warning("Password Not Match")
    } else {
      const { data } = await axios.post(endPointUrl + "/user/forgotPassword", {
        Token,
        Email: employer[0].Email,
        Password: password.newPassword
      })
      if (data.data) {
        message.success("Password Update");
      }
    }


  }


  function getLanguage(item) {
    let languages;
    try {
      languages = JSON.parse(item);
    } catch (error) {
      return (<span>{item?.Language}</span>);
    }

    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  }

  return (
    <>
      {updatePassword ?
        <div className="position-fixed d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%', zIndex: 5, top: 0, left: 0, backgroundColor: "#00000070" }}>
          <IoMdClose className="text-white position-absolute" style={{ top: "5px", right: "10px", borderRadius: "50%", fontSize: "1.5rem" }} onClick={() => {
            setPassword({ newPassword: "", conformPassword: "" })
            setUpdatePassword(false)
          }
          } />
          <div className="bg-white p-5 rounded">
            <form style={{ position: "relative" }} onSubmit={(e) => handlepasswordUpdate(e)}>
              <p className="text-center" style={{fontSize:'14px'}}>Update Password</p>
              <input type={`${showpass[0] ? "text" : "password"}`} value={password.newPassword} placeholder="Enter New Password" onChange={(e) => setPassword({ ...password, newPassword: e.target.value })} className="outline-none border-bottom" style={{ width: "100%" }} />
              <span style={{ position: "absolute", right: "10px" }} onClick={() => setshowpass({ ...showpass, 0: !showpass[0] })}>
                {showpass[0] ? "hide" : "show"}
              </span>
              <input type={`${showpass[1] ? "text" : "password"}`} value={password.conformPassword} placeholder="Conform Password" onChange={(e) => setPassword({ ...password, conformPassword: e.target.value })} className="outline-none border-bottom" style={{ width: "100%" }} />
              <span style={{ position: "absolute", right: "10px" }} onClick={() => setshowpass({ ...showpass, 1: !showpass[1] })}>
                {showpass[1] ? "hide" : "show"}
              </span>
              <button type="submit" className="btn btn-secondary">Submit</button>
            </form>
          </div>
        </div> : null}
      {reviewForm ?
        <ReviewAdd
          UserAccType={employer}
          Type={"Employer"}
          handleCloseForm={handleCloseForm}
          freelancer={employer}
        />
        :
        <>
          {!showEditForm ? (
            <>
              <span className="container-fluid d-flex justify-content-start">
                <span className="close profile" onClick={navigateToFinance}>
                  <BsArrowLeftShort style={{ fill: "#07b274", margin: "-20px 10px 0 -10px" }} />
                </span>
                <h6 className="heading-6" style={{ margin: "-4px 0 0 20px" }}>Employer Details</h6>
              </span>
              <div>
                {/* <div> */}
                {/* <span className="close profile" onClick={navigateToFinance}>
        <BsArrowLeftShort style={{fill:"#07b274",margin:"0 0 30px 0"}}/>
        </span> */}
                <Row className="modalContainer">
                  {employer.map((item) =>
                    <>
                      <Col md="8" style={{ paddingRight: "10px", paddingLeft: "25px" }}>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="about">
                            <strong style={{ display: "flex", alignItems: "center" }}>ABOUT</strong>
                            <p>{item?.About}</p>
                          </div>
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="projects">
                            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>PROJECTS</strong>

                            <div>
                              <div className="jobMatch" style={{ marginBottom: "10px" }}>
                                <span style={
                                  activeproject === 0
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => handleprojectTabchange(0)}>

                                  In Progress
                                </span>

                                <span style={
                                  activeproject === 1
                                    ? {
                                      borderBottom: "4px solid #07b273",
                                    }
                                    : null
                                }
                                  onClick={() => handleprojectTabchange(1)}> Closed
                                </span>

                              </div>
                              {activeproject === 0 && <OngoingProjects ID={item?.id} />}
                              {activeproject === 1 && <ClosedProjects ID={item?.id} />}
                            </div>
                          </div>
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="projects">
                            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>JOBS</strong>
                            <div className="jobMatch" style={{ marginBottom: "10px" }}>
                              <span style={{
                                borderBottom: activejob === 0 ? "4px solid #07b273" : '',
                              }}
                                onClick={() => handlejobTabchange(0)}
                              >
                                Posted Jobs
                              </span>
                              <span style={{
                                // borderBottom: activejob === 1 ? "4px solid #07b273" : '',
                              }}
                                onClick={() => handlejobTabchange(1)}
                              >
                                <AddJob item={item} />
                              </span>
                            </div>
                            <div className="ongoing-projects">
                              <PostedJobs ID={item?.id} />
                            </div>
                          </div>
                        </Card>
                        <Card className="card-use" style={{ margin: "2% auto", padding: "16px", border: "1px solid #888" }}>
                          <div className="projects">
                            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>FINANCE</strong>
                            <div className="jobMatch" style={{ marginBottom: "10px" }}>
                              <span style={{
                                borderBottom: "4px solid #07b273",
                              }}>

                                Amount
                              </span>
                            </div>
                            <div className="ongoing-projects">
                              <EmployerFinance ID={item?.id} />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" style={{ marginTop: "15px" }}>
                        <div className="compo2">
                          <div className="profileConatiner">
                            <div className="profile-details">
                              <div className="editimg" >
                                <img src={edit} alt="" onClick={() => handleEditClick(item)} />
                              </div>
                              <img
                                src={
                                  item?.Photo === "" || item?.Photo == null
                                    ? Profile
                                    : `${endPointUrl}/images/${item?.Photo}`
                                }
                                alt=""
                              // style={{width:'20%',display:"flex"}}
                              />

                              {showfeatured ? (
                                <div className="featuredd">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                                    <path d="m18 0 8 12 10-8-4 20H4L0 4l10 8 8-12z"></path>
                                  </svg>
                                </div>
                              ) : null}

                              <span className="profilename">{item.Name}</span>
                              <span className="profileDesignation">
                                {item?.Company}
                              </span>
                              <span className="d-flex justify-content-around" style={{ gap: "5px" }}>
                                <button className="bg-white text-gray-300 mb-1 py-1 px-2 rounded-lg border border-black hover:bg-gray-300" onClick={() => setReviewForm(true)}>Add Review</button>
                                <button className="bg-white text-gray-300 mb-1 py-1 px-2 rounded-lg border border-black hover:bg-gray-300" style={{fontSize:"14px"}} onClick={() => setUpdatePassword(true)}>Update Password</button>

                              </span>
                            </div>

                            <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                              <div class="radio-inputs">
                                <label class="radio">
                                  <input
                                    type="radio"
                                    name="radio"
                                    checked={employerStatus === true} // Assuming "true" represents "Active"
                                    onChange={() => handleStatusToggle(Id, true)} // Set status to "Active"
                                  />
                                  <span class="status">Active</span>
                                </label>

                                <label class="radio">
                                  <input type="radio" name="radio"
                                    checked={employerStatus === false} // Assuming "false" represents "Inactive"
                                    onChange={() => handleStatusToggle(Id, false)} // Set status to "Inactive"
                                  />

                                  <span class="status">InActive</span>
                                </label>
                              </div>
                            </div>

                            <div className="skillsset-card" style={{ marginTop: "10px" }}>
                              <h5>Finance</h5>
                              <ul>
                                <li>Total Spent : ${finance?.TotalAmountSpentSum + finance?.PaymentInEscrowSum + finance?.pendingPaymentSum}</li>
                                <li>Released Payment : ${finance?.TotalAmountSpentSum}</li>
                                <li>Payment in Escrow : ${finance?.PaymentInEscrowSum}</li>
                                <li>Pending Payment : ${finance?.pendingPaymentSum}</li>
                                {/* <li>Revenue Generate :5</li> */}
                              </ul>
                            </div>
                            <hr />
                            <div className="skillsset-card" >
                              <h5>Analytics</h5>
                              <ul>
                                {/* <li>Revenue Generated : ${analytics?.revenuegenerated}</li> */}
                                <li>Job Posted : {analytics?.totalprojects}</li>
                                <li>Total Projects : {analytics?.totalprojects}</li>
                                <li>Ongoing Project : {analytics?.ongoingprojects}</li>
                                <li>Closed Projects : {analytics?.completedProject}</li>
                                <li>Expired Projects : {analytics?.expiredprojects}</li>
                                {/* <li>Revenue Generate :5</li> */}
                              </ul>
                            </div>
                            <hr />
                            {/* 
                            <div className="skillsset-card rate-card" style={{ marginTop: "10px" }}>
                              <h5>Bank Details</h5>
                              <div className="user-details" >
                                <p>Bank Name</p>
                                <span>{item?.Bank_Name}</span>
                              </div>
                              <div className="user-details">
                                <p>Account Name</p>
                                <span>{item?.Account_Name}</span>
                              </div>
                              <div className="user-details">
                                <p>Account Number</p>
                                <span>{item?.Account_Number}</span>
                              </div>
                              <div className="user-details">
                                <p>IFSC Code</p>
                                <span>{item?.IFSC_CODE}</span>
                              </div>
                              <div className="user-details">
                                <p>Swift Code</p>
                                <span>{item?.SWIFT_CODE}</span>
                              </div>
                              <div className="user-details">
                                <p>Branch Detail</p>
                                <span>{item?.BRANCH_DETAILS}</span>
                              </div>
                              <div className="user-details">
                                <p>Bank Country</p>
                                <span>{item?.BANK_COUNTRY}</span>
                              </div>
                            </div> */}
                            <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                            <div className="rate-card">
                              <div className="user-details" style={{ marginTop: "20px" }}>
                                <p>Location</p>
                                <span>{item?.City + "," + item?.State}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Type</p>
                                <span>Employer</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Language</p>
                                <span>{getLanguage(item?.Language)}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Gender</p>
                                <span>{item.Gender}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Email</p>
                                <span>{item.Email}</span>
                              </div>
                              <hr style={{ borderTop: "1px solid #E4E4E4" }} />
                              <div className="user-details">
                                <p>Phone Number</p>
                                <span>+91 - {item?.Mobile}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div>
                <span className="container-fluid d-flex justify-content-start">

                  <span className="close profile" onClick={() => setShowEditForm(false)}>
                    <BsArrowLeftShort style={{ fill: "#07b274", margin: "-30px 0 0 -10px" }} />
                  </span>
                </span>

                <AddFreelancer
                  Type="Employer"
                  initialformData={selectedEmployer} // Pass the selected freelancer's data as initial form data
                  isFormVisible={showEditForm}
                  handleClose={handleEditFormClose}
                />
              </div>
            </>
          )}

        </>}
    </>

  );
}

export default EmployerDetails;
