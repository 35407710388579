import React, { useState, useContext, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '.././assets/css/testimonial.css';
import axios from "axios";
import moment from 'moment';
import MyContext from "../MyContext";
import PaginationSection from './PaginationSection';
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Selectant from 'react-select';
import { message } from "antd";
import TimezoneSelect from "react-timezone-select";
import { useTable, usePagination, useGlobalFilter, useSortBy, } from "react-table";
import { Card, Table, Button, Container, Row, Col, Form } from "react-bootstrap";
import { Description, LocationCity } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import { BiShow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

function DisplayPartnerWithUs() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [showModel, setshowModel] = useState(false);
    const [view, setView] = useState(false);
    const [formData, setFormData] = useState([]);
    const [partners, setPartners] = useState([])
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [allDetails, setallDetails] = useState([]);
    const [name, setName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [description, setDescription] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [establishment, setEstablishment] = useState('')
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('')
    const [numberOfEmployees, setNumberOfEmployees] = useState('')
    const [location, setLocation] = useState('')
    const [timeZone, setTimeZone] = useState('')
    const [languages, setLanguages] = useState([])
    const [numberOfOffices, setNumberOfOffices] = useState('')
    const [hourlyRate, setHourlyRate] = useState('')
    const [servicesOffered, setServicesOffered] = useState('')
    const [technologyOffered, setTechnologyOffered] = useState('')
    const [logo, setLogo] = useState('')
    const [localLogo, setLocalLogo] = useState('');
    const [portfolio, setPortfolio] = useState('')
    const [localportfolio, setLocalPortfolio] = useState('')
    const [websiteURL, setWebsiteURL] = useState('')
    const [socialMediaPresence, setSocailMediaPresence] = useState('')
    const [yearlyRevenue, setYearlyRevenue] = useState('')
    const [industryBasedRevenue, setIndustryBasedRevenue] = useState('')
    const [clientBasedRevenue, setClientBasedRevenue] = useState('')
    const [languageMAster, setLanguagaeMaster] = useState([]);
    const [error, setError] = useState({})
    const [selectedTimezone, setSelectedTimezone] = useState('')
    const [officeLocations, setOfficeLocations] = useState({})
    const [addShow, setAddShow] = useState(false)
    const [InstagramUsername, setInstagramUsername] = useState('')
    const [FacebookProfile, setFacebookProfile] = useState('')
    const [LinkedinProfile, setLinkedinProfile] = useState('')
    const [TwitterProfile, setTwitterProfile] = useState('')
    const [BP_ID, setBP_ID] = useState('');
    const [approved, setApproved] = useState('');

    const LanguageMAster = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
                Token,
            });
            if (data && data.data && Array.isArray(data.data)) {
                const languageNames = data.data.map((item) => ({
                    value: item.Name,
                    label: item.Name,
                }));
                setLanguagaeMaster(languageNames);
            } else {
                console.log('Failed to fetch language. Data may be empty or not in the expected format.');
            }
        } catch (error) {
            console.log('Failed to fetch skills:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    };
    const handleshowModel = (partnerData, showInfo) => {
        setBP_ID(partnerData.BP_ID);
        setName(partnerData?.Name);
        setCompanyName(partnerData?.CompanyName);
        setDescription(partnerData?.Description);
        setEmail(partnerData?.Email);
        setPhoneNumber(partnerData?.Phone);
        setEstablishment(partnerData?.Establishment);
        setNumberOfEmployees(partnerData?.NumberOFEmployees);
        setLocation(partnerData?.Location);
        setTimeZone(partnerData?.TimeZone)
        setLanguages(partnerData?.Languages.split(",").map((item) => ({ label: item, value: item })));
        setHourlyRate(partnerData?.HourlyRate);
        setServicesOffered({ label: partnerData?.ServiceOffered, value: partnerData?.ServiceOffered })
        setTechnologyOffered({ label: partnerData?.TechnologyOffered, value: partnerData?.TechnologyOffered })
        setWebsiteURL(partnerData?.WebsiteURL);
        setIndustryBasedRevenue(partnerData?.IndustryBaseRevenue.split(",").map((item) => ({ label: item, value: item })));
        setClientBasedRevenue(partnerData?.ClientBaseRevenue == 'undefined' ? "" : { item: partnerData?.ClientBaseRevenue, label: partnerData?.ClientBaseRevenue });
        setSelectedTimezone(partnerData?.SelectedTimeZone);
        setInstagramUsername(partnerData?.Instagram)
        setFacebookProfile(partnerData?.Facebook)
        setLinkedinProfile(partnerData?.Linkedin)
        setTwitterProfile(partnerData?.Twitter)
        setLogo(partnerData?.Logo);
        setPortfolio(partnerData?.Portfolio);
        setshowModel(true);
        setApproved(partnerData?.Approved);
        setView(showInfo ? true : false);
        setNumberOfOffices({ value: partnerData?.NumberOfOffices, label: partnerData?.NumberOfOffices });
        if (partnerData?.OfficeLocations) {
            const officeLocations = JSON.parse(partnerData?.OfficeLocations)
            const len = Object.values(officeLocations)
            setOfficeLocations(len);
        }
    };
    console.log('clientBasedRevenue', clientBasedRevenue)

    const handleUpdate = async (e, partnerID) => {
        e.preventDefault();
        try {
            const selectedPartnerString = selectedPartners.map((skill) => skill.value).join(', ');
            const industryBasedRevenues = industryBasedRevenue.map((item) => item.value).join(', ');

            const { data } = await axios.post(endPointUrl + "/updatePartner", {
                Token,
                BP_ID: partnerID,
                ...formData,
                Partner: selectedPartnerString,
                IndustryBaseRevenue: industryBasedRevenues
            });
            setallDetails(data.data);
            getPartnerWithUS()
            setshowModel(false);
            message.success("Text Update successfully");
        } catch (error) {
            console.log("Failed to update partner:", error);
            message.error("Failed to update partner.");
        }
    };


    const data = React.useMemo(() => partners, [partners]);

    const deletePartner = async (e, BP_ID) => {
        try {
            e.preventDefault();
            const response = await axios.post(endPointUrl + "/deletePartner", { Token, BP_ID });
            if (response.data && response.data.status) {
                message.success("Partner Deleted Successfully")
                getPartnerWithUS()
            } else {
                message.warning("Something Went Wrong")
                // console.error("Error deleting Newsletter:", response.data.message);
            }
        } catch (error) {
            message.warning("Something Went Wrong!")
            console.error("Error deleting Newsletter:", error);
        }
    }


    const handleApprove = async () => {
        try {

            const { data } = await axios.post(`${endPointUrl}/partner-approve`, {
                BP_ID
            })

            if (data.status) {
                getPartnerWithUS()
                setshowModel(false);
                message.success("Partner Approved");

                setBP_ID('');
                setName('');
                setCompanyName('');
                setDescription('');
                setEmail('');
                setPhoneNumber('');
                setEstablishment('');
                setNumberOfEmployees('');
                setLocation('');
                setTimeZone('')
                setLanguages('');
                setHourlyRate('');
                setServicesOffered('')
                setTechnologyOffered('')
                setWebsiteURL('');
                setIndustryBasedRevenue('');
                setClientBasedRevenue('');
                setSelectedTimezone('');
                setInstagramUsername('')
                setFacebookProfile('')
                setLinkedinProfile('')
                setTwitterProfile('')
                setLogo('');
                setPortfolio('');
            }
        } catch (e) {
            console.log('error while approve', e);
        }
    }

    const showOfficeLocation = (row) => {
        return <span>{row.original.OfficeLocations}</span>;
    }



    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Company Name",
                accessor: "CompanyName",
            },
            {
                Header: "Phone",
                accessor: "Phone",
            },
            {
                Header: "Languages",
                accessor: "Languages",
            },
            {
                Header: "Hourly Rate",
                accessor: "HourlyRate",
            },
            {
                Header: "Service Offered",
                accessor: "ServiceOffered",
            },
            {
                Header: "Technology Offered",
                accessor: "TechnologyOffered",
            },
            // {
            //     Header: "Date",
            //     accessor: "N_Date",
            //     Cell: ({ row }) => {
            //         const date = new Date(row.original.N_Date);
            //         const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            //         return <span>{date.toLocaleDateString('en-GB', options)}</span>;
            //     },
            // },


            {
                Header: "Action",
                accessor: "NID",
                Cell: ({ row }) => (

                    <div className="d-flex flex-row">
                        <span
                            onClick={(e) => handleshowModel(row.original, true)}
                            style={{ cursor: "pointer" }}>
                            <BiShow style={{ fill: "black", height: '1.4em', width: "1.4em", marginRight: "5px" }} />
                        </span>

                        <span
                            onClick={() => handleshowModel(row.original)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
                        </span>

                        <span
                            onClick={(e) => deletePartner(e, row.original.BP_ID)}
                            style={{ cursor: "pointer" }}>
                            <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                        </span>


                    </div>
                ),
            },
        ],
        []
    );

    const getPartnerWithUS = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/getPartnersAdmin", { Token });
            if (data) {
                setPartners(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    console.log("partners", partners)
    useEffect(() => {
        getPartnerWithUS()
        LanguageMAster();
    }, [])

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page: tablePage, canPreviousPage, canNextPage, pageOptions, nextPage, previousPage, setPageSize, setGlobalFilter, gotoPage, state, } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 5 }, autoResetPage: false, }, useGlobalFilter, useSortBy, usePagination);
    const { globalFilter, pageIndex, pageSize } = state;


    function handleError(label, error) {
        setError((prev) => ({ ...prev, [label]: error }))
    }


    function getOfficesLocation(number) {
        var data = []
        for (let i = 0; i < number; i++) {
            const label = `Location${i + 1}`
            data.push(<Row>
                <Col>
                    <Form.Group>
                        <label>Location of Office</label>
                        <Form.Control
                            name="locationOFFice"
                            placeholder="Location of Office"
                            type="text"
                            readOnly={view}
                            value={officeLocations[i]}
                            onChange={(event) => setOfficeLocations({ ...officeLocations, [label]: event.target.value })}
                        >
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>)
        }

        return data;
    };


    function validate(type) {
        let error = false;
        if (name.length === 0) {
            error = true;
            handleError('Name', 'Name is Required');
        }
        if (companyName.length === 0) {
            error = true;
            handleError('companyName', 'Company Name is Required');
        }
        if (password.length === 0 && type === "submit") {
            error = true;
            handleError('password', 'Password is Required');
        }
        if (rePassword.length === 0 && type === "submit") {
            error = true;
            handleError('rePassword', 'Re-Enter Password is Required')
        }
        if (email.length === 0) {
            error = true;
            handleError('email', 'Email Address is Required');
        }
        if (phoneNumber.length === 0) {
            error = true;
            handleError('phoneNumber', 'Phone Number is Required');
        }
        if (establishment.length === 0) {
            error = true;
            handleError('establishment', 'Date of Establishment is Required');
        }
        // if (companyPhoneNumber.length === 0) {
        //     error = true;
        //     handleError('companyPhoneNumber', 'Company Phone Number is Required');
        // }
        // if (numberOfEmployees.length === 0) {
        //     error = true;
        //     handleError('numberOfEmployees', 'Number Of Employees is Required');
        // }
        if (location.length === 0) {
            error = true;
            handleError('location', 'Location is Required');
        }
        // if (timeZone.length === 0) {
        //     error = true;
        //     handleError('timeZone', 'TimeZone is Required');
        // }
        if (languages.length === 0) {
            error = true;
            handleError('languages', 'Languages is Required');
        }
        if (hourlyRate.length === 0) {
            error = true;
            handleError('hourlyRate', 'Hourly Rate is Required');
        }
        if (servicesOffered.length === 0) {
            error = true;
            handleError('servicesOffered', 'Services Offered is Required');
        }
        if (technologyOffered.length === 0) {
            error = true;
            handleError('technologyOffered', 'Technology Offered is Required');
        }
        if (description.length === 0) {
            error = true;
            handleError('description', 'Description is Required');
        } if (logo === undefined || logo.length === 0) {
            error = true;
            handleError('logo', 'Logo is Required');
        }
        // if (portfolio === undefined || portfolio.length === 0) {
        //     error = true;
        //     handleError('portfolio', 'Portfolio is Required');
        // }
        if (websiteURL.length === 0) {
            error = true;
            handleError('websiteURL', 'WebsiteURL is Required');
        }
        // if (socialMediaPresence.length === 0) {
        //     error = true;
        //     handleError('socialMediaPresence', 'social Media Presence is Required');
        // }
        // if (yearlyRevenue.length === 0) {
        //     error = true;
        //     handleError('yearlyRevenue', 'Yearly Revenue is Required');
        // }
        // if (industryBasedRevenue.length === 0) {
        //     error = true;
        //     handleError('industryBasedRevenue', 'Field is Required');
        // }
        // if (clientBasedRevenue.length === 0) {
        //     error = true;
        //     handleError('clientBasedRevenue', 'Field is Required');
        // }
        if (numberOfOffices.length === 0) {
            error = true;
            handleError("numberOfOffices", 'Number OF Offices is Required')
        }

        return error;
    }

    const handleClose = () => {
        setBP_ID('');
        setName('');
        setCompanyName('');
        setDescription('');
        setEmail('');
        setPhoneNumber('');
        setEstablishment('');
        setNumberOfEmployees('');
        setLocation('');
        setTimeZone('')
        setLanguages('');
        setHourlyRate('');
        setServicesOffered('')
        setTechnologyOffered('')
        setWebsiteURL('');
        setIndustryBasedRevenue('');
        setClientBasedRevenue('');
        setSelectedTimezone('');
        setInstagramUsername('')
        setFacebookProfile('')
        setLinkedinProfile('')
        setTwitterProfile('')
        setLogo('');
        setPortfolio('');
    }


    const handleSubmit = async (event, type) => {
        event.preventDefault();
        const error = validate(type)
        if (error === false) {
            try {
                const selectedLanguagesString = languages?.map((language) => language.value).join(', ');
                // const socialMediaPresences = socialMediaPresence.map((item) => item.value).join(', ');
                // const yearlyRevenues = yearlyRevenue.map((item) => item.value).join(', ');
                const industryBasedRevenues = industryBasedRevenue?.map((item) => item.value).join(', ');
                // const clientBasedRevenues = clientBasedRevenue.map((item) => item.value).join(', ');
                // const servicesOffereds = servicesOffered.map((item) => item.value).join(', ');
                // const technologyOffereds = technologyOffered.map((item) => item.value).join(', ');

                const formData = new FormData();
                formData.append("Name", name);
                formData.append("CompanyName", companyName)
                formData.append("Password", password);
                formData.append("Email", email);
                formData.append("Phone", phoneNumber);
                formData.append("Establishment", establishment);
                formData.append("CompanyPhoneNumber", companyPhoneNumber);
                formData.append("NumberOFEmployees", numberOfEmployees);
                formData.append("Location", location);
                formData.append("OfficeLocations", JSON.stringify(officeLocations));
                formData.append("TimeZone", timeZone);
                formData.append("SelectedTimeZone", JSON.stringify(selectedTimezone));
                formData.append("Languages", selectedLanguagesString);
                formData.append("HourlyRate", hourlyRate);
                formData.append("ServiceOffered", servicesOffered.value);
                formData.append("TechnologyOffered", technologyOffered.value);
                formData.append("Description", description);
                formData.append("Logo", logo);
                formData.append("Portfolio", portfolio);
                formData.append("WebsiteURL", websiteURL);
                formData.append("YearlyRevenue", yearlyRevenue.value);
                formData.append("IndustryBaseRevenue", industryBasedRevenues);
                formData.append("ClientBaseRevenue", clientBasedRevenue.value);
                formData.append("Facebook", FacebookProfile);
                formData.append("Linkedin", LinkedinProfile);
                formData.append("Twitter", TwitterProfile);
                formData.append("NumberOfOffices", numberOfOffices.value);

                if (type === "update") {
                    const { data } = await axios.post(endPointUrl + "/updatePartner", formData)
                    setAddShow(false);
                    setView(false);
                    message.success("Parnter Updated Successfully");
                    getPartnerWithUS()
                }
                else {
                    const { data } = await axios.post(endPointUrl + "/become-partner", formData)
                    setView(false);
                    setAddShow(false);
                    message.success("Partner Submitted Successfully");
                    getPartnerWithUS()
                }
            } catch (error) {
                if (error?.response?.data?.message === "Email Already Exist") {
                    message.warning("Email Already Exist");
                }
                else {
                    message.warning('something went wrong')
                }
                console.log("Erro While Submitting Data in Database", error);
            }
        }

    }


    return (
        <div>
            {!showModel ?
                <Container fluid>
                    <div>
                        {
                            addShow ?
                                <div>
                                    <Container fluid>
                                        <div className="container-fluid d-flex justify-content-between">
                                            <h6 className="heading-6">
                                                Partner with us
                                            </h6>
                                            <Button onClick={() => {
                                                setAddShow(!addShow)
                                                handleClose()
                                            }}>Show Product</Button>
                                        </div>

                                        <Form onSubmit={(e) => handleSubmit(e, "submit")}>
                                            <Row>
                                                <Col md="12">
                                                    <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                                                        <Card.Header style={{ backgroundColor: '#07b274' }}>
                                                            <Card.Title as="h4">Fill Details</Card.Title>
                                                            <hr></hr>
                                                        </Card.Header>
                                                    </Card>

                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Name</label>
                                                                    <Form.Control
                                                                        name="Name"
                                                                        placeholder="Name"
                                                                        type="text"
                                                                        value={name}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 20) {
                                                                                setName(e.target.value)
                                                                                setError({ ...error, Name: "" })
                                                                            }
                                                                            else
                                                                                setError({ ...error, Name: "Name must be smaller then 20 characters" })

                                                                        }}
                                                                        onFocus={() => setError({ ...error, Name: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.Name}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Company Name</label>
                                                                    <Form.Control
                                                                        name="CompanyName"
                                                                        placeholder="Company Name"
                                                                        type="text"
                                                                        value={companyName}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 50) {
                                                                                setCompanyName(e.target.value)
                                                                                setError({ ...error, companyName: "" })
                                                                            }
                                                                            else {
                                                                                setError({ ...error, companyName: "Company Name must be smaller then 50 characters" })
                                                                            }
                                                                        }}
                                                                        onFocus={() => setError({ ...error, companyName: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.companyName}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Password</label>
                                                                    <Form.Control
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        type="text"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        onFocus={() => setError({ ...error, password: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.password}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Re-Enter Password</label>
                                                                    <Form.Control
                                                                        name="repassword"
                                                                        placeholder="Re-Enter password"
                                                                        type="text"
                                                                        value={rePassword}
                                                                        onChange={(e) => {
                                                                            if (password != e.target.value) {
                                                                                setError({ ...error, rePassword: "Password Not Match" })
                                                                                setRePassword(e.target.value)
                                                                            }
                                                                            else {
                                                                                setRePassword(e.target.value)
                                                                                setError({ ...error, rePassword: "" })
                                                                            }
                                                                        }}
                                                                        onFocus={() => setError({ ...error, rePassword: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.rePassword}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Email Address</label>
                                                                    <Form.Control
                                                                        name="email"
                                                                        placeholder="Email Address"
                                                                        type="email"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        onFocus={() => setError({ ...error, email: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.email}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Phone Number</label>
                                                                    <PhoneInput
                                                                        buttonStyle={{ marginTop: '0%' }}
                                                                        inputStyle={{ width: '100%' }}
                                                                        country={'in'}
                                                                        name="phoneNumber"
                                                                        placeholder="Phone Number"
                                                                        value={phoneNumber}
                                                                        onChange={(phone) => setPhoneNumber(phone)}
                                                                        onFocus={() => setError({ ...error, phoneNumber: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.phoneNumber}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Date of Establishment</label>
                                                                    <Form.Control
                                                                        name="establishment"
                                                                        placeholder="Date of Establishment"
                                                                        type="date"
                                                                        value={establishment}
                                                                        onChange={(e) => {
                                                                            if (new Date(e.target.value) < new Date()) {
                                                                                setEstablishment(e.target.value)
                                                                                setError({ ...error, establishment: "" })
                                                                            } else
                                                                                setError({ ...error, establishment: "Can Not Select Future Date" })
                                                                        }}
                                                                        onFocus={() => setError({ ...error, establishment: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.establishment}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            {/* <Col className="pl-1" md="6">
                                                        <Form.Group>
                                                            <label>Company Phone Number</label>
                                                            <Form.Control
                                                                name="CompanyPhone"
                                                                placeholder="Company Phone Number"
                                                                type="text"
                                                                value={companyPhoneNumber}
                                                                onChange={(e) => {
                                                                    const numbersOnly = /^[0-9]+$/;
                                                                    if (numbersOnly.test(e.target.value)) {
                                                                        if (e.target.value.length <= 10) {
                                                                            setCompanyPhoneNumber(e.target.value)
                                                                            setError({ ...error, companyPhoneNumber: "" })
                                                                        }
                                                                        else {
                                                                            setError({ ...error, companyPhoneNumber: "Phone Number Less than 10 Characters" })
                                                                        }
                                                                    }
                                                                }}
                                                                onFocus={() => setError({ ...error, companyPhoneNumber: "" })}
                                                            ></Form.Control>
                                                            <small style={{ color: "#ec1c24" }}>{error.companyPhoneNumber}</small>
                                                        </Form.Group>
                                                    </Col> */}
                                                        </Row>

                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Number Of Employees</label>
                                                                    <Selectant
                                                                        name="noofemployees"
                                                                        label="Number Of Employees"
                                                                        options={[
                                                                            { value: "1-20", label: "1-20" },
                                                                            { value: "20-50", label: "20-50" },
                                                                            { value: "50-100", label: "50-100" },
                                                                            { value: "100-200", label: "100-200" },
                                                                            { value: "200-500", label: "200-500" },
                                                                            { value: "500-1000", label: "500-1000" },
                                                                            { value: "1000-5000", label: "1000-5000" },
                                                                            { value: "5000-10000", label: "5000-10000" },
                                                                            { value: "10000-50000", label: "10000-50000" },
                                                                            { value: "50000-100000", label: "50000-100000" },
                                                                        ]}
                                                                        value={{ value: numberOfEmployees, label: numberOfEmployees }}
                                                                        onChange={(value) => {
                                                                            setNumberOfEmployees(value.value)
                                                                        }}
                                                                        onFocus={() => setError({ ...error, numberOfEmployees: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.numberOfEmployees}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Location</label>
                                                                    <Form.Control
                                                                        name="Location"
                                                                        placeholder="Location"
                                                                        type="text"
                                                                        value={location}
                                                                        onChange={(e) => setLocation(e.target.value)}
                                                                        onFocus={() => setError({ ...error, location: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.location}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Number of Offices</label>
                                                                    <Selectant
                                                                        name="noofoffices"
                                                                        placeholder="Number Of Offices"
                                                                        options={[{ value: "1", label: "1" }, { value: "2", label: "2" }, { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" },]}
                                                                        value={numberOfOffices}
                                                                        onChange={setNumberOfOffices}
                                                                        onFocus={() => setError({ ...error, numberOfOffices: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.numberOfOffices}</small>
                                                                </Form.Group>
                                                            </Col>


                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Website URL</label>
                                                                    <Form.Control
                                                                        name="websiteURL"
                                                                        placeholder="Website URL"
                                                                        type="text"
                                                                        value={websiteURL}
                                                                        onChange={(e) => {
                                                                            var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                                                                            if (urlRegex.test(e.target.value)) {
                                                                                setWebsiteURL(e.target.value)
                                                                                setError({ ...error, websiteURL: "" })
                                                                            }
                                                                            else {
                                                                                setWebsiteURL(e.target.value)
                                                                                setError({ ...error, websiteURL: "Enter Valid URL (Eg. http/ftp/https)" })
                                                                            }
                                                                        }}
                                                                        onFocus={() => setError({ ...error, websiteURL: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.websiteURL}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        {numberOfOffices.value > 0 ? getOfficesLocation(numberOfOffices.value) : <></>}


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Time Zone</label>
                                                                    <TimezoneSelect
                                                                        value={selectedTimezone}
                                                                        onChange={(val) => {
                                                                            setTimeZone(val.label);
                                                                            setSelectedTimezone(val);
                                                                        }}
                                                                        onFocus={() => { setError({ ...error, timeZone: "" }) }}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.timeZone}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Language</label>
                                                                    <Selectant
                                                                        name="Language"
                                                                        options={languageMAster}
                                                                        isMulti
                                                                        onChange={setLanguages}
                                                                        onFocus={() => setError({ ...error, languages: "" })}
                                                                        value={languages}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.languages}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Hourly Rate</label>
                                                                    <Form.Control
                                                                        name="HourlyRate"
                                                                        placeholder="Hourly Rate"
                                                                        type="text"
                                                                        value={hourlyRate}
                                                                        onChange={(e) => setHourlyRate(e.target.value)}
                                                                        onFocus={() => setError({ ...error, hourlyRate: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.hourlyRate}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Services Offered</label>
                                                                    <Selectant
                                                                        name="servicesOffered"
                                                                        placeholder="Services Offered"
                                                                        options={[{ value: "Software Development", label: "Software Development" }, { value: "Mobile App Development", label: "Mobile App Development" }, { value: "Web Design", label: "Web Design" }, { value: "Web Development", label: "Web Development" }, { value: "AI Development", label: "AI Development" }, { value: "Cloud Consulting", label: "Cloud Consulting" }, { value: "SI, IT Managed Services", label: "SI, IT Managed Services" }, { value: "IT Strategy Consulting", label: "IT Strategy Consulting" }]}
                                                                        value={servicesOffered}
                                                                        onChange={setServicesOffered}
                                                                        onFocus={() => setError({ ...error, servicesOffered: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.servicesOffered}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Technology Offered</label>
                                                                    <Selectant
                                                                        name="TechnologyOffered"
                                                                        value={technologyOffered}
                                                                        options={[{ value: "Dotnet", label: "Dotnet" }, { value: "PHP", label: "PHP" }, { value: "Mobile app development", label: "Mobile app development" }, { value: "IOS", label: "IOS" }, { value: "Android ", label: "Android " },]}
                                                                        onChange={setTechnologyOffered}
                                                                        onFocus={() => setError({ ...error, technologyOffered: "" })}
                                                                    ></Selectant>
                                                                    <small style={{ color: "#ec1c24" }}>{error.technologyOffered}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Description</label>
                                                                    <Form.Control
                                                                        name="description"
                                                                        placeholder="Description"
                                                                        type="text"
                                                                        value={description}
                                                                        onChange={(e) => setDescription(e.target.value)}
                                                                        onFocus={() => setError({ ...error, description: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.description}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Facebook Profile URL</label>
                                                                    <Form.Control
                                                                        name="FacebookProfile"
                                                                        placeholder="Facebook profile"
                                                                        type="text"
                                                                        value={FacebookProfile} onChange={(e) => {
                                                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                                                            setFacebookProfile(e.target.value)
                                                                            setError({ ...error, FacebookProfile: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, FacebookProfile: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.FacebookProfile}</small>
                                                                </Form.Group>

                                                            </Col>

                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Instagram Profile URL</label>
                                                                    <Form.Control
                                                                        name="InstagramUsername"
                                                                        placeholder="Instagram username"
                                                                        type="text"
                                                                        value={InstagramUsername} onChange={(e) => {
                                                                            setInstagramUsername(e.target.value)
                                                                            setError({ ...error, InstagramUsername: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, InstagramUsername: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.InstagramUsername}</small>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col lassName="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Linkedin Profile URL</label>
                                                                    <Form.Control
                                                                        name="Linkedin"
                                                                        placeholder="Linkedin profile"
                                                                        type="text"
                                                                        value={LinkedinProfile} onChange={(e) => {
                                                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                                                            setLinkedinProfile(e.target.value)
                                                                            setError({ ...error, LinkedinProfile: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, LinkedinProfile: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.LinkedinProfile}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label> Twitter Account</label>
                                                                    <Form.Control
                                                                        name="TwitterProfile"
                                                                        placeholder="Twitter Account"
                                                                        type="text"
                                                                        value={TwitterProfile} onChange={(e) => {
                                                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                                                            setTwitterProfile(e.target.value)
                                                                            setError({ ...error, TwitterProfile: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, TwitterProfile: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.TwitterProfile}</small>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>



                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Industry served based on Revenue</label>
                                                                    <Selectant
                                                                        isMulti
                                                                        name="IndustryservedbasedonRevenue"
                                                                        options={[{ value: "Automotive", label: "Automotive" }, { value: "Business services", label: "Business services" }, { value: "Dental", label: "Dental" }, { value: "Education", label: "Education" }, { value: "Financial services", label: "Financial services" }, { value: "Legal", label: "Legal" }, { value: "Medical", label: "Medical" }, { value: "Non-profit", label: "Non-profit" }, { value: "Real estate", label: "Real estate" }, { value: "ECommerce", label: "ECommerce" }]}
                                                                        value={industryBasedRevenue}
                                                                        onChange={setIndustryBasedRevenue}
                                                                        onFocus={() => setError({ ...error, industryBasedRevenue: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.industryBasedRevenue}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Client distribution Based on Revenue</label>
                                                                    <Selectant
                                                                        name="clientBasedRevenue"
                                                                        options={[{ value: "Small Business/Scale industry", label: "Small Business/Scale industry" }, { value: "Midsize Business/industry", label: "Midsize Business/industry" }, { value: "Enterprise level Business/industry", label: "Enterprise level Business/industry" }]}
                                                                        value={clientBasedRevenue}
                                                                        onChange={setClientBasedRevenue}
                                                                        onFocus={() => setError({ ...error, clientBasedRevenue: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.clientBasedRevenue}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>



                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Upload Logo</label>
                                                                    <Form.Control
                                                                        name="logo"
                                                                        type="file"
                                                                        onChange={(e) => setLogo(e.target.files[0])}
                                                                        onFocus={() => setError({ ...error, logo: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.logo}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Upload Company Profile</label>
                                                                    <Form.Control
                                                                        name="PortFolio"
                                                                        type="file"
                                                                        onChange={(e) => setPortfolio(e.target.files[0])}
                                                                        onFocus={() => setError({ ...error, portfolio: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.portfolio}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <button
                                                                className="btn mb-1 btn-lg mr-3"
                                                                type="submit"
                                                                variant="info"
                                                                style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px", cursor: "pointer" }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </Card.Body>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </Container>
                                </div> :
                                <Container fluid>
                                    <Row>
                                        <h1 style={{ width: "100%" }}>Partner with us</h1>
                                        <Col md="12">
                                            <Card className="stripped-tabled-with-hover">
                                                <Card.Header style={{ paddingTop: "5px" }}>
                                                    <Card.Title as="h4">
                                                        <Form className="d-flex">
                                                            <Form.Control
                                                                type="search"
                                                                placeholder="Search"
                                                                className="mx-2"
                                                                aria-label="Search"
                                                                value={globalFilter || ''}
                                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                                            />

                                                            <Button onClick={() => setAddShow(!addShow)}>Add Partner</Button>
                                                        </Form>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body className="table-full-width table-responsive px-0">
                                                    <Table className="table-hover table-striped" {...getTableProps()}>
                                                        <thead>
                                                            {headerGroups.map(headerGroup => (
                                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                                    {headerGroup.headers.map((column) => (
                                                                        <th {...column.getHeaderProps(column.getSortByToggleProps(), {

                                                                        })}>
                                                                            {column.render("Header")}
                                                                            {/* Add a sort direction indicator */}
                                                                            <span>
                                                                                {column.isSorted
                                                                                    ? column.isSortedDesc
                                                                                        ? " 🔽"
                                                                                        : " 🔼"
                                                                                    : ""}
                                                                            </span>
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </thead>

                                                        <tbody {...getTableBodyProps()}>
                                                            {tablePage.map((row) => {
                                                                prepareRow(row);
                                                                return (
                                                                    <tr {...row.getRowProps()}>
                                                                        {row.cells.map((cell) => (
                                                                            <td {...cell.getCellProps({

                                                                            })}>{cell.render("Cell")}</td>
                                                                        ))}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                                <PaginationSection
                                                    pageIndex={pageIndex}
                                                    pageCount={pageOptions.length}
                                                    canPreviousPage={canPreviousPage}
                                                    canNextPage={canNextPage}
                                                    gotoPage={gotoPage}
                                                    previousPage={previousPage}
                                                    nextPage={nextPage}
                                                    pageSize={pageSize}
                                                    setPageSize={setPageSize}
                                                    pageOptions={pageOptions}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                        }
                    </div >
                </Container> :
                <div>
                    <Container className='addskilltest' fluid>
                        <div className="container-fluid d-flex gap-3 align-items-center">
                            <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px,", fontSize: "40px" }} onClick={(() => {
                                setshowModel(false)
                                handleClose()
                            })} />
                            <h6 className="heading-6">{view ? "Partner Information" : "Updated Partner"}</h6>
                        </div>
                        <Row>
                            <Col md="12">
                                <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>
                                    {view ? "" : <Card.Header style={{ backgroundColor: '#07b274' }}>
                                        <Card.Title as="h4">Fill Details</Card.Title>
                                        <hr></hr>
                                    </Card.Header>}
                                    <Card.Body>
                                        <Form onSubmit={(e) => handleSubmit(e, "update")}>
                                            <Row>
                                                <Col md="12">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Name</label>
                                                                    <Form.Control
                                                                        name="Name"
                                                                        placeholder="Name"
                                                                        type="text"
                                                                        readOnly={view}
                                                                        value={name}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 20) {
                                                                                setName(e.target.value)
                                                                                setError({ ...error, Name: "" })
                                                                            }
                                                                            else
                                                                                setError({ ...error, Name: "Name must be smaller then 20 characters" })

                                                                        }}
                                                                        onFocus={() => setError({ ...error, Name: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.Name}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Company Name</label>
                                                                    <Form.Control
                                                                        name="CompanyName"
                                                                        placeholder="Company Name"
                                                                        readOnly={view}
                                                                        type="text"
                                                                        value={companyName}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 50) {
                                                                                setCompanyName(e.target.value)
                                                                                setError({ ...error, companyName: "" })
                                                                            }
                                                                            else {
                                                                                setError({ ...error, companyName: "Company Name must be smaller then 50 characters" })
                                                                            }
                                                                        }}
                                                                        onFocus={() => setError({ ...error, companyName: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.companyName}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Email Address</label>
                                                                    <Form.Control
                                                                        name="email"
                                                                        placeholder="Email Address"
                                                                        readOnly={view}
                                                                        type="email"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        onFocus={() => setError({ ...error, email: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.email}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Phone Number</label>
                                                                    <PhoneInput
                                                                        buttonStyle={{ marginTop: '0%' }}
                                                                        inputStyle={{ width: '100%' }}
                                                                        country={'in'}
                                                                        name="phoneNumber"
                                                                        disabled={view}
                                                                        placeholder="Phone Number"
                                                                        value={phoneNumber}
                                                                        onChange={(phone) => setPhoneNumber(phone)}
                                                                        onFocus={() => setError({ ...error, phoneNumber: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.phoneNumber}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Date of Establishment</label>
                                                                    <Form.Control
                                                                        name="establishment"
                                                                        placeholder="Date of Establishment"
                                                                        type="date"
                                                                        readOnly={view}
                                                                        value={establishment}
                                                                        onChange={(e) => {
                                                                            if (new Date(e.target.value) < new Date()) {
                                                                                setEstablishment(e.target.value)
                                                                                setError({ ...error, establishment: "" })
                                                                            } else
                                                                                setError({ ...error, establishment: "Can Not Select Future Date" })
                                                                        }}
                                                                        onFocus={() => setError({ ...error, establishment: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.establishment}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            {/* <Col className="pl-1" md="6">
                                                        <Form.Group>
                                                            <label>Company Phone Number</label>
                                                            <Form.Control
                                                                name="CompanyPhone"
                                                                placeholder="Company Phone Number"
                                                                type="text"
                                                                value={companyPhoneNumber}
                                                                onChange={(e) => {
                                                                    const numbersOnly = /^[0-9]+$/;
                                                                    if (numbersOnly.test(e.target.value)) {
                                                                        if (e.target.value.length <= 10) {
                                                                            setCompanyPhoneNumber(e.target.value)
                                                                            setError({ ...error, companyPhoneNumber: "" })
                                                                        }
                                                                        else {
                                                                            setError({ ...error, companyPhoneNumber: "Phone Number Less than 10 Characters" })
                                                                        }
                                                                    }
                                                                }}
                                                                onFocus={() => setError({ ...error, companyPhoneNumber: "" })}
                                                            ></Form.Control>
                                                            <small style={{ color: "#ec1c24" }}>{error.companyPhoneNumber}</small>
                                                        </Form.Group>
                                                    </Col> */}
                                                        </Row>

                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Number Of Employees</label>
                                                                    <Selectant
                                                                        name="noofemployees"
                                                                        label="Number Of Employees"
                                                                        isDisabled={view}
                                                                        options={[
                                                                            { value: "1-20", label: "1-20" },
                                                                            { value: "20-50", label: "20-50" },
                                                                            { value: "50-100", label: "50-100" },
                                                                            { value: "100-200", label: "100-200" },
                                                                            { value: "200-500", label: "200-500" },
                                                                            { value: "500-1000", label: "500-1000" },
                                                                            { value: "1000-5000", label: "1000-5000" },
                                                                            { value: "5000-10000", label: "5000-10000" },
                                                                            { value: "10000-50000", label: "10000-50000" },
                                                                            { value: "50000-100000", label: "50000-100000" },
                                                                        ]}
                                                                        value={{ value: numberOfEmployees, label: numberOfEmployees }}
                                                                        onChange={(value) => {
                                                                            setNumberOfEmployees(value.value)
                                                                        }}
                                                                        onFocus={() => setError({ ...error, numberOfEmployees: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.numberOfEmployees}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Location</label>
                                                                    <Form.Control
                                                                        name="Location"
                                                                        placeholder="Location"
                                                                        type="text"
                                                                        value={location}
                                                                        readOnly={view}
                                                                        onChange={(e) => setLocation(e.target.value)}
                                                                        onFocus={() => setError({ ...error, location: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.location}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Number of Offices</label>
                                                                    <Selectant
                                                                        name="noofoffices"
                                                                        placeholder="Number Of Offices"
                                                                        options={[{ value: "1", label: "1" }, { value: "2", label: "2" }, { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" },]}
                                                                        value={numberOfOffices}
                                                                        isDisabled={view}
                                                                        onChange={setNumberOfOffices}
                                                                        onFocus={() => setError({ ...error, numberOfOffices: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.numberOfOffices}</small>
                                                                </Form.Group>
                                                            </Col>


                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Website URL</label>
                                                                    <Form.Control
                                                                        name="websiteURL"
                                                                        placeholder="Website URL"
                                                                        readOnly={view}
                                                                        type="text"
                                                                        value={websiteURL}
                                                                        onChange={(e) => {
                                                                            var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                                                                            if (urlRegex.test(e.target.value)) {
                                                                                setWebsiteURL(e.target.value)
                                                                                setError({ ...error, websiteURL: "" })
                                                                            }
                                                                            else {
                                                                                setWebsiteURL(e.target.value)
                                                                                setError({ ...error, websiteURL: "Enter Valid URL (Eg. http/ftp/https)" })
                                                                            }
                                                                        }}
                                                                        onFocus={() => setError({ ...error, websiteURL: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error?.websiteURL}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        {numberOfOffices.value > 0 && view === false ? getOfficesLocation(numberOfOffices.value) : <></>}


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Time Zone</label>
                                                                    <TimezoneSelect
                                                                        value={selectedTimezone}
                                                                        isDisabled={view}
                                                                        onChange={(val) => {
                                                                            setTimeZone(val.label);
                                                                            setSelectedTimezone(val);
                                                                        }}
                                                                        onFocus={() => { setError({ ...error, timeZone: "" }) }}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.timeZone}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Language</label>
                                                                    <Selectant
                                                                        name="Language"
                                                                        options={languageMAster}
                                                                        isMulti
                                                                        isDisabled={view}
                                                                        onChange={setLanguages}
                                                                        onFocus={() => setError({ ...error, languages: "" })}
                                                                        value={languages}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.languages}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Hourly Rate</label>
                                                                    <Form.Control
                                                                        name="HourlyRate"
                                                                        placeholder="Hourly Rate"
                                                                        type="text"
                                                                        value={hourlyRate}
                                                                        readOnly={view}
                                                                        onChange={(e) => setHourlyRate(e.target.value)}
                                                                        onFocus={() => setError({ ...error, hourlyRate: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.hourlyRate}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Services Offered</label>
                                                                    <Selectant
                                                                        name="servicesOffered"
                                                                        placeholder="Services Offered"
                                                                        isDisabled={view}
                                                                        options={[{ value: "Software Development", label: "Software Development" }, { value: "Mobile App Development", label: "Mobile App Development" }, { value: "Web Design", label: "Web Design" }, { value: "Web Development", label: "Web Development" }, { value: "AI Development", label: "AI Development" }, { value: "Cloud Consulting", label: "Cloud Consulting" }, { value: "SI, IT Managed Services", label: "SI, IT Managed Services" }, { value: "IT Strategy Consulting", label: "IT Strategy Consulting" }]}
                                                                        value={servicesOffered}
                                                                        onChange={setServicesOffered}
                                                                        onFocus={() => setError({ ...error, servicesOffered: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.servicesOffered}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Technology Offered</label>
                                                                    <Selectant
                                                                        isDisabled={view}
                                                                        name="TechnologyOffered"
                                                                        value={technologyOffered}
                                                                        options={[{ value: "Dotnet", label: "Dotnet" }, { value: "PHP", label: "PHP" }, { value: "Mobile app development", label: "Mobile app development" }, { value: "IOS", label: "IOS" }, { value: "Android ", label: "Android " },]}
                                                                        onChange={setTechnologyOffered}
                                                                        onFocus={() => setError({ ...error, technologyOffered: "" })}
                                                                    ></Selectant>
                                                                    <small style={{ color: "#ec1c24" }}>{error.technologyOffered}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Description</label>
                                                                    <Form.Control
                                                                        name="description"
                                                                        placeholder="Description"
                                                                        readOnly={view}
                                                                        type="text"
                                                                        value={description}
                                                                        onChange={(e) => setDescription(e.target.value)}
                                                                        onFocus={() => setError({ ...error, description: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.description}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Facebook Profile URL</label>
                                                                    <Form.Control
                                                                        name="FacebookProfile"
                                                                        placeholder="Facebook profile"
                                                                        readOnly={view}
                                                                        type="text"
                                                                        value={FacebookProfile} onChange={(e) => {
                                                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                                                            setFacebookProfile(e.target.value)
                                                                            setError({ ...error, FacebookProfile: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, FacebookProfile: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.FacebookProfile}</small>
                                                                </Form.Group>

                                                            </Col>

                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Instagram Profile URL</label>
                                                                    <Form.Control
                                                                        name="InstagramUsername"
                                                                        placeholder="Instagram username"
                                                                        readOnly={view}
                                                                        type="text"
                                                                        value={InstagramUsername} onChange={(e) => {
                                                                            setInstagramUsername(e.target.value)
                                                                            setError({ ...error, InstagramUsername: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, InstagramUsername: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.InstagramUsername}</small>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col lassName="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Linkedin Profile URL</label>
                                                                    <Form.Control
                                                                        name="Linkedin"
                                                                        placeholder="Linkedin profile"
                                                                        readOnly={view}
                                                                        type="text"
                                                                        value={LinkedinProfile} onChange={(e) => {
                                                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                                                            setLinkedinProfile(e.target.value)
                                                                            setError({ ...error, LinkedinProfile: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, LinkedinProfile: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.LinkedinProfile}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label> Twitter Account</label>
                                                                    <Form.Control
                                                                        name="TwitterProfile"
                                                                        readOnly={view}
                                                                        placeholder="Twitter Account"
                                                                        type="text"
                                                                        value={TwitterProfile} onChange={(e) => {
                                                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                                                            setTwitterProfile(e.target.value)
                                                                            setError({ ...error, TwitterProfile: "" }) // Clear previous errors
                                                                        }}
                                                                        onFocus={() => setError({ ...error, TwitterProfile: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.TwitterProfile}</small>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>



                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Industry served based on Revenue</label>
                                                                    <Selectant
                                                                        isMulti
                                                                        name="IndustryservedbasedonRevenue"
                                                                        isDisabled={view}
                                                                        options={[{ value: "Automotive", label: "Automotive" }, { value: "Business services", label: "Business services" }, { value: "Dental", label: "Dental" }, { value: "Education", label: "Education" }, { value: "Financial services", label: "Financial services" }, { value: "Legal", label: "Legal" }, { value: "Medical", label: "Medical" }, { value: "Non-profit", label: "Non-profit" }, { value: "Real estate", label: "Real estate" }, { value: "ECommerce", label: "ECommerce" }]}
                                                                        value={industryBasedRevenue}
                                                                        onChange={setIndustryBasedRevenue}
                                                                        onFocus={() => setError({ ...error, industryBasedRevenue: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.industryBasedRevenue}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Client distribution Based on Revenue</label>
                                                                    <Selectant
                                                                        name="clientBasedRevenue"
                                                                        isDisabled={view}
                                                                        options={[{ value: "Small Business/Scale industry", label: "Small Business/Scale industry" }, { value: "Midsize Business/industry", label: "Midsize Business/industry" }, { value: "Enterprise level Business/industry", label: "Enterprise level Business/industry" }]}
                                                                        value={clientBasedRevenue}
                                                                        onChange={setClientBasedRevenue}
                                                                        onFocus={() => setError({ ...error, clientBasedRevenue: "" })}
                                                                    />
                                                                    <small style={{ color: "#ec1c24" }}>{error.clientBasedRevenue}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>



                                                        {!view && <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label>Upload Logo</label>
                                                                    <Form.Control
                                                                        name="logo"
                                                                        readOnly={view}
                                                                        type="file"
                                                                        onChange={(e) => {
                                                                            setLogo(e.target.files[0])
                                                                            setLocalLogo(URL.createObjectURL(e.target.files[0]))
                                                                        }}
                                                                        accept=".png, .jpg, .jpeg" onFocus={() => setError({ ...error, logo: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.logo}</small>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                <Form.Group>
                                                                    <label>Upload Company Profile</label>
                                                                    <Form.Control
                                                                        name="PortFolio"
                                                                        type="file"
                                                                        accept=".pdf"
                                                                        onChange={(e) => {
                                                                            setLocalPortfolio(URL.createObjectURL(e.target.files[0]))
                                                                            setPortfolio(e.target.files[0])
                                                                        }}
                                                                        onFocus={() => setError({ ...error, portfolio: "" })}
                                                                    ></Form.Control>
                                                                    <small style={{ color: "#ec1c24" }}>{error.portfolio}</small>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>}

                                                        {!view && <Row>
                                                            <Col className="pr-1" md="6">
                                                                {
                                                                    localLogo
                                                                        ?
                                                                        <img
                                                                            src={localLogo}
                                                                            style={{ borderRadius: '50%', width: 80, height: 80 }}
                                                                        />
                                                                        :
                                                                        logo
                                                                            ?
                                                                            < img
                                                                                src={`${endPointUrl}/images/${logo}`}
                                                                                style={{ borderRadius: '50%', width: 80, height: 80 }}
                                                                            />
                                                                            :
                                                                            ""
                                                                }
                                                            </Col>
                                                            <Col className="pl-1" md="6">
                                                                {
                                                                    localportfolio
                                                                        ?
                                                                        <a
                                                                            href={localportfolio}
                                                                            target="_blank"
                                                                        >Docuemnt</a>
                                                                        :
                                                                        portfolio
                                                                            ?
                                                                            <a
                                                                                href={localportfolio}
                                                                                target="_blank"
                                                                            >Docuemnt</a>
                                                                            :
                                                                            ""
                                                                }
                                                            </Col>
                                                        </Row>}


                                                        {view && <Row>
                                                            {logo && (
                                                                <div style={{ display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                                                    <strong style={{ verticalAlign: "top" }}>Logo: </strong>
                                                                    <img src={endPointUrl + "/images/" + logo} alt="Logo" style={{ width: "100px", height: '100px', borderRadius: '50%' }} />
                                                                </div>
                                                            )}
                                                            {portfolio && (
                                                                <div style={{ display: 'flex', marginTop: '2%' }}>
                                                                    <strong style={{ verticalAlign: "top" }}>Portfolio: </strong>
                                                                    <img src={endPointUrl + "/images/" + portfolio} alt="Portfolio" style={{ width: "100px", height: '100px', borderRadius: '50%' }} />
                                                                </div>
                                                            )}
                                                        </Row>}

                                                        {view && <Row>
                                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "5%" }}>
                                                                {approved
                                                                    ?
                                                                    <strong>Partner already approved</strong>
                                                                    :
                                                                    <button
                                                                        className="btn mb-1 btn-lg mr-3"
                                                                        type="submit"
                                                                        variant="info"
                                                                        onClick={handleApprove}
                                                                        style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px", cursor: "pointer" }}
                                                                    >
                                                                        Approve
                                                                    </button>}
                                                            </div>
                                                        </Row>}

                                                        {!view && <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <button
                                                                className="btn mb-1 btn-lg mr-3"
                                                                type="submit"
                                                                variant="info"
                                                                style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px", cursor: "pointer" }}
                                                            >
                                                                Update
                                                            </button>
                                                        </div>}
                                                    </Card.Body>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>


                        </Row>
                    </Container>
                </div>

            }
        </div >
    );
}

export default DisplayPartnerWithUs;