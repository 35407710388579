import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import { BsArrowLeftShort } from "react-icons/bs"
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import PaginationSection from './PaginationSection';

function PaymentToFreelancerDetails() {
  // const [formData, setFormData] = useState(initialFormData);
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const Id = location.pathname.split('/').pop();

  const getpayment = async (Id) => {
    console.log(Id)
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getfreelancerpaymentbyid", {
        Token,
        id: Id,
      });
      setallDetails(data.data.projects);
      console.log(data.data.projects);

    } catch (error) {
      console.log("get payment is not working", error);
    }
  };
  useEffect(() => {
    // console.log("Type state:", Type);
    getpayment(Id);
  }, []);

  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  //   const getProjectDetails = (row) => {
  //     return (
  //       <div>
  //         {row.Proj.map((project, index) => (
  //           <div key={index}>
  //             <strong>Project Name:</strong> {project.ProjectName}<br />
  //             <strong>Amount:</strong> {project.Amount}<br />
  //             <strong>Milestone Status:</strong> {project.MilestoneStatus}<br />
  //             <br />
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   };
  let serialNumber = 0;

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Project Name",
        accessor: (row) => row.Proj[0].ProjectName,
      },
      {
        Header: "Milstone Status",
        accessor: (row) => row.Proj[0].MilestoneStatus,
      },
      {
        Header: "Freelancer Status",
        accessor: (row) => row.Proj[0].FreelancerPaymentStatus,
      },
      {
        Header: "Employer Status",
        accessor: (row) => row.Proj[0].EmployerPaymentStatus,
      },
      {
        Header: "Milestone Due Date",
        accessor: (row) => row.Proj[0].M_Due_Date,
        Cell: ({ value }) => (
          <span>{value ? moment(value).format('DD/MM/YYYY') : "-"}</span>
        ),
      },
      {
        Header: "Amount",
        accessor: (row) => row.Proj[0].Amount,
      },


    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const getSNo = React.useCallback(
    (index) => {
      return index + 1 + pageIndex * pageSize;
    },
    [pageIndex, pageSize]
  );
  const navigateToFreelancerpaymentDetails = () => {
    history.push("/admin/Payment-To-Freelancer");
  };

  return (
    <>
      <div className="close profile" onClick={navigateToFreelancerpaymentDetails} >
        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 80px 0" }} />
      </div>

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()} style={{ width: '100%', textAlign: 'center' }}>
                  {/* <table style={{ width:'100%',textAlign:'center'}}> */}
                  <thead >
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>

                    {/* {allDetails.flatMap((row) =>
      row.Proj.map((proj, index) => (
        <tr key={proj.PM_ID}>
          <td>{index + 1}</td>
          <td>{proj.ProjectName}</td>
          <td>{proj.Amount}</td>
          <td>{proj.M_Due_Date}</td>
          <td>{proj.MilstoneStatus}</td>
          <td>{proj.FreelancerPaymentStatus}</td>
        </tr>
      ))
    )} */}
                    {allDetails.map((row) => (
                      <React.Fragment key={row.P_ID}>
                        {row.Proj.map((project) => {
                          // Increment the serial number for each project
                          serialNumber++;

                          return (
                            <tr key={project.PM_ID}>
                              <td>{serialNumber}</td>
                              <td>{project.ProjectName}</td>
                              <td>{project.MilstoneStatus}</td>
                              <td>{project.FreelancerPaymentStatus}</td>
                              <td>{project.EmployerPaymentStatus}</td>
                              {/* <td>{project.M_Due_Date}</td> */}
                              {project.M_Due_Date ? <td>
                                <span>{moment(project.M_Due_Date).format('DD/MM/YYYY')}</span>
                              </td>
                                :
                                <td>{project.M_Due_Date}</td>
                              }
                              <td>{project.Amount} :
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </tbody>
                  {/* </table> */}
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  )
};

export default PaymentToFreelancerDetails;