import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Report() {
  return (
    <>
       <Container fluid>
       
        <Row>
          <Col lg="6" sm="12">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="numbers">
                    <p className="card-category">Reports</p>
                    <Card.Title as="h3">Report 1</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="numbers">
                      <p className="card-category">Reports</p>
                      <Card.Title as="h3">Report 2</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
          </Col>
          </Row>
          </Container>
    </>
  );
}

export default Report;
