import React, { useContext,useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import {BsArrowLeftShort} from "react-icons/bs"
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
  } from "react-table";
  import moment from 'moment';
  import PaginationSection from './PaginationSection';

function EmployerPaymentt() {
    // const [formData, setFormData] = useState(initialFormData);
    const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const location=useLocation();
  const history = useHistory();

  const Id=location.pathname.split('/').pop();

  const getpayment = async (Id) => {
    console.log(Id)
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getpaymentbyProjectID", {
        Token,
        P_ID:Id,
      });
      setallDetails(data.projectInfo);
      console.log(data.projectInfo);   

    } catch(error) {
      console.log("get payment is not working",error);
    }
  };
  useEffect(() => {
    // console.log("Type state:", Type);
    getpayment(Id);
  }, []);

      const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
    {
        Header: "Milestone Description",
        accessor:"Description",
      },
      {
        Header: "Milstone Status",
        accessor:"MilstoneStatus",
      },
      {
        Header: "Freelancer Payment",
        accessor: "FreelancerPaymentStatus",
      },
      {
        Header: "Employer Payment",
        accessor: "EmployerPaymentStatus",
      },
      {
        Header: "Payment Date",
        accessor: "Payment_date",
        Cell: ({ value }) => (
          <span>{value ? moment(value).format('DD/MM/YYYY') : "-"}</span>
        ),
      },
      {
        Header: "Payment Reference",
        accessor: "PaymentReference",
        Cell: ({ value }) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      {
        Header: "Paid Amount",
        accessor:"Amount",
        Cell: ({ value }) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      
      
    ],
    []
  );
 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  
  const getSNo = React.useCallback(
    (index) => {
      return index + 1 + pageIndex * pageSize;
    },
    [pageIndex, pageSize]
  );
  const navigateToFreelancerpaymentDetails = () => {
    history.push("/admin/Payment-To-Freelancer"); 
  };

    return (
      <>

     
      <Row>
        <Col lg="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="mx-2"
                    aria-label="Search"
                    value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </Form>

              </Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table className="table-hover table-striped " {...getTableProps()} style={{ width:'100%',textAlign:'center'}}>
                {/* <table style={{ width:'100%',textAlign:'center'}}> */}
                <thead >
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
               
    <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr  {...row.getRowProps()}>
                        {/* {console.log(row.original.id)} */}
                        {row.cells.map((cell) => {
                          //  const { column } = cell.value;
                          //  let Id = column.Name;
                          //  console.log(cell.value);

                          return (<td onClick={() => handleclick(row.original.id)} {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                        })}
                       </tr>
                       );
                  }

                  )}
                </tbody>
                {/* </table> */}
              </Table>
            </Card.Body>
            <PaginationSection
      pageIndex={pageIndex}
      pageCount={pageOptions.length}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      gotoPage={gotoPage}
      previousPage={previousPage}
      nextPage={nextPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      pageOptions={pageOptions}
    />
          </Card>

        </Col>
      </Row>
      </>
    )
};

export default EmployerPaymentt;