import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import Profile from "assets/img/default-avatar.png"
import PaginationSection from './PaginationSection';
import MyContext from "MyContext";

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { Avatar } from "@mui/material";



function VerifiedVerification({ handleCount }) {
    const { endPointUrl, Token } = useContext(MyContext);
    const [showmodel, setshowModel] = useState(false);
    const [showImage, setImageShow] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null); // Store selected user details

    const [allDetails, setallDetails] = useState([]);

    const getverifiedVerification = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/getAllVerifieduser", {
                Token,
            });

            setallDetails(data.data);

        } catch {
            console.log("get verified is not working");
        }
    };
    const getuserDoc = async (Id) => {
        try {
            const { data } = await axios.post(endPointUrl + "/getUserById", {
                Token,
                id: Id,
            });
            setSelectedUser(data.data);
            setshowModel(true);
            document.body.classList.add("modal-open");
        } catch (error) {
            console.log("Error fetching user by ID", error);
        }
    };

    const updateVerificationStatus = async (Id, Status) => {

        let verificationDate = null; // Initialize verification date as null

        try {
            if (Status === "Verified") {
                verificationDate = new Date().toISOString().split('T')[0];
            }

            const { data } = await axios.post(endPointUrl + "/updateuserProfile", {
                Token,
                id: Id,
                Verified_Profile: Status,
                Verification_date: verificationDate, // Include verification date if it's not null

            });


            setSelectedUser((prevUser) => ({
                ...prevUser,
                Verified_Profile: Status,
                verification_Date: verificationDate, // Include verification date if it's not null

            }));

            setallDetails((Previous) =>
                Previous.filter((data) => data.id !== Id)
            );

            message.success("User status updated successfully");
            CloseModel();
            handleCount();

        } catch (error) {
            console.log("Error updating user", error);
            message.error("Failed to update user status");

        }
    };
    const data = React.useMemo(() => allDetails, [allDetails]);

    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1, // Auto-incremented serial number
            },
            {
                Header: ' ',
                Cell: tableProps => (
                    <div><img
                        src={tableProps.row.original.Photo === "" || tableProps.row.original.Photo == null
                            ? Profile
                            : `${endPointUrl}/images/${tableProps.row.original.Photo}`}
                        // src={`${endPointUrl}/images/${tableProps.row.original.Photo}`}
                        width={40}
                        height={40}
                        style={{ borderRadius: "50%" }}
                    /></div>
                )
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Email",
                accessor: "Email",
            },
            {
                Header: "Contact No.",
                accessor: "Mobile",
            },
            {
                Header: "User-type",
                accessor: "User-type",
                Cell: ({ row }) => {
                    // console.log('row',row)
                    const userType = row.original.Type;
                    return userType === 'Freelancer' ? 'Freelancer' : 'Employer';
                }


            },

            {
                Header: "Action",
                accessor: "id",
                Cell: ({ row }) => (
                    <div>
                        <span
                            onClick={() => handleclick(row.original.id)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <Button style={{ fill: "#07b274" }}>View Doc</Button>
                        </span>
                    </div>
                ),
            },
        ],
        []
    );
    useEffect(() => {
        getverifiedVerification();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page: tablePage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;


    const handleclick = (Id) => {
        getuserDoc(Id);

    };
    const CloseModel = () => {
        setshowModel(false);
        document.body.classList.remove("modal-open");
        setSelectedUser(null); // Clear selected user details when closing the modal



    };

    return (
        <>
            <>
                {/* {!showDetails ? ( */}
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card className="strpied-tabled-with-hover">
                                <Card.Header>
                                    <Card.Title as="h4">
                                        <Form className="d-flex">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                className="mx-2"
                                                aria-label="Search"
                                                value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                                            />
                                        </Form>

                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <Table className="table-hover table-striped " {...getTableProps()} >
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render("Header")}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? " 🔽"
                                                                        : " 🔼"
                                                                    : ""}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {tablePage.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr  {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                                                        })}

                                                    </tr>
                                                );
                                            }

                                            )}
                                        </tbody>

                                    </Table>
                                </Card.Body>
                                <PaginationSection
                                    pageIndex={pageIndex}
                                    pageCount={pageOptions.length}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pageOptions={pageOptions}
                                />
                            </Card>

                        </Col>
                    </Row>

                </Container>

            </>

            <div id="myModal"
                className={showmodel ? "model-open" : "model"}
                style={{ overflow: "auto" }}
            >
                <div className="modal-content" style={{ color: "black", }}>
                    <div className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
                        &times;
                    </div>
                    <Row>
                        <Col md="8" lg="8" sm="8" >
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", background: "", width: "50%" }}>
                                {selectedUser?.Photo ? (
                                    <img
                                        src={`${endPointUrl}/images/${selectedUser?.Photo}`}
                                        width="120px"
                                        height="120px"
                                        style={{ display: "flex", justifyContent: "center", borderRadius: "50%" }}
                                        onClick={() => setImageShow(true)}
                                    />
                                ) : (
                                    <Avatar sx={{
                                        width: "120px",
                                        height: "120px"
                                    }}></Avatar>
                                )}
                                <span style={{ marginTop: "3%" }}><strong>Name:</strong>{`${selectedUser?.Name} ${selectedUser?.Last_Name}`}</span>
                                <span style={{ marginTop: "3%" }}><strong>Address:</strong>{`${selectedUser?.Address ? selectedUser?.Address : "-"}`}</span>
                            </div>
                        </Col>
                        <Col md="4" lg="4" sm="4" >
                            {selectedUser?.Verification_Doc ?
                                <>
                                    <div>
                                        {selectedUser?.Verification_Doc?.substring(selectedUser?.Verification_Doc?.lastIndexOf(".") + 1) === "pdf" ?
                                            <div
                                                onClick={() => setImageShow(true)}
                                                style={{ fontSize: '16px', color: "#07b273", cursor: 'pointer', marginBottom: '10px' }}
                                            >
                                                Click to View Document
                                            </div>
                                            :
                                            <img
                                                src={`${endPointUrl}/images/${selectedUser?.Verification_Doc}`}
                                                width="80%"
                                                height="70%"
                                                style={{ display: "flex", justifyContent: "center", marginBottom: '20px', borderRadius: '10px' }}
                                                onClick={() => setImageShow(true)}
                                            />}
                                    </div>
                                    <div className="verify" style={{ flex: '2' }}>
                                        <div>
                                            <span className="bidhead">Type</span>
                                            <span id="bidheadValue">{selectedUser?.Verification_doc_type ? (selectedUser?.Verification_doc_type) : ("-")}</span>
                                        </div>
                                        <div>
                                            <span className="bidhead" >Verification Status</span>
                                            <span id="bidheadDuration" style={{ color: "rgb(14 154 235)" }}>
                                                {selectedUser?.Verified_Profile}
                                            </span>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="verify">
                                    <div className="bidhead">
                                        No Verification document uploaded
                                    </div>
                                    <span className="bidhead">Status</span>
                                    <span id="bidheadDuration" style={{ color: "rgb(14 154 235)" }}>
                                        {selectedUser?.Verified_Profile}
                                    </span>
                                </div>
                            }
                            <div style={{ margin: "20px 0 0 0" }}>
                                <button
                                    className="btn mb-2 btn-lg mr-4"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => updateVerificationStatus(selectedUser?.id, "Rejected")}        >
                                    Rejected
                                </button>
                            </div>
                        </Col>
                    </Row>

                </div>
                {
                    showImage ?
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "75%", height: "95%", display: 'flex', alignItems: "center", justifyContent: "center", background: "#fff" }}
                        >
                            <p style={{ position: "absolute", top: "-10px", right: "5px", fontSize: "35px" }} onClick={() => setImageShow(false)}>&times;</p>
                            {selectedUser?.Verification_Doc?.substring(selectedUser?.Verification_Doc?.lastIndexOf(".") + 1) === "pdf" ?
                                <embed src={`${endPointUrl}/images/${selectedUser?.Verification_Doc}`} width="800px" height="500px" type="application/pdf" />
                                :
                                <img
                                    src={`${endPointUrl}/images/${selectedUser?.Verification_Doc}`}
                                    width="800px"
                                    height="500px"
                                    alt=""
                                />}
                        </div>
                        : null
                }
            </div>
        </>
    );
}

export default VerifiedVerification;