import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message, Select } from "antd";
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import add from "assets/img/add1.jpg";
import PaginationSection from "./PaginationSection";
import { BiShow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import moment from 'moment';
import Selectant from 'react-select';

import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";

function Portfolio(Id) {

    const { endPointUrl, Token } = useContext(MyContext);
    const [allDetails, setallDetails] = useState([]);
    const history = useHistory();
    const [portfolio, setPortfolio] = useState([]);
    const [newPortfolio, setnewPortfolio] = useState({ DegreeName: null, University: null, Year: null });
    const [Editportfolio, setEditportfolio] = useState([]);
    const [showmodel, setshowModel] = useState(false);
    const [showEdit, setshowEdit] = useState(false);
    const [portfolioshow, setPortfolioshow] = useState(false);
    const [docshowform, setDocshowform] = useState(false);
    const [skillSelected, setskillSelected] = useState('');
    const [preform, setPreform] = useState(null);
    const [File_Type, setFile_Type] = useState([])
    const [skillmaster, setskillmaster] = useState([]);
    const [form, setForm] = useState({
        Title: "",
        Description: "",
        Type: "",
        Completion_Date: "",
        Skills: "",
        Project_Link: "",
    });    // const [showmodel, setshowModel] = useState(false);


    const getuserPortfolio = async (Id) => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
                UserID: Id,
                Token,
            });
            setallDetails(data.data);
        } catch (error) {
            console.log("get user portfolio is not working", error);
        }
    };

    const addPortfolio = async (portfolio) => {
        const { data } = await axios.post(endPointUrl + "/user/addportfolio", {
            formList: [{ ...portfolio, Skills: skillSelected }],
            UserID: Id.ID,
            Token
        });

        const PortfolioIDs = data.data;
        const imagaeData = [preform]


        imagaeData.forEach(async (images, index) => {
            const formdata = new FormData();
            images.forEach(image => {
                formdata.append('File_Upload', image);
            });

            try {
                const response = await axios.post(
                    endPointUrl + "/user/portfoliodocupload",
                    formdata,
                    {
                        params: {
                            Token,
                            UserID: Id.ID,
                            prtID: PortfolioIDs[index] // Using the corresponding Portfolio ID
                        }
                    }
                );
                setForm({
                    Title: "",
                    Description: "",
                    Type: "",
                    Completion_Date: "",
                    Skills: "",
                    Project_Link: "",
                })

                getuserPortfolio(Id.ID);
                message.success("Portfolio Add Successfully");
            } catch (error) {
                console.error("Error uploading documents:", error);
            }

        });
    };
    const deletePortfolio = async (PF_Id) => {
        try {
            await axios.post(endPointUrl + "/user/deletePortfolio", { PF_Id, UserID: Id.ID, Token });
            getuserPortfolio(Id.ID);
            message.success('Delete Succesfull');
        } catch {
            message.error('something Went wrong');
        }
    };
    function getFileExtension(filename) {
        var a = filename?.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();
    }
    const handlefiles = () => {
        let arr = [];
        preform.map((file) => arr.push(getFileExtension(file.name)));
        setFile_Type(arr);
    }

    useEffect(() => {
        if (File_Type.length > 0) { uploadfiles() }
    }, [File_Type])

    const uploadfiles = async () => {

        const formdata = new FormData();
        preform.map((item) => formdata.append('File_Upload', item))

        try {
            const { data } = await axios.post(
                endPointUrl + "/user/docupload", formdata //Files array upload
            );

            try {
                await axios.post(endPointUrl + "/user/addportfolioDoc", {
                    Token,
                    PF_Id: Editportfolio.PF_Id,
                    UserID: Editportfolio.UserID,
                    File_Upload: data.filenames,
                    File_Type: File_Type
                });
                // message.success("Portfolio Updated")
                getuserPortfolio(Editportfolio.UserID);
            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }


    const updatePortfolio = async () => {

        try {
            await axios.post(endPointUrl + "/user/updatePortfolio ", {
                ...Editportfolio,
                U_E_ID: Editportfolio.U_E_ID,
                Skills: skillSelected,
                Token,
            });
            getuserPortfolio(Editportfolio.UserID);
            handlefiles();
            message.success("Update Succesfull");
        } catch (error) {
            console.log(error);
            message.error("something went Wrong ");
        }
    };

    const handlePortfolioModel = (e) => {
        setshowModel(true);
    }
    const CloseModel = () => {
        setshowModel(false);
        setshowEdit(false);
    };
    const handlesubmitPortfolioModel = (e) => {
        e.preventDefault();
        if (!form.Title || !form.Description || !form.Type || !form.Completion_Date || skillSelected.length === 0 || !preform) {
            message.warning("Plese fill all fields!")
            return
        }
        addPortfolio(form);
        setshowModel(false);
    };
    const handleEditPortfolioModel = (rowData) => {
        setEditportfolio(rowData);
        setPreform(rowData?.docs)
        setskillSelected(rowData?.Skills)
        setshowEdit(true);
    };


    const handleEditsubPortfolioModel = (e) => {
        e.preventDefault();
        if (!Editportfolio.Title || !Editportfolio.Description || !Editportfolio.Type || !Editportfolio.Completion_Date || skillSelected.length === 0 || !preform) {
            message.warning("Plese fill all fields!")
            return
        }
        updatePortfolio();
        setshowEdit(false);
    };
    const handledocs = (e, Docs, Title, Type, Des) => {
        e.preventDefault();
        // setForm({

        // }
        // );
    }

    console.log("edit portfolio", Editportfolio)
    const data = React.useMemo(() => allDetails, [allDetails]);

    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1, // Auto-incremented serial number
            },
            {
                Header: "Title",
                accessor: "Title",
            },
            {
                Header: "Type",
                accessor: "Type",
            },
            {
                Header: "Skills",
                accessor: "Skills",
            },
            {
                Header: "Completion Date",
                accessor: "Completion_Date",
                Cell: ({ value }) => (
                    <span>{moment(value).format('DD/MM/YYYY')}</span>
                ),
            },
            {
                Header: "Actions",
                accessor: "U_E_ID",
                Cell: ({ row }) => (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        {/* "Update" icon */}
                        <span
                            onClick={() => handleEditPortfolioModel(row.original)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
                        </span>
                        {/* "Delete" icon */}
                        <span
                            onClick={() => deletePortfolio(row.original.PF_Id)}
                            style={{ cursor: "pointer" }}
                        >
                            <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                        </span>
                    </div>
                ),
            },
        ],
        []
    );


    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        setskillmaster(a);
    };


    useEffect(() => {
        getuserPortfolio(Id.ID);
        handleskillsmaster()
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        pageCount,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;
    return (
        <>

            <Container fluid>
                <div className="education">
                    <div className="d-flex justify-content-between align-items-center">
                        <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px", textTransform: "uppercase" }}>Portfolio
                            <img style={{ cursor: "pointer", width: "35px", height: "35px", marginLeft: "10px" }} src={add} alt="" onClick={handlePortfolioModel} /></strong>
                        <div style={{ cursor: "pointer", fontSize: "25px" }} onClick={(() => setPortfolioshow(!portfolioshow))}>
                            {!portfolioshow ? <BiShow /> : <RxCross2 />}
                        </div>

                    </div>

                    <Row className={portfolioshow ? "showalldta" : "hidealldta"}>
                        <Col md="12">
                            <Card className="strpied-tabled-with-hover">
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <Table className="table-hover table-striped " {...getTableProps()}>
                                        <thead >
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render("Header")}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? " 🔽"
                                                                        : " 🔼"
                                                                    : ""}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr style={{ cursor: "pointer" }}
                                                        onClick={(e) => handledocs(e,
                                                            row.original.docs,
                                                            row.original.Title,
                                                            row.original.Type,
                                                            row.original.Description
                                                        )}  {...row.getRowProps()}>
                                                        {/* {console.log(row.original.id)} */}
                                                        {row.cells.map((cell) => {
                                                            //  const { column } = cell.value;
                                                            //  let Id = column.Name;
                                                            //  console.log(cell.value);

                                                            return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                                                        })}

                                                    </tr>
                                                );
                                            }

                                            )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <PaginationSection
                                    pageIndex={pageIndex}
                                    pageCount={pageOptions.length}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pageOptions={pageOptions}
                                />
                            </Card>

                        </Col>
                    </Row>
                </div>

                <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
                    <div className="modal-content" style={{ width: "55%" }} >
                        <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>&times;</span>
                        <h2>Add Portfolio</h2>
                        <form className="model-field" onSubmit={handlesubmitPortfolioModel}>
                            <Row>
                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="title">Portfolio Name<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            value={form.Title}
                                            placeholder='Enter title'
                                            onChange={(e) => {
                                                const inputValue = e.target.value.slice(0, 45);
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Title: "" });
                                                }
                                                setForm({ ...form, Title: inputValue });
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="Completion_Date">Date<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            style={{ width: "100%" }}
                                            value={form.Completion_Date}
                                            type="date"
                                            name="Completion_Date"
                                            placeholder="Enter completion date"
                                            min="1900"
                                            max="2099"
                                            onChange={(e) => {
                                                const enteredDate = e.target.value;
                                                const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                setForm({ ...form, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>


                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="project_type">Project Type<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Selectant
                                            label={"Select"}
                                            style={{ width: "100%", padding: '0px' }}
                                            name="project_type"
                                            value={form.Type.split(",").map((item) => ({ value: item, label: item }))}
                                            options={
                                                [{ value: "Website", label: "Website" },
                                                { value: "Mobile App", label: "Mobile App" },
                                                { value: "ERP", label: "ERP" },
                                                { value: "SPA", label: "SPA" }]
                                            }
                                            onChange={(e) => {
                                                setForm({ ...form, Type: e.value });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="SkillsRequired">Skills<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Selectant
                                            isMulti
                                            label={"Enter Skills"}
                                            options={skillmaster}
                                            onChange={(value) => {
                                                const data = value.map((item) => item.value)
                                                setskillSelected(data.join(","))
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="12">
                                    <Form.Group>
                                        <strong htmlFor="descrption" style={{ display: "block" }}>Description<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            name="descrption"
                                            id=""
                                            // cols="30"
                                            style={{ width: "100%" }}
                                            // rows="3"
                                            maxLength={500}
                                            value={form.Description}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Description: "" });
                                                }
                                                setForm({ ...form, Description: inputValue });
                                            }}
                                            placeholder='Enter description'>

                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong>File Upload<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            style={{ marginInlineEnd: "12%", width: "100%" }}
                                            type="file"
                                            name="File_Upload"
                                            multiple
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                const currentFile = e.target.files[0]
                                                const allowedFileTypes = [
                                                    "image/jpeg",
                                                    "image/png",
                                                    "image/jpg",
                                                    "application/pdf",
                                                ];

                                                if ((currentFile.size / 1024) <= 500) {
                                                    if (files) {
                                                        const filteredFiles = Array.from(files).filter(file => (
                                                            allowedFileTypes.includes(file.type) && file.size / (1024 * 1024) <= 10
                                                        ));

                                                        if (filteredFiles.length > 0) {
                                                            setPreform(filteredFiles);
                                                        } else {
                                                            setPreform([])
                                                            e.target.value = null
                                                            message.warning("Files format and size mismatch");
                                                        }
                                                    }
                                                }
                                                else {
                                                    e.target.value = ''; // Reset the input value
                                                    message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                }
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="Project_Link">Project Link</strong>
                                        <Form.Control
                                            style={{ marginInlineEnd: "12%" }}
                                            type="text"
                                            name="Project_Link"
                                            value={form.Project_Link}
                                            placeholder='Project Link'
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Project_Link: "" });
                                                }
                                                setForm({ ...form, Project_Link: inputValue });
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <input type="submit" className="btn" value={'ADD'} />

                        </form>
                    </div >
                </div >


                {/* this model is for update Portfolio */}

                < div id="myModal" className={showEdit ? 'model-open' : 'model'} >
                    <div className="modal-content" style={{ width: "55%" }}>
                        <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>&times;</span>
                        <h2>Update Portfolio</h2>
                        <form className="model-field" onSubmit={handleEditsubPortfolioModel}>
                            <Row>
                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="title">Portfolio Name<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            value={Editportfolio.Title}
                                            placeholder='Enter title'
                                            onChange={(e) => {
                                                const inputValue = e.target.value.slice(0, 45);
                                                if (!inputValue.trim()) {
                                                    return setEditportfolio({ ...Editportfolio, Title: "" });
                                                }
                                                setEditportfolio({ ...Editportfolio, Title: inputValue });
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="Completion_Date">Date<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            style={{ width: "100%" }}
                                            value={Editportfolio?.Completion_Date?.slice(0, 10)}
                                            type="date"
                                            name="Completion_Date"
                                            placeholder="Enter completion date"
                                            min="1900"
                                            max="2099"
                                            onChange={(e) => {
                                                const enteredDate = e.target.value;
                                                const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                setEditportfolio({ ...Editportfolio, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>


                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="project_type">Project Type<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Selectant
                                            label={"Select"}
                                            style={{ width: "100%", padding: '0px' }}
                                            name="project_type"
                                            value={Editportfolio?.Type?.split(",").map((item) => ({ value: item, label: item }))}
                                            options={
                                                [{ value: "Website", label: "Website" },
                                                { value: "Mobile App", label: "Mobile App" },
                                                { value: "ERP", label: "ERP" },
                                                { value: "SPA", label: "SPA" }]
                                            }
                                            onChange={(e) => {
                                                setEditportfolio({ ...Editportfolio, Type: e.value });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="SkillsRequired">Skills<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Selectant
                                            isMulti
                                            label={"Enter Skills"}
                                            options={skillmaster}
                                            value={skillSelected?.split(',')?.map((item) => ({ value: item, label: item }))}
                                            onChange={(value) => {
                                                const data = value.map((item) => item.value)
                                                setskillSelected(data.join(","))
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="12">
                                    <Form.Group>
                                        <strong htmlFor="descrption" style={{ display: "block" }}>Description<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            name="descrption"
                                            id=""
                                            // cols="30"
                                            style={{ width: "100%" }}
                                            // rows="3"
                                            maxLength={500}
                                            value={Editportfolio.Description}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setEditportfolio({ ...Editportfolio, Description: "" });
                                                }
                                                setEditportfolio({ ...Editportfolio, Description: inputValue });
                                            }}
                                            placeholder='Enter description'>

                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong>File Upload<span style={{ color: "#BA0000" }}>*</span></strong>
                                        <Form.Control
                                            style={{ marginInlineEnd: "12%", width: "100%" }}
                                            type="file"
                                            name="File_Upload"
                                            multiple
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                const currentFile = e.target.files[0]
                                                const allowedFileTypes = [
                                                    "image/jpeg",
                                                    "image/png",
                                                    "image/jpg",
                                                    "application/pdf",
                                                ];

                                                if ((currentFile.size / 1024) <= 500) {
                                                    if (files) {
                                                        const filteredFiles = Array.from(files).filter(file => (
                                                            allowedFileTypes.includes(file.type) && file.size / (1024 * 1024) <= 10
                                                        ));

                                                        if (filteredFiles.length > 0) {
                                                            setPreform(filteredFiles);
                                                        } else {
                                                            setPreform([])
                                                            e.target.value = null
                                                            message.warning("Files format and size mismatch");
                                                        }
                                                    }
                                                }
                                                else {
                                                    e.target.value = ''; // Reset the input value
                                                    message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                }
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="pl-1" md="6">
                                    <Form.Group>
                                        <strong htmlFor="Project_Link">Project Link</strong>
                                        <Form.Control
                                            style={{ marginInlineEnd: "12%" }}
                                            type="text"
                                            name="Project_Link"
                                            value={Editportfolio.Project_Link}
                                            placeholder='Project Link'
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setEditportfolio({ ...Editportfolio, Project_Link: "" });
                                                }
                                                setEditportfolio({ ...Editportfolio, Project_Link: inputValue });
                                            }}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col className="pl-1" md="12">
                                    {preform && preform.length > 0 && (
                                        <div>
                                            <h4>Selected Files:</h4>
                                            <ul>
                                                {Array.from(preform).map((file, index) => (
                                                    <li key={index} style={{ display: "grid", gridTemplateColumns: "30% 60%", alignItems: "center", gap: "30px" }}>
                                                        <span style={{ overflowWrap: "break-word" }}><strong>Type:</strong> {file.File_Type || file.type}</span>
                                                        <span style={{ overflowWrap: "break-word" }}> <strong>Name:</strong> {file.File_Upload || file.name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <input type="submit" className="btn" value={'Update'} />
                        </form>
                    </div>
                </div >
            </Container >
            {/* 
            <div id="myModal"
                className={docshowform ? "model-open" : "model"}
                style={{ overflow: "auto" }}
            >
                <div className="modal-content" style={{ color: "black", lineHeight: "30px" }}>
                    <div className="close" onClick={() => { setDocshowform(false) }} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
                        &times;
                    </div>
                    <div>
                        <h3 style={{ textAlign: "center" }}>{doc.type}</h3>
                        <div>{doc.title}</div>
                        <span>{doc.des}</span>
                        <div style={{ gap: "10px", marginTop: "10px" }} className="d-flex align-self-center">
                            {doc.docs.map((item, index) => (

                                <a
                                    key={index}
                                    href={`${endPointUrl}/portfoliodoc/${item.File_Upload}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={item.File_Name}
                                >
                                    {item.File_Type === "png" ||
                                        item.File_Type === "jpg" ||
                                        item.File_Type === "jpeg" ||
                                        item.File_Type === "gif" ||
                                        item.File_Type === "webp" ||
                                        item.File_Type === "jfif" ? (

                                        <img
                                            src={`${endPointUrl}/portfoliodoc/${item.File_Upload}`}
                                            style={{ width: "50px", height: "50px", borderRadius: "25%" }}
                                            alt={item.File_Type}
                                            className="potfoliothumbail"
                                        />
                                    ) : item.File_Type === "pdf" ? (
                                        <AiOutlineFilePdf className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                    ) : (
                                        <AiOutlineFileText className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                    )}

                                </a>


                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Portfolio;