
import React, { useState, useContext, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '.././assets/css/testimonial.css';
import axios from "axios";
import moment from 'moment';
import MyContext from "../MyContext";
import PaginationSection from './PaginationSection';
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Selectant from 'react-select';
import { message } from "antd";
import TimezoneSelect from "react-timezone-select";
import { useTable, usePagination, useGlobalFilter, useSortBy, } from "react-table";
import { Card, Table, Button, Container, Row, Col, Form } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Description } from "@mui/icons-material";


function ProductInerested() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [product, setProduct] = useState([])
    const data = React.useMemo(() => product, [product]);
    const [allDetails, setallDetails] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Product Name",
                accessor: "ProductName",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "EmailID",
                accessor: "Email",
            },
            {
                Header: "Phone",
                accessor: "Phone",
            },
            {
                Header: "Description",
                accessor: "Description",
            },
            // {
            //     Header: "Action",
            //     accessor: "NID",
            //     Cell: ({ row }) => (
            //         <div className="d-flex flex-row">

            //             <span
            //                 onClick={() => handleshowModel(row)}
            //                 style={{ cursor: "pointer", marginRight: "10px" }}
            //             >
            //                 <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
            //             </span>

            //             <span
            //                 onClick={(e) => deleteProduct(e, row.original.Product_ID)}
            //                 style={{ cursor: "pointer" }}>
            //                 <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
            //             </span>
            //         </div>
            //     ),
            // },

        ],
        []
    );

    const getProduct = async () => {
        try {
            const { data } = await axios.get(endPointUrl + "/getProductInterested");
            if (data) {
                setProduct(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getProduct()
    }, [])

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page: tablePage, canPreviousPage, canNextPage, pageOptions, nextPage, previousPage, setPageSize, setGlobalFilter, gotoPage, state, } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 5 }, autoResetPage: false, }, useGlobalFilter, useSortBy, usePagination);
    const { globalFilter, pageIndex, pageSize } = state;


    return (
            <Container fluid>
                <Row>
                    <h1 style={{ width: "100%" }}>Product</h1>
                    <Col md="12">
                        <Card className="stripped-tabled-with-hover">
                            <Card.Header style={{ paddingTop: "5px" }}>
                                <Card.Title as="h4">
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            className="mx-2"
                                            aria-label="Search"
                                            value={globalFilter || ''}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                    </Form>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped" {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps(), {

                                                    })}>
                                                        {column.render("Header")}
                                                        {/* Add a sort direction indicator */}
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? " 🔽"
                                                                    : " 🔼"
                                                                : ""}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...getTableBodyProps()}>
                                        {tablePage.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps({

                                                        })}>{cell.render("Cell")}</td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <PaginationSection
                                pageIndex={pageIndex}
                                pageCount={pageOptions.length}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                gotoPage={gotoPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageOptions={pageOptions}
                            />
                        </Card>
                    </Col>
                </Row>
            </Container>
    );
}

export default ProductInerested;