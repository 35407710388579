import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import add from "assets/img/add1.jpg";
import PaginationSection from "./PaginationSection";
import { BiShow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import Selectant from 'react-select';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";
import { CollectionsBookmark } from "@mui/icons-material";

function Education(Id) {

  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const history = useHistory();
  const [education, setEducation] = useState([]);
  const [newEducation, setnewEducation] = useState({ DegreeName: null, Univercity: null, Year: null });
  const [Editeducation, setEditeducation] = useState([]);
  const [showmodel, setshowModel] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [educationshow, setEducationShow] = useState(false);
  const [preform, setPreform] = useState(null);
  const [imgURL, setImgURL] = useState('')
  const [DegreeMaster, setDegreeMaster] = useState([]);
  // const [showmodel, setshowModel] = useState(false);

  const getuserEducation = async (Id) => {
    console.log("[h", Id)
    try {
      const { data } = await axios.post(endPointUrl + "/user/getuserEducation", {
        UserID: Id.ID,
        Token,
      });

      setallDetails(data.data);
      console.log(data.data);

    } catch {
      console.log("get user education is not working");
    }
  };

  const addEducation = async (education) => {
    const { data } = await axios.post(endPointUrl + "/user/userEducation", {
      formList: [education],
      UserID: Id.ID,
      Token
    }
    );
    const EducationID = data.data;
    const formdata = new FormData();
    var imagaeData = "";

    imagaeData = [preform]

    imagaeData.forEach(item => formdata.append('File_Upload', item));

    try {
      const response = await axios.post(
        endPointUrl + "/user/educationdocupload",
        formdata,
        {
          params: {
            Token,
            eduID: EducationID
          }
        }
      );
      console.log("Document upload response:", response.data);
    } catch (error) {
      console.error("Error uploading documents:", error);
    }


    message.success("Education Added")

    getuserEducation();
  };
  const deleteEducation = async (U_E_ID) => {
    try {
      await axios.post(endPointUrl + "/user/deleteuserEducation", { U_E_ID, Token });
      message.success('Delete Succesfull');
      getuserEducation();
    } catch {
      message.error('something Went wrong');
    }
  };
  const updateEducation = async () => {
    try {
      let res = "";
      if (preform) {
        const formData = new FormData()
        formData.append("Education_File", preform)

        res = await axios.post(
          endPointUrl + "/user/educationupload", formData
        );

        await axios.post(endPointUrl + "/user/updateUserEducation ", {
          ...Editeducation,
          U_E_ID: Editeducation.U_E_ID,
          Education_File: res?.data?.fileName,
          Token,
        });

        setPreform(null)
        setEditeducation({});
        setImgURL('')

        // getEducation();
        message.success("Update Successful");
      }
      else {
        alert('update data')
        await axios.post(endPointUrl + "/user/updateOnlyUserEducation ", {
          ...Editeducation,
          U_E_ID: Editeducation.U_E_ID,
          Token,
        });
        setEditeducation({});
        // getEducation();
        message.success("Update Successful");
      }
    } catch (erro) {
      console.log("ERROR : : :  : ", erro)
      message.error("something went Wrong ");
    }
  };

  const handleEducationModel = (e) => {
    setshowModel(true);
  }
  const CloseModel = () => {
    setshowModel(false);
    setshowEdit(false);
  };
  const handlesubmitEducationModel = (e) => {
    e.preventDefault();
    if (!newEducation.DegreeName || !newEducation.Univercity || !newEducation.Year || !preform) {
      message.warning("Please fill all fields")
      return
    }
    addEducation(newEducation);
    setshowModel(false);
  };
  // const handleEditEducationModel = (Item) => {
  //   let tem = education.filter((item) => {
  //     return item.U_E_ID === Item.U_E_ID});
  //     setEditeducation(tem);
  //     console.log(Editeducation);
  //     setshowEdit(true);
  // };
  const handleEditEducationModel = (rowData) => {
    setEditeducation(rowData);
    console.log(rowData);
    setshowEdit(true);
  };

  const handleEditsubEducationModel = (e) => {
    e.preventDefault();

    updateEducation();
    setshowEdit(false);
  };
  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "University",
        accessor: "Univercity",
      },
      {
        Header: "Degree",
        accessor: "DegreeName",
      },
      {
        Header: "Year",
        accessor: "Year",
        Cell: ({ value }) => (
          <span>{moment(value).format('YYYY')}</span>
        ),
      },
      {
        Header: "Actions",
        accessor: "U_E_ID",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
            {/* "Update" icon */}
            <span
              onClick={() => handleEditEducationModel(row.original)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
            </span>
            {/* "Delete" icon */}
            <span
              onClick={() => deleteEducation(row.original.U_E_ID)}
              style={{ cursor: "pointer" }}
            >
              <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const DegreeMAster = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/Admin/getallDegree", {
        Token,
      });
      if (data && data.data && Array.isArray(data.data)) {
        const languageNames = data.data.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));
        setDegreeMaster(languageNames);
      } else {
        console.log('Failed to fetch Degree. Data may be empty or not in the expected format.');
      }
    } catch (error) {
      console.log('Failed to fetch Degree:', error);
    }
  };
  useEffect(() => {
    getuserEducation(Id);
    DegreeMAster()
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>

      <Container fluid>
        {/* {!showDetails ? ( */}
        <div className="education">
          <div className="d-flex justify-content-between align-items-center">
            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px", textTransform: "uppercase" }}>EDUCATION
              <img style={{ cursor: "pointer", width: "35px", height: "35px", marginLeft: "10px" }} src={add} alt="" onClick={handleEducationModel} /></strong>
            <div style={{ cursor: "pointer", fontSize: "25px" }} onClick={(() => setEducationShow(!educationshow))}>
              {!educationshow ? <BiShow /> : <RxCross2 />}
            </div>

          </div>

          <Row className={educationshow ? "showalldta" : "hidealldta"}>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped " {...getTableProps()}>
                    <thead >
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr  {...row.getRowProps()}>
                            {/* {console.log(row.original.id)} */}
                            {row.cells.map((cell) => {
                              //  const { column } = cell.value;
                              //  let Id = column.Name;
                              //  console.log(cell.value);

                              return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                            })}

                          </tr>
                        );
                      }

                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                <PaginationSection
                  pageIndex={pageIndex}
                  pageCount={pageOptions.length}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageOptions={pageOptions}
                />
              </Card>

            </Col>
          </Row>
        </div>

        <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
          <div className="modal-content" style={{ width: "55%" }} >
            <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>&times;</span>
            <h2>Add Education</h2>
            <form className="model-field" onSubmit={handlesubmitEducationModel}>
              <Card.Body>
                <Row>
                  <Col className="pr-1" md="6" style={{ paddingLeft: "5px" }}>
                    <strong htmlFor="DegreeName">Degree<span style={{ color: "#BA0000" }}>*</span></strong>
                    <Selectant
                      styles={{ width: "100%", background: "blue" }}
                      name="DegreeName"
                      options={DegreeMaster}
                      onChange={(e) => setnewEducation({ ...newEducation, DegreeName: e.value })}
                      value={{ value: newEducation.DegreeName, label: newEducation.DegreeName }}
                    />
                  </Col>


                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <strong htmlFor="University">University<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control type="text" name="University" style={{ marginInlineEnd: "12%" }}
                        placeholder="Enter University/college name"
                        onChange={(e) => setnewEducation({ ...newEducation, Univercity: e.target.value })}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <strong>Completion Year<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        style={{ width: "100%" }}
                        type="month"
                        name="Year"
                        min="1960" max="2030"
                        value={newEducation.Year}
                        onChange={(e) => {
                          setnewEducation({ ...newEducation, Year: e.target.value });
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <strong>Upload Certificate<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        type="file"
                        name="File_Upload"
                        id="certificatefile"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
                          if (selectedFile && selectedFile.size / 1024 <= 500) {
                            if (allowedTypes.includes(selectedFile.type)) {
                              setPreform(selectedFile);
                            } else {
                              if (selectedFile.size / 1024 > 500) {
                                message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                              }
                              e.target.value = '';
                              setPreform(null)
                              return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                            }
                          }
                          else {
                            e.target.value = '';
                            setPreform(null);
                            message.warning('File size exceeds 500 KB. Please upload a smaller file.');
                          }
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <input type="submit" className="btn" value={'ADD'} />
                </Row>
              </Card.Body>
            </form>
          </div>
        </div>


        {/* this model is for update education */}

        <div id="myModal" className={showEdit ? 'model-open' : 'model'}>
          <div className="modal-content" style={{ width: "55%" }}>
            <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>&times;</span>
            <h2>Update Education</h2>
            <form className="model-field" onSubmit={handleEditsubEducationModel}>
              <Card.Body>
                <Row>
                  <Col className="pr-1" md="6" style={{ paddingLeft: "5px" }}>
                    <strong htmlFor="DegreeName">Degree<span style={{ color: "#BA0000" }}>*</span></strong>
                    <Selectant
                      styles={{ width: "100%", background: "blue" }}
                      name="DegreeName"
                      options={DegreeMaster}
                      onChange={(e) => setEditeducation({ ...Editeducation, DegreeName: e.value })}
                      value={{ value: Editeducation.DegreeName, label: Editeducation.DegreeName }}
                    />
                  </Col>


                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <strong htmlFor="University">University<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control type="text" name="University" style={{ marginInlineEnd: "12%" }}
                        placeholder="Enter University/college name"
                        value={Editeducation.Univercity}
                        onChange={(e) => setEditeducation({ ...Editeducation, Univercity: e.target.value })}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <strong>Completion Year<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        style={{ width: "100%" }}
                        type="month"
                        name="Year"
                        min="1960" max="2030"
                        value={Editeducation.Year}
                        onChange={(e) => {
                          setEditeducation({ ...Editeducation, Year: e.target.value });
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <strong>Upload Certificate<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        type="file"
                        name="File_Upload"
                        id="certificatefile"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
                          if (selectedFile && selectedFile.size / 1024 <= 500) {
                            if (allowedTypes.includes(selectedFile.type)) {
                              setPreform(selectedFile);
                              setImgURL(URL.createObjectURL(selectedFile))
                            } else {
                              if (selectedFile.size / 1024 > 500) {
                                message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                              }
                              e.target.value = '';
                              setPreform(null)
                              return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                            }
                          }
                          else {
                            e.target.value = '';
                            setPreform(null);
                            message.warning('File size exceeds 500 KB. Please upload a smaller file.');
                          }
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>



                  <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between", marginBottom: "10px" }}>
                    {imgURL ?
                      preform?.type == "application/pdf" ?
                        <div>
                          <embed src={imgURL} width="50%" height="200px" type="application/pdf" />
                        </div>
                        :
                        <img src={imgURL} width="50%" style={{ borderRadius: '10px' }} />
                      :
                      Editeducation.Education_File?.substring(Editeducation.Education_File?.lastIndexOf(".") + 1) === "pdf" ?
                        <embed src={`${endPointUrl}/education/${Editeducation.Education_File}`} width="50%" height="200px" type="application/pdf" />
                        :
                        <img src={`${endPointUrl}/education/${Editeducation.Education_File}`} width="50%" style={{ borderRadius: '10px' }} />
                    }
                  </div>
                  <input type="submit" className="btn" value={'Update'} />
                </Row>
              </Card.Body>
            </form>

          </div>
        </div>


      </Container>


    </>
  )
}

export default Education;