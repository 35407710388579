import React, { useState, useContext, useEffect } from "react";
import MyContext from "MyContext";
import Selectant from "react-select";
import axios from "axios";
import { message } from "antd";
import Select from "react-select";
import { Country } from "country-state-city";
import { Button } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function AddJob({ item, button }) {
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [showpublished, setshowPublished] = useState(false);
    const [skillmaster, setskillmaster] = useState([]);

    const [showmodel, setshowModel] = useState(false);
    const [technologyMaster, settechnologyMaster] = useState([]);
    const [categoryMaster, setCategorymaster] = useState([]);
    const [Languagemaster, setLanguagemaster] = useState([]);

    const [skillSelected, setskillSelected] = useState([]);
    const [LocationSelected, setLocationSelected] = useState([]);
    const [technologySelected, settechnologySelected] = useState([]);
    const [categorySelected, setCategorySelected] = useState([]);
    const [LanguageSelected, setLanguageSelected] = useState([]);
    const [LocationMaster, setLocationMaster] = useState([])
    const [Degreemaster, setDegreemaster] = useState();
    const [Document, setDocument] = useState(" ");
    const [LocalDocument, setLocalDocument] = useState("");



    const [form, setForm] = useState({
        jobTitle: "",
        Budget_Type: "",
        Budget_From: "",
        ProjectType: "",
        Budget_To: "",
        Type: "",
        skillSelected: "",
        categorySelected: "",
        technologySelected: "",
        Description: "",
        expertise: "",
        projectScope: "",
        shortDescription: "",
        projectTime: "",
    })


    const [Description, setDescription] = useState("");
    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        a.push({ value: "Other", label: "Other" })
        setskillmaster(a);
    };

    const handletechnologyMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getAlltechnology", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        a.push({ value: "Other", label: "Other" })
        settechnologyMaster(a);
    };

    const handleLocationMaster = async () => {
        let a = [];
        Country.getAllCountries().map((item) => a.push({ value: item.name, label: item.name }));
        a.push({ value: "Other", label: "Other" })
        setLocationMaster(a);
    };
    const handleLanguageMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        setLanguagemaster(a);
    };
    const handlecategoryMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallcategory", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        a.push({ value: "Other", label: "Other" })
        setCategorymaster(a);
    };


    const handleDegreeMaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getAllDegree", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push({ value: item.Name, label: item.Name }));
        setDegreemaster(a);
    };

    const [job, setjob] = useState({
        Title: null,
        Budget_From: null,
        ProjectType: null,
        Budget_To: null,
        Budget_Type: null,
        Type: null,
        Completion_Date: null,
        expertise: "",
        projectTime: "",
        projectScope: "",
        JobCategory: "",
        shortDescription: "",
    });

    const onEditorChange = (evt, editor) => {
        const data = evt.editor && evt.editor.getData();
        setDescription(data);
    };
    useEffect(() => {
        handleLanguageMaster();
        handleskillsmaster();
        handlecategoryMaster();
        handletechnologyMaster();
        handleLocationMaster();
        handleDegreeMaster()
    }, []);
    const handleExpModel = () => {
        setshowModel(true);
    };

    const CloseModel = () => {
        setshowModel(false);
    };



    const handlejobSubmit = async (e) => {
        e.preventDefault();
        const newFormErrors = {
            Budget_FromTitle: "",
            jobTitle: "",
            ProjectType: "",
            Budget_Type: "",
            Budget_From: "",
            Budget_To: "",
            Type: "",
            skillSelected: "",
            categorySelected: "",
            technologySelected: "",
            //LocationSelected: "",
            //LanguageSelected: "",
            //Description: "",
            expertise: "",
            projectScope: "",
            shortDescription: "",
            projectTime: "",
        };

        let showError = false;
        if (parseInt(job.Budget_From) > parseInt(job.Budget_To)) {
            showError = true
            newFormErrors.Budget_FromTitle = "Budget from cant be less than Budget to"
        } else {
            newFormErrors.Budget_FromTitle = ''
        }
        if (job.Title === null || job.Title === "") {
            showError = true;
            newFormErrors.jobTitle = 'Please fill out this field!';
        } else {
            newFormErrors.jobTitle = '';
        }
        if (job.ProjectType === null || job.ProjectType === "") {
            showError = true;
            newFormErrors.ProjectType = 'Please fill out this field!';
        } else {
            newFormErrors.ProjectType = '';
        }
        if (job.Budget_Type == null || job.Budget_Type === "") {
            showError = true;
            setForm({ ...form, Budget_Type: 'Please fill out this field!' });
            newFormErrors.Budget_Type = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, Budget_Type: "" })
        }
        if (job.Budget_From == null || job.Budget_From === "") {
            showError = true;
            setForm({ ...form, Budget_From: 'Please fill out this field!' });
            newFormErrors.Budget_From = 'Please fill out this field!';
        }
        else if (isNaN(parseInt(job.Budget_From))) {
            showError = true;
            setForm({ ...form, Budget_From: 'Should contain only numbers' });
            newFormErrors.Budget_From = 'Should contain only numbers!';
        }
        else {
            setForm({ ...form, Budget_From: "" })
        }
        if (job.Budget_To == null || job.Budget_To === "") {
            showError = true;
            setForm({ ...form, Budget_To: 'Please fill out this field!' });
            newFormErrors.Budget_To = 'Please fill out this field!';

        } else if (parseInt(job.Budget_From) > parseInt(job.Budget_To)) {
            showError = true;
            setForm({ ...form, Budget_From: 'Mininum Budget cant be more than Maximum' });
            newFormErrors.Budget_From = 'Mininum Budget cant be more than Maximum';
        } else if (isNaN(parseInt(job.Budget_To))) {
            showError = true;
            setForm({ ...form, Budget_To: 'Should contain only numbers' });
            newFormErrors.Budget_To = 'Should contain only numbers!';
        } else {
            setForm({ ...form, Budget_To: "" })
        }
        if (job.Type == null || job.Type === "") {
            showError = true;
            setForm({ ...form, Type: 'Please fill out this field!' });
            newFormErrors.Type = 'Please fill out this field!';

        } else {
            setForm({ ...form, Type: "" })
        }
        if (skillSelected.length == 0) {
            showError = true;
            setForm({ ...form, skillSelected: 'Please fill out this field!' });
            newFormErrors.skillSelected = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, skillSelected: "" })
        }
        if (categorySelected.length == 0) {
            showError = true;
            setForm({ ...form, categorySelected: 'Please fill out this field!' });
            newFormErrors.categorySelected = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, categorySelected: "" })
        }
        if (technologySelected.length == 0) {
            showError = true;
            setForm({ ...form, technologySelected: 'Please fill out this field!' });
            newFormErrors.technologySelected = 'Please fill out this field!';
        }
        else {
            setForm({ ...form, technologySelected: "" })
        }
        // if (LocationSelected.length == 0) {
        //   showError = true;
        //   setForm({ ...form, LocationSelected: 'Please fill out this field!' });
        //   newFormErrors.LocationSelected = 'Please fill out this field!';
        // }
        // else {
        //   setForm({ ...form, LocationSelected: "" })
        // }
        // if (LanguageSelected.length == 0) {
        //   showError = true;
        //   setForm({ ...form, LanguageSelected: 'Please fill out this field!' });
        //   newFormErrors.LanguageSelected = 'Please fill out this field!';
        // }
        // else {
        //   setForm({ ...form, LanguageSelected: "" })
        // }
        // if (Description === "") {
        //     showError = true;
        //     setForm({ ...form, Description: 'Please fill out this field!' });
        //     newFormErrors.Description = 'Please fill out this field!';
        // }
        // else {
        //     setForm({ ...form, Description: "" })
        // }
        if (job.projectScope.length === 0) {
            showError = true;
            setForm({ ...form, projectScope: "Please fill out this field!" })
            newFormErrors.projectScope = "Please fill out this field!";
        }
        if (job.projectTime.length === 0) {
            showError = true;
            setForm({ ...form, projectTime: "Please fill out of this field!" });
            newFormErrors.projectTime = "Please fill out this field!";
        }
        if (job.expertise.length === 0) {
            showError = true;
            setForm({ ...form, expertise: "Please fill out this field!" })
            newFormErrors.expertise = "Please fill out this field!";
        }
        if (job.shortDescription.length === 0) {
            showError = true;
            setForm({ ...form, shortDescription: "Please fill out this field!" })
            newFormErrors.shortDescription = "Please fill out this field!"
        }
        console.log("show error", showError)
        console.log("newfromerror", newFormErrors)
        setForm(newFormErrors);
        if (showError) {
            setshowPublished(false);
            return;
        }

        try {
            const { data } = await axios.post(endPointUrl + "/user/userAddJobs", {
                ...job,
                Location: LocationSelected.join(","),
                SkillsRequired: skillSelected.join(","),
                Technology: technologySelected.join(","),
                Category: categorySelected.join(","),
                Language: LanguageSelected.join(","),
                // Keywords: tags.join(","),
                Employer_ID: item.id,
                Description,
                Token,
                IsActive: true,
                Status: "Published",
                From_Name: `${item.Name} ${item.Last_Name}`
            });
            // socket.emit('job_posting_alert', {
            //     FromID: item.id,
            //     Title: job.Title,
            //     Type: "Project",
            //     SkillsRequired: skillSelected,
            //     // LanguageRequired: languageSelected,
            //     // LocationRequired: LocationSelected,
            //     Message: `${item.Name + " " + item.Last_Name} posted recently one job for ${job.Title}`,
            //     IsRead: false,
            //     ItemID: data.data.JobID,
            // })
            message.success("Job Published Successfully");
            setshowModel(false);
        } catch (error) {
            console.log("error", error)
            window.scroll(0, 0);
            message.warning("please fill all the fields");
        }
    }

    return (
        <>

            {/* <span >Add Job</span> */}
            <Button onClick={() => handleExpModel()} style={{ fill: "#07b274" }}>Add Job</Button>
            <div
                id="myModal"
                className={showmodel ? "model-open" : "model"}
                style={{ overflow: "scroll" }}
            >
                <div className="modal-content" style={{ color: 'black' }}>
                    <span className="close" onClick={CloseModel}>
                        &times;
                    </span>
                    <h2>Add Job</h2>
                    <form
                        onSubmit={(e) => {
                            handlejobSubmit(e)
                        }}
                    >
                        <div style={{ marginTop: '5%', boxShadow: '0px 0px 5px 2px #f2f2f2', padding: '50px 10px', boxSizing: 'border-box', borderRadius: '10px' }}>
                            <div className="userdeatail_container">
                                <div>
                                    <div>Job Heading <span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <input
                                        type="text"
                                        value={job.Title}
                                        name="Title"
                                        placeholder="Enter Your title"
                                        className={form.jobTitle != "" ? "errorborder" : ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value.slice(0, 80);
                                            if (!inputValue.trim()) {
                                                return setjob({ ...job, Title: "" });
                                            }
                                            setjob({ ...job, Title: inputValue });
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.jobTitle}</small>
                                </div>

                                <div className={job.projectScope ? "userdeatail_container" : ""} style={{ padding: "0px" }}>
                                    <div>
                                        <div>Project Scope<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                        <Selectant
                                            name="Projectscope"
                                            placeholder="Project Scope"
                                            className={form.projectScope != "" ? "errorborder" : ""}
                                            options={[{ value: "", label: "Select Project Scope" }, { value: "One Time Project", label: "One Time project" }, { value: "Long Time Project", label: "Long Time Project" }]}
                                            onChange={(value) => setjob({ ...job, projectScope: value.value })}
                                        />
                                        <small style={{ color: "#ec1c24" }}>{form.projectScope}</small>
                                    </div>

                                    {job.projectScope === "One Time Project" ?
                                        <div>
                                            <div>Select<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                            <Selectant
                                                name="Projectscope"
                                                className={form.projectTime != "" ? "errorborder" : ""}
                                                placeholder="Select"
                                                options={[{ value: "Less than 1 month", label: "Less than 1 month" }, { value: "1 to 3 months", label: "1 to 3 months" }, { value: "3 to 6 months", label: "3 to 6 months" }, { value: "Greater than 1 year", label: "Greater than 1 year" }]}
                                                onChange={(value) => setjob({ ...job, projectTime: value.value })}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.projectTime}</small>
                                        </div> : <></>}

                                    {job.projectScope === "Long Time Project" ?
                                        <div>
                                            <div>Select<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                            <Selectant
                                                name="Projectscope"
                                                className={form.projectTime != "" ? "errorborder" : ""}
                                                placeholder="Select"
                                                options={[{ value: "1 to 3 months", label: "1 to 3 months" }, { value: "3 to 6 months", label: "3 to 6 months" }, { value: "Greater than 1 year", label: "Greater than 1 year" }]}
                                                onChange={(value) => setjob({ ...job, projectTime: value.value })}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.projectTime}</small>
                                        </div> : <></>}
                                </div>
                            </div>



                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <div>Expertise<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <Selectant
                                        className={form.expertise != "" ? "errorborder" : ""}
                                        options={[{ value: "Beginner", label: "Beginner" }, { value: "Intermediate", label: "Intermediate" }, { value: "Expert", label: "Expert" },]}
                                        onChange={(value) => setjob({ ...job, expertise: value.value })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.expertise}</small>
                                </div>

                                <div>
                                    <div>Project Type<span className="asterisk " style={{ color: 'red' }}>*</span></div>
                                    <Selectant
                                        className={form.ProjectType != "" ? "errorborder" : ""}
                                        options={[{ value: "Fixed", label: "Fixed" }, { value: "Hourly", label: "Hourly" }]}
                                        onChange={(value) => {
                                            setjob({ ...job, ProjectType: value.value })
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.ProjectType}</small>
                                </div>
                            </div>


                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <div>Budget<span className="asterisk " style={{ color: 'red' }}>*</span></div>
                                    <div style={{ display: 'flex' }}>
                                        <div className="bid_range" style={{ paddingRight: "2%", marginRight: "10%" }}>
                                            <div htmlFor="Budget_From" style={{ color: "GrayText" }}>From </div>
                                            <input
                                                value={job?.Budget_From}
                                                type="text"
                                                name="Budget_From"
                                                placeholder={job.ProjectType === "Hourly" ? "Min hourly" : "Min"}
                                                className={form.Budget_From != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                                        setjob({ ...job, Budget_From: input.slice(0, 6) });
                                                    } else {
                                                        setjob({ ...job, Budget_From: "" });
                                                    }
                                                }}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.Budget_From}</small>
                                        </div>
                                        <div className="bid_range" style={{ paddingRight: "2%" }}>
                                            <div htmlFor="Budget_To" style={{ color: "GrayText" }}>To </div>
                                            <input
                                                value={job?.Budget_To}
                                                type="text"
                                                name="Budget_To"
                                                placeholder={job.ProjectType === "Hourly" ? "Max hourly" : "Max"}
                                                className={form.Budget_To != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                                        setjob({ ...job, Budget_To: input.slice(0, 6) });
                                                    } else {
                                                        setjob({ ...job, Budget_To: "" });
                                                    }
                                                }}
                                            />
                                            <small style={{ color: "#ec1c24" }}>{form.Budget_To}</small>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div htmlFor="Priority" style={{ margin: 0 }}>Job Type<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <div className="type_job" style={{ marginTop: "20px" }}>
                                        <div>
                                            <input style={{ width: "auto" }}
                                                type="radio" value="Full-time" name="Type"
                                                onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Full time</div>
                                        <div>
                                            <input style={{ width: "auto" }}
                                                type="radio" value="Part-time" name="Type"
                                                onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Part time</div>
                                    </div>
                                    <small style={{ color: "#ec1c24" }}>{form.Type}</small>
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <div>Payment Type<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <Selectant
                                        name="Budget_type"
                                        className={form.Budget_Type != "" ? "errorborder" : ""}
                                        onChange={(e) =>
                                            setjob({ ...job, Budget_Type: e.value })
                                        }
                                        options={[{ value: "Hourly", label: "Hourly" }, { value: "Weekly", label: "Weekly" }, { value: "Monthly", label: "Monthly" }]}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.Budget_Type}</small>
                                </div>

                                <div>
                                    <div htmlFor="SkillsRequired">Skills<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <Selectant
                                        isMulti
                                        label={"Enter Skills"}
                                        options={skillmaster}
                                        onChange={(selectedOptions) => {
                                            const skill = selectedOptions.map(option => option.value);
                                            setskillSelected(skill);
                                        }}
                                        className={form.skillSelected != "" ? "errorborder" : ""}
                                        type="skills"
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.skillSelected}</small>

                                    {skillSelected.includes("Other") ? <div style={{ marginTop: '2%' }}>
                                        <input
                                            type="text"
                                            placeholder="Enter Description"
                                        />
                                    </div> : <></>}
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <div htmlFor="SkillsRequired">Job Category<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <Selectant
                                        isMulti
                                        label="Category"
                                        options={categoryMaster}
                                        onChange={(selectedOptions) => {
                                            const category = selectedOptions.map(option => option.value);
                                            setCategorySelected(category);
                                        }}
                                        className={form.categorySelected != "" ? "errorborder" : ""}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.categorySelected}</small>
                                    {categorySelected.includes("Other") ?
                                        <div style={{ marginTop: '2%' }}>
                                            <input
                                                type="text"
                                                placeholder="Enter Description"
                                            />
                                        </div> : <></>}
                                </div>


                                <div>
                                    <div htmlFor="Enter Technology">Technology<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <Selectant
                                        isMulti
                                        label="Technology"
                                        options={technologyMaster}
                                        onChange={(choosedOption) => {
                                            const tech = choosedOption.map((item) => item.value)
                                            settechnologySelected(tech)
                                        }}
                                        className={form.technologySelected != "" ? "errorborder" : ""}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.technologySelected}</small>

                                    {technologySelected.includes("Other") ?
                                        <div style={{ marginTop: '2%' }}>
                                            <input
                                                type="text"
                                                placeholder="Enter Description"
                                            />
                                        </div> : <></>}
                                </div>
                            </div>

                            <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                                <div>
                                    <div htmlFor="SkillsRequired">Location Preference</div>
                                    <Select
                                        isMulti
                                        // className={form.LocationSelected != "" ? "errorborder" : ""}
                                        options={LocationMaster}
                                        value={Array.isArray(LocationSelected) ? LocationSelected.map((item) => ({ value: item, label: item })) : LocationSelected?.split(",").map((item) => ({ value: item, label: item }))}
                                        onChange={(selectedOptions) => {
                                            const lang = selectedOptions.map(option => option.value);
                                            setLocationSelected(lang);
                                        }}
                                    />

                                </div>
                                <div>
                                    <div htmlFor="Enter Language">Language Preference</div>
                                    <Selectant
                                        isMulti
                                        label="Language"
                                        value={Array.isArray(LanguageSelected) ? LanguageSelected.map((item) => ({ value: item, label: item })) : LanguageSelected?.split(",").map((item) => ({ value: item, label: item }))}
                                        options={Languagemaster}
                                        onChange={(selectedOptions) => {
                                            const lang = selectedOptions.map(option => option.value);
                                            setLanguageSelected(lang);
                                        }}
                                    // className={form.LanguageSelected != "" ? "errorborder" : ""}
                                    />
                                    {/* <small style={{ color: "#ec1c24" }}>{form.LanguageSelected}</small> */}
                                </div>

                            </div>
                            <div className="userdeatail_container" style={{ gridTemplateColumns: "100%", marginTop: "3%" }}>
                                <div>
                                    <div>Upload Document :</div>
                                    <input
                                        style={{ width: '99%' }}
                                        type="file"
                                        onChange={(e) => {
                                            setDocument(e.target.files[0]);
                                            setLocalDocument(URL.createObjectURL(e.target.files[0]))
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.document}</small>
                                </div>
                                {
                                    LocalDocument ?
                                        <div>
                                            <img src={LocalDocument} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                                        </div>
                                        :
                                        <></>
                                }
                            </div>


                            <div className="userdeatail_container" style={{ gridTemplateColumns: "100%", marginTop: "3%" }}>
                                <div>
                                    <div>Short Description<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                    <input
                                        style={{ width: '99%' }}
                                        type="text"
                                        value={job.shortDescription}
                                        className={form.shortDescription != "" ? "errorborder" : ""}
                                        placeholder="Short Description"
                                        onChange={(event) => {
                                            if (event.target.value.split(" ").length <= 50)
                                                setjob({ ...job, shortDescription: event.target.value })
                                            else
                                                message.warning("Short Description Not More than 50 Words")
                                        }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{form.shortDescription}</small>
                                </div>
                            </div>

                            {/* <div className="userdeatail_container" style={{ gridTemplateColumns: "100%", marginTop: "3%" }}>
                                <label htmlFor="Description">Description<span className="asterisk">*</span></label>
                                <article>
                                    <CKEditor
                                        initData={Description}
                                        placeholder="Give Description About Your Project..."
                                        className={form.Description != "" ? "errorborder" : ""}
                                        config={{
                                            toolbar: [
                                                { name: "document", items: ["Undo", "Redo"] },
                                                { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                                                { name: "styles", items: ["Format"] },
                                                {
                                                    name: "basicstyles",
                                                    items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                                                },
                                            ],
                                        }}
                                        onChange={onEditorChange}
                                    />
                                </article>
                                <small style={{ color: "#ec1c24" }}>{form.Description}</small>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <input
                                    type="submit"
                                    value="Post a Job"
                                    className="buton"
                                    style={{ marginBlockStart: "20px" }}
                                />
                            </div> */}
                            < div className="userdeatail_container" style={{ gridTemplateColumns: "100%", marginTop: "3%" }}>
                                <div htmlFor="Description">Description<span className="asterisk" style={{ color: 'red' }}>*</span></div>
                                <article>
                                    {/*    <CKEditor
                                        initData={Description}
                                        placeholder="Give Description About Your Project..."
                                        className={form.Description != "" ? "errorborder" : ""}
                                        config={{
                                            toolbar: [
                                                { name: "document", items: ["Undo", "Redo"] },
                                                { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                                                { name: "styles", items: ["Format"] },
                                                {
                                                    name: "basicstyles",
                                                    items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                                                },
                                            ],
                                        }}
                                        onChange={onEditorChange}
                                    /> */}
                                    <CKEditor
                                        placeholder="Give Description About Your Project..."
                                        className={form.Description != "" ? "errorborder" : ""}
                                        data={Description}
                                        editor={ClassicEditor}
                                        onChange={onEditorChange}
                                    />
                                </article>
                                <small style={{ color: "#ec1c24" }}>{form.Description}</small>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <input
                                    type="submit"
                                    value="Add a Job"
                                    className="buton"
                                    style={{ marginBlockStart: "20px" }}
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AddJob;