import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import { BsArrowLeftShort } from "react-icons/bs"
import PaginationSection from './PaginationSection';
import { useHistory } from "react-router-dom";
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import Projects from './Projects';


function PaymentToFreelancer() {
  // const [formData, setFormData] = useState(initialFormData);
  const { endPointUrl, Token } = useContext(MyContext);
  const history = useHistory();
  const [allDetails, setallDetails] = useState([]);

  const getpayment = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getfreelancerpayment", {
        Token,
      });
      setallDetails(data.data);
    } catch (error) {
      console.log("get reviews is not working", error);
    }
  };
  useEffect(() => {
    // console.log("Type state:", Type);
    getpayment();
  }, []);

  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);


  const columns = React.useMemo(
    () => [

      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "Freelancer",
        accessor: "Name",
        Cell: ({ row }) => {
          const handleClick = () => {
            history.push(`/admin/Freelancer-Details/${row?.original?.id}`);
          };
  
          return (
            <div style={{ cursor: "pointer" }} onClick={handleClick}>
              {row.original.Name}
            </div>
          );
        },
      },

      {
        Header: "Email",
        accessor: "Email",
      },

      {
        Header: "Paid Amount",
        accessor: "projects[0].paidPayment",
      },
      {
        Header: "Due Amount",
        accessor: "projects[0].duePayment",
      },
      {
        Header: "Total Amount",
        accessor: "projects[0].TotalPayment",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <span
              onClick={() => handleclick(row.original.id)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <Button style={{ fill: "#07b274" }}>Show More</Button>
            </span>
          </div>
        ),
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const handleclick = (Id) => {
    console.log(Id);
    history.push(`/admin/PaymentToFreelancer-Details/${Id}`);
  };
  const navigateToFinance = () => {
    history.push("/admin/Finance");
  };
  return (
    <>

      <div className="close profile" onClick={navigateToFinance} >
        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 20px 0" }} />
      </div>

      <Container fluid>
        <div className="container-fluid d-flex justify-content-between">
          <h6 className="heading-6">Payment To Freelancers</h6>
        </div>
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header >
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()} >

                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {/* {console.log(row.original.id)} */}
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}
                        </tr>
                      );
                    }

                    )}
                  </tbody>

                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  )
};

export default PaymentToFreelancer;