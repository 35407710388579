import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import Curdcontenior from "components/helper/Curdcontenior";
import PaginationSection from 'views/PaginationSection';

import MyContext from "MyContext";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function Master({ name, getUrl, deleteUrl, updateurl, addurl, id }) {
  const { endPointUrl, Token } = useContext(MyContext);

  const [showAdd, setshowAdd] = useState(false);
  const [skills, setallskills] = useState([]);
  const [searchValue, setsearchvalue] = useState("");
  const [FilterData, setFilterData] = useState([]);
  const [skill, setskills] = useState("");
  const [numberOfRows, setNumberOfRows] = useState(5);

  const handleDelete = async (Id) => {
    try {
      await axios.post(endPointUrl + deleteUrl, {
        Token,
        [id]: Id,
      });
      getAllskills();
      message.success("Deleted successfully");
    } catch {
      message.error("something went wrong");
    }
  }
  const handleUpdate = async (Id, value) => {
    try {
      await axios.post(endPointUrl + updateurl, {
        Token,
        [id]: Id,
        Name: value,
      });
      message.success("Updated successfully");
      getAllskills();
    } catch {
      message.error("something went wrong");
    }
  };
  const handleAdd = async () => {
    try {
      await axios.post(endPointUrl + addurl, {
        Token,
        Name: skill,
      });
      getAllskills();
      handleAddshow();
      message.success("Added successfully");
    } catch {
      message.error("something went wrong");
    }
  };
  const getAllskills = async () => {
    try {
      const { data } = await axios.post(endPointUrl + getUrl, {
        Token,
      });
      setallskills(data.data);
    } catch {
      console.log("get skill is not working");
    }
  };
  const handleAddshow = () => setshowAdd(!showAdd);
  useEffect(() => {
    getAllskills();
  }, []);


  const data = React.useMemo(() => skills, [skills]);
  const columns = React.useMemo(
    () => [
      {
        Header: id,
        accessor: id, // accessor is the "key" in the data
      },
      {
        Header: "Name",
        accessor: "Name",
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      {!showAdd ? (
        <div className="container-fluid d-flex justify-content-between">
          <h6 className="heading-6">{name.name}</h6>
          <button
            className="btn mb-3 btn-lg"
            style={{ cursor: "pointer" }}
            onClick={handleAddshow}
          >
            ADD
          </button>
        </div>
      ) : null}
      {showAdd ? (
        <div className="input-group" >
          <h6 className="heading-6">{name.name}</h6>

          <input style={{ marginLeft: "45%", marginBottom: "20px" }}
            type="text"
            className="form-control border-success"
            placeholder="Add here"
            aria-label="Add Your Skills Here"
            aria-describedby="add-button"
            onChange={(e) => {
              e.preventDefault();
              setskills(e.target.value);
            }}
            autoFocus
          />
          <div className="input-group-append" style={{ paddingBottom: "24px", paddingLeft: "8px" }}>
            <button
              className="btn btn-success"
              type="button"
              id="add-button"
              onClick={handleAdd}
            >
              Add
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={handleAddshow}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

        </div>
      ) : null}

      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);

                      return (
                        <Curdcontenior
                          item={row.original}
                          row={row}
                          handleUpdate={handleUpdate}
                          handleDelete={handleDelete}
                          id={row.index}
                          idName={id}
                        />
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>



              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>
      </Container>

    </>
  );
}

export default Master;
