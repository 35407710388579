import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import { BsArrowLeftShort } from "react-icons/bs"
import { FaStar } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import Switch from 'react-switch'; // Import the react-switch component
import ReviewAdd from './ReviewAdd';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import PaginationSection from './PaginationSection';

function Review() {
  const history = useHistory();
  const { endPointUrl, Token } = useContext(MyContext);
  const [activeCard, setActiveCard] = useState("Freelancerreview");
  const [UserAccType, setUserAccType] = useState([]);
  // const [projectAccToUSer, setProjectAccToUSer] = useState([]);
  // const [selectedUser, setSelectedUser] = useState("");
  // const [selectedProject, setSelectedProject] = useState("");
  const [allDetails, setallDetails] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [Type, setType] = useState("Freelancer");
  const [selectedStars, setSelectedStars] = useState(0);
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [reviewForm, setReviewForm] = useState(false);
  const [formData, setFormData] = useState({
    To_ID: '',
    From_Name: '',
    To_Name: '',
    Review: '',
    Stars: '',
    P_Name: "",
    Review_Date: ''
  });


  // const handleUserSelect = (selectedOption) => {
  //   setSelectedUser(selectedOption);
  //   setFormData({ ...formData, To_ID: selectedOption.value, To_Name: selectedOption.label })
  //   // Assuming projectAccToUSer is your state setter function
  //   const filteredProjects = allProjects.filter(item => item.Awarded_By_ID === selectedOption.value);
  //   setProjectAccToUSer(filteredProjects);
  // };

  // const handleProjetselect = (selectedOption) => {
  //   setSelectedProject(selectedOption)
  //   setFormData({ ...formData, P_Name: selectedOption.label })
  // }

  // const addReviews = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const { data } = await axios.post(endPointUrl + "/addreview", {
  //       Token,
  //       ...formData
  //     });
  //     if (data.data) {
  //       const userFound = allDetails.find(user => user.id === data.data.id);
  //       if (userFound) {
  //         // User found, update the details
  //         const updatedDetails = allDetails.map(user => {
  //           if (user.id === userFound.id) {
  //             return { ...user, ...data.data };
  //           }
  //           return user;
  //         });
  //         setallDetails(updatedDetails);
  //       } else {
  //         // User not found, add the data
  //         setallDetails(prevAllDetails => [...prevAllDetails, data.data]);
  //       }
  //       setFormData({
  //         To_ID: '',
  //         From_Name: '',
  //         To_Name: '',
  //         Review: '',
  //         Stars: '',
  //         Review_Date: '',
  //         P_Name: ''
  //       });
  //       setSelectedUser("")
  //       message.success("Review add sucessfully")
  //       setReviewForm(false);
  //     } else {
  //       // Handle error if needed
  //       console.log("Error adding Review:", data.message);
  //     }
  //   } catch (error) {
  //     console.log("Error adding Review:", error);
  //   }
  // }

  const handleRatingClick = (value) => {
    if (rating === value) {
      // If the same star is clicked again, set the rating to 0 (deselect)
      setRating(0);
      setSelectedStars(0); // Notify the parent component that the rating is deselected
      setShowAll(false);
    } else {
      setRating(value);
      setSelectedStars(value); // Notify the parent component about the new rating
      setShowAll(false);
    }
  };
  const handleShowAllClick = () => {
    setShowAll(true);
    setSelectedStars(0); // Reset the selected stars when showing all
    setRating(0); // Reset the rating to 0 (deselect)
    setHover(0);
  };

  const getreviews = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/userBytype", {
        Token,
        Type: Type,
      });
      setUserAccType(data.data)
      let filteredData = data.data;
      if (!showAll && selectedStars > 0) {
        filteredData = filteredData.filter((review) => review.avg_Rating >= selectedStars);
      }
      // Use functional update to correctly update the state
      const newData = filteredData.filter((filterdata) => filterdata.avg_Rating > 0);
      setallDetails(newData);
    } catch (error) {
      console.log("get reviews is not working", error);
    }
  };

  // const getProjects = async () => {
  //   try {
  //     const { data } = await axios.post(endPointUrl + "/findAllFreelancer", {
  //       Token,
  //     })
  //     setAllProjects(data.data);
  //   } catch (err) {
  //     console.log("Error", err)
  //   }
  // }

  useEffect(() => {
    getreviews();
    // getProjects();
    // setSelectedUser("")
    // setSelectedProject("")
    // getAllUsersAccType();
  }, [Type, selectedStars, showAll]);

  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "User Name",
        accessor: "Name",
      },

      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "No. of Reviews",
        accessor: "no_of_reviews",
      },
      {
        Header: "Average Rating",
        accessor: "avg_Rating",
        Cell: ({ value }) => {
          const roundedValue = parseFloat(value).toFixed(1); // Round the value to 1 decimal place
          const fullStars = Math.floor(roundedValue); // Get the integer part (number of full stars)
          const hasHalfStar = roundedValue % 1 !== 0; // Check if there is a fractional part (half star)

          const fullStarsArray = Array.from({ length: fullStars }).map((_, index) => (
            <FaStar key={index} style={{ color: "#FFD700" }} />
          ));

          // If there's a half star, add it to the array
          if (hasHalfStar) {
            fullStarsArray.push(<FaStar key={fullStars} style={{ color: "#FFD700", clipPath: "inset(0 50% 0 0)" }} />);
          }

          return (
            <div >
              <span style={{ marginRight: "5px" }}>{roundedValue}</span>
              <span>{fullStarsArray}</span>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            {/* "Update" icon */}
            <span
              onClick={() => handleclick(row.original.id)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <Button style={{ fill: "#07b274" }}>Show More</Button>
            </span>
          </div>
        ),
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const handleclick = (Id) => {
    console.log(Id);
    history.push(`/admin/Review-Details/${Id}`);
  };

  const navigateToFreelancerreview = () => {
    setActiveCard("Freelancerreview");
    setType("Freelancer");
  };
  const navigateToEmployerreview = () => {
    setActiveCard("Employerreview");
    setType("Employer");

  };
  const handleCloseForm = () => {
    setReviewForm(false)
  }
  return (
    <>
      {reviewForm ?
        <>
          <ReviewAdd
            UserAccType={UserAccType}
            Type={Type}
            handleCloseForm={handleCloseForm}
          />
          {/* <Container className='addskilltest' fluid>
            <div className="container-fluid d-flex">
              <span className="close profile" onClick={() => setReviewForm(false)}>
                <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
              </span>
              <h6 className="heading-6">Add Review</h6>
            </div>
            <Row>
              <Col md="12">
                <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                  <Card.Header style={{ backgroundColor: '#07b274' }}>
                    <Card.Title as="h4">Fill Details</Card.Title>
                    <hr></hr>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={(e) => addReviews(e)}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label htmlFor="name">From Name</label>
                            <Form.Control
                              placeholder="Enter name"
                              type="text"
                              name="name"
                              value={formData.From_Name}
                              onChange={(e) => setFormData({ ...formData, From_Name: e.target.value })}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>{Type} Name</label>
                            <Selectant
                              name="Skills"
                              options={formattedOptions}
                              onChange={handleUserSelect}
                              value={selectedUser}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label htmlFor="date">Published Date</label>
                            <Form.Control
                              placeholder="Date"
                              type="date"
                              name="date"
                              value={formData.Review_Date}
                              onChange={(e) => setFormData({ ...formData, Review_Date: e.target.value })}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label htmlFor="rating">Rating</label>
                            <Form.Control
                              placeholder="Raitng above 5"
                              type="number"
                              name="rating"
                              value={formData.Stars}
                              onChange={(e) => setFormData({ ...formData, Stars: e.target.value })}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>{selectedUser.label} Project</label>
                            <Selectant
                              name="Project"
                              options={formattedProjectOptions}
                              onChange={handleProjetselect}
                              value={selectedProject}
                            />
                          </Form.Group>
                        </Col>

                        <Col className="pr-1 w-full" md="12">
                          <Form.Group>
                            <label htmlFor="review">Review</label>
                            <Form.Control
                              placeholder="Enter your review here"
                              as="textarea"
                              name="review"
                              value={formData.Review}
                              onChange={(e) => setFormData({ ...formData, Review: e.target.value })}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </>
        :
        <>
          <Container fluid>
            <Row>
              <Col lg="6" sm="12">
                <Card className={`card-stats ${activeCard === "Freelancerreview" ? "active" : ""}`} onClick={navigateToFreelancerreview} style={{ borderBottom: activeCard === "Freelancerreview" ? "4px solid #07b273" : "" }}>
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col sm="7">
                        <div className="numbers">
                          <p className="card-category">Freelancers Reviews</p>
                          <Card.Title as="h3"></Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" sm="12">
                <Card className={`card-stats ${activeCard === "Employerreview" ? "active" : ""}`} onClick={navigateToEmployerreview} style={{ borderBottom: activeCard === "Employerreview" ? "4px solid #07b273" : "" }}>
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Employers Reviews</p>
                          <Card.Title as="h3"></Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <div className='Rating'>
              <div className="star-rating">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    <strong>Filter by Average Rating:</strong>{' '}
                  </span>
                  {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    return (
                      <button
                        type="button"
                        key={starValue}
                        className={starValue <= (hover || rating) ? "on" : "off"}
                        onClick={() => handleRatingClick(starValue)}
                        onMouseEnter={() => setHover(starValue)}
                        onMouseLeave={() => setHover(0)}
                      >
                        <span className="star-rating-star"><FaStar /></span>
                      </button>
                    );
                  })}
                  <span style={{ paddingLeft: "20px" }}>
                    <Button onClick={handleShowAllClick}>Show All</Button></span>
                </div>
              </div>


            </div>

            <Row>
              <div style={{ width: "100%" }}>
                <Button className="btn btn-lg mr-3 float-right p-2" onClick={() => setReviewForm(true)}>Add Reviews</Button>
              </div>
              <Col lg="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">
                      <Form className="d-flex">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className="mx-2"
                          aria-label="Search"
                          value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                      </Form>

                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover table-striped " {...getTableProps()}>
                      <table style={{ width: '100%', textAlign: 'center' }}>
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  {column.render("Header")}
                                  {/* Add a sort direction indicator */}
                                  <span>
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                      : ""}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            // Check if no_of_reviews is greater than 0 before rendering the row
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Table>
                  </Card.Body>
                  <PaginationSection
                    pageIndex={pageIndex}
                    pageCount={pageOptions.length}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                  />

                </Card>

              </Col>
            </Row>
          </Container>
        </>
      }
    </>
  )
};

export default Review;