import Dashboard from "views/Dashboard";
import UserProfile from "views/UserProfile.js";
import Freelancer from "views/Freelancer";
import Icons from "views/Icons.js";
import skillMaster from "views/skillMaster";
import CategoryMaster from "views/CategoryMaster";
import TechnologyMaster from "views/TechnologyMaster";
import LocationMaster from "views/LocationMaster";
import TableList from "views/TableList";
import Employers from "views/Employers";
import Finance from "views/Finance";
import GeneralSetting from "views/GeneralSetting";
import SiteManagement from "views/SiteManagement";
import Report from "views/Report";
import Support from "views/Support";
import Settings from "views/Settings";
import QuestionBank from "views/QuestionBank";
import Jobs from "views/Jobs";
import Projects from "views/Projects";
import Publishedjobs from "views/Publishedjobs";
import Draftjobs from "views/Draftjobs";
import Expiredjobs from "views/Expiredjobs";
import SkillTest from "views/SkillTest";
import Review from "views/Review";
import Jobdetailsss from "views/Jobdetailsss";
import Trackpayment from "views/Trackpayment";
import Projectdetailsss from "views/Projectdetailsss";
import ReviewDetails from "views/ReviewDetails";
import PaymentToFreelancer from "views/PaymentToFreelancer";
import PaymentToFreelancerDetails from "views/PaymentToFreelancerDetails";
import FreelancerDetails from "views/FreelancerDetails";
import EmployerDetails from "views/EmployerDetails";
import Userform from "views/Userform";
import EmployerFinanceDetails from "views/EmployerFinanceDetails";
import Invoices from "views/Invoices";
import InvoiceGenerator from "views/InvoiceGenerator";
import PaymentsSetting from "views/PaymentsSettings";
import LanguageMaster from "views/LanguageMaster";
import Verification from "views/Verification";
import EmployerpaymentImport from "views/EmployerpaymentImport";
import Testimonial from "views/Testimonial";
import GuideDigilanxer from "views/GuideDigilanxer";
import Newsletter from "views/Newsletter";
import PartnerWithUs from "views/PartnerWithUs";
import DisplayPartnerWithUs from "views/DisplayPartnerWithUs";
import Product from "views/Product";
import Bids from "views/Bids";
import BidDetail from "views/BidDetail";
import ApplyBid from "views/ApplyBid";
import DisplayDispute from "views/DisplayDispute";
import DisputeDetail from "views/DiputeDetail";
import DegreeMaster from "views/DegreeMaster";
import PendingBankStatus from "views/PendingBankStatus";
import RefundDetails from "views/RefundDetails"
import PaymentConfirmation from "views/PaymentConfirmation";

const accordianroutes = [
  {
    path: "/table",
    name: "Table List",
    icon: "nc-icon nc-notes",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 3,
    path: "/Freelancers",
    name: "Freelancers",
    icon: "nc-icon nc-bell-55",
    component: Freelancer,
    layout: "/admin",
    accordian: false,
  },

  {
    id: 4,
    path: "/Employers",
    name: "Employers",
    icon: "nc-icon nc-bell-55",
    component: Employers,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 5,
    path: "/jobs",
    name: "Jobs",
    icon: "nc-icon nc-atom",
    component: Jobs,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 6,
    path: "/projects",
    name: "Projects",
    icon: "nc-icon nc-atom",
    component: Projects,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 7,
    path: "/SkillTests",
    name: "SkillTest",
    icon: "nc-icon nc-atom",
    component: SkillTest,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 8,
    path: "/QuestionBank",
    name: "QuestionBank",
    icon: "nc-icon nc-atom",
    component: QuestionBank,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 9,
    path: "/Finance",
    name: "Finance",
    icon: "nc-icon nc-bell-55",
    component: Finance,
    layout: "/admin",
    accordian: false,
  },
  // {
  //   path: "/Ongoing Projects",
  //   name: "Ongoing Projects",
  //   icon: "nc-icon nc-atom",
  //   component: OngoingProjects,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Closed Projects",
  //   name: "Closed Projects",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Pending Invoices",
  //   name: "Pending Invoices",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Payment to Freelancer",
  //   name: "Payment to Freelancer",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Track Payment",
  //   name: "Track Payment",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Refunds",
  //   name: "Refunds",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  {
    id: 10,
    path: "/Reviews",
    name: "Reviews",
    icon: "nc-icon nc-chart-pie-35",
    component: Review,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 11,
    path: "/SiteManagement",
    name: "SiteManagement",
    icon: "nc-icon nc-bell-55",
    component: SiteManagement,
    layout: "/admin",
    accordian: false,
  },
  //  {
  //   path: "/Banners",
  //   name: "Banners",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Edit",
  //   name: "Edit Content",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Advertisements",
  //   name: "Advertisements",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Style-Change",
  //   name: "Style Change",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  {
    id: 12,
    path: "/Messages",
    name: "Messages",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
    accordian: false,
  },
  , {
    id: 13,
    path: "/Reports",
    name: "Reports",
    icon: "nc-icon nc-atom",
    component: Report,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 14,
    path: "/Support",
    name: "Support",
    icon: "nc-icon nc-chart-pie-35",
    component: Support,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 15,
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-chart-pie-35",
    component: Settings,
    layout: "/admin",
    accordian: false,
  },
  {
    id: 16,
    path: "/Verification",
    name: "verification",
    icon: "nc-icon nc-chart-pie-35",
    component: Verification,
    layout: "/admin",
    accordian: false,

  },

  // {
  //   path: "/Open",
  //   name: "Open",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  // {
  //   path: "/Closed",
  //   name: "Closed",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  //   accordian: false,
  // },
  {
    path: "/Skill-master",
    name: "SkillMaster",
    icon: "nc-icon nc-atom",
    component: skillMaster,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Category-master",
    name: "Category Master",
    icon: "nc-icon nc-atom",
    component: CategoryMaster,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Technology-Master",
    name: "Technology Master",
    icon: "nc-icon nc-atom",
    component: TechnologyMaster,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Location-Master",
    name: "Location Master",
    icon: "nc-icon nc-atom",
    component: LocationMaster,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Language-Master",
    name: "Language Master",
    icon: "nc-icon nc-atom",
    component: LanguageMaster,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Degree-Master",
    name: "Degree Master",
    icon: "nc-icon nc-atom",
    component: DegreeMaster,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Payments-settings",
    name: "Payments Settings",
    icon: "nc-icon nc-atom",
    // component: Icons,
    component: PaymentsSetting,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/General-Settings",
    name: "General Settings",
    icon: "nc-icon nc-atom",
    component: GeneralSetting,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Published-Jobs",
    name: "Published Jobs",
    icon: "nc-icon nc-atom",
    component: Publishedjobs,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Draft-Jobs",
    name: "Draft Jobs",
    icon: "nc-icon nc-atom",
    component: Draftjobs,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Expired-Jobs",
    name: "Expired Jobs",
    icon: "nc-icon nc-atom",
    component: Expiredjobs,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Jobs-Details/:ID",
    name: "Jobs Details",
    icon: "nc-icon nc-atom",
    component: Jobdetailsss,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Apply_Bid/:ID/:FreelancerID",
    name: "Apply Bid",
    icon: "nc-icon nc-atom",
    component: ApplyBid,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Project-Details/:ID",
    name: "Project Details",
    icon: "nc-icon nc-atom",
    component: Projectdetailsss,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Review-Details/:ID",
    name: "Review Details",
    icon: "nc-icon nc-atom",
    component: ReviewDetails,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Payment-Confirmation/:ID",
    name: "Payment Confirmation",
    icon: "nc-icon nc-atom",
    component: PaymentConfirmation,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/PaymentToFreelancer-Details/:ID",
    name: "PaymentToFreelancerDetails",
    icon: "nc-icon nc-atom",
    component: PaymentToFreelancerDetails,
    layout: "/admin",
    accordian: false,
  },
  
  {
    path: "/Invoices",
    name: "Invoices",
    icon: "nc-icon nc-atom",
    component: Invoices,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Track-Payments",
    name: "Track Payments",
    icon: "nc-icon nc-atom",
    component: Trackpayment,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Employer-Import",
    name: "EmployerpaymentImport",
    icon: "nc-icon nc-atom",
    component: EmployerpaymentImport,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Payment-To-Freelancer",
    name: "Payment To Freelancer",
    icon: "nc-icon nc-atom",
    component: PaymentToFreelancer,
    layout: "/admin",
    accordian: false,
  },
  {
    path:"/Freelancer-Payment-Details",
    name:"Freelancer Payment Details",
    icon: "nc-icon nc-atom",
    component:PaymentToFreelancerDetails,
    layout:"admin",
    accordian:false,
  },
  {
    path:"/Pending-Bank-Status",
    name:"Pending Bank Status",
    icon: "nc-icon nc-atom",
    component:PendingBankStatus,
    layout:"/admin",
    accordian:false,
  },
  {
    path:"/Refund-Details",
    name:"Refund Details",
    icon: "nc-icon nc-atom",
    component:RefundDetails,
    layout:"/admin",
    accordian:false,
  },


  {
    path: "/User-form",
    name: "Userform",
    icon: "nc-icon nc-atom",
    component: Userform,
    layout: "/admin",
    accordian: false,
  },

  // {
  //   path: "/Invoice-Generator/:ID",
  //   name: "InvoiceGenerator",
  //   icon: "nc-icon nc-atom",
  //   component: InvoiceGenerator,
  //   layout: "/admin",
  //   accordian: false,
  // },
  {
    path: "/Freelancer-Details/:ID",
    name: "Freelancer Details",
    icon: "nc-icon nc-atom",
    component: FreelancerDetails,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Employer-Details/:ID",
    name: "Employer Details",
    icon: "nc-icon nc-atom",
    component: EmployerDetails,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/Employer-Finance-Details/:ID",
    name: "Employer Finance Details",
    icon: "nc-icon nc-atom",
    component: EmployerFinanceDetails,
    layout: "/admin",
    accordian: false,
  },
  {
    path: "/testimonial",
    name: "Testimonial",
    component: Testimonial,
    layout: "/admin",
  },
  {
    path: "/guidDigilanxer",
    name: "guideDigilanxer",
    component: GuideDigilanxer,
    layout: "/admin",
  },
  {
    path: "/newsletter",
    name: "newsletter",
    component: Newsletter,
    layout: "/admin",
  },
  {
    path: "/PartnerWithUs",
    name: "Partner With Us",
    component: DisplayPartnerWithUs,
    layout: "/admin",
  },
  {
    path: "/Product",
    name: "Product",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/Dispute",
    name: "Dispute",
    component: DisplayDispute,
    layout: "/admin",
  },
  {
    path: "/Dispute-Details/:ID",
    name: "Dispute Detail",
    icon: "nc-icon nc-atom",
    component: DisputeDetail,
    layout: "/admin",
    accordian: false,
  },
  //  {
  //   path: "/AllBids",
  //   name: "Bids",
  //   component: Bids,
  //   layout: "/admin",
  // },
  // {
  //   path: "/Bid-Details/:ID",
  //   name: "Bid Detail",
  //   icon: "nc-icon nc-atom",
  //   component: BidDetail,
  //   layout: "/admin",
  //   accordian: false,
  // },

];
export default accordianroutes;
