import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import MyContext from "MyContext"
import Logo from "../assets/css/Logo.png"
import "../assets/css/print.css"

import { useLocation } from "react-router-dom";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import moment from 'moment';

function Skilltestinvoicegenerator() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [items, setitems] = useState({});

    const [detailview, setdetailview] = useState(false);
    const [user, setuser] = useState({});
    const [PaymentReference, setPaymentReference] = useState();
    const location = useLocation();
    // const location=useLocation();

    //   const Id=location.pathname.split('/').pop();
    // const { Id, invoiceNumber } = useParams(); // Extract Id and InvoiceNumber from URL
    const searchParams = new URLSearchParams(location.search);
    const invoiceNumber = searchParams.get('invoiceNumber');
    const Id = searchParams.get('Id');

    //   console.log("ID",Id);

    const Payment = async (Id, data) => {

        try {
            const { data } = await axios.post(endPointUrl + "/get-certificate-payment-by-Id", {
                CertificatePayment_ID: Id,
                Token,
            });
            setitems(data.data);
            setuser(data.data)
            console.log(data.data)

        } catch (err) {
            console.log("get subscription by id is not working");
            console.log(err);

        }

    };

    useEffect(() => {
        Payment(Id);
    }, []);

    const handlePrint = () => {
        window.print(); // Open the browser's print dialog
    };
    return (
        <>
            {/* <Container fluid className="invoice-container" >
    <div>
   <Button variant="primary" onClick={handleDownloadPDF} style={{margin:"10px"}}>
      Download PDF
    </Button>
    </div> */}
            <Card className='invoice-card' style={{ height: "100vh", width: "100vw" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <img src={Logo} alt="" width={"20%"} />
                    <div>
                        <div className="download-button" onClick={handlePrint} >
                            Download PDF
                        </div>
                    </div>
                </div>
                <div className="invoice-heading">
                    <h2>INVOICE</h2>
                </div>
                {/* <hr style={{ width: "85%", border: "2px solid #07b273", marginTop: "-1rem", marginLeft: "-1.3rem" }}></hr> */}
                <div style={{ margin: "30px 0 50px 80px" }}>
                    <Row>
                        <Col md="6" lg="6" sm="6" >
                            <div className="addr">
                                <span className="addr-heading">{user?.user?.Name} {user?.user?.Last_Name}</span>
                                <div>{user?.user?.Type}</div>
                                <div>{user?.user?.Address}</div>
                                <div>{user?.user?.City ? `${user?.user?.City},` : ''} {user?.State}</div>
                                <div>{user?.user?.Country ? `${user?.user?.Country}` : ''},{user?.Zip}</div>
                            </div>
                            <div className="addr">
                                <span className="addr-heading">COMMENTS OR SPECIAL INSTRUCTIONS:</span>
                                <div>Due upon receipt</div>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" style={{ paddingLeft: "45px" }}>
                            <div>
                                <span className="addr-heading">INVOICE : </span><span> {invoiceNumber}</span></div>
                            <span className="addr-heading">DATE OF ISSUE : </span><span> {moment(items?.Payment_date).format('DD-MM-YYYY')}</span>
                        </Col>
                    </Row>
                </div>
                <table className="table-invoice" style={{ margin: "30px" }} >
                    <thead>
                        <th>Skill Test</th>
                        <th>PaymentReference</th>
                        <th>AMOUNT</th>
                        <th>TOTAL</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{items?.skillTest?.SKT_Title}</td>

                            <td>{items?.PaymentReference}</td>
                            <td>{items?.Amount}</td>
                            <td>{items?.Amount}</td>
                        </tr>

                        <tr >
                            <td colspan="3" style={{ border: "none", textAlign: "end" }} >Subtotal</td>
                            <td>{items?.Amount}</td>

                        </tr>
                        <tr >
                            <td colspan="3" style={{ border: "none", textAlign: "end" }} >Sales Tax</td>
                            <td>0</td>
                        </tr>
                        <tr >
                            <td colspan="3" style={{ border: "none", textAlign: "end" }} >Shipping and Handling</td>
                            <td></td>
                        </tr>
                        <tr >
                            <td colspan="3" className="addr-heading" style={{ border: "none", textAlign: "end" }} >TOTAL DUE</td>
                            <td>{items?.Amount}</td>
                        </tr>

                    </tbody>

                </table>
            </Card>
            {/* </Container> */}
        </>
    );
}

export default Skilltestinvoicegenerator;