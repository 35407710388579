import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MyContext from "MyContext"
import Publishedjobs from "./Publishedjobs"; // Import the Publishedjobs component
import Expiredjobs from "./Expiredjobs";
import Draftjobs from "./Draftjobs";
import Approvedjobs from "./Approvedjobs";
import PaginationSection from "./PaginationSection";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";


function Jobs() {

  const history = useHistory();
  const { endPointUrl, Token } = useContext(MyContext);
  const [items, setitems] = useState({});
  const [showPublishedJobs, setShowPublishedJobs] = useState(true);
  const [showExpiredJobs, setShowExpiredJobs] = useState(false);
  const [showApprovedJobs, setShowApprovedJobs] = useState(false);
  const [showDraftJobs, setShowDraftJobs] = useState(false);
  const [activeCard, setActiveCard] = useState("published");

  const count = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/countJobsandProjects", {
        Token,
      });
      setitems(data.data);
      //  console.log(data.data.publishedjobCount);
    } catch {
      console.log("counts is not working");
    }
  };

  useEffect(() => {
    count();
  }, []);



  const navigateToPublishedjobs = () => {
    // history.push("/admin/Published-Jobs"); 
    setShowPublishedJobs(true); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
    setShowDraftJobs(false);
    setShowExpiredJobs(false);
    setShowApprovedJobs(false);
    setActiveCard("published");
  };
  const navigateToDraftjobs = () => {
    // history.push("/admin/Draft-Jobs"); 
    setShowDraftJobs(true);
    setShowPublishedJobs(false);
    setShowExpiredJobs(false);
    setShowApprovedJobs(false);
    setActiveCard("draft");

  };
  const navigateToExpiredjobs = () => {
    // history.push("/admin/Expired-Jobs"); 
    setShowExpiredJobs(true);
    setShowPublishedJobs(false);
    setShowDraftJobs(false);
    setShowApprovedJobs(false);
    setActiveCard("expired");

  };
  const navigateToApprovedjobs = () => {
    // history.push("/admin/Expired-Jobs"); 
    setShowApprovedJobs(true);
    setShowExpiredJobs(false);
    setShowPublishedJobs(false);
    setShowDraftJobs(false);
    setActiveCard("approved");
  };



  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="12">
            <Card className={`card-stats ${activeCard === "published" ? "active" : ""}`} onClick={navigateToPublishedjobs} style={{ borderBottom: activeCard === "published" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Published Jobs</p>
                      <Card.Title as="h3">{items?.publishedjobCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="12">
            <Card className={`card-stats ${activeCard === "approved" ? "active" : ""}`} onClick={navigateToApprovedjobs} style={{ borderBottom: activeCard === "approved" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Approved Jobs</p>
                      <Card.Title as="h3">{items?.approvedjobCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="12">
            <Card className={`card-stats ${activeCard === "draft" ? "active" : ""}`} onClick={navigateToDraftjobs} style={{ borderBottom: activeCard === "draft" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Draft Jobs</p>
                      <Card.Title as="h3">{items?.draftjobCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="12">
            <Card className={`card-stats ${activeCard === "expired" ? "active" : ""}`} onClick={navigateToExpiredjobs} style={{ borderBottom: activeCard === "expired" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Expired Jobs</p>
                      <Card.Title as="h3">{items?.expiredjobCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
      <Container fluid className="jobtable">

        {/* Render Publishedjobs component if showPublishedJobs is true */}
        {showPublishedJobs && <Publishedjobs />}
        {showExpiredJobs && <Expiredjobs />}
        {showDraftJobs && <Draftjobs />}
        {showApprovedJobs && <Approvedjobs />}

      </Container>
    </>
  );
}

export default Jobs;
