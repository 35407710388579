import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import PaginationSection from './PaginationSection';
import { BsArrowLeftShort } from "react-icons/bs"
import EmployerpaymentImport from './EmployerpaymentImport';
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import { useHistory } from "react-router-dom";


function Trackpayment() {
  // const [formData, setFormData] = useState(initialFormData);
  const { endPointUrl, Token } = useContext(MyContext);
  const [activeCard, setActiveCard] = useState("import");
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [allDetails, setallDetails] = useState([]);
  const [showimport, setShowimport] = useState(true);
  const [showemplimport, setShowemplimport] = useState(false);
  const [showexport, setShowexport] = useState(false);
  const history = useHistory();

  const handleButtonClick = async (e) => {
    e.preventDefault();

    try {

      const { data } = await axios.post(endPointUrl + '/admin/getSubscriptionbyDates', {
        Token,
        From: fromDate,
        To: toDate,
      });

      setallDetails(data.data);
      console.log(data.data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Get today's date in the format "YYYY-MM-DD"
    const today = new Date().toISOString().split('T')[0];

    // Set the default values for "From" and "To" inputs when they are not provided
    if (!fromDate) {
      setFromDate(today);
    }
    if (!toDate) {
      setToDate(today);
    }
  }, [fromDate, toDate]);

  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "Freelancer",
        accessor: "freelancer.Name",
      },

      {
        Header: "Num Of Bids",
        accessor: "NumOfbids",
      },

      {
        Header: "Payment Mode",
        accessor: "Payment_Mode",
      },
      {
        Header: "Payment_Status",
        accessor: "Payment_Status",
      },
      {
        Header: "Subscription Status",
        accessor: "Subscription_Status",
      },
      {
        Header: "Subscription Date",
        accessor: "Subscription_Date",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: "Amount",
        accessor: "Amount",
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;


  const calculateTotalAmount = () => {
    let total = 0;
    for (const item of allDetails) {
      total += parseFloat(item.Amount);
    }
    return total.toFixed(2); // Return the total sum as a string with two decimal places
  };
  const navigateTosubsimport = () => {
    setActiveCard("import");
    setShowimport(true);
    setShowemplimport(false);
    setShowexport(false);
  };
  const navigateToemplimport = () => {
    setActiveCard("emplimport");
    setShowimport(false);
    setShowemplimport(true);
    setShowexport(false);
  };

  const navigateToexport = () => {
    setActiveCard("export");
    setShowimport(false);
    setShowemplimport(false);
    setShowexport(true);
  };

  const navigateToFinance = () => {
    history.push("/admin/Finance");
  };
  return (

    <>
      <div className="close profile" onClick={navigateToFinance} >
        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 20px 0" }} />
      </div>
      <Container fluid>
        <Row>
          <Col lg="4" sm="12">
            <Card className={`card-stats ${activeCard === "import" ? "active" : ""}`} onClick={navigateTosubsimport} style={{ borderBottom: activeCard === "import" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Subscription Payment </p>
                      <Card.Title as="h3"></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className={`card-stats ${activeCard === "emplimport" ? "active" : ""}`} onClick={navigateToemplimport} style={{ borderBottom: activeCard === "emplimport" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                      <p className="card-category">Employer Payment </p>
                      <Card.Title as="h3"></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/*<Col lg="4" sm="12">
             <Card className={`card-stats ${activeCard === "export" ? "active" : ""}`} onClick={navigateToexport} style={{ borderBottom: activeCard === "export" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Payment Export</p>
                      <Card.Title as="h3"></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card> 
          </Col>*/}
        </Row>
      </Container>

      <>
        <Container className='addskilltest' fluid>
          {showimport && (
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Form onSubmit={handleButtonClick}>
                      <Row>
                        <Col className="pr-1" md="5">
                          <Form.Group>
                            <label>From</label>
                            <Form.Control
                              name="From"
                              placeholder="From"
                              type="Date"
                              value={fromDate}
                              onChange={(e) => setFromDate(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="5" >
                          <Form.Group>
                            <label>To</label>
                            <Form.Control
                              name="To"
                              placeholder="To"
                              type="Date"
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="2" style={{ paddingTop: '40px', display: "flex", justifyContent: "center" }}>
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                            style={{ backgroundColor: "#07b274" }}
                          >
                            Fetch Data
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
        <Container fluid>
          {showimport && (
            <Row>
              <Col lg="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">
                      <Form className="d-flex">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className="mx-2"
                          aria-label="Search"
                          value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                      </Form>

                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover table-striped " {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr  {...row.getRowProps()}>
                              {/* {console.log(row.original.id)} */}
                              {row.cells.map((cell) => {
                                //  const { column } = cell.value;
                                //  let Id = column.Name;
                                //  console.log(cell.value);

                                return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                              })}
                            </tr>
                          );
                        }

                        )}
                        <hr></hr>
                        {/* Additional row for displaying the total amount */}
                        <tr>
                          <td colSpan={columns.length - 1} style={{ textAlign: 'right' }}>
                            Total Amount:
                          </td>
                          <td style={{ fontWeight: 'bold', paddingRight: '20px' }}>${calculateTotalAmount()}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                  <PaginationSection
                    pageIndex={pageIndex}
                    pageCount={pageOptions.length}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                  />
                </Card>

              </Col>
            </Row>
          )}
        </Container>

        {showemplimport && <EmployerpaymentImport />}
      </>
    </>
  )
};

export default Trackpayment;