import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import add from "assets/img/add1.jpg";
import PaginationSection from "./PaginationSection";
import { BiShow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import Select from "react-select";
import { Country } from "country-state-city";
import ReactPaginate from 'react-paginate';
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form } from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";

function WorkExp(Id) {

  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const history = useHistory();
  const [exp, setExp] = useState([]);
  const [workshow, setWorkShow] = useState(false);
  const [newExp, setnewExp] = useState({
    UserID: null,
    Company: null,
    Location: null,
    Country: null,
    Designation: null,
    Current: null,
    From: null,
    to: null,
    Description: null,
  });
  const [Editexp, setEditexp] = useState([]);
  const [showmodel, setshowModel] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [expireDatetoggle, setExpirteDatetoogle] = useState(false)
  const getuserExperience = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getExpbyuser", {
        UserID: Id.ID,
        Token,
      });

      setallDetails(data.data);
      console.log(data.data);

    } catch {
      console.log("get user education is not working");
    }
  };

  const addExp = async (exp) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/userExp", {
        formList: [exp],
        UserID: Id.ID,
        Token
      }
      );
      setnewExp({
        UserID: null,
        Location: null,
        Designation: null,
        Current: null,
        From: null,
        to: null,
        Description: null,
      })
      getuserExperience({ ID: Id.ID });
    } catch (error) {
      console.log("ERROR WHILE ADD EXPERIENCE", error)
    }
  };
  const deleteExp = async (U_Ex_ID) => {
    try {
      await axios.post(endPointUrl + "/user/deleteuserExp", { U_Ex_ID, Token });
      message.success('Deleted Succesfully');
      getuserExperience({ ID: Id.ID });
    } catch (error) {
      console.log(error);
      message.error('not deleted');
    }
  };
  const updateExp = async () => {

    try {
      await axios.post(endPointUrl + "/user/updateUserExp", {
        ...Editexp,
        U_E_ID: Editexp.U_Ex_ID,
        Token,
      });
      getuserExperience({ ID: Editexp.UserID });
      console.log(Editexp.U_Ex_ID)
      message.success("Updated Succesfully");
    } catch (error) {
      console.log(error);
      message.error("something went Wrong ");
    }
  };

  const handleExpModel = (e) => {
    setshowModel(true);
  }
  const CloseModel = () => {
    setshowModel(false);
    setshowEdit(false);
  };
  const handlesubmitExpModel = (e) => {
    e.preventDefault();

    if (!newExp.Company || !newExp.Location || !newExp.Designation || !newExp.From || !newExp.to || !newExp.Description) {
      message.warning("Please select all fields")
      return;
    }
    addExp(newExp);
    setshowModel(false);
  };

  const handleEditExpModel = (rowData) => {
    setEditexp(rowData);
    setExpirteDatetoogle(rowData.to === "Present" ? true : false)
    console.log(Editexp);
    setshowEdit(true);
  };

  const handleEditsubExpModel = (e) => {
    e.preventDefault();

    updateExp();
    setshowEdit(false);
  };


  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "Company",
        accessor: "Company",
      },
      // {
      //   Header: "Title",
      //   accessor: "Title",

      // },
      {
        Header: "Description",
        accessor: "Description",
        Cell: ({ value }) => {
          const [isHovered, setIsHovered] = useState(false);

          return (<span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {isHovered ? value : value && value?.length > 30 ? `${value.slice(0, 30)}...` : value}
          </span >)
        }
      },
      {
        Header: "Location",
        accessor: "Location",
      },
      // {
      //   Header: "Country",
      //   accessor: "Country",

      // },
      {
        Header: "From",
        accessor: "From",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: "To",
        accessor: "to",
        Cell: ({ value }) => (
          <span>{value === "Present" ? value : moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: "Actions",
        accessor: "U_Ex_ID",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
            {/* "Update" icon */}
            <span
              onClick={() => handleEditExpModel(row.original)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
            </span>
            {/* "Delete" icon */}
            <span
              onClick={() => deleteExp(row.original.U_Ex_ID)}
              style={{ cursor: "pointer" }}
            >
              <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
            </span>
          </div>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getuserExperience(Id);
  }, []);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const checkValidDate = (date) => {
    let flag = false;
    const enteredDate = new Date(date);

    if (allDetails.length > 0) {
      allDetails.some((item) => {
        const FromDate = new Date(item.From);
        enteredDate.setHours(0, 0, 0, 0);
        FromDate.setHours(0, 0, 0, 0);
        let ToDate;
        if (item.to === "Present") {
          ToDate = new Date();
          ToDate.setHours(0, 0, 0, 0);
        } else {
          ToDate = new Date(item.to);
          ToDate.setHours(0, 0, 0, 0);
        }
        console.log("entered Date : ", enteredDate);
        console.log("From Date", FromDate);
        console.log("To Date", ToDate);

        if (enteredDate >= FromDate && enteredDate <= ToDate) {
          flag = false;
          return true;
        } else {
          flag = true;
        }
      });
    } else {
      return true;
    }

    return flag;
  };

  const checkToValidDate = (date) => {
    let flag = false;
    const enteredDate = new Date(date);

    if (allDetails.length > 0) {
      allDetails.some((item) => {
        const FromDate = new Date(item.From);
        const enterFrom = new Date(newExp.From);
        enterFrom.setHours(0, 0, 0, 0)
        enteredDate.setHours(0, 0, 0, 0);
        FromDate.setHours(0, 0, 0, 0);
        let ToDate;
        if (item.to === "Present") {
          ToDate = new Date();
          ToDate.setHours(0, 0, 0, 0);
        } else {
          ToDate = new Date(item.to);
          ToDate.setHours(0, 0, 0, 0);
        }

        if (enterFrom <= FromDate && (enteredDate >= ToDate || (enteredDate >= FromDate && enteredDate <= ToDate))) {
          flag = false;
          return true;
        } else {
          flag = true;
        }
      });
    } else {
      return true;
    }
    return flag;
  }

  return (
    <>

      <Container fluid>
        {/* {!showDetails ? ( */}
        <div className="education">
          <div className="d-flex justify-content-between align-items-center">
            <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px", textTransform: "uppercase" }}>WORK & EXPERIENCE
              <img style={{ cursor: "pointer", width: "35px", height: "35px", marginLeft: "10px" }} src={add} alt="" onClick={handleExpModel} /></strong>
            <div style={{ cursor: "pointer", fontSize: "25px" }} onClick={(() => setWorkShow(!workshow))}>
              {!workshow ? <BiShow /> : <RxCross2 />}
            </div>
          </div>
          <Row className={workshow ? "showalldta" : "hidealldta"}>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped " {...getTableProps()}>
                    <thead >
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr  {...row.getRowProps()}>
                            {/* {console.log(row.original.id)} */}
                            {row.cells.map((cell) => {
                              //  const { column } = cell.value;
                              //  let Id = column.Name;
                              //  console.log(cell.value);

                              return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                            })}

                          </tr>
                        );
                      }

                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                <PaginationSection
                  pageIndex={pageIndex}
                  pageCount={pageOptions.length}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageOptions={pageOptions}
                />
              </Card>

            </Col>
          </Row>
        </div>

        <div id="myModal" className={showmodel ? "model-open" : "model"}>
          <div className="modal-content">
            <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
              &times;
            </span>
            <h2>Add Experience</h2>
            <form
              className="model-field exp-form"
              onSubmit={handlesubmitExpModel}
            >
              <Card.Body>
                <Row>
                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="Company">Company<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        type="text"
                        name="Compnay"
                        placeholder="Enter Company Name"
                        onChange={(e) =>
                          setnewExp({ ...newExp, Company: e.target.value })
                        }
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="pl-1" md="6" >
                    <Form.Group>
                      <strong htmlFor="title">Designation<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        type="text"
                        name="Title"
                        placeholder="Enter Designation"
                        onChange={(e) =>
                          setnewExp({ ...newExp, Designation: e.target.value })
                        }
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>


                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong>From<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        value={newExp.From || ""}
                        type="date"
                        name="From"
                        style={{ width: '100%' }}
                        placeholder="Enter Joining Date"
                        onChange={(e) => {
                          const enteredDate = e.target.value;
                          const currentDate = new Date().toISOString().split("T")[0]
                          const valid = checkValidDate(enteredDate)
                          if (valid) {
                            if (enteredDate > currentDate) {
                              message.warning("Can Not Select Future Date!")
                            }
                            else {
                              const extractedYear = enteredDate.split("-")[0]?.slice(0, 4);
                              setnewExp({ ...newExp, From: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                            }
                          }
                          else {
                            message.warning("Date is already falls bewtween previous Experience")
                          }
                        }}
                        min="1900"
                        max="2099"
                      ></Form.Control>
                    </Form.Group>
                  </Col>


                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong>To<span style={{ color: "#BA0000" }}>*</span></strong>
                      <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px' }}>
                        <input
                          type="checkbox"
                          style={{ width: "auto", margin: 0, padding: 0 }}
                          checked={expireDatetoggle}
                          onChange={() => {
                            const valid = checkToValidDate(new Date());
                            if (valid) {
                              setExpirteDatetoogle(!expireDatetoggle)
                              setnewExp({ ...newExp, to: "Present" });
                            }
                            else {
                              message.warning("Date is already falls bewtween previous Experience")
                            }
                          }}
                        />
                        Currently Employee
                      </span>
                    </Form.Group>

                    <Form.Control
                      value={newExp.to || ""}
                      readOnly={expireDatetoggle}
                      style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "", width: "100%" }}
                      type="date"
                      name="to"
                      placeholder="Enter left Date"
                      onChange={(e) => {
                        const toDate = new Date(e.target.value);
                        const fromDate = new Date(newExp.From);
                        const today = new Date();
                        const valid = checkToValidDate(toDate);
                        // alert("valid "+valid)
                        if (valid) {
                          if (toDate > today) {
                            message.warning("Can Not Select Future Date")
                          }
                          else if (fromDate < toDate) {
                            setnewExp({ ...newExp, to: e.target.value });
                          } else {
                            message.warning("Invalid Entry")
                          }
                        }
                        else {
                          message.warning("Date is already falls bewtween previous Experience")
                        }
                      }}
                      min="1900"
                      max="2099"
                    ></Form.Control>
                  </Col>


                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="Location">Location<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Select
                        placeholder="Select Location"
                        options={Country.getAllCountries()}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={
                          Country.getAllCountries().find(
                            (country) => country.name === newExp.Location
                          ) || null
                        }
                        onChange={(item) => {
                          setSelectedCountry(item);
                          setnewExp({
                            ...newExp,
                            Location: item.name,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="description">Description<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        name="Description"
                        id="description"
                        style={{ width: "100%" }}
                        cols="50"
                        rows="5"
                        placeholder="Description"
                        onChange={(e) =>
                          setnewExp({ ...newExp, Description: e.target.value })
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <input type="submit" className="btn" value={"ADD"} />
                </Row >
              </Card.Body>
            </form>
          </div >
        </div >


        {/* this model is for update education */}

        < div className={showEdit ? "model-open" : "model"} >
          <div className="modal-content">
            <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
              &times;
            </span>
            <h2>Update Work Experience</h2>
            <form className="model-field exp-form" onSubmit={handleEditsubExpModel}>
              <Card.Body>
                <Row>
                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="Company">Company<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        type="text"
                        name="Compnay"
                        placeholder="Enter Company Name"
                        value={Editexp.Company}
                        onChange={(e) =>
                          setEditexp({ ...Editexp, Company: e.target.value })
                        }
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="title">Designation<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        type="text"
                        value={Editexp.Designation}
                        name="Title"
                        placeholder="Enter Designation"
                        onChange={(e) =>
                          setEditexp({ ...Editexp, Designation: e.target.value })
                        }
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>


                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong>From<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        value={Editexp?.From?.slice(0, 10) || ""}
                        type="date"
                        name="From"
                        style={{ width: '100%' }}
                        placeholder="Enter Joining Date"
                        onChange={(e) => {
                          const enteredDate = e.target.value;
                          const currentDate = new Date().toISOString().split("T")[0]
                          const valid = checkValidDate(enteredDate)
                          if (valid) {
                            if (enteredDate > currentDate) {
                              message.warning("Can Not Select Future Date!")
                            }
                            else {
                              const extractedYear = enteredDate.split("-")[0]?.slice(0, 4);
                              setEditexp({ ...Editexp, From: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                            }
                          }
                          else {
                            message.warning("Date is already falls bewtween previous Experience")
                          }
                        }}
                        min="1900"
                        max="2099"
                      ></Form.Control>
                    </Form.Group>
                  </Col>


                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="to" style={{ display: "block" }}>To<span style={{ color: "#BA0000" }}>*</span></strong>
                      <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px', marginTop: "6px" }}>
                        <input
                          type="checkbox"
                          style={{ width: "auto", margin: 0, padding: 0 }}
                          checked={expireDatetoggle}
                          onChange={() => {
                            const valid = checkToValidDate(new Date());
                            if (valid) {
                              setExpirteDatetoogle(!expireDatetoggle)
                              setEditexp({ ...Editexp, to: "Present" });
                            }
                            else {
                              message.warning("Date is already falls bewtween previous Experience")
                            }
                          }}
                        />
                        Currently Employee
                      </span>
                    </Form.Group>

                    <Form.Control
                      value={Editexp.to || ""}
                      readOnly={expireDatetoggle}
                      style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "", width: '100%' }}
                      type="date"
                      name="to"
                      placeholder="Enter left Date"
                      onChange={(e) => {
                        const toDate = new Date(e.target.value);
                        const fromDate = new Date(Editexp.From);
                        const today = new Date();
                        const valid = checkToValidDate(toDate);
                        // alert("valid "+valid)
                        if (valid) {
                          if (toDate > today) {
                            message.warning("Can Not Select Future Date")
                          }
                          else if (fromDate < toDate) {
                            setEditexp({ ...Editexp, to: e.target.value });
                          } else {
                            message.warning("Invalid Entry")
                          }
                        }
                        else {
                          message.warning("Date is already falls bewtween previous Experience")
                        }
                      }}
                      min="1900"
                      max="2099"
                    ></Form.Control>
                  </Col>


                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="Location">Location<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Select
                        placeholder="Select Location"
                        options={Country.getAllCountries()}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={
                          Country.getAllCountries().find(
                            (country) => country.name === Editexp.Location
                          ) || null
                        }
                        onChange={(item) => {
                          setSelectedCountry(item);
                          setEditexp({
                            ...Editexp,
                            Location: item.name,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <strong htmlFor="description">Description<span style={{ color: "#BA0000" }}>*</span></strong>
                      <Form.Control
                        name="Description"
                        id="description"
                        cols="50"
                        style={{ width: '100%' }}
                        rows="5"
                        value={Editexp.Description}
                        placeholder="Description"
                        onChange={(e) =>
                          setEditexp({ ...Editexp, Description: e.target.value })
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row >
                <button type="submit" className="btn">
                  Update
                </button>
              </Card.Body>
            </form>
          </div>
        </div >


      </Container >


    </>
  )
}

export default WorkExp;