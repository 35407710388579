import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import MyContext from "MyContext";
import AdminLayout from "layouts/Admin.js";
import Login from "./components/login.js";
import InvoiceGenerator from "views/InvoiceGenerator";
import SubscriptionInvoiceGenerator from "views/SubscriptionInvoiceGenerator";
import TestInvoiceGenerator from "views/TestInvoiceGenerator"

function App() {
  //   const Navigate = useNavigate();
  const { endPointUrl, Token } = useContext(MyContext);
  // const Token = "W4lyaidIGicUCR1gCtaC2JEbn4D2AvzyRwRAHCCcUjvWOx6Epe";
  const [user, setuser] = useState({});
  const updateUser = (user) => {
    setuser(user);
  };

  //   useEffect(() => {
  //     if (
  //       sessionStorage.getItem("data") &&
  //       sessionStorage.getItem("data").length > 300
  //     ) {
  //       updateUser(JSON.parse(sessionStorage.getItem("data")));
  //     } else {
  //       Navigate("./");
  //     }
  //   }, []);
  //   useEffect(() => {
  //     if (user.length > 10)
  //       sessionStorage.setItem("data", JSON.stringify({ ...user, Password: "" }));
  //   }, [user]);
  //   window.scrollTo(0, 0);
  // const endPointUrl = "http://13.234.77.179:5000";

  // const endPointUrl = "http://13.235.33.158:5000";
  return (
    <MyContext.Provider value={{ Token, user, updateUser, endPointUrl }}>
      <Switch>
        {/* <Route path="/Invoice-Generator/:ID/:invoiceNumber" render={(props) => <InvoiceGenerator />} /> */}
        {
          JSON.stringify(user) != "{}"
            ? <>
              <Route
                path="/Subscription-Invoice-Generator"
                render={(props) => <SubscriptionInvoiceGenerator {...props} />}
              />
              <Route
                path="/Invoice-Generator"
                render={(props) => <InvoiceGenerator {...props} />}
              />
              <Route
                path="/Test-Invoice-Generator"
                render={(props) => <TestInvoiceGenerator {...props} />}
              />
              <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            </>
            :
            <Route path="/" render={(props) => <Login updateUser={updateUser} />} />
        }

        {/* <Redirect from="/" to="/admin/dashboard" /> */}
      </Switch>
    </MyContext.Provider>
  );
}

export default App;