import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MyContext from "MyContext";
import { useHistory } from "react-router-dom";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import moment from 'moment';
import PaginationSection from "./PaginationSection";
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";

function AddBid({ ID, FreelancerID }) {
    const { endPointUrl, Token } = useContext(MyContext);
    const [allDetails, setallDetails] = useState([]);
    const [project, setproject] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [Bid_ID, setID] = useState([]);
    const [jobs, setjobs] = useState([]);
    const history = useHistory();



    const getjobs = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/getAllJobs", {
                Token,
            });
            let Published = data.data.filter((item) => {
                return item.IsActive === true && item.Status === "Published";
            });
            setjobs(Published);
        } catch {
            setjobs([]);
        }
    };

    const handleApplyBid = (JobID) => {
        history.push(`/admin/Apply_Bid/${JobID}/${FreelancerID}`);
    }

    const data = React.useMemo(() => jobs, [jobs]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Job ID",
                accessor: "JobID",
            },
            {
                Header: "Job Title",
                accessor: "Title",
            },
            {
                Header: "Employer ID",
                accessor: "Employer_ID"
            },
            {
                Header: "Job Type",
                accessor: "ProjectType",
            },
            {
                Header: "Action",
                // accessor: "JobID",
                Cell: ({ row }) => (
                    <div>
                        <span
                            onClick={() => handleApplyBid(row.original.JobID)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <Button style={{ fill: "#07b274" }}>APPLY BID</Button>
                        </span>
                    </div>
                ),
            },
        ],
        []
    );
    useEffect(() => {
        getjobs();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        pageCount,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;


    return (
        <>
            <Container fluid>

                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">

                            <Card.Header>
                                <Card.Title as="h4">
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            className="mx-2"
                                            aria-label="Search"
                                            value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                    </Form>

                                </Card.Title>
                            </Card.Header>

                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped " {...getTableProps()}>
                                    <thead >
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render("Header")}
                                                        {/* Add a sort direction indicator */}
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? " 🔽"
                                                                    : " 🔼"
                                                                : ""}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr  {...row.getRowProps()}>
                                                    {/* {console.log(row.original.id)} */}
                                                    {row.cells.map((cell) => {
                                                        //  const { column } = cell.value;
                                                        //  let Id = column.Name;
                                                        //  console.log(cell.value);

                                                        return (<td onClick={() => handleApplyBid(row.original.JobID)} {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                                                    })}

                                                </tr>
                                            );
                                        }

                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <PaginationSection
                                pageIndex={pageIndex}
                                pageCount={pageOptions.length}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                gotoPage={gotoPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageOptions={pageOptions}
                            />
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddBid;
