import React, { useContext,useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import {BsArrowLeftShort} from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
  } from "react-table";
  import moment from 'moment';


  import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
  } from "react-bootstrap";

const Questionform = ({
    selectedQuestion,
    isUpdateMode,
    initialFormData,
    initialSelectedSkills,
    initialIsPublished,
    onSubmit,
    onClose,
    skillmaster,
  }) => {
    const { endPointUrl, Token } = useContext(MyContext);
    const [formData, setFormData] = useState(initialFormData);
    const [selectedSkills, setSelectedSkills] = useState(initialSelectedSkills);
    const [isPublished, setIsPublished] = useState(initialIsPublished);

    useEffect(() => {
        if (isUpdateMode && selectedQuestion) {
          setFormData({
            Question: selectedQuestion.Question,
            Option1: selectedQuestion.Option1,
            Option2: selectedQuestion.Option2,
            Option3: selectedQuestion.Option3,
            Option4: selectedQuestion.Option4,
            Answer: selectedQuestion.Answer,
          });
    
          if (!Array.isArray(selectedQuestion.Skills)) {
            setSelectedSkills([{ value: selectedQuestion.Skills, label: selectedQuestion.Skills }]);
          } else {
            setSelectedSkills(
              selectedQuestion.Skills.map((skill) => ({ value: skill, label: skill }))
            );
          }
    
          setIsPublished(selectedQuestion.Type === 'Published');
        }
      }, [isUpdateMode, selectedQuestion]);


    const handleSkillSelect = (selectedOption) => {
      setSelectedSkills(selectedOption);
    };
  
    const handleStatusChange = (checked) => {
      setIsPublished(checked);
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const selectedSkillsString = selectedSkills.map((skill) => skill.value).join(', ');

        const updatedData = {
            ...formData,
            Skills: selectedSkillsString,
            Type: isPublished ? 'Published' : 'Draft',
            Token:Token,
          };
          if (isUpdateMode) {
            updatedData.Token = Token;
            updatedData.Ques_ID = selectedQuestion.Ques_ID;
          }
          const endpoint = isUpdateMode ? '/admin/updatequestion' : '/admin/addquestion';
    const response = await axios.post(endPointUrl+endpoint, updatedData);
    
        // const { data } = await axios.post(endPointUrl + "/admin/addquestion", {
        //     Token,
        //   ...formData,
        //   Skills: selectedSkillsString,
        //   Type: isPublished ? 'Published' : 'Draft',
        // });
  
        // Handle the response data as needed
        console.log('API Response:', response.data);

        const successMessage = isUpdateMode ? 'Question updated successfully.' : 'Question added successfully.';
        message.success(successMessage);
        // Show a success message
        // message.success('Question submitted successfully.'); 
        onSubmit();
        // Clear the form after successful submission
        // setFormData(initialFormData);
        // setSelectedSkills(initialSelectedSkills);
        // setIsPublished(initialIsPublished);
  
        // Trigger the onSubmit callback from the parent component
        // onSubmit();
      } catch (error) {
        // Handle error response or show an error message
        console.log('Question submission failed:', error);
        message.error('Failed to submit Question.');
      }
    };
  
    return (
      <Container className="addskilltest" fluid>
        <div className="container-fluid d-flex justify-content-between">
         <h6 className="heading-6">  {isUpdateMode ? 'Update Question' : 'Add Question'}</h6>
         </div>
        <Row>
          <Col md="12">
          <Card className='card-user' style={{margin:"2% auto",padding:"20px",border:"1px solid #888"}}>

<Card.Header style={{backgroundColor:'#07b274'}}>
    <Card.Title as="h4">Fill Details</Card.Title>
  <hr></hr>
  </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Question</label>
                        <Form.Control
                          name="Question"
                          placeholder="Question"
                          type="text"
                          value={formData.Question}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group >
                        <label style={{ display: 'flex', justifyContent:'flex-start', marginLeft:"30px"  }}>Status</label>
                        {/* Use the react-switch for the sliding switch */}
                        <div style={{ display: 'flex',justifyContent:'flex-start', marginLeft:"50px" }} >
                          <Switch
                            onChange={handleStatusChange}
                            checked={isPublished}
                            onColor="#86d3ff"
                            onHandleColor="#07b274"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          />
                          <span style={{ marginLeft: '10px' }}>
                            {isPublished ? 'Published' : 'Draft'}
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Option 1</label>
                        <Form.Control
                          name="Option1"
                          placeholder="Option1"
                          type="text"
                          value={formData.Option1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Option 2</label>
                        <Form.Control
                          name="Option2"
                          placeholder="Option2"
                          type="text"
                          value={formData.Option2}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Option 3</label>
                        <Form.Control
                         name="Option3"
                         placeholder="Option3"
                         type="text"
                         value={formData.Option3}
                         onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Option 4</label>
                        <Form.Control
                          name="Option4"
                          placeholder="Option4"
                          type="text"
                          value={formData.Option4}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Answer</label>
                        <Form.Control
                          name="Answer"
                          placeholder="Answer(option no.)"
                          type="text"
                          value={formData.Answer}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                    <Form.Group>
                      <label>Skills</label>
                      <Selectant
                        name="Skills"
                        options={skillmaster}
                        isMulti
                        onChange={handleSkillSelect}
                        value={selectedSkills}
                      />
                    </Form.Group>
                  </Col>
                  </Row>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    style={{ backgroundColor: '#07b274', margin:"20px 0px 0px 20px" }}
                  >
                    {isUpdateMode ? 'Update' : 'Submit'}
                  </Button>
                  </div>
                  {/* <Button
                    className="btn-fill pull-right"
                    type="button"
                    variant="danger"
                    style={{ backgroundColor: 'red', marginLeft: '10px' }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button> */}
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default Questionform;