import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs"
import PaginationSection from "./PaginationSection";
import Selectant from 'react-select';
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Switch from 'react-switch'; // Import the react-switch component

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";



function Publishedskilltest({ skillmaster }) {
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isPublished, setIsPublished] = useState(false); // State to handle the published status
  const [showModel, setshowModel] = useState(false);
  // State to handle the published status
  const [isPaid, setIsPaid] = useState(true);
  const [PaidAmount, setPaidAmount] = useState('');
  const [changeAmount, setchangeAmount] = useState('');



  // const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);
  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  console.log("amt", selectedSkills.Amount);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSkillSelect = async (selectedOption) => {
    setSelectedSkills(selectedOption);
  };

  const handleStatusChange = (checked) => {
    setIsPublished(checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      Status: checked ? "Published" : "Draft",
    }));
  };
  const handlePaidChange = (checked) => {
    setIsPaid(checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      isPaid: checked,
    }));
  };



  const handleshowModel = async (sktid) => {
    const { data } = await axios.post(endPointUrl + "/getAllpublishedSkilltest", {
      Token,
    });
    const selectedSkillTest = data.data.find((test) => test.SKT_ID === sktid);
    if (!selectedSkillTest) {
      console.error("Skill test not found");
      return;
    }
    setFormData({
      SKT_ID: sktid,
      SKT_Title: selectedSkillTest.SKT_Title || '',
      Published_Date: selectedSkillTest.Published_Date ? moment(selectedSkillTest.Published_Date).format('YYYY-MM-DD') : '',
      No_of_Questions: selectedSkillTest.No_of_Questions || '',
      Duration: selectedSkillTest.Duration || '',
      Amount: selectedSkillTest.Amount || 0,
    });

    const skillsArray = selectedSkillTest.Skills.split(", ").map(skill => ({ value: skill, label: skill }));
    setSelectedSkills(skillsArray);
    setIsPublished(selectedSkillTest.Status === 'Published');
    setIsPaid(true);
    setshowModel(true);
    setPaidAmount(selectedSkillTest?.Amount);
  };


  const getpublishedskilltest = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/getAllpublishedSkilltest", {
        Token,
      });
      setallDetails(data.data);
    } catch {
      console.log("get Published Jobs is not working");
    }
  };
  const handleAmountChange = (event) => {
    setchangeAmount(event.target.value);
    setFormData({
      ...formData, Amount: event.target.value
    })

  };


  // console.log('changeamt',changeAmount)

  const handleUpdate = async (e, sktid) => {
    e.preventDefault();
    if (isPaid == true && formData.Amount == 0) {
      message.warning("Amount is required");
      return;
    }
    console.log("Enter")
    try {
      const selectedSkillsString = selectedSkills.map((skill) => skill.value).join(', ');
      console.log(sktid)
      const { data } = await axios.post(endPointUrl + "/updateskilltest", {
        Token,
        SKT_ID: sktid,
        ...formData,
        Skills: selectedSkillsString,
        Amount: formData.Amount || 0
      });
      setallDetails(data.data);
      setshowModel(false);
      getpublishedskilltest();
      message.success("Text Update successfully");
    } catch (error) {
      console.log("Failed to update test:", error);
      if (error?.response?.data?.err?.name === "SequelizeUniqueConstraintError") {
        message.error('Skill Title already exists!');
      }
      else if (error?.response?.data?.err?.name === "SequelizeValidationError") {
        message.error('No of question minimum value 10');
      }
      else {
        message.error('Something Went Wrong');
      }
    }

  };

  const handleDelete = async (sktid) => {
    try {
      await axios.post(endPointUrl + "/deleteskilltest", {
        Token,
        SKT_ID: sktid,
      });
      // Remove the deleted text from the table
      setallDetails((prevDetails) =>
        prevDetails.filter((detail) => detail.SKT_ID !== sktid)
      );
      message.success("Test deleted successfully.");

    } catch (error) {
      console.log("Failed to delete test:", error);
      message.error("Failed to delete test.");
    }
  };




  const columns = React.useMemo(
    () => [
      {
        Header: "Skill ID",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: "Title",
        accessor: "SKT_Title",
      },
      {
        Header: "Skills",
        accessor: "Skills",
      },
      {
        Header: "Questions",
        accessor: "No_of_Questions",
      },
      {
        Header: "Duration (in min.)",
        accessor: "Duration",
      },
      {
        Header: "Published Date",
        accessor: "Published_Date",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: "Actions",
        accessor: "SKT_ID",
        Cell: ({ value }) => (
          <div>
            {/* "Update" icon */}
            <span
              onClick={() => handleshowModel(value)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
            </span>
            {/* "Delete" icon */}
            <span
              onClick={() => handleDelete(value)}
              style={{ cursor: "pointer" }}
            >
              <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
            </span>
          </div>
        ),

      },

    ],
    []
  );
  useEffect(() => {
    getpublishedskilltest();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      {!showModel ? <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {/* {console.log(row.original.id)} */}
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}

                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>
      </Container> :

        <Container className='addskilltest' fluid>

          <div className="container-fluid d-flex gap-3 align-items-center">
            <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px,", fontSize: "40px" }} onClick={(() => setshowModel(false))} />
            <h6 className="heading-6">Updated Skill Test</h6>
          </div>
          <Row>
            <Col md="12">
              <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                <Card.Header style={{ backgroundColor: '#07b274' }}>
                  <Card.Title as="h4">Fill Details</Card.Title>
                  <hr></hr>
                </Card.Header>
                <Card.Body>
                  <Form
                    onSubmit={((e) => { handleUpdate(e, formData.SKT_ID) })}
                  >
                    <Row>
                      <Col className="pr-1" md="6">
                        <Form.Group>
                          <label>Title</label>
                          <Form.Control
                            name="SKT_Title"
                            placeholder="Title"
                            type="text"
                            value={formData.SKT_Title}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" md="6" style={{ display: 'flex', alignItems: 'center' }} >
                        <Form.Group>
                          <label style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "30px" }}>Status</label>
                          {/* Use the react-switch for the sliding switch */}
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "50px" }}>
                            <Switch
                              onChange={handleStatusChange}
                              checked={isPublished}
                              onColor="#86d3ff"
                              onHandleColor="#07b274"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            />
                            <span style={{ marginLeft: '10px' }}>
                              {isPublished ? 'Published' : 'Draft'}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <Form.Group>
                          <label>Published Date</label>
                          <Form.Control
                            name="Published_Date"
                            placeholder="Published_Date"
                            type="date"
                            value={formData.Published_Date}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" md="6">
                        <Form.Group>
                          <label>No. of Questions</label>
                          <Form.Control
                            name="No_of_Questions"
                            placeholder="Questions"
                            type="number"
                            value={formData.No_of_Questions}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <Form.Group>
                          <label>Skills</label>
                          <Selectant
                            name="Skills"
                            options={skillmaster}
                            value={selectedSkills}
                            isMulti
                            onChange={handleSkillSelect}
                          />
                        </Form.Group>
                      </Col>


                      <Col className="pl-1" md="6" >
                        <Form.Group>
                          <label>Test Duration (in min.)</label>

                          <Form.Control
                            name="Duration"
                            placeholder="Duration"
                            type="number"
                            value={formData.Duration}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                    </Row>

                    <Row>

                      <Col className="pr-1" md="6" >
                        <Form.Group>
                          <label style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "10px" }}>Paid</label>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "30px" }}>
                            <Switch
                              onChange={handlePaidChange}
                              checked={isPaid}
                              onColor="#86d3ff"
                              onHandleColor="#07b274"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="pl-1" md="6" >
                        {isPaid && (
                          <Form.Group>
                            <label className="pl-1" md="6" style={{ display: 'flex', marginTop: '10px' }}>Amount:</label>
                            <Form.Control
                              type="number"
                              value={formData.Amount}
                              onChange={handleAmountChange}
                              style={{ display: 'flex', marginTop: '0px', border: "1px solid lightgrey", padding: '7px', borderRadius: "4px" }}
                            ></Form.Control>
                          </Form.Group>
                        )}
                      </Col>
                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row >

        </Container >
      }




    </>
  );
}

export default Publishedskilltest;