import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import axios from "axios";




function Masterss({ milestone }) {
  const history = useHistory();
  const data = React.useMemo(() => milestone, [milestone]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Milestone ID",
        accessor: "M_ID",
      },
      {
        Header: "Amount",
        accessor: "Amount", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "Description",
        Cell: ({ value }) => {
          if (value) {
            const words = value.split(' ');
            if (words.length > 10) {
              return (
                <span style={{ whiteSpace: "nowrap" }}>
                  {words.slice(0, 10).join(' ')} ...
                </span>
              );
            } else {
              return <span style={{ whiteSpace: "nowrap" }}>{value}</span>;
            }
          } else {
            return <span style={{ whiteSpace: "nowrap" }}>-</span>;
          }
        }
      },
      // {
      //   Header: "Milestone Due Date",
      //   accessor: "M_Due_Date",
      // },
      // {
      //   Header: "Milstone Status",
      //   accessor: "MilstoneStatus",
      // },
      // {
      //   Header: "Freelancer Payment",
      //   accessor: "FreelancerPaymentStatus",
      // },
      // {
      //   Header: "Employer Payment",
      //   accessor: "EmployerPaymentStatus",
      // },
      // {
      //   Header: "Action",
      //   accessor: "id",

      //   Cell: ({ row }) => (

      //     <div>
      //       {(row.original.MilstoneStatus === "Approved" && row.original.FreelancerPaymentStatus === "Released" && row.original.EmployerPaymentStatus === "Released") &&
      //         <Button onClick={() => handleNavigate(row.original.PM_ID)} style={{ fill: "#07b274" }} >Transfer</Button>}
      //     </div>
      //   ),
      // },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const handleNavigate = (PM_ID) => {
    history.push(`/admin/Pending-Bank-Status/${PM_ID}`)
  }

  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      {/*             
                    <input
                    className="search-box"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={globalFilter||''} onChange={(e)=>setGlobalFilter(e.target.value)} 
                    />
                */}

      <Card className="strpied-tabled-with-hover" style={{ marginTop: '10px' }}>
        <Card.Body className="table-full-width table-responsive px-0" style={{
          width: '100%', overflowX: 'scroll',
          overflowY: 'hidden'
        }}>
          <Table className="table-hover table-striped " style={{
            width: '100%', overflowX: 'scroll',
            overflowY: 'hidden'
          }}>
            {/* <table style={{ width:'100%',textAlign:'center'}}> */}
            <thead >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      // Check if the cell value is a date
                      const { column } = cell;
                      if (column.id === 'M_Due_Date') {
                        const date = new Date(cell.value);
                        const formattedDate = date.toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        });

                        return <td {...cell.getCellProps()}>{formattedDate}</td>;
                      }
                      if (column.id === "M_ID") {
                        return <td>{cell.render(index + 1)}</td>
                      }

                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}

            </tbody>
            {/* </table> */}
          </Table>
        </Card.Body>




      </Card>
    </>
  );
}

export default Masterss;
