import React, { useContext, useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MyContext from "MyContext"


// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Ongoingprojects from "./Ongoingprojects";
import Closedprojects from "./Closedprojects";


function Projects() {
  const history = useHistory();
    const { endPointUrl, Token } = useContext(MyContext);
  const [items, setitems] = useState({});
  const [activeCard, setActiveCard] = useState("ongoing");
  const [showOngoingprojects, setShowOngoingprojects] = useState(true); 
  const [showClosedprojects, setShowClosedprojects] = useState(false); 

  const count = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/countJobsandProjects", {
        Token,
      });
      setitems(data.data);
       console.log(data.data.publishedjobCount);
   
   

    } catch {
      console.log("counts is not working");
    }
  };

  useEffect(() => {
    count();
  }, []);

  const navigateToongoingprojects = () => {
    // history.push("/admin/Published-Jobs"); 
   setShowOngoingprojects(true);
   setShowClosedprojects(false);
    setActiveCard("ongoing");
  };
  const navigateToclosedprojects = () => {
    // history.push("/admin/Draft-Jobs"); 
   setShowClosedprojects(true);
   setShowOngoingprojects(false);
    setActiveCard("closed");

  };
  

  return (
    <>
       <Container fluid>
        <Row>
          <Col lg="6" sm="12">
            <Card className={`card-stats ${activeCard === "ongoing" ? "active" : ""}`} onClick={navigateToongoingprojects} style={{ borderBottom: activeCard === "ongoing" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                    <p className="card-category">Ongoing Projects</p>
                    <Card.Title as="h3">{items?.ongoingprojectCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
          </Col>
         
          <Col lg="6" sm="12">
            <Card className={`card-stats ${activeCard === "closed" ? "active" : ""}`} onClick={navigateToclosedprojects} style={{ borderBottom: activeCard === "closed" ? "4px solid #07b273" : "" }}>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col sm="7">
                    <div className="numbers">
                    <p className="card-category">Closed Projects</p>
                    <Card.Title as="h3">{items?.closedprojectCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
          </Col>
          </Row>
          </Container>
          <Container fluid className="projecttable">
            {showOngoingprojects && <Ongoingprojects/>}
            {showClosedprojects && <Closedprojects/>}
       
          </Container>
    </>
  );
}

export default Projects;
