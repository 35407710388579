import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from 'react-bootstrap';
import axios from "axios";
import MyContext from "MyContext";
import { useHistory } from "react-router-dom";
import '../assets/css/dashboardtoggle.css';

// react-bootstrap components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  // Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend
);

// import ChartistGraph from 'react-chartist';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
} from "react-bootstrap";

function Dashboard() {
  const history = useHistory();
  const { endPointUrl, Token } = useContext(MyContext);
  const [items, setitems] = useState({});
  const [label, setLabel] = useState([]);
  const [chatType, setChatType] = useState("Yearly");
  const [bidingrevenue, setBidingRevenue] = useState([]);
  const [mielstonerevenue, setMielstoneRevenue] = useState([]);
  const [revenueOut, setRevenueOut] = useState([]);
  const [profitRevenue, setProfitRevenue] = useState([]);
  const [getjob, setGetJob] = useState([]);
  const [getnotification, setGetNotification] = useState([]);


  const handleclick = (Id) => {
    history.push(`/admin/Jobs-Details/${Id}`);

  };
  const count = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/counts", {
        Token,
      });
      setitems(data.data);
    } catch {
      console.log("counts is not working");
    }
  };
  // const data = React.useMemo(() => allDetails, [allDetails]);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  // const fetchBiddingRevenue = async () => {
  //   try {
  //     const From = `${year}-01-01`;
  //     const To = `${year}-${month}-${day}`;

  //     const { data } = await axios.post(endPointUrl + "/admin/getSubscriptionbyDates", {
  //       Token,
  //       From,
  //       To
  //     });

  //     if (chatType === "Yearly") {
  //       console.log("chatType", chatType)
  //       // const revenueByMonth = new Array(month).fill(0);
  //       const monthNames = [
  //         'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //         'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  //       ];
  //       const months = [];
  //       // Ensure monthNumber is within a valid range (1 to 12)
  //       for (let i = 1; i <= month; i++) {
  //         months.push(monthNames[i - 1]);
  //       }
  //       const revenueByMonth = {
  //         '01': 0, '02': 0, '03': 0, '04': 0, '05': 0, '06': 0,
  //         '07': 0, '08': 0, '09': 0, '10': 0, '11': 0, '12': 0
  //       };
  //       for (let i = 0; i < data.data.length; i++) {
  //         const subscription = data.data[i];
  //         const subscriptionDate = new Date(subscription.createdAt);
  //         const subscriptionMonth = String(subscriptionDate.getMonth() + 1).padStart(2, '0');
  //         const subscriptionAmount = subscription.Amount;
  //         revenueByMonth[subscriptionMonth] += subscriptionAmount;
  //       }
  //       // Convert the revenue object into an array with values
  //       const revenueArray = Object.values(revenueByMonth);
  //       // Update the state with the revenue data
  //       setBidingRevenue(revenueArray);
  //       setLabel(months);
  //     } else if (chatType === "Monthly") {
  //       console.log("chatType", chatType, "Day", day)
  //       const days = [];
  //       // Ensure monthNumber is within a valid range (1 to 12)
  //       for (let i = 1; i <= day; i++) {
  //         days.push([i]);
  //       }
  //       console.log("DAYS", days)
  //       const revenueByDay = {
  //         '01': 0, '02': 0, '03': 0, '04': 0, '05': 0, '06': 0,
  //         '07': 0, '08': 0, '09': 0, '10': 0, '11': 0, '12': 0,
  //         '13': 0, '14': 0, '15': 0, '16': 0, '17': 0, '18': 0,
  //         '19': 0, '20': 0, '21': 0, '22': 0, '23': 0, '24': 0,
  //         '25': 0, '26': 0, '27': 0, '28': 0, '29': 0, '30': 0
  //       };

  //       for (let i = 0; i < data.data.length; i++) {
  //         const subscription = data.data[i];
  //         const subscriptionDate = new Date(subscription.createdAt);
  //         const subscriptionDay = String(subscriptionDate.getDate()).padStart(2, '0');
  //         const subscriptionAmount = subscription.Amount;
  //         revenueByDay[subscriptionDay] += subscriptionAmount;
  //       }
  //       const temp = Object.values(revenueByDay);
  //       setLabel(days);
  //       console.log(temp)
  //       setBidingRevenue(temp);;
  //     }
  //   } catch (error) {
  //     console.log("Error fetching bidding revenue:", error);
  //   }
  // };

  const fetchBiddingRevenue = async () => {
    try {
      const From = `${year}-01-01`;
      const To = `${year}-${month}-${day}`;

      const response = await axios.post(endPointUrl + "/admin/getSubscriptionbyDates", {
        Token,
        From,
        To
      });
      const subscriptionData = response.data.data;

      if (chatType === "Yearly") {
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const revenueByMonth = Array(12).fill(0);

        for (const subscription of subscriptionData) {
          const subscriptionDate = new Date(subscription.createdAt);
          const subscriptionMonth = subscriptionDate.getMonth();
          const subscriptionAmount = subscription.Amount;
          revenueByMonth[subscriptionMonth] += subscriptionAmount;
        }

        const months = monthNames.slice(0, month);

        setBidingRevenue(revenueByMonth);
        setLabel(months);
      }
      //  else if (chatType === "Monthly") {

      //   const days = Array(day + 1).fill(0);
      //   const revenueByDay = {};

      //   for (const subscription of subscriptionData) {
      //     const subscriptionDate = new Date(subscription.createdAt);
      //     const subscriptionDay = subscriptionDate.getDate().toString().padStart(2, '0');
      //     const subscriptionAmount = subscription.Amount;
      //     revenueByDay[subscriptionDay] = (revenueByDay[subscriptionDay] || 0) + subscriptionAmount;
      //   }

      //   const temp = Object.values(revenueByDay);
      //   setLabel(days);
      //   setBidingRevenue(temp);
      // }
    } catch (error) {
      console.log("Error fetching bidding revenue:", error);
    }
  };

  const mielstoneRevenue = async () => {
    try {
      const From = `${year}-01-01`;
      const To = `${year}-${month}-${day}`;
      const response = await axios.post(endPointUrl + "/admin/getMilestonePaymentRevnue", {
        Token,
        From,
        To
      });
      const subscriptionData = response.data.data;

      const revenueByMonth = Array(12).fill(0);
      for (const subscription of subscriptionData) {

        const subscriptionDate = new Date(subscription.M_Due_Date);
        const subscriptionMonth = subscriptionDate.getMonth();
        const subscriptionAmount = parseInt(((subscription.Amount / (100 - 10)) * 100).toFixed(0), 10);
        revenueByMonth[subscriptionMonth] += subscriptionAmount;
      }
      setMielstoneRevenue(revenueByMonth);
    } catch (error) {
      console.log("Error fetching bidding revenue:", error);
    }
  }
  const mielstoneRevenueOut = async () => {
    try {
      const From = `${year}-01-01`;
      const To = `${year}-${month}-${day}`;
      const response = await axios.post(endPointUrl + "/admin/getMilestonePaymentRevnueOut", {
        Token,
        From,
        To
      });
      const subscriptionData = response.data.data;
      const revenueByMonth = Array(12).fill(0);
      for (const subscription of subscriptionData) {
        const subscriptionDate = new Date(subscription.M_Due_Date);
        const subscriptionMonth = subscriptionDate.getMonth();
        const subscriptionAmount = parseFloat((subscription.Amount).toFixed(2));
        revenueByMonth[subscriptionMonth] += subscriptionAmount;
      }
      setRevenueOut(revenueByMonth);
    } catch (error) {
      console.log("Error fetching bidding revenue:", error);
    }
  }

  const profiltRevenue = async () => {
    if (mielstonerevenue && revenueOut) {
      const newProfitRevenue = mielstonerevenue.map((item, i) => {
        const profit = (item - revenueOut[i]).toFixed(2);
        return parseFloat(profit);
      });
      setProfitRevenue(newProfitRevenue);
    }
  };



  const getNumberOfJob = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getNumberOfJobs", { Token, Number: 10 });
      if (data.data) {
        setGetJob(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getNumberOfNotification = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getNotificationsByType", { Token, Number: 10, Type: 'Job' });
      if (data.notificationData) {
        setGetNotification(data.notificationData)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    count();
    fetchBiddingRevenue();
    mielstoneRevenue();
    mielstoneRevenueOut();
    getNumberOfJob();
    getNumberOfNotification();
  }, [chatType]);
  useEffect(() => {
    profiltRevenue();
  }, [mielstonerevenue, revenueOut])

  const option = {
    responsive: true,
    // outerWidth: 140,
    // innerWidth:1140,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "bottom" },
      title: {
        display: false,
        text: "Modular Bar Chart",
      },
    },
  };

  const data = chatType === "Yearly" ? {
    labels: label,
    datasets: [
      {
        label: "Bidding revenue",
        data: bidingrevenue,
        backgroundColor: '#ffc362',
      },
      {
        label: 'Milestone revenue',
        data: mielstonerevenue,
        backgroundColor: 'rgba(54, 162, 238, 0.6)',
      },
      {
        label: 'Milestone revenue out',
        data: revenueOut,
        backgroundColor: "#D5D5D5",
      },
      {
        label: "Platform revenue",
        data: profitRevenue,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },

    ],

  } :
    {
      labels: label,
      datasets: [
        {
          label: "Bidding revenue",
          data: bidingrevenue,
          backgroundColor: '#ffc362',
        },
        {
          label: 'Milestone revenue',
          data: mielstonerevenue,
          backgroundColor: 'rgba(54, 162, 238, 0.6)',
        },
        {
          label: 'Milestone revenue out',
          data: revenueOut,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },

      ],

    }
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Freelancers</p>
                      <Card.Title as="h3">{items?.freelancer}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Employers</p>
                      <Card.Title as="h3">{items?.employer}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Last day
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Job Posted</p>
                      <Card.Title as="h3">{items?.jobs}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o mr-1"></i>
                  In the last hour
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Bids Applied</p>
                      <Card.Title as="h3">{items?.bids}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update now
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Col className="container">
          <Col>
            <Card>
              <Card className="d-flex flex-row align-items-center justify-content-between">
                <Card.Header>
                  <Card.Title as="h4">Revenue Model</Card.Title>
                  <p className="card-category">All products including Taxes</p>
                </Card.Header>
              </Card>

              <Card.Body>
                <div className="ct-chart" id="chartActivity">
                  <Bar options={option} data={data} />
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-check"></i>
                  Data information certified
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Container>
            <Row>
              <Col>
                <Card className="card-tasks">
                  <Card.Header>
                    <Card.Title as="h4">Top Jobs</Card.Title>
                    <p className="card-category">Backend development</p>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-full-width">
                      <Table>
                        <tbody>
                          {getjob?.map((job, index) => (
                            <tr key={index}>
                              <td onClick={() => handleclick(job.JobID)} style={{ fontWeight: "600" }}>
                                {job.Title}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="now-ui-icons loader_refresh spin"></i>
                      Updated 3 minutes ago
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card className="card-tasks">
                  <Card.Header>
                    <Card.Title as="h4">Latest Notifcation</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-full-width">
                      <Table>
                        <tbody >
                          {getnotification?.map(({ fromUser, notification }, index) => (
                            <tr key={index} className="d-flex align-items-center">
                              <img src={`${endPointUrl + /images/ + fromUser.Photo}`} alt="User Photo" width={"40px"} height={"40px"} className="rounded-circle" />
                              <td style={{ fontWeight: "600", textAlign: "start" }}>
                                {notification.Message}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="now-ui-icons loader_refresh spin"></i>
                      Updated 3 minutes ago
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>


        </Col>
      </Container>
    </>
  );
}

export default Dashboard;
