import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Accordion } from 'react-bootstrap'

import { Nav } from "react-bootstrap";

function Accordian({ prop }, { key }) {
  const [toogle, settoogle] = useState(false);
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const handletoogle = () => {
    settoogle(!toogle);

  };
  return (
    <>
      {/* <Accordian> */}
      {/* <Accordion.Item > */}
      {/* <Accordion.Header> */}
      <NavLink
        to={prop.layout + prop.path}
        className="nav-link d-flex align-items-center justify-content-between"
        activeClassName="active"
        onClick={handletoogle}
      >
        <span className="d-flex align-items-center">
          <i className={prop.icon} />
          <p>{prop.name}</p>
        </span>
        {prop.accordian ? (
          <i
            className={` nc-icon ${toogle ? "nc-stre-down" : "nc-stre-right"} `}
            style={{ marginRight: "4px" }}
          ></i>
        ) : null}
      </NavLink>
      {/* </Accordion.Header> */}
      {/* <Accordion.Body>   */}
      {(toogle) ? (
        prop.accordian ? (
          <Nav>
            <ul>
              {prop.accordianpath?.map((item, key) => {
                if (!item.redirect)
                  return (
                    <li
                      className={
                        item.upgrade
                          ? "active active-pro"
                          : activeRoute(item.layout + item.path)
                      }
                      key={key}
                    >
                      <Accordian prop={item} />
                    </li>
                  );
                return null;
              })}
            </ul>
          </Nav>
        ) : null
      ) : null}
      {/* </Accordion.Body>   */}
      {/* </Accordion.Item>  */}
      {/* </Accordian> */}
    </>
  );
}

export default Accordian;
