import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Selectant from 'react-select';
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { FcImageFile } from 'react-icons/fc';
import add from "assets/img/add1.jpg";
import PaginationSection from "./PaginationSection";
import { BiShow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import moment from 'moment';

import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";

function Certificate(Id) {

    const { endPointUrl, Token } = useContext(MyContext);
    const [allDetails, setallDetails] = useState([]);
    const history = useHistory();
    const [certificate, setCertificate] = useState([]);
    const [newCertificate, setnewCertificate] = useState({
        Title: "",
        Type: "",
        Authorized_Certification: "",
        Certification_UID: "",
        Completion_Date: null,
        Completion_Expire_Date: null,
    });
    const [Editcertificate, setEditcertificate] = useState([]);
    const [showmodel, setshowModel] = useState(false);
    const [showEdit, setshowEdit] = useState(false);
    const [certificateshow, setCertificateshow] = useState(false);
    const [docshowform, setDocshowform] = useState(false);
    const [doc, setDoc] = useState({ title: "", type: "", des: "", docs: [] });
    const [expireDatetoggle, setExporeDatetoggle] = useState(false)
    const [preform, setPreform] = useState(null);
    const [fileName, setFileName] = useState(null)

    const getuserCertificate = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
                UserID: Id.ID,
                Token,
            });

            setallDetails(data.data);

        } catch {
            console.log("get user certificate is not working");
        }
    };

    const addCertificate = async (certificate) => {
        const { data } = await axios.post(endPointUrl + "/user/addcertificate", {
            formList: [certificate],
            UserID: Id.ID,
            Token
        });

        const CertificateID = data.data;
        const formdata = new FormData();
        var imagaeData = [preform]
        imagaeData.forEach(item => formdata.append('File_Upload', item));

        try {
            const response = await axios.post(
                endPointUrl + "/user/certificatedocupload",
                formdata,
                {
                    params: {
                        Token,
                        cerID: CertificateID
                    }
                }
            );
            console.log("Document upload response:", response.data);
        } catch (error) {
            console.error("Error uploading documents:", error);
        }

        setnewCertificate({
            Title: "",
            Type: "",
            Authorized_Certification: "",
            Certification_UID: "",
            Completion_Date: null,
            Completion_Expire_Date: null,
        })
        message.success("Certificate Added")

        getuserCertificate();
    };
    const deleteCertificate = async (C_Id) => {
        try {
            await axios.post(endPointUrl + "/user/deleteCertiDoc", { C_Id, Token });
            message.success('Delete Succesfull');
            getuserCertificate();
        } catch {
            message.error('something Went wrong');
        }
    };
    const updateCertificate = async () => {
        try {
            await axios.post(endPointUrl + "/user/updatecertificate", {
                formList: [Editcertificate],
                UserID: Id.ID,
                C_Id: Editcertificate.C_Id,
                Token
            });
            if (preform) {
                const formdata = new FormData();
                var imageData = [preform]
                imageData.forEach(item => formdata.append('File_Upload', item));

                try {
                    const response = await axios.post(
                        endPointUrl + "/user/certificatedocupload",
                        formdata,
                        {
                            params: {
                                Token,
                                cerID: [{ C_Id: Editcertificate.C_Id }]
                            }
                        }
                    );
                    console.log("Document upload response:", response.data);
                } catch (error) {
                    console.error("Error uploading documents:", error);
                }

            }
            getuserCertificate();
            console.log(Editcertificate.U_E_ID)
            message.success("Update Succesfull");
        } catch (error) {
            console.log(error);
            message.error("something went Wrong ");
        }
    };

    const handleCertificateModel = (e) => {
        setshowModel(true);
    }
    const CloseModel = () => {
        setshowModel(false);
        setshowEdit(false);
    };
    const handlesubmitCertificateModel = (e) => {
        e.preventDefault();
        console.log('newcertificate', newCertificate)
        console.log('expireDatetoggle', expireDatetoggle, "preform", preform)
        if (!newCertificate.Title || !newCertificate.Type || !newCertificate.Certification_UID || (!expireDatetoggle && !newCertificate.Completion_Expire_Date) || !preform) {
            message.warning("Please fill all fields")
            return
        }
        addCertificate(newCertificate);
        setshowModel(false);
    };
    const handleEditCertificateModel = (rowData) => {
        setEditcertificate(rowData);
        setExporeDatetoggle(Editcertificate?.Completion_Expire_Date ? false : true)
        console.log("rowData", rowData);
        setshowEdit(true);
    };

    const handleEditsubCertificateModel = (e) => {
        e.preventDefault();

        updateCertificate();
        setshowEdit(false);
    };
    const handledocs = (e, Docs, Title, Type, Des) => {
        e.preventDefault();
        setDoc({
            title: Title,
            type: Type,
            des: Des,
            docs: Docs
        }

        );
        setDocshowform(true);

    }
    const data = React.useMemo(() => allDetails, [allDetails]);

    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1, // Auto-incremented serial number
            },
            {
                Header: "Title",
                accessor: "Title",
            },
            {
                Header: "Type",
                accessor: "Type",
            },
            {
                Header: "Document",
                accessor: "Certificate_Image",
                Cell: ({ value }) => (
                    <a style={{ cursor: "pointer" }} href={`${endPointUrl}/certificates/${value}`} target="blank">
                        <FcImageFile />
                        {/* Use an appropriate icon class or component */}
                    </a>
                ),
            },
            {
                Header: "Completion Date",
                accessor: "Completion_Date",
                Cell: ({ value }) => (
                    <span>{moment(value).format('DD/MM/YYYY')}</span>
                ),
            },
            {
                Header: "Actions",
                accessor: "U_E_ID",
                Cell: ({ row }) => (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        {/* "Update" icon */}
                        <span
                            onClick={() => handleEditCertificateModel(row.original)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
                        </span>
                        {/* "Delete" icon */}
                        <span
                            onClick={() => deleteCertificate(row.original.C_Id)}
                            style={{ cursor: "pointer" }}
                        >
                            <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                        </span>
                    </div>
                ),
            },
        ],
        []
    );
    useEffect(() => {
        getuserCertificate();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        pageCount,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;


    return (
        <>

            <Container fluid>
                {/* {!showDetails ? ( */}
                <div className="education">
                    <div className="d-flex justify-content-between align-items-center">
                        <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px", textTransform: "uppercase" }}>Certificate
                            <img style={{ cursor: "pointer", width: "35px", height: "35px", marginLeft: "10px" }} src={add} alt="" onClick={handleCertificateModel} /></strong>
                        <div style={{ cursor: "pointer", fontSize: "25px" }} onClick={(() => setCertificateshow(!certificateshow))}>
                            {!certificateshow ? <BiShow /> : <RxCross2 />}
                        </div>
                    </div>

                    <Row className={certificateshow ? "showalldta" : "hidealldta"}>
                        <Col md="12">
                            <Card className="strpied-tabled-with-hover">
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <Table className="table-hover table-striped " {...getTableProps()}>
                                        <thead >
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render("Header")}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? " 🔽"
                                                                        : " 🔼"
                                                                    : ""}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr
                                                        {...row.getRowProps()}>
                                                        {/* {console.log(row.original.id)} */}
                                                        {row.cells.map((cell) => {
                                                            //  const { column } = cell.value;
                                                            //  let Id = column.Name;
                                                            //  console.log(cell.value);

                                                            return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                                                        })}

                                                    </tr>
                                                );
                                            }

                                            )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <PaginationSection
                                    pageIndex={pageIndex}
                                    pageCount={pageOptions.length}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pageOptions={pageOptions}
                                />
                            </Card>

                        </Col>
                    </Row>
                </div>

                <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
                    <div className="modal-content" style={{ width: "55%" }} >
                        <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>&times;</span>
                        <h2>Add Certificate</h2>
                        <form className="model-field" onSubmit={handlesubmitCertificateModel}>
                            <div style={{ paddingInlineEnd: "20px" }}>
                                <Card.Body>
                                    <Row>
                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong htmlFor="title">Certificate Name<span style={{ color: "#BA0000" }}>*</span></strong>
                                                <Form.Control
                                                    style={{ marginInlineEnd: "12%" }}
                                                    value={newCertificate.Title}
                                                    type="text"
                                                    name="title"
                                                    placeholder='Enter title'
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value.slice(0, 45);
                                                        if (!inputValue.trim()) {
                                                            return setnewCertificate({ ...newCertificate, Title: "" });
                                                        }
                                                        setnewCertificate({ ...newCertificate, Title: inputValue });
                                                    }}

                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong>Certificate Type<span style={{ color: "#BA0000" }}>*</span></strong>
                                                <Selectant
                                                    // style={{ marginBottom: "0", width: "100%", padding: '10px' }}
                                                    name="certificate_type"
                                                    value={newCertificate?.Type?.split(",").map((item) => ({ "value": item, "label": item }))}
                                                    options={[
                                                        { value: "SEO", label: "SEO" },
                                                        { value: "Development", label: "Development" },
                                                        { value: "Management", label: "Management" },
                                                        { value: "Digital Marketing", label: "Digital Marketing" },
                                                        { value: "YouTube Marketing", label: "YouTube Marketing" },
                                                        { value: "Data Analytics", label: "Data Analytics" },
                                                        { value: "Other", label: "Other" },
                                                    ]}
                                                    onChange={(e) => {
                                                        setnewCertificate({ ...newCertificate, Type: e.value });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>


                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong htmlFor="Authorized_Certification">Authorized certification body</strong>
                                                <Form.Control
                                                    style={{ marginInlineEnd: "12%" }}
                                                    value={newCertificate.Authorized_Certification}
                                                    type="text"
                                                    name="Authorized_Certification"
                                                    placeholder='Enter authorized certification body'
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value.slice(0, 55);
                                                        if (!inputValue.trim()) {
                                                            return setnewCertificate({ ...newCertificate, Authorized_Certification: "" });
                                                        }
                                                        setnewCertificate({ ...newCertificate, Authorized_Certification: inputValue });
                                                    }}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong htmlFor="Certification_UID">Certification UID<span style={{ color: "#BA0000" }}>*</span></strong>
                                                <Form.Control
                                                    style={{ marginInlineEnd: "12%" }}
                                                    value={newCertificate.Certification_UID}
                                                    type="text"
                                                    name="Certification_UID"
                                                    placeholder='Enter certification UID'
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (!inputValue.trim()) {
                                                            return setnewCertificate({ ...newCertificate, Certification_UID: "" });
                                                        }
                                                        if (inputValue.length <= 20) {
                                                            setnewCertificate({ ...newCertificate, Certification_UID: inputValue });
                                                        }
                                                        else {
                                                            message.warning("Certification UID contain only 20 Characters")
                                                        }
                                                    }}

                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong htmlFor="Completion_Date">Certificate Completion Date<span style={{ color: "#BA0000" }}>*</span></strong>
                                                <Form.Control
                                                    type="date"
                                                    value={newCertificate.Completion_Date}
                                                    name="Completion_Date"
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter certificate completion date"
                                                    min="1900"
                                                    max="2099"
                                                    onChange={(e) => {
                                                        const enteredDate = e.target.value;
                                                        const extractedYear = enteredDate?.split("-")[0].slice(0, 4);
                                                        setnewCertificate({ ...newCertificate, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                    }}
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong htmlFor="Completion_Expire_Date">Completion Expire Date<span style={{ color: "#BA0000" }}>*</span></strong>
                                                <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px', marginTop: "6px" }}><input type="checkbox" onChange={() => {
                                                    setExporeDatetoggle(!expireDatetoggle)
                                                    setnewCertificate({ ...newCertificate, Completion_Expire_Date: null });
                                                }
                                                } style={{ width: "auto", margin: 0, padding: 0 }} />Certificate not expire</span>
                                                <Form.Control
                                                    readOnly={expireDatetoggle}
                                                    style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "", width: '100%' }}
                                                    type="date"
                                                    value={!expireDatetoggle ? newCertificate.Completion_Expire_Date : ""}
                                                    name="Completion_Expire_Date"
                                                    placeholder="Enter completion expire date"
                                                    min="1900"
                                                    max="2099"
                                                    onChange={(e) => {
                                                        const enteredDate = e.target.value;
                                                        const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                        setnewCertificate({ ...newCertificate, Completion_Expire_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                    }}

                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col className="pl-1" md="6">
                                            <Form.Group>
                                                <strong htmlFor="file" style={{ display: "block", marginBottom: "0" }}>Upload Certificate<span style={{ color: "#BA0000" }}>*</span></strong>
                                                <span style={{ fontSize: "12px", color: "#9b9b9b" }}>maximum size 500kb*</span>
                                                <Form.Control
                                                    type="file"
                                                    name="File_Upload"
                                                    id="certificatefile"
                                                    style={{ width: "100%" }}
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files[0];
                                                        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

                                                        if (selectedFile && selectedFile.size / 1024 <= 500) {
                                                            if (allowedTypes.includes(selectedFile.type)) {
                                                                setPreform(selectedFile);
                                                                setFileName(URL.createObjectURL(selectedFile))
                                                            } else {
                                                                if (selectedFile.size / 1024 > 500) {
                                                                    message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                                }
                                                                e.target.value = ''; // Reset the input value
                                                                setPreform(null)
                                                                return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                                                            }
                                                        }
                                                        else {
                                                            e.target.value = ''; // Reset the input value
                                                            setPreform(null)
                                                            e.target.value = ''; // Reset the input value
                                                            message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                        }
                                                    }}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        {fileName ? <div>
                                            <img src={fileName} width="50%"></img>
                                        </div> : <></>}

                                    </Row>
                                </Card.Body>
                            </div>

                            <input type="submit" className="btn" value={'ADD'} />

                        </form>
                    </div >
                </div >

                {/* this model is for update certificate */}

                < div id="myModal" className={showEdit ? 'model-open' : 'model'} >
                    <div className="modal-content" style={{ width: "55%" }}>
                        <span className="close" onClick={CloseModel} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>&times;</span>
                        <h2>Update Certificate</h2>
                        <form className="model-field" onSubmit={handleEditsubCertificateModel}>
                            <Card.Body>
                                <Row>
                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong htmlFor="title">Certificate Name<span style={{ color: "#BA0000" }}>*</span></strong>
                                            <Form.Control
                                                style={{ marginInlineEnd: "12%" }}
                                                value={Editcertificate.Title}
                                                type="text"
                                                name="title"
                                                placeholder='Enter title'
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.slice(0, 45);
                                                    if (!inputValue.trim()) {
                                                        return setEditcertificate({ ...Editcertificate, Title: "" });
                                                    }
                                                    setEditcertificate({ ...Editcertificate, Title: inputValue });
                                                }}

                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong>Certificate Type<span style={{ color: "#BA0000" }}>*</span></strong>
                                            <Selectant
                                                // style={{ marginBottom: "0", width: "100%", padding: '10px' }}
                                                name="certificate_type"
                                                value={Editcertificate?.Type?.split(",").map((item) => ({ "value": item, "label": item }))}
                                                options={[
                                                    { value: "SEO", label: "SEO" },
                                                    { value: "Development", label: "Development" },
                                                    { value: "Management", label: "Management" },
                                                    { value: "Digital Marketing", label: "Digital Marketing" },
                                                    { value: "YouTube Marketing", label: "YouTube Marketing" },
                                                    { value: "Data Analytics", label: "Data Analytics" },
                                                    { value: "Other", label: "Other" },
                                                ]}
                                                onChange={(e) => {
                                                    setEditcertificate({ ...Editcertificate, Type: e.value });
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong htmlFor="Authorized_Certification">Authorized certification body</strong>
                                            <Form.Control
                                                style={{ marginInlineEnd: "12%" }}
                                                value={Editcertificate.Authorized_Certification}
                                                type="text"
                                                name="Authorized_Certification"
                                                placeholder='Enter authorized certification body'
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.slice(0, 55);
                                                    if (!inputValue.trim()) {
                                                        return setEditcertificate({ ...Editcertificate, Authorized_Certification: "" });
                                                    }
                                                    setEditcertificate({ ...Editcertificate, Authorized_Certification: inputValue });
                                                }}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong htmlFor="Certification_UID">Certification UID<span style={{ color: "#BA0000" }}>*</span></strong>
                                            <Form.Control
                                                style={{ marginInlineEnd: "12%" }}
                                                value={Editcertificate.Certification_UID}
                                                type="text"
                                                name="Certification_UID"
                                                placeholder='Enter certification UID'
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (!inputValue.trim()) {
                                                        return setnewCertificate({ ...Editcertificate, Certification_UID: "" });
                                                    }
                                                    if (inputValue.length <= 20) {
                                                        setEditcertificate({ ...Editcertificate, Certification_UID: inputValue });
                                                    }
                                                    else {
                                                        message.warning("Certification UID contain only 20 Characters")
                                                    }
                                                }}

                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong htmlFor="Completion_Date">Certificate Completion Date<span style={{ color: "#BA0000" }}>*</span></strong>
                                            <Form.Control
                                                type="date"
                                                value={Editcertificate?.Completion_Date?.slice(0,10)}
                                                name="Completion_Date"
                                                style={{ width: "100%" }}
                                                placeholder="Enter certificate completion date"
                                                min="1900"
                                                max="2099"
                                                onChange={(e) => {
                                                    const enteredDate = e.target.value;
                                                    const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                    setEditcertificate({ ...Editcertificate, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                }}
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong htmlFor="Completion_Expire_Date">Completion Expire Date<span style={{ color: "#BA0000" }}>*</span></strong>
                                            <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px', marginTop: "6px" }}>
                                                <input type="checkbox" 
                                                checked={expireDatetoggle}
                                                onChange={() => {
                                                setExporeDatetoggle(!expireDatetoggle)
                                                setEditcertificate({ ...Editcertificate, Completion_Expire_Date: null });
                                            }
                                            } style={{ width: "auto", margin: 0, padding: 0 }} />Certificate not expire</span>
                                            <Form.Control
                                                readOnly={expireDatetoggle}
                                                style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "", width: '100%' }}
                                                type="date"
                                                value={!expireDatetoggle ? Editcertificate?.Completion_Expire_Date?.slice(0,10) : ""}
                                                name="Completion_Expire_Date"
                                                placeholder="Enter completion expire date"
                                                min="1900"
                                                max="2099"
                                                onChange={(e) => {
                                                    const enteredDate = e.target.value;
                                                    const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                    setEditcertificate({ ...Editcertificate, Completion_Expire_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                }}

                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <strong htmlFor="file" style={{ display: "block", marginBottom: "0" }}>Upload Certificate<span style={{ color: "#BA0000" }}>*</span></strong>
                                            <span style={{ fontSize: "12px", color: "#9b9b9b" }}>maximum size 500kb*</span>
                                            <Form.Control
                                                type="file"
                                                name="File_Upload"
                                                id="certificatefile"
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    const selectedFile = e.target.files[0];
                                                    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

                                                    if (selectedFile && selectedFile.size / 1024 <= 500) {
                                                        if (allowedTypes.includes(selectedFile.type)) {
                                                            setPreform(selectedFile);
                                                            setFileName(URL.createObjectURL(selectedFile))
                                                        } else {
                                                            if (selectedFile.size / 1024 > 500) {
                                                                message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                            }
                                                            e.target.value = ''; // Reset the input value
                                                            setPreform(null)
                                                            return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                                                        }
                                                    }
                                                    else {
                                                        e.target.value = ''; // Reset the input value
                                                        setPreform(null)
                                                        e.target.value = ''; // Reset the input value
                                                        message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                    }
                                                }}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    {fileName ?
                                        preform?.type == "application/pdf" ?
                                            <div>
                                                <embed src={fileName} width="50%" height="200px" type="application/pdf" />
                                            </div>
                                            :
                                            < div >
                                                <img src={fileName} width="50%"></img>
                                            </div> :
                                        Editcertificate?.Certificate_Image?.substring(Editcertificate?.Certificate_Image?.lastIndexOf(".") + 1) === "pdf" ?
                                            <embed src={`${endPointUrl}/certificates/${Editcertificate?.Certificate_Image}`} width="50%" height="200px" type="application/pdf" />
                                            : <img src={`${endPointUrl}/certificates/${Editcertificate?.Certificate_Image}`} width="50%" />
                                    }

                                </Row>
                            </Card.Body>
                            <input type="submit" className="btn" value={'Update'} />
                        </form>
                    </div>
                </div >
            </Container >

            <div id="myModal"
                className={docshowform ? "model-open" : "model"}
                style={{ overflow: "auto" }}
            >
                <div className="modal-content" style={{ color: "black", lineHeight: "30px" }}>
                    <div className="close" onClick={() => { setDocshowform(false) }} style={{ display: 'inline-block', alignSelf: 'flex-end', paddingLeft: '5px' }}>
                        &times;
                    </div>
                    <div>
                        <h3 style={{ textAlign: "center" }}>{doc.type}</h3>
                        <div>{doc.title}</div>
                        <span>{doc.des}</span>
                        <div style={{ gap: "10px", marginTop: "10px" }} className="d-flex align-self-center">
                            {doc.docs.map((item, index) => (

                                <a
                                    key={index}
                                    href={`${endPointUrl}/certificatedoc/${item.File_Upload}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={item.File_Name}
                                >
                                    {item.File_Type === "png" ||
                                        item.File_Type === "jpg" ||
                                        item.File_Type === "jpeg" ||
                                        item.File_Type === "gif" ||
                                        item.File_Type === "webp" ||
                                        item.File_Type === "jfif" ? (

                                        <img
                                            src={`${endPointUrl}/certificatedoc/${item.File_Upload}`}
                                            style={{ width: "50px", height: "50px", borderRadius: "25%" }}
                                            alt={item.File_Type}
                                            className="potfoliothumbail"
                                        />
                                    ) : item.File_Type === "pdf" ? (
                                        <AiOutlineFilePdf className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                    ) : (
                                        <AiOutlineFileText className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                    )}

                                </a>


                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Certificate;