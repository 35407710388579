import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MyContext from "MyContext"
import PaginationSection from "./PaginationSection";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import PendingDispute from "./PendingDispute";
import ResolvedDispute from "./ResolvedDispute";
import InProgressDispute from "./InProgressDispute";

function Jobs() {

    const history = useHistory();
    const { endPointUrl, Token } = useContext(MyContext);
    const [resolved, setResolved] = useState(true);
    const [pending, setPending] = useState(false);
    const [inProgress, setInProgess] = useState(false);
    const [activeCard, setActiveCard] = useState("Pending");
    const [pendingLength, setPendingLength] = useState(0);
    const [resolveLength, setResolvedLength] = useState(0);
    const [inProgressLength, setInProgressLength] = useState(0);

    const getCount = async () => {
        try {
            const { data } = await axios.get(endPointUrl + "/" + "getDisputeCount");

            if (data.status) {
                const pendingCount = data.data.find(item => item.Status === "Pending")?.count;
                setPendingLength(pendingCount);

                const submitCount = data.data.find(item => item.Status === "Resolved")?.count;
                setResolvedLength(submitCount);

                const inProgess = data.data.find(item => item.Status === "InProgress")?.count;
                setInProgressLength(inProgess);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        getCount()
    }, [])

    const navigateToResolved = () => {
        setResolved(true); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
        setPending(false);
        setInProgess(false);
        setActiveCard("Resolved");
    };

    const navigateToPending = () => {
        setResolved(false); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
        setPending(true);
        setInProgess(false);
        setActiveCard("Pending");
    };


    const navigateToInProgress = () => {
        setResolved(false); // Set showPublishedJobs to true when the "Published Jobs" card is clicked
        setPending(false);
        setInProgess(true);
        setActiveCard("InProgress");
    };


    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="4" sm="12">
                        <Card className={`card-stats ${activeCard === "Pending" ? "active" : ""}`} onClick={navigateToPending} style={{ borderBottom: activeCard === "Pending" ? "4px solid #07b273" : "" }}>
                            <Card.Body>
                                <Row>
                                    <Col xs="4">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart text-warning"></i>
                                        </div>
                                    </Col>
                                    <Col sm="7">
                                        <div className="numbers">
                                            <p className="card-category">Pending</p>
                                            <Card.Title as="h3">{pendingLength || 0}</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg="4" sm="12">
                        <Card className={`card-stats ${activeCard === "InProgress" ? "active" : ""}`} onClick={navigateToInProgress} style={{ borderBottom: activeCard === "InProgress" ? "4px solid #07b273" : "" }}>
                            <Card.Body>
                                <Row>
                                    <Col xs="4">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart text-warning"></i>
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">In Progress</p>
                                            <Card.Title as="h3">{inProgressLength || 0}</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg="4" sm="12">
                        <Card className={`card-stats ${activeCard === "Resolved" ? "active" : ""}`} onClick={navigateToResolved} style={{ borderBottom: activeCard === "Resolved" ? "4px solid #07b273" : "" }}>
                            <Card.Body>
                                <Row>
                                    <Col xs="4">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart text-warning"></i>
                                        </div>
                                    </Col>
                                    <Col sm="7">
                                        <div className="numbers">
                                            <p className="card-category">Resolved</p>
                                            <Card.Title as="h3">{resolveLength || 0}</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="jobtable">

                {/* Render Publishedjobs component if showPublishedJobs is true */}
                {activeCard === "Resolved" ? <ResolvedDispute /> : <></>}
                {activeCard === "Pending" ? <PendingDispute /> : <></>}
                {activeCard === "InProgress" ? <InProgressDispute /> : <></>}

            </Container>
        </>
    );
}

export default Jobs;
