import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MyContext from "MyContext";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
// import ProjectDetails from "./EmployerProjectDetails";
import { BsArrowLeftShort } from "react-icons/bs"
import ProjectDetails from "./Projectdetailsss";
import { useHistory } from "react-router-dom";
import PaginationSection from "./PaginationSection";
import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function EmployerOngoingProjects(Id) {
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();
  const [ProjId, setID] = useState([]);
  const [project, setproject] = useState([]);;

  const getOngoingProjects = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/employerinProgressproject", {
        Awarded_By_ID: Id.ID,
        Token,
      });

      setallDetails(data.data);
      console.log(Id);

    } catch {
      console.log("get ongoing projects is not working");
    }
  };
  const data = React.useMemo(() => allDetails, [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ProjectID",
        accessor: "P_ID",
      },
      {
        Header: "Freelancer",
        accessor: "freelancers.Name",
        Cell: (({value}) => {
          return <div onClick={() => console.log('row data value => ', value)} style={{ cursor: 'pointer' }}>{value}</div>
        })
      },
      {
        Header: "Job Title",
        accessor: "jobs.Title",
      },
      {
        Header: "Project StartDate",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      // {
      //   Header: "Project Deadline",
      //   accessor: "Project_Deadline", Cell: ({ value }) => (
      //     <span>{moment(value).format('DD/MM/YYYY')}</span>
      //   ),
      // },
    ],
    []
  );
  useEffect(() => {
    getOngoingProjects(Id);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const ProjectSelect = (Id) => {
    setID(Id);
    // console.log(Id)
    let select = allDetails.filter((item) => {
      // console.log(cell);
      return (item.id === Id);
    })
    setproject(select);
    console.log(project)
  };

  const handleprojclick = (Id) => {
    // setShowDetails(true);
    // ProjectSelect(Id);
    console.log(Id);
    history.push(`/admin/Project-Details/${Id}`);
  };

  const CloseDetails = () => {
    setShowDetails(false);
  };



  return (
    <Container fluid>
      {!showDetails ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {/* {console.log(row.original.id)} */}
                          {row.cells.map((cell) => {
                            //  const { column } = cell.value;
                            //  let Id = column.Name;
                            //  console.log(cell.value);

                            return (<td onClick={() => handleprojclick(row.original.P_ID)} {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                          })}

                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />
            </Card>

          </Col>
        </Row>
      ) : (
        <>
          <div className="card">
            <span>
              <span className="close profile" onClick={() => CloseDetails()}>
                <BsArrowLeftShort style={{ fill: "#07b274", margin: "20px 0 0 30px" }} />
              </span>
              <span className='heading' style={{ display: "flex", justifyContent: "center", margin: '20px 0 10 0' }}>
                Project Details</span>
            </span>
            <ProjectDetails ID={ProjId} />
          </div>
        </>
      )}
    </Container>
  )
}

export default EmployerOngoingProjects;