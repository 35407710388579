import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import Selectant from 'react-select';
import { BsArrowLeftShort } from "react-icons/bs"
import { FaStar } from 'react-icons/fa';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
// import { OverlayTrigger, Popover } from 'react-bootstrap'
// import Popover from "react-popover";
import Switch from 'react-switch'; // Import the react-switch component
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
import PaginationSection from './PaginationSection';
import { useLocation } from "react-router-dom";

function ReviewDetails() {
  // const [formData, setFormData] = useState(initialFormData);
  const { endPointUrl, Token } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [Type, setType] = useState("Freelancer");
  const [selectedStars, setSelectedStars] = useState(0);
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [bidder, setbidder] = useState({});
  const location = useLocation();
  const history = useHistory();
  const Id = location.pathname.split('/').pop();

  const handleRatingClick = (value) => {
    if (rating === value) {
      // If the same star is clicked again, set the rating to 0 (deselect)
      setRating(0);
      setSelectedStars(0); // Notify the parent component that the rating is deselected
      setShowAll(false);
    } else {
      setRating(value);
      setSelectedStars(value); // Notify the parent component about the new rating
      setShowAll(false);
    }
  };
  const handleShowAllClick = () => {
    setShowAll(true);
    setSelectedStars(0); // Reset the selected stars when showing all
    setRating(0); // Reset the rating to 0 (deselect)
    setHover(0);
  };

  const getreviews = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/getReviewDetails", {
        Token,
        ID: Id,
      });
      setbidder(data.To)
      let filteredData = data.data;
      if (!showAll && selectedStars > 0) {
        filteredData = filteredData.filter((review) => review.Stars === selectedStars);
      }
      setallDetails(filteredData);
    } catch (error) {
      console.log("get reviews is not working", error);
    }
  };
  useEffect(() => {
    // console.log("Type state:", Type);
    getreviews(Id);
  }, [Type, selectedStars, showAll]);

  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: (row, index) => index + 1, // Auto-incremented serial number
      },
      {
        Header: dataToRender => {
          return (
            <span>
              {bidder.Type === "Freelancer" ? "Employers" : "Freelancers"}
            </span>
          );
        },
        accessor: "From_Name",
      },
      {
        Header: "Project",
        accessor: "P_Name",
      },
      {
        Header: "Review",
        accessor: "Review",
        Cell: ({ value }) => {
          const maxLength = 80;
          const truncatedReview = value.length > maxLength
            ? value.slice(0, maxLength) + "....." // If review is longer than maxLength, truncate it and add ellipsis
            : value; // If review is shorter than or equal to maxLength, use the whole review

          const [open, setOpen] = useState(false);
          const [anchorEl, setAnchorEl] = useState(null);

          const handleMouseEnter = (event) => {
            setOpen(true);
            setAnchorEl(event.currentTarget);
          };

          const handleMouseLeave = () => {
            setOpen(false);
          };

          return (
            <div
              style={{ position: 'relative' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {open && (
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  onClose={() => setOpen(false)}
                >
                  <Paper sx={{ maxWidth: 500, p: 1 }}>
                    <Typography sx={{ p: 2 }}>{value}</Typography>
                  </Paper>
                </Popover>
              )}
              <span>{truncatedReview}</span>
            </div>
          );

        },
      },
      {
        Header: "Ratings",
        accessor: "Stars",
        Cell: ({ value }) => {
          const numberOfStars = parseInt(value, 10);
          const stars = Array.from({ length: 5 }).map((_, index) => (
            <FaStar key={index} style={{ color: index + 1 <= numberOfStars ? "#FFD700" : "#C4C4C4", }} />
          ));
          return <span>{stars}</span>;
        },
      },
      {
        Header: "Review Date",
        accessor: "Review_Date",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
    ],
    [bidder]

  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;



  const navigateToFreelancerreview = () => {
    history.push("/admin/Reviews");
  };

  return (
    <>
      <div className="close profile" onClick={navigateToFreelancerreview} >
        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 80px 0" }} />
      </div>
      <Container fluid>

        <div className='Rating'>
          <div className="username">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <img
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                src={
                  bidder?.Photo === "" || bidder?.Photo == null
                    ?
                     `${endPointUrl}/images/${bidder?.Photo}`:null
                }
                alt="" />
              <div>
                <span
                  className="job-name"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {bidder.Name}
                </span>
              </div>
            </div>
          </div>

          <div className="star-rating">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: '18px', marginTop: '8px' }}>
                <strong>Filter by Rating:</strong>{' '}
              </span>
              {[...Array(5)].map((_, index) => {
                const starValue = index + 1;
                return (
                  <button
                    type="button"
                    key={starValue}
                    className={starValue <= (hover || rating) ? "on" : "off"}
                    onClick={() => handleRatingClick(starValue)}
                    onMouseEnter={() => setHover(starValue)}
                    onMouseLeave={() => setHover(0)}
                  >
                    <span className="star-rating-star"><FaStar /></span>
                  </button>
                );
              })}
              <span style={{ paddingLeft: "20px" }}>
                <Button onClick={handleShowAllClick}>Show All</Button></span>
            </div>
          </div>


        </div>
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header >
                <Card.Title as="h4">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="mx-2"
                      aria-label="Search"
                      value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                  </Form>

                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()} >
                  <table style={{ width: '100%', textAlign: 'center' }}>
                    <thead >
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr  {...row.getRowProps()}>
                            {/* {console.log(row.original.id)} */}
                            {row.cells.map((cell) => {
                              //  const { column } = cell.value;
                              //  let Id = column.Name;
                              //  console.log(cell.value);

                              return (<td {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                            })}
                          </tr>
                        );
                      }

                      )}
                    </tbody>
                  </table>
                </Table>
              </Card.Body>
              <PaginationSection
                pageIndex={pageIndex}
                pageCount={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
              />

            </Card>

          </Col>
        </Row>
      </Container>
    </>
  )
};

export default ReviewDetails;