import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs"
import BiddingCard from "./BiddingCard";
import Profile from "assets/img/default-avatar.png"
import { useLocation } from "react-router-dom";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';

import { Collapse } from "antd";
const { Panel } = Collapse;

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import EditJob from "./editJob";
import Dispute from "./Dispute";





const Jobdetailsss = () => {
  const { endPointUrl, Token } = useContext(MyContext);

  const [items, setitems] = useState({});
  const [detailview, setdetailview] = useState(false);
  const [user, setuser] = useState({});
  const location = useLocation();
  const [showEditForm, setShowEditForm] = useState(false);
  const Id = location.pathname.split('/').pop();

  const Jobs = async (Id) => {
    // console.log(Id);
    try {
      const { data } = await axios.post(endPointUrl + "/admin/getemployerJobpostedDetails", {
        JobID: Id,
        Token,
      });
      setitems(data.data.job);
      setuser(data.data.employer);
      console.log(data.data.employer)
    } catch {
      console.log("get all details projects is not working");
    }
  };


  console.log("SETITEMS", items);
  console.log("EMployer JOB", user);
  const navigateToFinance = () => {
    window.history.back();
  };
  //   const data = React.useMemo(() => allDetails, [allDetails]);

  useEffect(() => {
    Jobs(Id);
  }, []);

  const handleEditClick = () => {
    setShowEditForm(true);
  }

  return (
    <>
      <Container fluid>
        <div className="container-fluid d-flex align-items-center">
          <span className="close profile" onClick={navigateToFinance}>
            <BsArrowLeftShort style={{ fill: "#07b274", margin: "-20px 10px 0 -10px" }} />
          </span>
          <h6 className="heading-6" style={{ padding: "20px 0 20px 0" }}>Job Details</h6>
        </div>
        <Row>
          <Col md="8">
            <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

              <Card.Header style={{ backgroundColor: '#07b274' }}>
                <Card.Title as="h4">{items?.Title}</Card.Title>
                <hr></hr>
              </Card.Header>
              <Card.Body>

                <div className="about">
                  <span>{items?.ShortDesc}</span>
                </div>
                <hr></hr>
                <div className="about">
                  <h6>Detail Description</h6>
                  <span dangerouslySetInnerHTML={{ __html: items?.Description }}></span>
                </div>
              </Card.Body>
            </Card>


            <Card>
              {/* <Card.Header>
                <Card.Title as="h4"></Card.Title>
              </Card.Header>
             <Card.Body>

             </Card.Body>
              */}
              <BiddingCard ID={Id} />
            </Card>
          </Col>

          <Col md="4" style={{ marginTop: "15px" }}>
            <div className="compo2">
              <div className="profileConatiner">
                <div className="profile-details">
                  <div className="editimg" >
                    <EditJob Job={items} />
                  </div>
                  <img
                    src={
                      user?.Photo === "" || user?.Photo == null
                        ? Profile
                        : `${endPointUrl}/images/${user?.Photo}`
                    }
                    alt=""
                  // style={{width:'20%',display:"flex"}}
                  />
                  <span className="profilename">{user?.Name}</span>
                  <span className="profileDesignation">
                    {user?.Company}
                  </span>
                </div>
              </div>


              <div className="uploadDocs skillsset-card" style={{ marginTop: "10px" }}>
                <h5>
                  {items?.IsActive ? "Documents" : "Upload Documents"}{" "}
                </h5>
                {items?.IsActive ? (
                  <div className="uploadtags">
                    {(items.Documents
                      ? items.Documents.split(",")
                      : []
                    ).map((tag, index) => (
                      <span key={index}>
                        <a
                          href={`${endPointUrl}/images/${tag}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {tag}
                        </a>
                      </span>
                    ))}
                  </div>
                ) : (
                  // <DocumentUpload detail={items} getjobs={getjobs} />
                  <p>Document</p>
                )}
              </div>
              <hr style={{ width: "100%", background: "" }} />
              <div className="skillsset-card">
                <h5>Budget Details</h5>
                <small>
                  <span>${items?.Budget_From}-</span>
                  <span>${items?.Budget_To}</span>
                </small>
              </div>
              <hr style={{ width: "100%", background: "" }} />
              <div className="skillsset-card">
                {" "}
                <h5>Job Type</h5>
                <small>{items?.Type}</small>
              </div>
              <hr style={{ width: "100%", background: "" }} />
              <div className="skillsset-card">
                {" "}
                <h5>Project Type</h5>
                <small>{items?.ProjectType}</small>
              </div>
              <hr style={{ width: "100%", background: "" }} />
              <div className="skillsset-card">
                {" "}
                <h5>Skills</h5>
                <div className="skillview">
                  {((items?.SkillsRequired) ? items?.SkillsRequired : '').split(",")?.map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>Technology</h5>
                <div className="skillview">
                  {((items?.Technology) ? items?.Technology : '').split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>Category</h5>
                <div className="skillview">
                  {((items?.Category) ? items?.Category : '').split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>Language</h5>
                <div className="skillview">
                  {((items?.Language) ? items?.Language : '').split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>KeyWords</h5>
                <div className="skillview">
                  {(items?.Keywords)?.split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>


    </>
  )
}

export default Jobdetailsss;