
import React, { useState, useContext, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '.././assets/css/testimonial.css';
import axios from "axios";
import moment from 'moment';
import MyContext from "../MyContext";
import PaginationSection from './PaginationSection';
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Selectant from 'react-select';
import { message } from "antd";
import TimezoneSelect from "react-timezone-select";
import { useTable, usePagination, useGlobalFilter, useSortBy, } from "react-table";
import { Card, Table, Button, Container, Row, Col, Form } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Description } from "@mui/icons-material";


function DisplayProduct() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [product, setProduct] = useState([])
    const data = React.useMemo(() => product, [product]);
    const [showModel, setshowModel]=useState(false);
    const [allDetails, setallDetails] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [formData , setFormData] = useState([]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };


const handleshowModel = async (productID) => {
    const { data } = await axios.get(endPointUrl + "/getProduct", {
      Token,
    });
    console.log("productID",productID)
    const selectedProduct= data.data.find((test) => test.Product_ID === productID.original.Product_ID);
    if (!selectedProduct) {
      console.error("product not found");
      return;
    }
    setFormData({
     Product_ID: productID,
      ProductName: selectedProduct.ProductName || '',
      PluginInfo: selectedProduct.PluginInfo || '',
      Description:selectedProduct.Description||"",
      Image:selectedProduct.Image ||" ",


    });
    const product_id = selectedProduct.Product_ID.toString();
    const productsArray = product_id.split(", ").map(skill => ({ value: skill, label: skill }));
    setSelectedProducts( productsArray);
 
    setshowModel(true);
  };
  console.log("product",product);
  const handleUpdate = async (e, productID) => {
    e.preventDefault();
    console.log("ERnter");
    try {
        const selectedProductString = selectedProducts.map((skill) => skill.value).join(', ');

  const { data } = await axios.post(endPointUrl + "/updateProduct", {
    Token,
    Product_ID: productID,
    ...formData,
   Product:selectedProductString,
  });
  setallDetails(data.data);
  setshowModel(false);
  message.success("Text Updated successfully");
} catch (error) {
  console.log("Failed to update product:", error);
  message.error("Failed to update product.");
}

   

  };
 


    const deleteProduct = async (e, Product_ID) => {
        try {
            e.preventDefault();
            const response = await axios.post(endPointUrl + "/deleteProduct", { Token, Product_ID });
            if (response.data && response.data.status) {
                message.success("Product Deleted Successfully")
                getProduct()
            } else {
                message.warning("Something Went Wrong")
            }
        } catch (error) {
            message.warning("Something Went Wrong!")
            console.error("Error deleting Newsletter:", error);
        }
    }
    
     


    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Product Name",
                accessor: "ProductName",
            },
            {
                Header: "Plugin Info",
                accessor: "PluginInfo",
            },
            {
                Header: "Description",
                accessor: "Description",
            },
            {
                Header: "Image",
                accessor: "Image",
            },

            {
                Header: "Action",
                accessor: "NID",
                Cell: ({ row }) => (
                    <div className="d-flex flex-row">

                        <span
                            onClick={() => handleshowModel(row)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
                        </span>

                        <span
                            onClick={(e) => deleteProduct(e, row.original.Product_ID)}
                            style={{ cursor: "pointer" }}>
                            <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                        </span>
                    </div>
                ),
            },

        ],
        []
    );

    const getProduct = async () => {
        try {
            const { data } = await axios.get(endPointUrl + "/getProduct");
            if (data) {
                setProduct(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getProduct()
    }, [])

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page: tablePage, canPreviousPage, canNextPage, pageOptions, nextPage, previousPage, setPageSize, setGlobalFilter, gotoPage, state, } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 5 }, autoResetPage: false, }, useGlobalFilter, useSortBy, usePagination);
    const { globalFilter, pageIndex, pageSize } = state;

    const [productName, setProductName] = useState('')
    const [pluginInfo, setPluginInfo] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [addShow, setAddShow] = useState(false)
    const [error, setError] = useState({})

    function handleError(label, error) {
        setError((prev) => ({ ...prev, [label]: error }))
    }


    function validate() {
        let error = false;
        if (productName.length === 0) {
            error = true;
            handleError('productName', 'Product Name is Required');
        }
        if (pluginInfo.length === 0) {
            error = true;
            handleError('pluginInfo', 'Plugin INfo is Required');
        }
        if (description.length === 0) {
            error = true;
            handleError('description', 'Description is Required');
        }
        if (!image || image.length === 0) {
            error = true;
            handleError('image', 'image Number is Required')
        }
        return error;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const error = validate()
        if (error === false) {
            try {
                const formData = new FormData()
                formData.append("Image", image);
                formData.append("ProductName", productName);
                formData.append("PluginInfo", pluginInfo);
                formData.append("Description", description);

                const { data } = await axios.post(endPointUrl + "/AddProduct", formData)
                if (data.status) {
                    setAddShow(false);
                    message.success("Data Submitted Successfully");
                    getProduct()
                }
            } catch (error) {
                console.log("Erro While Submitting Data in Database");
            }
        }
    }


    return (
        <div>
         {!showModel ? <Container fluid>   
        <div>
            {addShow ?
                <div>
                    <Container fluid>
                        <div className="container-fluid d-flex justify-content-between">
                            <h6 className="heading-6">
                                Product
                            </h6>
                            <Button onClick={() => setAddShow(!addShow)}>Show Product</Button>
                        </div>

                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md="12">
                                    <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                                        <Card.Header style={{ backgroundColor: '#07b274' }}>
                                            <Card.Title as="h4">Fill Details</Card.Title>
                                            <hr></hr>
                                        </Card.Header>
                                    </Card>

                                    <Card.Body>
                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <Form.Group>
                                                    <label>Proudct Name</label>
                                                    <Form.Control
                                                        name="Product_Name"
                                                        placeholder="Product Name"
                                                        type="text"
                                                        value={productName}
                                                        onChange={(e) => {
                                                            if (e.target.value.length <= 20) {
                                                                setProductName(e.target.value)
                                                                setError({ ...error, productName: "" })
                                                            }
                                                            else
                                                                setError({ ...error, productName: "Product Name must be smaller then 20 characters" })

                                                        }}
                                                        onFocus={() => setError({ ...error, productName: "" })}
                                                    ></Form.Control>
                                                    <small style={{ color: "#ec1c24" }}>{error.productName}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Plugin Info</label>
                                                    <Form.Control
                                                        name="pluginInfo"
                                                        placeholder="Plugin Info"
                                                        type="text"
                                                        value={pluginInfo}
                                                        onChange={(e) => {
                                                            setPluginInfo(e.target.value)
                                                        }}
                                                        onFocus={() => setError({ ...error, pluginInfo: "" })}
                                                    ></Form.Control>
                                                    <small style={{ color: "#ec1c24" }}>{error.pluginInfo}</small>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <Form.Group>
                                                    <label>Product Image</label>
                                                    <Form.Control
                                                        name="productImage"
                                                        type="file"
                                                        onChange={(e) => {
                                                            setImage(e.target.files[0])
                                                        }}
                                                        onFocus={() => setError({ ...error, image: "" })}
                                                    ></Form.Control>
                                                    <small style={{ color: "#ec1c24" }}>{error.image}</small>
                                                    {/* <div>
                                                        <PhoneInput
                                                            inputStyle={{ width: '100%' }}
                                                            country={'in'}
                                                            value={phone}
                                                            onChange={(phone) => setPhone(phone)}
                                                            onFocus={() => setError({ ...error, Phone: "" })}
                                                        />
                                                        <small style={{ color: "#ec1c24" }}>{error.Phone}</small>
                                                    </div>

                                                    <small style={{ color: "#ec1c24" }}>{error.phone}</small> */}
                                                </Form.Group>
                                            </Col>


                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Description</label>
                                                    <Form.Control
                                                        name="description"
                                                        placeholder="Description"
                                                        type="text"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        onFocus={() => setError({ ...error, description: "" })}
                                                    ></Form.Control>
                                                    <small style={{ color: "#ec1c24" }}>{error.description}</small>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <button
                                                className="btn mb-1 btn-lg mr-3"
                                                type="submit"
                                                variant="info"
                                                style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px", cursor: "pointer" }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
                :
                <Container fluid>
                    <Row>
                        <h1 style={{ width: "100%" }}>Product</h1>
                        <Col md="12">
                            <Card className="stripped-tabled-with-hover">
                                <Card.Header style={{ paddingTop: "5px" }}>
                                    <Card.Title as="h4">
                                        <Form className="d-flex">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                className="mx-2"
                                                aria-label="Search"
                                                value={globalFilter || ''}
                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                            />

                                            <Button onClick={() => setAddShow(!addShow)}>Add Product</Button>
                                        </Form>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <Table className="table-hover table-striped" {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps(), {

                                                        })}>
                                                            {column.render("Header")}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? " 🔽"
                                                                        : " 🔼"
                                                                    : ""}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                            {tablePage.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell) => (
                                                            <td {...cell.getCellProps({

                                                            })}>{cell.render("Cell")}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <PaginationSection
                                    pageIndex={pageIndex}
                                    pageCount={pageOptions.length}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pageOptions={pageOptions}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </div >
        </Container> :
        <div>
        <Container className='addskilltest' fluid>
          <div className="container-fluid d-flex gap-3 align-items-center">
            <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px,", fontSize: "40px" }} onClick={(() => setshowModel(false))} />
            <h6 className="heading-6">Updated Product Details</h6>
          </div>
          <Row>
            <Col md="12">
              <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>
                <Card.Header style={{ backgroundColor: '#07b274' }}>
                  <Card.Title as="h4">Fill Details</Card.Title>
                  <hr></hr>
                </Card.Header>
                <Card.Body>
                  <Form
                    onSubmit={((e) => { handleUpdate(e, formData.Product_ID) })}
                  >
                    <Row>
                      <Col className="pr-1" md="6">
                        <Form.Group>
                          <label>Title</label>
                          <Form.Control
                            name="ProductName"
                            placeholder="Title"
                            type="text"
                            value={formData.ProductName}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      

                      <Col className="pl-1" md="6" >
                        <Form.Group>
                          <label>PluginInfo</label>

                          <Form.Control
                            name="PluginInfo"
                            placeholder="PluginInfo"
                            type="text"
                            value={formData.PluginInfo}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" md="6" >
                        <Form.Group>
                          <label>Description</label>

                          <Form.Control
                            name="Descriptiopn"
                            placeholder="Description"
                            type="text"
                            value={formData.Description}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" md="6" >
                        <Form.Group>
                          <label>Image of product</label>

                          <Form.Control
                            name="Image"
                            placeholder="Image"
                            type="file"
                            // value={formData.Image}
                            onChange={(e) => {
                                setImage(e.target.files[0])
                            }}
                            
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            
            
          </Row>
        </Container>
      </div>
           
        }
        </div>

        
    );
}

export default DisplayProduct;