
import React, { useState, useContext, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '.././assets/css/testimonial.css';
import axios from "axios";
import moment from 'moment';
import MyContext from "../MyContext";
import PaginationSection from './PaginationSection';
import Switch from 'react-switch'; // Import the react-switch component
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import {
    Card,
    Table,
    Button,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";



function Newsletter() {
    const [N_Email, setN_Email] = useState("");
    const { endPointUrl, Token } = useContext(MyContext);
    const [newsletter, setNewsletter] = useState([]);
    const [showForm, setShowForm] = useState("");
    const [isPublished, setIsPublished] = useState(false); // State to handle the published status


    // const handleshowform = async (value) => {
    //     setFormData({
    //         GlobalReview_Name: '',
    //         GlobalReview_Date: '',
    //         GlobalReview_Review: '',
    //         Stars: '',
    //         GlobalReview_Review_Status: false,
    //     });
    //     setShowForm(value);
    //     setIsPublished(false)
    // }

    const getNewsletter = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/newsletterdatafind", { Token });
            if (data) {
                setNewsletter(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const deleteNewsletter = async (e, NID) => {
        e.preventDefault();
        try {
            // Make an API call to delete the testimonial
            const response = await axios.post(endPointUrl + "/admin/getNewsletterDelete", { Token, NID });
            if (response.data && response.data.data) {
                // Filter out the deleted testimonial from the state
                setNewsletter((prevNewsletters) =>
                    prevNewsletters.filter((newsletter) => newsletter.NID !== NID)
                );
            } else {
                console.error("Error deleting Newsletter:", response.data.message);
            }
        } catch (error) {
            console.error("Error deleting Newsletter:", error);
        }
    };


    //Convert json data in csv formate
    const exportToCSV = () => {
        const csvRows = [];

        // Adding headers to CSV
        const headers = Object.keys(newsletter[0]); // Assuming the first element in newsletter contains all keys
        csvRows.push(headers.join(','));

        // Adding data rows to CSV
        newsletter.forEach((row) => {
            const values = headers.map((header) => {
                return `"${row[header]}"`;
            });
            csvRows.push(values.join(','));
        });

        // Create CSV file
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'newsletter.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const data = React.useMemo(() => newsletter, [newsletter]);
    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Email",
                accessor: "N_Email",
            },
            {
                Header: "Date",
                accessor: "N_Date",
                Cell: ({ row }) => {
                    const date = new Date(row.original.N_Date);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    return <span>{date.toLocaleDateString('en-GB', options)}</span>;
                },
            },
            {
                Header: "Action",
                accessor: "NID",
                Cell: ({ row }) => (

                    <span
                        onClick={(e) => deleteNewsletter(e, row.original.NID)}
                        style={{ cursor: "pointer" }}>
                        <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                    </span>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        getNewsletter();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page: tablePage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <Container fluid>
            <Row>
                <h1 style={{ width: "100%" }}>Newsletter</h1>
                <Col md="12">
                    <Card className="stripped-tabled-with-hover">
                        <Card.Header style={{ paddingTop: "5px" }}>
                            <Card.Title as="h4">
                                <Form className="d-flex">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        className="mx-2"
                                        aria-label="Search"
                                        value={globalFilter || ''}
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />

                                    <Button onClick={() => exportToCSV()}>Export CSV</Button>
                                </Form>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps(), {

                                                })}>
                                                    {column.render("Header")}
                                                    {/* Add a sort direction indicator */}
                                                    <span>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? " 🔽"
                                                                : " 🔼"
                                                            : ""}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                    {tablePage.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <td {...cell.getCellProps({

                                                    })}>{cell.render("Cell")}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <PaginationSection
                            pageIndex={pageIndex}
                            pageCount={pageOptions.length}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pageOptions={pageOptions}
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Newsletter;

